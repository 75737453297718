<p-toast></p-toast>

<app-article-list-filters
  #articleFilters
  [service]="service"
  [tipologies]="tipologies"
  [categoryItems]="categoryItems"
  [tipologyItems]="tipologyItems"
  [locale]="langIt"
  [archivedFilter]="archivedFilter"
  [clearTipology]="clearTipology"
  [clearCategory]="clearCategory"
  [selectedTipology]="selectedTipology"
  [selectedCategory]="selectedCategory"
  (setArchivedFilter)="setArchivedFilter($event)"
  (filterSelected)="filterSelected($event)"
  (cerca)="cerca(table)"
  (reset)="reset(table)"
></app-article-list-filters>

<div class="main-content clearfix">
  <div class="list-wrapper clearfix">
    <div class="content clearfix">
      <div class="row">
        <div class="col-sm-8">
          <h4>
            <i aria-hidden="true" class="fa fa-file-text"></i>
            Articoli
          </h4>
        </div>
        <div class="col-sm-4">
          <button class="btn btn-add pull-right" type="button" (click)="new()" label="">
            <i class="fa fa-plus-circle" aria-hidden="true"></i>
            Aggiungi
          </button>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="clearfix"></div>
      <div class="filter-wrapper clearfix">
        <button
          type="text"
          class="btn btn-filter"
          label="Basic"
          appendTo="body"
          (click)="articleFilters.overlayPanel.toggle($event)"
        >
          <i class="fa fa-sliders" aria-hidden="true"></i>
          Cerca
        </button>

        <div class="tags-wrapper">
          <div class="tag-item" *ngIf="service.search.like.internal_code">
            <strong>cod. art. interno:</strong>
            {{ service.search.like.internal_code }}
            <button
              (click)="service.search.like.internal_code = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.like.name">
            <strong>cod. articolo:</strong>
            {{ service.search.like.name }}
            <button (click)="service.search.like.name = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.like.description">
            <strong>descrizione:</strong>
            {{ service.search.like.description }}
            <button
              (click)="service.search.like.description = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.like.codiceGara">
            <strong>codice gara:</strong>
            {{ service.search.like.codiceGara }}
            <button
              (click)="service.search.like.codiceGara = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.obj.category_uuid">
            <strong>categoria:</strong>
            {{ selectedCategory?.name }}
            <button (click)="clearCategory(); reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.obj.tipology_uuid">
            <strong>tipologia:</strong>
            {{ selectedTipology?.name }}
            <button (click)="clearTipology(); reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.obj.closed">
            <strong>congelato:</strong>
            {{ service.search.obj.closed ? 'Si' : 'No' }}
            <button (click)="service.search.obj.closed = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="archivedFilter">
            <strong>archiviato:</strong>
            {{ archivedFilter ? 'Si' : 'No' }}
            <button
              (click)="archivedFilter = false; service.search.obj.active = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <app-article-property-tag-filter
            [service]="service"
            (reload)="reload(table)"
          ></app-article-property-tag-filter>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-body list">
          <div class="row">
            <p-table
              [value]="model"
              [lazy]="true"
              [loading]="loading"
              (onLazyLoad)="lazyLoad($event)"
              [paginator]="true"
              [responsive]="true"
              [rows]="service.search.pageSize"
              [totalRecords]="service.listSize"
              [resizableColumns]="true"
              stateStorage="session"
              [stateKey]="path + '_table'"
              #table
            >
              <ng-template pTemplate="header" let-columns class="search-filter">
                <tr class="sort-header">
                  <th class="RowIndex"></th>
                  <th pResizableColumn [pSortableColumn]="'internal_code'">
                    Cod. art. Interno
                    <p-sortIcon [field]="'internal_code'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'name'">
                    Cod. Articolo
                    <p-sortIcon [field]="'name'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'codiceGara'">
                    Codice Gara
                    <p-sortIcon [field]="'codiceGara'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'description'">
                    Descrizione
                    <p-sortIcon [field]="'description'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'category_uuid'">
                    Categoria
                    <p-sortIcon [field]="'category_uuid'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'tipology_uuid'">
                    Tipologia
                    <p-sortIcon [field]="'tipology_uuid'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'closed'">
                    Congelato
                    <p-sortIcon [field]="'closed'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'archived'">
                    Archiviato
                    <p-sortIcon [field]="'archived'"></p-sortIcon>
                  </th>
                  <th pResizableColumn></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="colspan">Nessun risultato.</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                  <td class="RowIndex">{{ rowIndex + 1 }}</td>
                  <td class="ui-resizable-column">
                    {{ rowData.internal_code }}
                  </td>
                  <td class="ui-resizable-column">
                    {{ rowData.name }}
                  </td>
                  <td class="ui-resizable-column">
                    {{ rowData.codiceGara }}
                  </td>
                  <td class="ui-resizable-column">
                    {{ rowData.description }}
                  </td>
                  <td class="ui-resizable-column">
                    {{ rowData.category_name }}
                  </td>
                  <td class="ui-resizable-column">
                    {{ rowData.tipology_name }}
                  </td>
                  <td class="ui-resizable-column">
                    <i class="fa fa-{{ rowData.closed ? 'check' : 'times' }}"></i>
                  </td>
                  <td class="ui-resizable-column">
                    <i class="fa fa-{{ !rowData.active ? 'check' : 'times' }}"></i>
                  </td>
                  <td class="ui-resizable-column">
                    <div class="p-d-flex btn-group">
                      <button
                        *ngIf="!rowData.closed"
                        type="button"
                        pButton
                        pTooltip="Modifica"
                        tooltipPosition="top"
                        (click)="edit(rowData, { articleIndex: +rowIndex })"
                        class="btn btn-success btn-sm"
                        icon="fa fa-edit"
                      ></button>

                      <button
                        type="button"
                        pButton
                        pTooltip="Visualizza"
                        tooltipPosition="top"
                        (click)="view(rowData)"
                        class="btn btn-info btn-sm"
                        icon="fa fa-eye"
                      ></button>

                      <button
                        type="button"
                        pButton
                        pTooltip="Clona"
                        tooltipPosition="top"
                        (click)="clone(rowData)"
                        class="btn btn-default btn-sm"
                        icon="fa fa-clone"
                      ></button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
