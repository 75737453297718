<p-toast></p-toast>

<div class="main-content clearfix">
  <div class="list-wrapper clearfix">
    <div class="p-d-flex p-jc-between p-mb-5">
      <h4>
        <i aria-hidden="true" class="fa fa-th-list"></i>
        Riepilogo modifiche proprietà
      </h4>
      <div class="btn-group p-d-flex">
        <button type="button" class="btn btn-success" (click)="salva()">
          Salva e Modifica ancora
        </button>
        <button type="button" class="btn btn-success" (click)="salvaGotoGara()">
          Salva e vai alla Gara
        </button>
        <button type="button" class="btn btn-warning" (click)="goBack()">Torna indietro</button>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="panel panel-default">
      <div class="panel-body view">
        <div>
          <ng-container *ngIf="printablePropertiesPatch?.length > 0; else elseBlock">
            <div
              class="ui-table ui-widget ui-table-responsive ui-table-resizable ui-table-resizable-fit"
            >
              <div class="ui-table-wrapper">
                <div class="fixed-header-table">
                  <p-table
                    [value]="printablePropertiesPatch"
                    [paginator]="true"
                    [rows]="20"
                    [totalRecords]="printablePropertiesPatch.length"
                    [resizableColumns]="true"
                    class="table table-striped massiva-table"
                    #table
                  >
                    <ng-template pTemplate="header" let-columns>
                      <tr class="sort-header">
                        <th class="ui-sortable-column ui-resizable-column"></th>
                        <th class="ui-sortable-column ui-resizable-column">Articolo</th>
                        <th
                          class="ui-sortable-column ui-resizable-column"
                          *ngFor="
                            let property of printablePropertiesPatch[0].properties | orderBy: 'name'
                          "
                        >
                          {{ property?.label | titlecase }}
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      class="ui-table-tbody"
                      let-rowData
                      let-rowIndex="rowIndex"
                    >
                      <tr>
                        <td class="ui-resizable-column">{{ rowIndex + 1 }}</td>
                        <td class="ui-resizable-column">{{ printablesMap?.get(rowData.uuid) }}</td>
                        <td
                          class="ui-resizable-column"
                          *ngFor="
                            let property of rowData.properties | orderBy: 'name';
                            let j = index
                          "
                        >
                          <div>
                            <div class="col-sm-9" [ngSwitch]="property?.property_type">
                              <div *ngSwitchCase="'IMAGE_VALUE'">
                                <img
                                  *ngIf="property?.value"
                                  [src]="property?.value + '?format=300x300' | safe: 'resourceUrl'"
                                  style="height: 100px;"
                                />
                              </div>
                              <div *ngSwitchCase="'ATTACH_VALUE'">
                                <a
                                  href="{{ property?.value }}"
                                  target="_blank"
                                  *ngIf="property?.value"
                                >
                                  {{ property?.extra?.fileName }}
                                  <i class="fa fa-download"></i>
                                </a>
                              </div>
                              <div *ngSwitchCase="'BARCODE'">
                                <img
                                  *ngIf="property?.value"
                                  [src]="property?.value | safe: 'resourceUrl'"
                                  class="img-responsive"
                                />
                              </div>
                              <div *ngSwitchCase="'MULTISELECT'" class="filter-wrapper">
                                <div class="tags-wrapper" *ngIf="property.extra.choices">
                                  <span
                                    class="tag-item"
                                    *ngFor="let choice of property.extra.choices"
                                  >
                                    {{ choice }}
                                  </span>
                                </div>
                              </div>
                              <div *ngSwitchCase="'ICON_VALUE'">
                                <i class="{{ property?.value }}"></i>
                              </div>
                              <div *ngSwitchCase="'DATE_VALUE'">
                                <span *ngIf="property?.value">
                                  {{ property?.value | date: 'dd/MM/yyyy' }}
                                </span>
                                <span *ngIf="!property?.value">-</span>
                              </div>
                              <div
                                *ngSwitchCase="'LONG_TEXT'"
                                [innerHTML]="property?.value | safe: 'html'"
                              ></div>
                              <div *ngSwitchCase="'BOOL_VALUE'">
                                <p-inputSwitch
                                  [ngModel]="property?.value"
                                  inputStyleClass="form-control"
                                  [disabled]="true"
                                ></p-inputSwitch>
                              </div>
                              <div *ngSwitchDefault>
                                <span *ngSwitchDefault>{{ property?.value }}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #elseBlock>
            <div class="col-sm-12">
              <div class="alert alart-warning text-center">
                La tua Sessione è scaduta, verrai reindirizzato alla pagina precedente...
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
