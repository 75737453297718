import { SelectItem } from 'primeng/api';

export type propertyType =
    | 'STR_VALUE'
    | 'LONG_TEXT'
    | 'BOOL_VALUE'
    | 'TUPLE_VALUE'
    | 'NUMBER_VALUE'
    | 'ICON_VALUE'
    | 'IMAGE_VALUE'
    | 'COLOR_VALUE'
    | 'BARCODE'
    | 'MULTISELECT'
    | 'LISTABLE'
    | 'ATTACH_VALUE'
    | 'DATE_VALUE'
    | 'SUMMABLE'
    | 'SOTTRAIBILE'
    | 'MULTIPLIABLE'
    | 'DIVISIBLE'
    | 'GLOBAL_SUMMABLE'
    | 'GROUP_SUMMABLE'
    | 'GROUPABLE';

export const filtrablePropertyTypes: propertyType[] = [
    'STR_VALUE',
    'NUMBER_VALUE',
    'DATE_VALUE',
    'LONG_TEXT',
    'LISTABLE',
];

export const propertyTypesPrintableItems: SelectItem[] = [
    { label: 'Allegato', value: 'ATTACH_VALUE' },
    { label: 'Area di testo', value: 'LONG_TEXT' },
    { label: 'Booleano', value: 'BOOL_VALUE' },
    { label: 'Codice a barre', value: 'BARCODE' },
    { label: 'Colore', value: 'COLOR_VALUE' },
    { label: 'Data', value: 'DATE_VALUE' },
    { label: 'Icona', value: 'ICON_VALUE' },
    { label: 'Immagine', value: 'IMAGE_VALUE' },
    { label: 'Lista', value: 'TUPLE_VALUE' },
    { label: 'Listabile', value: 'LISTABLE' },
    { label: 'Numero', value: 'NUMBER_VALUE' },
    { label: 'Selezione Multipla', value: 'MULTISELECT' },
    { label: 'Stringa', value: 'STR_VALUE' },

    {
        label: '-- Operazioni --',
        value: 'this is useless, but value attribute must defined cause of typescript',
        disabled: true,
    },
    { label: 'Somma', value: 'SUMMABLE' },
    { label: 'Sottrazione', value: 'SOTTRAIBILE' },
    { label: 'Moltiplicazione', value: 'MULTIPLIABLE' },
    { label: 'Divisione', value: 'DIVISIBLE' },
];

export const propertyTypesCatalogueItems: SelectItem[] = [
    { label: 'Area di testo', value: 'LONG_TEXT' },
    { label: 'Immagine', value: 'IMAGE_VALUE' },
    { label: 'Numero', value: 'NUMBER_VALUE' },
    { label: 'Stringa', value: 'STR_VALUE' },
    {
        label: '-- Operazioni --',
        value: 'this is useless, but value attribute must defined cause of typescript',
        disabled: true,
    },
    { label: 'Raggruppamento', value: 'GROUPABLE' },
    { label: 'Somma Globale', value: 'GLOBAL_SUMMABLE' },
    { label: 'Raggruppamento e Somma Globale', value: 'GROUP_SUMMABLE' },
];

export const propertyTypesTemplateItems: SelectItem[] = [
    { label: 'Allegato', value: 'ATTACH_VALUE' },
    { label: 'Area di testo', value: 'LONG_TEXT' },
    { label: 'Immagine', value: 'IMAGE_VALUE' },
    { label: 'Numero', value: 'NUMBER_VALUE' },
    { label: 'Stringa', value: 'STR_VALUE' },
];
