import { PrintTemplate } from './print-template';
import { PropertyValue } from './property-value';

export class Tipology {
    public uuid: string;
    public name: string;
    public max_columns: number;
    public category_uuid: string;
    public properties: PropertyValue[];

    public active: boolean;

    public templates: PrintTemplate[];
    public default_template: PrintTemplate;

    public page_content: string;

    constructor() {}
}
