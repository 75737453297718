import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { CatalogueService } from '../../service/catalogue.service';
import { ContainerService } from '../../service/container.service';

import { AbstractListComponent } from '../../common/abstract-list-component';
import { Catalogue } from '../../model/catalogue';
import { takeUntil } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector: 'app-catalogue-list',
    templateUrl: './catalogue-list.component.html',
    styleUrls: ['./catalogue-list.component.css'],
})
export class CatalogueListComponent extends AbstractListComponent<Catalogue> implements OnInit {
    public gare: Map<string, string> = new Map<string, string>();
    archivedFilter = false;

    roles: any;
    primaryRole: any;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected catalogueService: CatalogueService,
        public containerService: ContainerService,
        protected keycloakService: KeycloakService
    ) {
        super(router, catalogueService, 'catalogues');
    }

    ngOnInit() {
        this.loading = true;
        this.archivedFilter = this.service.search.obj.active === false;
    }

    clone(catalogue) {
        this.router.navigate(['/' + this.path + '/clone/' + catalogue.uuid]);
    }

    getId() {
        return this.element.uuid;
    }

    public new() {
        this.router.navigate(['/' + this.path + '/new']);
    }

    addArticles(uuid: string) {
        this.router.navigate([`/${this.path}/${uuid}/aggiungi-articoli`]);
    }

    postList() {
        for (const catalogue of this.model) {
            const container_uuid = catalogue.container_uuid;
            if (container_uuid) {
                if (this.gare.has(container_uuid)) {
                    catalogue['gara'] = this.gare.get(container_uuid);
                } else {
                    // mi manca
                    this.containerService
                        .find(catalogue.container_uuid)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(
                            (cnt) => {
                                // ora ce l'ho
                                this.gare[cnt.codiceGara] = cnt.name;
                                catalogue['gara'] = cnt.name;
                            },
                            (error) => {
                                console.error(error);
                                this.addError('Errore generico');
                            }
                        );
                }
            }
        }
    }

    public handleFilterSenzaCodiceGara(event) {
        if (event.checked) {
            this.service.search.not.container_uuid = `true`;
            this.service.search.like.container_uuid = null;
        } else {
            this.service.search.not.container_uuid = null;
        }
    }

    onFilterArchived(event: any) {
        this.archivedFilter = event.checked;
        this.service.search.obj.active = event.checked ? false : null;
    }
}
