import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'special',
})
export class SpecialcharsPipe implements PipeTransform {
    public transform(value) {
        // return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        // return value.replace(/\s/g, '').toLowerCase();
        return value
            .replace(/\s/g, '')
            .replace(/\./gi, '')
            .replace(/[^a-zA-Z ]/g, '')
            .toLowerCase();
    }
}
