import { Table } from 'primeng/table';
import { LazyLoadEvent, Message } from 'primeng/api';
import { AbstractService } from './abstract-service';
import { Router } from '@angular/router';
import { OnInit, OnDestroy, Directive } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { langIt } from '../constants/calendar-it';
@Directive()
export abstract class AbstractListComponent<T> implements OnInit, OnDestroy {
    msgs: Message[] = [];
    protected destroy$: Subject<void> = new Subject<void>();

    element: T = null;
    errorMessage: string;
    model: T[] = [];
    listSize: number;
    public colspan = 3;

    public langIt = { ...langIt };

    protected firstReload: boolean;
    public filters: any;
    loading: boolean;

    constructor(
        protected router: Router,
        public service: AbstractService<T>,
        public path?: string
    ) {}

    ngOnInit() {
        this.service.buildSearch();
        this.loadSavedFilters();
        this.firstReload = true;
    }

    public loaddata(firstReload: boolean, datatable?: any) {
        this.loading = true;
        this.preLoaddata();
        this.service
            .getList()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (model) => {
                    this.model = model as T[];
                    console.log(model);
                    this.listSize = this.service.listSize;
                    this.postList();
                    this.loading = false;
                },
                (error) => {
                    this.addError('Errore nel caricamento dei dati.' + (error?.msg ?? error ?? ''));
                    this.errorMessage = error;
                    this.loading = false;
                }
            );
    }

    public preLoaddata() {}

    public lazyLoad(event: LazyLoadEvent, datatable?: any) {
        if (!this.firstReload) {
            this.service.search.startRow = event.first;
        }
        this.service.search.pageSize = event.rows;

        this.preLoad(event);
        this.loaddata(this.firstReload, datatable);
        if (this.firstReload) {
            this.firstReload = false;
        }
    }

    public refresh(datatable: Table) {
        this.clearMsgs();
        datatable.reset();
    }

    public reload(datatable: Table) {
        this.service.search.startRow = 0;
        this.refresh(datatable);
    }

    public reset(datatable: Table) {
        this.service.buildSearch();
        this.refresh(datatable);
    }

    public newElement(): T {
        throw new Error('override this');
    }

    public onRowSelect(event: T, focusable: any) {
        this.element = event;
        if (focusable) {
            focusable.focus();
        }
    }

    public getNavigateOnView() {
        return null;
    }

    public getNavigateOnEdit() {
        return null;
    }

    public postSave() {}

    public postUpdate() {}

    public postDelete() {}

    public save() {
        this.clearMsgs();
        this.service
            .persist(this.element)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (element) => {
                    this.addInfo('Salvataggio completato con successo. ');
                    this.element = this.newElement();
                    this.loaddata(false);
                    this.postSave();
                },
                (error) => {
                    this.addError('Impossibile completare il salvataggio. Si prega di riprovare. ');
                }
            );
    }

    public undo(focusable: any) {
        this.clearMsgs();
        this.element = this.newElement();
        if (focusable) {
            focusable.focus();
        }
    }

    public delete(element: T) {
        this.clearMsgs();
        this.service
            .delete(this.getId())
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (result) => {
                    this.addInfo('Eliminazione completata con successo. ');
                    this.element = this.newElement();
                    this.loaddata(false);
                    this.postDelete();
                },
                (error) => {
                    this.addError('Impossibile completare la eliminazione. ');
                }
            );
    }

    public update() {
        this.clearMsgs();
        this.service
            .update(this.element)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (element) => {
                    this.addInfo('Modifica completata con successo. ');
                    this.element = this.newElement();
                    this.loaddata(false);
                    this.postUpdate();
                },
                (error) => {
                    this.addError('Impossibile completare la modifica. ');
                }
            );
    }

    public postList() {}

    public addInfo(message: string) {
        this.msgs.push({
            severity: 'info',
            summary: 'Informazioni: ',
            detail: message,
        });
    }

    public addWarn(message: string) {
        this.msgs.push({
            severity: 'warn',
            summary: 'Attenzione: ',
            detail: message,
        });
    }

    public addError(message: string) {
        this.msgs.push({
            severity: 'error',
            summary: 'Errore: ',
            detail: message,
        });
        this.service.addMessage({
            severity: 'error',
            summary: 'Errore',
            detail: message,
        });
    }

    public clearMsgs() {
        this.msgs = [];
    }

    public view(element: T) {
        this.element = element;
        localStorage.setItem(`${this.path}_search`, JSON.stringify(this.service.search));
        this.router.navigate(['/' + this.path + '/view', this.getId()]);
    }

    public loadSavedFilters() {
        const item = localStorage.getItem(`${this.path}_search`);
        if (item) {
            const search = JSON.parse(item);
            this.service.search = { ...search };
            localStorage.removeItem(`${this.path}_search`);
        }
    }

    public edit(element: T, metadata?: any) {
        this.element = element;
        this.router.navigate(['/' + this.path + '/edit', this.getId()], { queryParams: metadata });
    }

    getId() {
        return this.element['uuid'];
    }

    protected preLoad(event: LazyLoadEvent, datatable?: any) {
        if (event.sortField) {
            this.service.search.orderBy =
                event.sortField + (event.sortOrder > 0 ? ' ASC' : ' DESC');
        }
        this.manageFilters(event);
    }

    protected manageFilters(event: LazyLoadEvent) {}

    public resetAllFilters(table: Table) {
        if (table.filters) {
            this.filters = {
                nome: null,
            };
            table.filters = {};
            table.onLazyLoad.emit(table.createLazyLoadMetadata());
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
