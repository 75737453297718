import { OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';

import { ActivatedRoute, Router } from '@angular/router';
import { PrintableService } from '../../service/printable.service';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';
import { SelectItem } from 'primeng/api';
import { statesAsArray } from '../../model/enum/state';

import { AbstractListComponent } from '../../common/abstract-list-component';
import { Printable } from '../../model/printable';
import { Category } from 'src/app/model/category';
import { Tipology } from 'src/app/model/tipology';
import { ListableService } from 'src/app/service/listable.service';
import { ArticleListFiltersComponent } from '../filters/article-list-filters/article-list-filters.component';

export abstract class PrintableListComponent extends AbstractListComponent<Printable>
    implements OnInit {
    @ViewChild('table') tableRef: Table;
    @ViewChild('articleFilters', { static: false })
    articleFiltersCmp: ArticleListFiltersComponent;
    categories: Map<string, Category> = new Map<string, Category>();
    tipologies: Map<string, Tipology> = new Map<string, Tipology>();
    categoryItems: Category[];
    tipologyItems: Tipology[];
    stateItems: SelectItem[] = [];

    selectedCategory: Category = null;
    selectedTipology: Tipology = null;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        public path: string,
        protected printableService: PrintableService,
        protected categoryService: CategoryService,
        protected tipologyService: TipologyService,
        protected listableService: ListableService
    ) {
        super(router, printableService, path);
    }

    ngOnInit() {
        console.log('NG ON INIT CALLED');
        this.loading = true;
        statesAsArray.map((s) => {
            this.stateItems.push({ label: s, value: s });
        });
    }

    clone(printable) {
        this.router.navigate(['/' + this.path + '/clone/' + printable.uuid]);
    }

    cerca(table: Table) {
        this.printableService.checkAndRemoveZombieJsonTypes();
        this.printableService.removeEmptyPropertyFilters(
            this.articleFiltersCmp.propertyFilter.form
        );
        this.printableService.applyPropertyFilters(this.articleFiltersCmp.propertyFilter.form);
        this.reload(table);
    }

    getId() {
        return this.element.uuid;
    }

    public new() {
        this.router.navigate(['/' + this.path + '/new']);
    }

    filterTipology(event = null): void {
        this.tipologyService.buildSearch();
        this.tipologyService.search.like.name = event.query;
        this.tipologyService.search.pageSize = 20;

        this.tipologyService.getList().subscribe(
            (list) => (this.tipologyItems = list),
            () => {
                console.log('Errore nel filraggio delle tipologie');
            }
        );
    }

    fillTipology(): void {
        this.service.search.obj.tipology_uuid = this.selectedTipology.uuid;
    }

    clearTipology(): void {
        this.selectedTipology = null;
        this.service.search.obj.tipology_uuid = null;
    }

    filterCategory(event = null): void {
        this.categoryService.buildSearch();
        this.categoryService.search.like.description = event.query;
        this.categoryService.search.pageSize = 20;

        this.categoryService.getList().subscribe(
            (list) => (this.categoryItems = list),
            () => {
                console.log('Errore nel filraggio dei piani');
            }
        );
    }

    fillCategory(): void {
        this.service.search.obj.category_uuid = this.selectedCategory.uuid;
    }

    clearCategory(): void {
        this.selectedCategory = null;
        this.service.search.obj.category_uuid = null;
    }
}
