import { Component, OnInit } from '@angular/core';
import { AbstractViewComponent } from '../../common/abstract-view-component';
import { SystemEventLog } from '../../model/system-event-log';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemEventLogService } from '../../service/system-event-log.service';

@Component({
    selector: 'app-system-event-log-view',
    templateUrl: './system-event-log-view.component.html',
})
export class SystemEventLogViewComponent extends AbstractViewComponent<SystemEventLog>
    implements OnInit {
    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected systemEventLogService: SystemEventLogService
    ) {
        super(router, route, systemEventLogService, 'system_event_logs');
    }

    getId() {
        return this.element.uuid;
    }
}
