<p-toast></p-toast>
<div *ngIf="isLoading" class="loading-overlay">
  <p-progressSpinner></p-progressSpinner>
</div>

<div class="main-content clearfix">
  <div class="list-wrapper clearfix">
    <div class="row">
      <div class="col-sm-8">
        <h4>
          <i aria-hidden="true" class="fa fa-upload"></i>
          Importazioni
        </h4>
        <br />
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="panel panel-default">
      <h4 class="panel-head view">Generazione modello</h4>
      <div class="panel-body view">
        <div class="search-panel">
          <div class="row">
            <div class="col-sm-6">
              <p-dropdown
                [options]="tipologiesAsItems$ | async"
                [(ngModel)]="selectedTipologyDownload"
                (onChange)="tipologyDownloadSelected($event)"
                placeholder="Seleziona
                            Tipologia..."
                [style]="{ width: '100%' }"
              ></p-dropdown>
            </div>

            <div class="col-sm-5">
              <p-multiSelect
                [options]="downloadPropertiesAsItems"
                [(ngModel)]="selectedDownloadProperties"
                [selectedItemsLabel]="'{0} proprietà selezionate'"
                (onChange)="downloadPropertySelected($event)"
                [disabled]="!selectedTipologyDownload"
                [defaultLabel]="'Seleziona Proprietà...'"
                [style]="{ width: '100%' }"
              ></p-multiSelect>
            </div>

            <div class="col-sm-1">
              <button
                type="button"
                class="btn btn-success"
                [disabled]="!selectedDownloadProperties?.length"
                (click)="download()"
              >
                Scarica
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-default">
      <h4 class="panel-head view">Importazioni</h4>
      <div class="panel-body view">
        <div class="search-panel">
          <div class="row">
            <div class="col-sm-3">
              <p-dropdown
                [options]="tipologiesAsItems$ | async"
                [(ngModel)]="selectedTipologyUpload"
                (onChange)="tipologyUploadSelected($event)"
                placeholder="Seleziona
                            Tipologia..."
                [style]="{ width: '100%' }"
              ></p-dropdown>
            </div>

            <div class="col-sm-3">
              <p-multiSelect
                [options]="uploadPropertiesAsItems"
                [(ngModel)]="selectedUploadProperties"
                [selectedItemsLabel]="'{0} proprietà selezionate'"
                (onChange)="uploadPropertySelected($event)"
                [disabled]="!selectedTipologyUpload"
                [defaultLabel]="'Seleziona Proprietà...'"
                [style]="{ width: '100%' }"
              ></p-multiSelect>
            </div>

            <div class="col-sm-3">
              <p-dropdown
                [options]="containersAsItems$ | async"
                [(ngModel)]="selectedContainer"
                (onChange)="containerSelected($event)"
                placeholder="Seleziona
                            Gara..."
                [style]="{ width: '100%' }"
              ></p-dropdown>
            </div>

            <div class="col-sm-2">
              <input
                type="file"
                (change)="onChooseFile($event)"
                #fileInput
                style="display: none;"
              />

              <button
                *ngIf="!chosenFile"
                type="button"
                class="btn btn-primary"
                pButton
                icon="ui-icon-plus"
                label="Carica file"
                (click)="fileInput.click()"
              ></button>

              <div class="p-mb-3">
                <div
                  *ngIf="chosenFile"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 1.5rem;
                  "
                >
                  <h4>{{ chosenFile.name }}</h4>
                  <button
                    type="button"
                    pButton
                    class="btn btn-danger"
                    icon="pi pi-times"
                    [disabled]="!chosenFile"
                    (click)="clearFile()"
                  ></button>
                </div>
              </div>
            </div>

            <div class="col-sm-1">
              <button
                type="button"
                class="btn btn-success"
                [disabled]="!chosenFile || !selectedTipologyUpload || !isFileVerified"
                (click)="upload()"
              >
                Carica
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
