<p-toast></p-toast>
<p-confirmDialog icon="fa fa-question-circle" #cd>
  <footer>
    <button type="button" pButton icon="ui-icon-undo" label="No" (click)="cd.reject()"></button>
    <button type="button" pButton icon="ui-icon-check" label="Sì" (click)="cd.accept()"></button>
  </footer>
</p-confirmDialog>

<div class="page-title-wrapper" *ngIf="element.codiceGara">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <h3>
          Gara:
          <strong>{{ element?.name }}</strong>
          <p>
            <span>
              Descrizione:
              <strong>{{ element?.description }}</strong>
            </span>
            <span>
              Codice:
              <strong>{{ element?.codiceGara }}</strong>
            </span>
          </p>
        </h3>
      </div>

      <div class="col-sm-6">
        <div class="sections">
          <div class="row">
            <div class="item col-sm-4 active">
              Gestione Gara
            </div>

            <div class="item col-sm-4" (click)="addCatalogues()">
              Gestione Cataloghi
            </div>

            <div class="item col-sm-4" (click)="addArticles()">
              Gestione Articoli
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main-content clearfix">
  <form [formGroup]="registerForm">
    <div class="row">
      <div class="col-sm-8">
        <h4 *ngIf="element.codiceGara">
          Aggiorna Gara
        </h4>
        <h4 *ngIf="!element.codiceGara">
          <i aria-hidden="true" class="fa fa-tachometer"></i>
          Crea una nuova Gara
        </h4>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="clearfix"></div>
    <br />
    <div class="panel panel-default">
      <div class="panel-body view">
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Nome
              <span *ngIf="name | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="name"
              placeholder="Nome"
              formControlName="name"
            />
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Gara Id
              <span *ngIf="codiceGara | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="codiceGara"
              placeholder="Codice Gara"
              formControlName="codiceGara"
            />
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Descrizione
              <span *ngIf="description | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="description"
              placeholder="Descrizione"
              formControlName="description"
            />
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Riferimenti
              <span *ngIf="reference | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="reference"
              placeholder="Riferimenti"
              formControlName="reference"
            />
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Ente / Tipologia
              <span *ngIf="entity_type | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="entity_type"
              placeholder="Ente / Tipologia"
              formControlName="entity_type"
            />
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Settore
              <span *ngIf="sector | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-dropdown
              [pTooltip]="
                listableSectorItems?.length === 0
                  ? 'Bisogna aggiungere i settori nella sezione Listabili con il nome \'settore\''
                  : ''
              "
              tooltipPosition="top"
              [options]="listableSectorItems"
              placeholder="Scegli Settore..."
              formControlName="sector"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Indirizzo di consegna campionatura
              <span *ngIf="delivery_address | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="delivery_address"
              placeholder="Indirizzo di consegna campionatura"
              formControlName="delivery_address"
            />
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Scadenza
              <span *ngIf="deadline | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-calendar
              [locale]="langIt"
              [dateFormat]="'dd/mm/yy'"
              showButtonBar="true"
              clearButtonStyleClass="btn ui-button-text-only"
              todayButtonStyleClass="btn ui-button-text-only"
              inputStyleClass="form-control"
              [style]="{ width: '100%' }"
              formControlName="deadline"
            ></p-calendar>
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Offerente
              <span *ngIf="bidder | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="bidder"
              placeholder="Offerente"
              formControlName="bidder"
            />
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Cliente di portafoglio
              <span *ngIf="portfolioCustomer | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-inputSwitch
              formControlName="portfolioCustomer"
              inputStyleClass="form-control"
            ></p-inputSwitch>
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Proroga
              <span *ngIf="prorogation | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-calendar
              [locale]="langIt"
              [dateFormat]="'dd/mm/yy'"
              showButtonBar="true"
              clearButtonStyleClass="btn ui-button-text-only"
              todayButtonStyleClass="btn ui-button-text-only"
              inputStyleClass="form-control"
              [style]="{ width: '100%' }"
              formControlName="prorogation"
            ></p-calendar>
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Note
              <span *ngIf="notes | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <textarea
              class="form-control"
              pInputTextarea
              type="text"
              name="Note"
              placeholder="..."
              formControlName="notes"
            ></textarea>
          </div>
        </div>
        <div class="clearfix"></div>
        <div formArrayName="properties">
          <div *ngFor="let property of propertyForms.controls; let i = index" [formGroupName]="i">
            <app-property-definition
              [property]="property"
              [i]="i"
              [iconsItems]="iconsItems"
              [barcodeTypesItems]="barcodeTypesItems"
              [listableNamesItems]="listableNamesItems$ | async"
              [propertyTypesItems]="propertyTypesTemplateItems"
              [propertyValueName]="'value'"
              [hideName]="true"
              [hideMandatory]="true"
              [hideOrder]="true"
              [locale]="langIt"
              (deletePropertyEvent)="deleteProperty($event)"
              (valueChangedFromChildEvent)="valueChangedFromChild($event)"
              (propertyTypeSelectedEvent)="propertyTypeSelected($event)"
            ></app-property-definition>
          </div>
        </div>

        <div class="btn-group uull-right">
          <div class="btn-group">
            <button type="button" class="btn btn-default" (click)="addEmptyProperty()">
              <i class="fa fa-plus"></i>
              Aggiungi proprietà
            </button>
          </div>
          <button
            type="button"
            class="btn btn-success"
            *ngIf="!element.codiceGara"
            (click)="save()"
            [disabled]="registerForm.invalid"
          >
            Salva
          </button>
          <button
            type="button"
            class="btn btn-success"
            *ngIf="element.codiceGara"
            (click)="complexUpdate()"
            [disabled]="registerForm.invalid"
          >
            Aggiorna
          </button>
          <button type="button" class="btn btn-warning" (click)="goToList()">
            Annulla
          </button>
          <button
            *ngIf="element.codiceGara"
            type="button"
            class="btn btn-danger"
            (click)="confirmDelete()"
          >
            Elimina
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
