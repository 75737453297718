<p-toast></p-toast>

<p-overlayPanel
  #op
  [baseZIndex]="9999999"
  [dismissable]="true"
  appendTo="body"
  styleClass="clearfix"
>
  <div class="col-sm-12">
    <div class="form-group row cleafix">
      <input
        class="form-control"
        pInputText
        type="text"
        name="name"
        placeholder="Nome"
        [(ngModel)]="service.search.like.file_name"
      />
    </div>
  </div>
  <div class="form-group cleafix">
    <div class="row">
      <div class="col-sm-6">
        <button type="button" class="btn btn-success btn-block" (click)="reload(table); op.hide()">
          Cerca
        </button>
      </div>
      <div class="col-sm-6">
        <button type="button" class="btn btn-default btn-block" (click)="reset(table); op.hide()">
          Reset
        </button>
      </div>
    </div>
  </div>
</p-overlayPanel>

<div class="main-content clearfix">
  <div class="list-wrapper clearfix">
    <div class="content clearfix">
      <div class="clearfix"></div>
      <div class="filter-wrapper clearfix">
        <button
          type="text"
          class="btn btn-filter"
          label="Basic"
          appendTo="body"
          (click)="op.toggle($event)"
        >
          <i class="fa fa-sliders" aria-hidden="true"></i>
          Cerca
        </button>

        <div class="tags-wrapper">
          <div class="tag-item" *ngIf="service.search.like.file_name">
            <strong>nome:</strong>
            {{ service.search.like.file_name }}
            <button (click)="service.search.like.file_name = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-body list">
          <div class="row">
            <p-table
              [value]="model"
              [lazy]="true"
              (onLazyLoad)="lazyLoad($event)"
              [paginator]="true"
              [responsive]="true"
              [rows]="service.search.pageSize"
              [totalRecords]="service.listSize"
              [resizableColumns]="true"
              #table
            >
              <ng-template pTemplate="header" let-columns class="search-filter">
                <tr class="sort-header">
                  <th [pSortableColumn]="'file_name'">
                    Nome
                    <p-sortIcon [field]="'file_name'"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="colspan">
                    Nessun risultato.
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <div class="col-sm-3 text-center">
                  <div class="image-wrapper">
                    <img
                      *ngIf="rowData.url"
                      [src]="rowData.url + '?format=300x300' | safe: 'resourceUrl'"
                      class="clickable-image"
                      (click)="imageClicked($event)"
                    />
                  </div>
                  <div class="clearfix"></div>
                  <span class="label label-default" title="{{ rowData?.file_name }}">
                    {{ rowData.file_name }}
                  </span>
                </div>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
