<p-toast></p-toast>

<div class="wrapper-md">
  <div class="panel panel-default">
    <div class="panel-body view">
      <div class="row">
        <div class="col-sm-8">
          <label>Pagina</label>
        </div>
        <div class="col-sm-4"></div>
      </div>

      <div class="clearfix"></div>

      <table class="table">
        <tbody>
          <tr>
            <td width="200">Cod. Art. Interno:</td>
            <td>{{ element.internal_code }}</td>
          </tr>
          <tr>
            <td width="200">Nome:</td>
            <td>{{ element.name }}</td>
          </tr>
          <tr>
            <td>Descrizione:</td>
            <td>{{ element.description }}</td>
          </tr>

          <tr>
            <td>Codice Gara:</td>
            <td>{{ element.codiceGara }}</td>
          </tr>

          <tr>
            <td>Tags:</td>
            <td>{{ element.tags }}</td>
          </tr>

          <tr>
            <td>Tipologia:</td>
            <td>{{ currentTipology?.name }}</td>
          </tr>

          <tr>
            <td>Categoria:</td>
            <td>{{ currentCategory?.name }}</td>
          </tr>

          <tr>
            <td>Stato:</td>
            <td>{{ element.state }}</td>
          </tr>
        </tbody>
      </table>

      <div class="row clearfix">
        <div class="col-sm-9">
          <div class="col-sm-12">
            <label>Proprieta:</label>
            <div style="display: flex; float: right;">
              <div style="margin-right: 10px;">
                <label *ngIf="!openAllTabs">Apri tutto</label>
                <label *ngIf="openAllTabs">Chiudi tutto</label>
              </div>
              <p-inputSwitch
                [(ngModel)]="openAllTabs"
                inputStyleClass="form-control"
              ></p-inputSwitch>
            </div>
          </div>
          <ng-container *ngFor="let property of element.properties; let i = index">
            <div class="col-sm-12">
              <app-property-view
                [property]="property"
                [i]="i"
                [propertyValueName]="'value'"
                [openAllTabs]="openAllTabs"
                [propertyTypesMap]="propertyTypesMap"
              ></app-property-view>
            </div>
          </ng-container>
        </div>

        <div class="col-sm-3">
          Templates:
          <div class="clearfix"></div>
          <br />
          Includi allegati, se presenti, nella stampa &nbsp;
          <p-checkbox binary="true" [(ngModel)]="includeAttachments"></p-checkbox>
          <div class="clearfix"></div>
          <br />
          <div class="clearfix"></div>
          <div class="well">
            <div *ngFor="let template of currentTipology?.templates">
              <button (click)="printCustom(template.uuid)" class="btn btn-success btn-block">
                <i class="fa fa-print"></i>
                Stampa {{ template.name }}
              </button>
              <div class="clearfix"></div>
              <br />
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>

      <div class="btn-group">
        <button type="button" class="btn btn-default" (click)="goToList()">
          <i class="fa fa-arrow-left"></i>
          Indietro
        </button>
        <button type="button" (click)="edit(element)" class="btn btn-info">
          <i class="fa fa-edit right"></i>
          Modifica
        </button>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
