<p-dialog
  appendTo="body"
  header="Scegli un'immagine"
  [(visible)]="isDialogVisible"
  [modal]="true"
  [draggable]="false"
  [contentStyle]="{ 'max-height': '50vh' }"
  [positionTop]="180"
  [style]="{ width: '850px', minWidth: '200px' }"
  [maximizable]="false"
  [baseZIndex]="10000"
>
  <app-existing-images-list
    *ngIf="isDialogVisible"
    (selectImage)="selectImage($event)"
  ></app-existing-images-list>
  <p-footer>
    <button
      type="button"
      (click)="isDialogVisible = false"
      style="padding: 0px 15px;"
      class="btn btn-danger"
    >
      Chiudi
    </button>
  </p-footer>
</p-dialog>

<div class="upload-box">
  <div *ngIf="currentImageUrl" class="btn-wrp">
    <button (click)="confirmDelete()" class="btn-trash" type="button">
      <i class="fa fa-trash"></i>
    </button>
  </div>

  <span class="fake-btn" *ngIf="!currentImageUrl">Seleziona immagine</span>
  <span class="file-msg" *ngIf="!currentImageUrl">clicca o trascina il file qui</span>
  <div style="width: 100%;" *ngIf="currentImageUrl">
    <span class="file-msg" *ngIf="currentImageUrl.name">
      {{ currentImageUrl.name }}
    </span>
    <div class="file-msg image-box" *ngIf="!currentImageUrl.name">
      <img
        [src]="currentImageUrl + '?format=300x300' | safe: 'resourceUrl'"
        style="height: 100px;"
      />
    </div>
  </div>

  <input class="file-input" type="file" />

  <div class="btn-wrp">
    <button class="btn btn-default btn-catalogue" (click)="showDialog()" type="button">
      Prendi da articolo esistente
    </button>
  </div>
</div>

<p-confirmDialog
  icon="fa fa-question-circle"
  #cdDeleteImage
  [key]="'deleteImage-' + indexOnArrayForm + (printableUuid ? '-' + printableUuid : '')"
>
  <footer>
    <button
      type="button"
      pButton
      icon="fa fa-undo"
      class="btn-danger"
      label="No"
      (click)="cdDeleteImage.reject()"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      classyy="btn-success"
      label="Sì"
      (click)="cdDeleteImage.accept()"
    ></button>
  </footer>
</p-confirmDialog>
