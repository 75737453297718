export class SystemEventLog {
    public uuid: string;
    public received_date: string;
    public obj: string;
    public operation_type: string;

    public data: string;
    public principal: string;

    constructor() {}
}
