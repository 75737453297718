import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';

import { AbstractListComponent } from '../../common/abstract-list-component';
import { Tipology } from '../../model/tipology';
import { Category } from 'src/app/model/category';
import { takeUntil } from 'rxjs/operators';
import { PrintableService } from 'src/app/service/printable.service';

@Component({
    selector: 'app-tipology-list',
    templateUrl: './tipology-list.component.html',
    styleUrls: ['./tipology-list.component.css'],
})
export class TipologyListComponent extends AbstractListComponent<Tipology> implements OnInit {
    element: Tipology;
    categories: Map<string, Category> = new Map<string, Category>();
    categoryItems: Category[];
    archivedFilter = false;

    selectedCategory = null;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected tipologyService: TipologyService,
        protected categoryService: CategoryService,
        protected printableService: PrintableService
    ) {
        super(router, tipologyService, 'tipologies');
    }

    ngOnInit() {
        this.loading = true;
        this.element = new Tipology();
        this.archivedFilter = this.service.search.obj.active === false;
    }

    clone(printable) {
        this.router.navigate(['/' + this.path + '/clone/' + printable.uuid]);
    }

    delete(element: Tipology) {
        this.service
            .delete(element.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((del) => this.loaddata(true));
    }

    public new() {
        this.router.navigate(['/' + this.path + '/new']);
    }

    onFilterArchived(event: any) {
        this.archivedFilter = event.checked;
        this.service.search.obj.active = event.checked ? false : null;
    }

    filterCategory(event = null): void {
        this.categoryService.buildSearch();
        this.categoryService.search.like.description = event.query;
        this.categoryService.search.pageSize = 20;

        this.categoryService.getList().subscribe(
            (list) => (this.categoryItems = list),
            () => {
                console.log('Errore nel filraggio delle categorie');
            }
        );
    }

    fillCategory(): void {
        this.service.search.obj.category_uuid = this.selectedCategory.uuid;
    }

    clearCategory(): void {
        this.selectedCategory = null;
        this.service.search.obj.category_uuid = null;
    }

    exportTemplate(tipology: Tipology): void {
        const payload = {
            tipology_uuid: tipology.uuid,
            properties: tipology.properties,
        };

        this.printableService.downloadTemplate(payload).subscribe({
            next: (resp) => {
                const url = URL.createObjectURL(resp);

                const link = document.createElement('a');
                link.href = url;
                link.download = `Template_${tipology.uuid}.xlsx`;
                link.click();
            },
            error: (err) =>
                err.error.text().then((errText: string) => {
                    this.addError(errText ? JSON.parse(errText).msg : 'Errore');
                }),
        });
    }
}
