import { objectType } from './enum/object-type';
import { PropertyValue } from './property-value';
import { SelectItem } from 'primeng/api/selectitem';

export type PrintTemplateType = 'CATALOGUE' | 'PRINTABLE';
export const printTemplateTypeAsItems: SelectItem[] = [
    { label: 'Catalogo', value: 'CATALOGUE' },
    { label: 'Articolo / Pagina', value: 'PRINTABLE' },
];
export const printTemplateTypeMap = new Map<PrintTemplateType, string>([
    ['CATALOGUE', 'Catalogo'],
    ['PRINTABLE', 'Articolo / Pagina'],
]);

export type PrintTemplateFormat = 'PDF' | 'CSV' | 'XLS';
export const printTemplateFormatAsItems: SelectItem[] = [
    { label: 'PDF', value: 'PDF' },
    { label: 'CSV', value: 'CSV' },
    { label: 'XLS', value: 'XLS' },
];

export class PrintTemplate {
    public uuid: string;
    public name: string;
    public description: string;
    public printTemplateType: PrintTemplateType;
    public printTemplateFormat: PrintTemplateFormat;

    public first_page: string;
    public last_page: string;
    public header: string;
    public footer: string;
    public style: string;
    public with_number: boolean;

    public active: boolean;

    public properties: PropertyValue[];
    public page_content: string;
    public objectType: objectType;

    public default_template: boolean;

    constructor() {}
}
