import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    OnDestroy,
} from '@angular/core';
import { FormGroupDirective, ControlContainer, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api/selectitem';
import { TipologyService } from 'src/app/service/tipology.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TIPOLOGY_STYLE_MAX_COLUMNS } from 'src/app/constants/constants';

@Component({
    selector: 'app-property-definition',
    templateUrl: './property-definition.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    styleUrls: ['./property-definition.component.css'],
})
export class PropertyDefinitionComponent implements OnInit, OnChanges, OnDestroy {
    @Input() property: FormGroup;
    @Input() i: number;
    @Input() iconsItems: SelectItem[];
    @Input() barcodeTypesItems: SelectItem[];
    @Input() listableNamesItems: SelectItem[];
    @Input() propertyTypesItems: SelectItem[];
    @Input() propertyValueName;
    @Output() deletePropertyEvent = new EventEmitter<number>();
    @Output() valueChangedFromChildEvent = new EventEmitter();
    @Output() propertyTypeSelectedEvent = new EventEmitter();
    @Input() allCurrentNumericPropsItems: SelectItem[];
    @Input() allTipologiesItems: SelectItem[];
    @Input() locale: {};
    @Input() hideName = false;
    @Input() hideMandatory = false;
    @Input() hideOrder = false;

    allPropsOfSelectedTipologyItems: SelectItem[];
    protected destroy$: Subject<void> = new Subject<void>();
    public TIPOLOGY_STYLE_MAX_COLUMNS = TIPOLOGY_STYLE_MAX_COLUMNS;

    constructor(private tipologyService: TipologyService) {}

    ngOnInit(): void {
        if (!this.propertyValueName) {
            throw new Error('propertyValueName must be defined!');
        }
        if (this.allCurrentNumericPropsItems && this.property) {
            this.allCurrentNumericPropsItems = this.allCurrentNumericPropsItems.filter(
                (np) => np.value !== this.property.value.name
            );
        }
        if (!this.locale) {
            throw new Error('locale for calendar must be defined!');
        }

        if (this.hideName) {
            this.property
                .get('label')
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe((labelValue) =>
                    this.property.patchValue({ name: labelValue.replace(/\s+/g, '') })
                );
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const property = changes?.property?.currentValue;
        if (property) {
            if (property?.value?.property_type === 'GLOBAL_SUMMABLE') {
                const storedTiplogyUuid = property?.value?.extra?.tipology_uuid;
                if (storedTiplogyUuid) {
                    this.findTipology(storedTiplogyUuid);
                }
            } else if (property?.value?.property_type === 'GROUPABLE') {
                const storedTiplogyUuid = property?.value?.extra?.tipology_uuid;
                if (storedTiplogyUuid) {
                    this.findTipologyForGroupable(storedTiplogyUuid);
                }
            } else if (property?.value?.property_type === 'GROUP_SUMMABLE') {
                const storedTiplogyUuid = property?.value?.extra?.tipology_uuid;
                if (storedTiplogyUuid) {
                    this.findTipology(storedTiplogyUuid);
                    this.findTipologyForGroupable(storedTiplogyUuid);
                }
            }
        }
    }

    deleteProperty(i: number) {
        this.deletePropertyEvent.emit(i);
    }

    valueChangedFromChild(value, index) {
        this.valueChangedFromChildEvent.emit({ value, index });
    }

    propertyTypeSelected(event, indexInFormArray) {
        this.propertyTypeSelectedEvent.emit({ event, indexInFormArray });
    }

    tipologySelected(event) {
        this.property.patchValue({
            extra: { operandOne: null, operandTwo: null },
        });
        if (event.value) {
            this.findTipology(event.value);
        }
    }

    findTipology(tipologyUuid) {
        this.tipologyService
            .find(tipologyUuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((tipology) => {
                this.allCurrentNumericPropsItems = [];
                tipology?.properties.map((p) => {
                    if (
                        p.property_type === 'NUMBER_VALUE' ||
                        p.property_type === 'SUMMABLE' ||
                        p.property_type === 'SOTTRAIBILE' ||
                        p.property_type === 'MULTIPLIABLE' ||
                        p.property_type === 'DIVISIBLE'
                    ) {
                        this.allCurrentNumericPropsItems.push({
                            label: p.label,
                            value: p.name,
                        });
                    }
                });
            });
    }

    groupableTipologySelected(event) {
        this.property.patchValue({
            extra: { propertyName: null },
        });
        if (event.value) {
            this.findTipologyForGroupable(event.value);
        }
    }

    findTipologyForGroupable(tipologyUuid) {
        this.tipologyService
            .find(tipologyUuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((tipology) => {
                this.allPropsOfSelectedTipologyItems = [];
                tipology?.properties.map((p) => {
                    this.allPropsOfSelectedTipologyItems.push({
                        label: p.label,
                        value: p.name,
                    });
                });
            });
    }

    groupSummableTipologySelected(event) {
        this.tipologySelected(event);
        this.groupableTipologySelected(event);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
