import { SelectItem } from 'primeng/api';

export const FONT_AWESOME_ICONS: SelectItem[] = [
    { label: 'fa glass', value: 'fa fa-glass' },
    { label: 'fa music', value: 'fa fa-music' },
    { label: 'fa search', value: 'fa fa-search' },
    { label: 'fa envelope o', value: 'fa fa-envelope-o' },
    { label: 'fa heart', value: 'fa fa-heart' },
    { label: 'fa star', value: 'fa fa-star' },
    { label: 'fa star o', value: 'fa fa-star-o' },
    { label: 'fa user', value: 'fa fa-user' },
    { label: 'fa film', value: 'fa fa-film' },
    { label: 'fa th large', value: 'fa fa-th-large' },
    { label: 'fa th', value: 'fa fa-th' },
    { label: 'fa th list', value: 'fa fa-th-list' },
    { label: 'fa check', value: 'fa fa-check' },
    { label: 'fa times', value: 'fa fa-times' },
    { label: 'fa search plus', value: 'fa fa-search-plus' },
    { label: 'fa search minus', value: 'fa fa-search-minus' },
    { label: 'fa power off', value: 'fa fa-power-off' },
    { label: 'fa signal', value: 'fa fa-signal' },
    { label: 'fa cog', value: 'fa fa-cog' },
    { label: 'fa trash o', value: 'fa fa-trash-o' },
    { label: 'fa home', value: 'fa fa-home' },
    { label: 'fa file o', value: 'fa fa-file-o' },
    { label: 'fa clock o', value: 'fa fa-clock-o' },
    { label: 'fa road', value: 'fa fa-road' },
    { label: 'fa download', value: 'fa fa-download' },
    { label: 'fa arrow circle o down', value: 'fa fa-arrow-circle-o-down' },
    { label: 'fa arrow circle o up', value: 'fa fa-arrow-circle-o-up' },
    { label: 'fa inbox', value: 'fa fa-inbox' },
    { label: 'fa play circle o', value: 'fa fa-play-circle-o' },
    { label: 'fa repeat', value: 'fa fa-repeat' },
    { label: 'fa refresh', value: 'fa fa-refresh' },
    { label: 'fa list alt', value: 'fa fa-list-alt' },
    { label: 'fa lock', value: 'fa fa-lock' },
    { label: 'fa flag', value: 'fa fa-flag' },
    { label: 'fa headphones', value: 'fa fa-headphones' },
    { label: 'fa volume off', value: 'fa fa-volume-off' },
    { label: 'fa volume down', value: 'fa fa-volume-down' },
    { label: 'fa volume up', value: 'fa fa-volume-up' },
    { label: 'fa qrcode', value: 'fa fa-qrcode' },
    { label: 'fa barcode', value: 'fa fa-barcode' },
    { label: 'fa tag', value: 'fa fa-tag' },
    { label: 'fa tags', value: 'fa fa-tags' },
    { label: 'fa book', value: 'fa fa-book' },
    { label: 'fa bookmark', value: 'fa fa-bookmark' },
    { label: 'fa print', value: 'fa fa-print' },
    { label: 'fa camera', value: 'fa fa-camera' },
    { label: 'fa font', value: 'fa fa-font' },
    { label: 'fa bold', value: 'fa fa-bold' },
    { label: 'fa italic', value: 'fa fa-italic' },
    { label: 'fa text height', value: 'fa fa-text-height' },
    { label: 'fa text width', value: 'fa fa-text-width' },
    { label: 'fa align left', value: 'fa fa-align-left' },
    { label: 'fa align center', value: 'fa fa-align-center' },
    { label: 'fa align right', value: 'fa fa-align-right' },
    { label: 'fa align justify', value: 'fa fa-align-justify' },
    { label: 'fa list', value: 'fa fa-list' },
    { label: 'fa outdent', value: 'fa fa-outdent' },
    { label: 'fa indent', value: 'fa fa-indent' },
    { label: 'fa video camera', value: 'fa fa-video-camera' },
    { label: 'fa picture o', value: 'fa fa-picture-o' },
    { label: 'fa pencil', value: 'fa fa-pencil' },
    { label: 'fa map marker', value: 'fa fa-map-marker' },
    { label: 'fa adjust', value: 'fa fa-adjust' },
    { label: 'fa tint', value: 'fa fa-tint' },
    { label: 'fa pencil square o', value: 'fa fa-pencil-square-o' },
    { label: 'fa share square o', value: 'fa fa-share-square-o' },
    { label: 'fa check square o', value: 'fa fa-check-square-o' },
    { label: 'fa arrows', value: 'fa fa-arrows' },
    { label: 'fa step backward', value: 'fa fa-step-backward' },
    { label: 'fa fast backward', value: 'fa fa-fast-backward' },
    { label: 'fa backward', value: 'fa fa-backward' },
    { label: 'fa play', value: 'fa fa-play' },
    { label: 'fa pause', value: 'fa fa-pause' },
    { label: 'fa stop', value: 'fa fa-stop' },
    { label: 'fa forward', value: 'fa fa-forward' },
    { label: 'fa fast forward', value: 'fa fa-fast-forward' },
    { label: 'fa step forward', value: 'fa fa-step-forward' },
    { label: 'fa eject', value: 'fa fa-eject' },
    { label: 'fa chevron left', value: 'fa fa-chevron-left' },
    { label: 'fa chevron right', value: 'fa fa-chevron-right' },
    { label: 'fa plus circle', value: 'fa fa-plus-circle' },
    { label: 'fa minus circle', value: 'fa fa-minus-circle' },
    { label: 'fa times circle', value: 'fa fa-times-circle' },
    { label: 'fa check circle', value: 'fa fa-check-circle' },
    { label: 'fa question circle', value: 'fa fa-question-circle' },
    { label: 'fa info circle', value: 'fa fa-info-circle' },
    { label: 'fa crosshairs', value: 'fa fa-crosshairs' },
    { label: 'fa times circle o', value: 'fa fa-times-circle-o' },
    { label: 'fa check circle o', value: 'fa fa-check-circle-o' },
    { label: 'fa ban', value: 'fa fa-ban' },
    { label: 'fa arrow left', value: 'fa fa-arrow-left' },
    { label: 'fa arrow right', value: 'fa fa-arrow-right' },
    { label: 'fa arrow up', value: 'fa fa-arrow-up' },
    { label: 'fa arrow down', value: 'fa fa-arrow-down' },
    { label: 'fa share', value: 'fa fa-share' },
    { label: 'fa expand', value: 'fa fa-expand' },
    { label: 'fa compress', value: 'fa fa-compress' },
    { label: 'fa plus', value: 'fa fa-plus' },
    { label: 'fa minus', value: 'fa fa-minus' },
    { label: 'fa asterisk', value: 'fa fa-asterisk' },
    { label: 'fa exclamation circle', value: 'fa fa-exclamation-circle' },
    { label: 'fa gift', value: 'fa fa-gift' },
    { label: 'fa leaf', value: 'fa fa-leaf' },
    { label: 'fa fire', value: 'fa fa-fire' },
    { label: 'fa eye', value: 'fa fa-eye' },
    { label: 'fa eye slash', value: 'fa fa-eye-slash' },
    { label: 'fa exclamation triangle', value: 'fa fa-exclamation-triangle' },
    { label: 'fa plane', value: 'fa fa-plane' },
    { label: 'fa calendar', value: 'fa fa-calendar' },
    { label: 'fa random', value: 'fa fa-random' },
    { label: 'fa comment', value: 'fa fa-comment' },
    { label: 'fa magnet', value: 'fa fa-magnet' },
    { label: 'fa chevron up', value: 'fa fa-chevron-up' },
    { label: 'fa chevron down', value: 'fa fa-chevron-down' },
    { label: 'fa retweet', value: 'fa fa-retweet' },
    { label: 'fa shopping cart', value: 'fa fa-shopping-cart' },
    { label: 'fa folder', value: 'fa fa-folder' },
    { label: 'fa folder open', value: 'fa fa-folder-open' },
    { label: 'fa arrows v', value: 'fa fa-arrows-v' },
    { label: 'fa arrows h', value: 'fa fa-arrows-h' },
    { label: 'fa bar chart', value: 'fa fa-bar-chart' },
    { label: 'fa twitter square', value: 'fa fa-twitter-square' },
    { label: 'fa facebook square', value: 'fa fa-facebook-square' },
    { label: 'fa camera retro', value: 'fa fa-camera-retro' },
    { label: 'fa key', value: 'fa fa-key' },
    { label: 'fa cogs', value: 'fa fa-cogs' },
    { label: 'fa comments', value: 'fa fa-comments' },
    { label: 'fa thumbs o up', value: 'fa fa-thumbs-o-up' },
    { label: 'fa thumbs o down', value: 'fa fa-thumbs-o-down' },
    { label: 'fa star half', value: 'fa fa-star-half' },
    { label: 'fa heart o', value: 'fa fa-heart-o' },
    { label: 'fa sign out', value: 'fa fa-sign-out' },
    { label: 'fa linkedin square', value: 'fa fa-linkedin-square' },
    { label: 'fa thumb tack', value: 'fa fa-thumb-tack' },
    { label: 'fa external link', value: 'fa fa-external-link' },
    { label: 'fa sign in', value: 'fa fa-sign-in' },
    { label: 'fa trophy', value: 'fa fa-trophy' },
    { label: 'fa github square', value: 'fa fa-github-square' },
    { label: 'fa upload', value: 'fa fa-upload' },
    { label: 'fa lemon o', value: 'fa fa-lemon-o' },
    { label: 'fa phone', value: 'fa fa-phone' },
    { label: 'fa square o', value: 'fa fa-square-o' },
    { label: 'fa bookmark o', value: 'fa fa-bookmark-o' },
    { label: 'fa phone square', value: 'fa fa-phone-square' },
    { label: 'fa twitter', value: 'fa fa-twitter' },
    { label: 'fa facebook', value: 'fa fa-facebook' },
    { label: 'fa github', value: 'fa fa-github' },
    { label: 'fa unlock', value: 'fa fa-unlock' },
    { label: 'fa credit card', value: 'fa fa-credit-card' },
    { label: 'fa rss', value: 'fa fa-rss' },
    { label: 'fa hdd o', value: 'fa fa-hdd-o' },
    { label: 'fa bullhorn', value: 'fa fa-bullhorn' },
    { label: 'fa bell', value: 'fa fa-bell' },
    { label: 'fa certificate', value: 'fa fa-certificate' },
    { label: 'fa hand o right', value: 'fa fa-hand-o-right' },
    { label: 'fa hand o left', value: 'fa fa-hand-o-left' },
    { label: 'fa hand o up', value: 'fa fa-hand-o-up' },
    { label: 'fa hand o down', value: 'fa fa-hand-o-down' },
    { label: 'fa arrow circle left', value: 'fa fa-arrow-circle-left' },
    { label: 'fa arrow circle right', value: 'fa fa-arrow-circle-right' },
    { label: 'fa arrow circle up', value: 'fa fa-arrow-circle-up' },
    { label: 'fa arrow circle down', value: 'fa fa-arrow-circle-down' },
    { label: 'fa globe', value: 'fa fa-globe' },
    { label: 'fa wrench', value: 'fa fa-wrench' },
    { label: 'fa tasks', value: 'fa fa-tasks' },
    { label: 'fa filter', value: 'fa fa-filter' },
    { label: 'fa briefcase', value: 'fa fa-briefcase' },
    { label: 'fa arrows alt', value: 'fa fa-arrows-alt' },
    { label: 'fa users', value: 'fa fa-users' },
    { label: 'fa link', value: 'fa fa-link' },
    { label: 'fa cloud', value: 'fa fa-cloud' },
    { label: 'fa flask', value: 'fa fa-flask' },
    { label: 'fa scissors', value: 'fa fa-scissors' },
    { label: 'fa files o', value: 'fa fa-files-o' },
    { label: 'fa paperclip', value: 'fa fa-paperclip' },
    { label: 'fa floppy o', value: 'fa fa-floppy-o' },
    { label: 'fa square', value: 'fa fa-square' },
    { label: 'fa bars', value: 'fa fa-bars' },
    { label: 'fa list ul', value: 'fa fa-list-ul' },
    { label: 'fa list ol', value: 'fa fa-list-ol' },
    { label: 'fa strikethrough', value: 'fa fa-strikethrough' },
    { label: 'fa underline', value: 'fa fa-underline' },
    { label: 'fa table', value: 'fa fa-table' },
    { label: 'fa magic', value: 'fa fa-magic' },
    { label: 'fa truck', value: 'fa fa-truck' },
    { label: 'fa pinterest', value: 'fa fa-pinterest' },
    { label: 'fa pinterest square', value: 'fa fa-pinterest-square' },
    { label: 'fa google plus square', value: 'fa fa-google-plus-square' },
    { label: 'fa google plus', value: 'fa fa-google-plus' },
    { label: 'fa money', value: 'fa fa-money' },
    { label: 'fa caret down', value: 'fa fa-caret-down' },
    { label: 'fa caret up', value: 'fa fa-caret-up' },
    { label: 'fa caret left', value: 'fa fa-caret-left' },
    { label: 'fa caret right', value: 'fa fa-caret-right' },
    { label: 'fa columns', value: 'fa fa-columns' },
    { label: 'fa sort', value: 'fa fa-sort' },
    { label: 'fa sort desc', value: 'fa fa-sort-desc' },
    { label: 'fa sort asc', value: 'fa fa-sort-asc' },
    { label: 'fa envelope', value: 'fa fa-envelope' },
    { label: 'fa linkedin', value: 'fa fa-linkedin' },
    { label: 'fa undo', value: 'fa fa-undo' },
    { label: 'fa gavel', value: 'fa fa-gavel' },
    { label: 'fa tachometer', value: 'fa fa-tachometer' },
    { label: 'fa comment o', value: 'fa fa-comment-o' },
    { label: 'fa comments o', value: 'fa fa-comments-o' },
    { label: 'fa bolt', value: 'fa fa-bolt' },
    { label: 'fa sitemap', value: 'fa fa-sitemap' },
    { label: 'fa umbrella', value: 'fa fa-umbrella' },
    { label: 'fa clipboard', value: 'fa fa-clipboard' },
    { label: 'fa lightbulb o', value: 'fa fa-lightbulb-o' },
    { label: 'fa exchange', value: 'fa fa-exchange' },
    { label: 'fa cloud download', value: 'fa fa-cloud-download' },
    { label: 'fa cloud upload', value: 'fa fa-cloud-upload' },
    { label: 'fa user md', value: 'fa fa-user-md' },
    { label: 'fa stethoscope', value: 'fa fa-stethoscope' },
    { label: 'fa suitcase', value: 'fa fa-suitcase' },
    { label: 'fa bell o', value: 'fa fa-bell-o' },
    { label: 'fa coffee', value: 'fa fa-coffee' },
    { label: 'fa cutlery', value: 'fa fa-cutlery' },
    { label: 'fa file text o', value: 'fa fa-file-text-o' },
    { label: 'fa building o', value: 'fa fa-building-o' },
    { label: 'fa hospital o', value: 'fa fa-hospital-o' },
    { label: 'fa ambulance', value: 'fa fa-ambulance' },
    { label: 'fa medkit', value: 'fa fa-medkit' },
    { label: 'fa fighter jet', value: 'fa fa-fighter-jet' },
    { label: 'fa beer', value: 'fa fa-beer' },
    { label: 'fa h square', value: 'fa fa-h-square' },
    { label: 'fa plus square', value: 'fa fa-plus-square' },
    { label: 'fa angle double left', value: 'fa fa-angle-double-left' },
    { label: 'fa angle double right', value: 'fa fa-angle-double-right' },
    { label: 'fa angle double up', value: 'fa fa-angle-double-up' },
    { label: 'fa angle double down', value: 'fa fa-angle-double-down' },
    { label: 'fa angle left', value: 'fa fa-angle-left' },
    { label: 'fa angle right', value: 'fa fa-angle-right' },
    { label: 'fa angle up', value: 'fa fa-angle-up' },
    { label: 'fa angle down', value: 'fa fa-angle-down' },
    { label: 'fa desktop', value: 'fa fa-desktop' },
    { label: 'fa laptop', value: 'fa fa-laptop' },
    { label: 'fa tablet', value: 'fa fa-tablet' },
    { label: 'fa mobile', value: 'fa fa-mobile' },
    { label: 'fa circle o', value: 'fa fa-circle-o' },
    { label: 'fa quote left', value: 'fa fa-quote-left' },
    { label: 'fa quote right', value: 'fa fa-quote-right' }
];
