import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';
import { PrintTemplateService } from '../../service/print-template.service';

import { Tipology } from '../../model/tipology';
import { AbstractViewComponent } from '../../common/abstract-view-component';
import { propertyTypesPrintableItems } from '../../model/enum/property-type';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-tipology-view',
    templateUrl: './tipology-view.component.html',
    styleUrls: ['./tipology-view.component.css']
})
export class TipologyViewComponent extends AbstractViewComponent<Tipology> implements OnInit {
    element: Tipology;
    propertyTypesMap: Map<string, string> = new Map<string, string>();
    categoriesTranscode: Map<string, string> = new Map<string, string>();
    openAllTabs = false;
    editorOptions = {
        language: 'html',
        minimap: {
            enabled: false
        },
        automaticLayout: true,
        readOnly: true
    };

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected tipologyService: TipologyService,
        protected categoryService: CategoryService,
        protected printTemplateService: PrintTemplateService
    ) {
        super(router, route, tipologyService, 'tipologies');
        this.element = new Tipology();
    }

    ngOnInit() {
        super.ngOnInit();
        this.getTipologyLabel();
    }

    postFind() {
        this.categoryService
            .getAllList()
            .pipe(takeUntil(this.destroy$))
            .subscribe(categories =>
                categories.map(category =>
                    this.categoriesTranscode.set(category.uuid, category.name)
                )
            );
    }

    getTipologyLabel() {
        propertyTypesPrintableItems.map(t => this.propertyTypesMap.set(t.value, t.label));
    }

    getId() {
        return this.element.uuid;
    }
}
