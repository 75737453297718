<p-toast></p-toast>

<div class="wrapper-md">
  <div class="list-wrapper clearfix with-sidebar">
    <div class="row">
      <div class="col-sm-8">
        <h4>
          <i aria-hidden="true" class="fa fa-file-text"></i>
          Catalogo / {{ element?.name }}
        </h4>
        <br />
      </div>
      <div class="col-sm-4">
        <div class="btn-group pull-right">
          <button type="button" class="btn btn-default" (click)="goToList()">
            <i class="fa fa-arrow-left"></i>
            Indietro
          </button>
          <button *ngIf="!returnBack" type="button" (click)="edit(element)" class="btn btn-info">
            <i class="fa fa-edit right"></i>
            Modifica
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="content clearfix">
      <div class="panel panel-default">
        <div class="panel-body list">
          <div class="row">
            <p-tabView>
              <p-tabPanel header="Articoli">
                <br />
                <div class="clearfix"></div>
                <div class="col-sm-8">Elenco Articoli</div>
                <div class="col-sm-4">
                  <div class="btn-group pull-right">
                    <button
                      type="button"
                      (click)="addArticles()"
                      class="btn btn-success pull-right"
                    >
                      <i class="fa fa-file-text"></i>
                      Aggiungi Articoli
                    </button>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="col-sm-12 clearfix">
                  <p-table
                    [value]="element?.catalogueElements"
                    [rows]="10"
                    [paginator]="true"
                    [rowsPerPageOptions]="[10, 25, 50]"
                  >
                    <ng-template pTemplate="header" let-columns class="search-filter">
                      <tr class="sort-header">
                        <th style="width: 2.5em;"></th>
                        <th>Nome</th>
                        <th *ngIf="!element.monopage">Template</th>
                        <th>Tipo</th>
                        <th>Stampa Singolarmente</th>
                        <th>Catalogo con articolo singolo</th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-rowData
                      let-columns="columns"
                      let-index="rowIndex"
                    >
                      <tr [pReorderableRow]="index">
                        <td>
                          {{ rowData.orderBy !== undefined ? rowData.orderBy + 1 : '' }}
                        </td>
                        <td>
                          {{ printablesTranscode?.get(rowData.printable_uuid) }}
                        </td>
                        <td *ngIf="!element.monopage">
                          {{ templatesTranscode?.get(rowData.template_uuid) }}
                        </td>
                        <td>
                          {{ rowData.printable_type }}
                        </td>
                        <td>
                          <button
                            (click)="printSinglePrintable(rowData.printable_uuid)"
                            class="btn btn-success"
                            [disabled]="isPrinting"
                          >
                            <i class="fa fa-print"></i>
                            Stampa
                          </button>
                        </td>
                        <td>
                          <button
                            (click)="printCatalogueWithSinglePrintable(rowData.printable_uuid)"
                            class="btn btn-success"
                            [disabled]="isPrinting"
                          >
                            <i class="fa fa-print"></i>
                            Stampa
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Gruppi">
                <br />
                <div class="clearfix"></div>
                <div class="col-sm-8">
                  <label>Gruppi:</label>
                </div>

                <div class="clearfix"></div>
                <div class="col-sm-12 clearfix">
                  <p-table [value]="groups">
                    <ng-template pTemplate="header" class="search-filter">
                      <tr class="sort-header">
                        <th>gruppo</th>
                        <th>nome</th>
                        <th>Stampa Singolarmente</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                      <tr>
                        <td>
                          {{ rowData.groupBy }}
                        </td>
                        <td>
                          {{ rowData.name }}
                        </td>
                        <td>
                          <button
                            (click)="printSingleGroup(rowData.groupBy, rowData.name)"
                            class="btn btn-success"
                            [disabled]="isPrinting"
                          >
                            <i class="fa fa-print"></i>
                            Stampa Singolo Gruppo
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <div class="clearfix"></div>
                  <br />
                </div>
              </p-tabPanel>
            </p-tabView>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>

      <div class="panel panel-default clearfix">
        <div class="panel-body view">
          <div class="row">
            <div class="col-sm-8">
              <label>Proprieta:</label>
            </div>
            <div class="col-sm-4">
              <div style="display: flex; float: right;">
                <div style="margin-right: 10px;">
                  <label *ngIf="!openAllTabs">Apri tutto</label>
                  <label *ngIf="openAllTabs">Chiudi tutto</label>
                </div>
                <p-inputSwitch
                  [(ngModel)]="openAllTabs"
                  inputStyleClass="form-control"
                ></p-inputSwitch>
              </div>
            </div>

            <div class="col-sm-12">
              <ng-container *ngFor="let property of element.properties; let i = index">
                <app-property-view
                  [property]="property"
                  [i]="i"
                  [propertyValueName]="'value'"
                  [openAllTabs]="openAllTabs"
                  [propertyTypesMap]="propertyTypesMap"
                ></app-property-view>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fixed-sidebar">
    <div class="panel panel-default">
      <div class="panel-heading">Opzioni Catalogo</div>
      <div class="panel-body view">
        <h5>{{ element.name }}</h5>
        <div class="clearfix"></div>
        <button
          (click)="print()"
          [disabled]="isPrinting"
          class="btn btn-success btn-block"
          *ngIf="element?.catalogueElements"
        >
          <i class="fa fa-print"></i>
          Stampa Catalogo
        </button>
        <b *ngIf="!element?.catalogueElements">Non Stampabile (zero articoli)</b>
        <br />
        <div class="clearfix"></div>
        <strong>Descrizione:</strong>
        <br />
        <p>{{ element?.description }}</p>

        <div *ngIf="gara">
          <hr />
          <strong>Gara:</strong>
          <br />
          <p>{{ gara }}</p>
        </div>
        <hr />
        <strong>Tags:</strong>
        <br />
        <p>{{ element?.tags }}</p>
        <hr />
        <strong>Stampa allegati alla fine:</strong>
        <br />
        <p>{{ element?.withAttchamentsAtEnd ? 'si' : 'no' }}</p>
        <hr />
        <strong>Stampa allegato relativo dopo ogni articolo:</strong>
        <br />
        <p>{{ element?.withAttchamentAfterSingleArticle ? 'si' : 'no' }}</p>
        <hr />
        <strong>Stampa solo allegati:</strong>
        <br />
        <p>{{ element?.onlyAttchaments ? 'si' : 'no' }}</p>
        <hr />
        <strong>Stampa mono pagina:</strong>
        <br />
        <p>{{ element?.monopage ? 'si' : 'no' }}</p>
        <hr />
        <strong>Template selezionato:</strong>
        <br />
        <p>
          {{ templatesTranscode?.get(element?.monopage_template_uuid) }}
          <button (click)="goToTemplate()" class="btn btn-success">
            <i class="fa fa-eye"></i>
          </button>
        </p>
      </div>
    </div>
  </div>
</div>
