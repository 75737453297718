import {
    Component,
    ElementRef,
    HostListener,
    Input,
    EventEmitter,
    Output,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Attachment } from 'src/app/model/attachment';

@Component({
    selector: 'app-attachment-upload',
    templateUrl: './attachment-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AttachmentUploadComponent,
            multi: true,
        },
    ],
    styleUrls: ['./file-upload.component.css'],
})
export class AttachmentUploadComponent implements ControlValueAccessor, OnInit {
    @Input() property: FormGroup;

    @Input() currentAttachmentUrl;
    @Input() currentAttachmentName;
    @Input() indexOnArrayForm;
    @Output() currentAttachmentUrlChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() currentAttachmentNameChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() changeValue: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('fileInput') fileInput: any = null;

    onChange: (file: any) => void;
    isDialogVisible = false;

    @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
        const file = event && event.item(0);
        this.onChange(file);
    }

    generatedKey: string = null;

    fileForm = this.fb.group({
        file: [null],
    });

    constructor(
        private host: ElementRef<HTMLInputElement>,
        protected confirmationService: ConfirmationService,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.generatedKey = `deleteAttachment-${this.indexOnArrayForm}(${this.property.value.name})`;
    }

    writeValue(value: null) {
        this.host.nativeElement.value = '';
    }

    registerOnChange(fn: (file: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: (file: any) => void) {}

    remove() {
        this.currentAttachmentUrl = '';
        this.currentAttachmentUrlChange.emit('');
        this.currentAttachmentNameChange.emit('');
        this.changeValue.emit('');

        this.fileForm.get('file').reset();
    }

    confirmDelete() {
        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            key: this.generatedKey,
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () => this.remove(),
        });
    }

    showDialog() {
        this.isDialogVisible = true;
    }

    selectAttachment(attachment: Attachment) {
        console.log('attachment', attachment);

        const attachmentUrl = attachment.url;
        const attachmentName = attachment.file_name;
        this.onChange(attachmentUrl);
        this.currentAttachmentUrl = attachmentUrl;
        this.currentAttachmentUrlChange.emit(attachmentUrl);
        this.currentAttachmentName = attachmentName;
        this.currentAttachmentNameChange.emit(attachmentName);
        this.changeValue.emit(attachmentUrl);
        this.isDialogVisible = false;
    }
}
