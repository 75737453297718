<p-toast></p-toast>
<p-confirmDialog icon="fa fa-question-circle" #cd>
  <footer>
    <button type="button" pButton icon="ui-icon-undo" label="No" (click)="cd.reject()"></button>
    <button type="button" pButton icon="ui-icon-check" label="Sì" (click)="cd.accept()"></button>
  </footer>
</p-confirmDialog>

<p-overlayPanel #op [dismissable]="true" appendTo="body" styleClass="clearfix">
  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="text"
      name="name"
      placeholder="Nome"
      [(ngModel)]="service.search.like.name"
    />
  </div>
  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="value"
      name="value"
      placeholder="Valore"
      [(ngModel)]="service.search.like.value"
    />
  </div>
  <div class="form-group cleafix">
    <div class="row">
      <div class="col-sm-6">
        <button type="button" class="btn btn-success btn-block" (click)="reload(table); op.hide()">
          Cerca
        </button>
      </div>
      <div class="col-sm-6">
        <button type="button" class="btn btn-default btn-block" (click)="reset(table); op.hide()">
          Reset
        </button>
      </div>
    </div>
  </div>
</p-overlayPanel>

<div class="main-content clearfix">
  <div class="list-wrapper clearfix">
    <div class="content clearfix">
      <h4>
        <i class="fa fa-tasks" aria-hidden="true"></i>
        Listabili
      </h4>
      <div class="clearfix"></div>
      <div class="clearfix"></div>
      <div class="filter-wrapper clearfix">
        <button
          type="text"
          class="btn btn-filter"
          label="Basic"
          appendTo="body"
          (click)="op.toggle($event)"
        >
          <i class="fa fa-sliders" aria-hidden="true"></i>
          Cerca
        </button>

        <div class="tags-wrapper">
          <div class="tag-item" *ngIf="service.search.like.name">
            <strong>nome:</strong>
            {{ service.search.like.name }}
            <button (click)="service.search.like.name = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="tag-item" *ngIf="service.search.like.value">
            <strong>valore:</strong>
            {{ service.search.like.value }}
            <button (click)="service.search.like.value = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="panel panel-default">
        <div class="panel-body list">
          <div class="row">
            <p-table
              [value]="model"
              [loading]="loading"
              [lazy]="true"
              (onLazyLoad)="lazyLoad($event)"
              [paginator]="true"
              [responsive]="true"
              [rows]="service.search.pageSize"
              [totalRecords]="service.listSize"
              [resizableColumns]="true"
              #table
            >
              <ng-template pTemplate="header" let-columns class="search-filter">
                <tr class="sort-header">
                  <th class="RowIndex"></th>
                  <th pResizableColumn [pSortableColumn]="'name'">
                    Nome
                    <p-sortIcon [field]="'name'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'value'">
                    Valore
                    <p-sortIcon [field]="'value'"></p-sortIcon>
                  </th>
                  <th class="Operations">Operazioni</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="colspan">
                    Nessun risultato.
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                  <td class="RowIndex">{{ rowIndex + 1 }}</td>
                  <td class="ui-resizable-column">{{ rowData.name }}</td>
                  <td class="ui-resizable-column">
                    {{ rowData.value }}
                  </td>
                  <td>
                    <div class="btn-group">
                      <button
                        type="button"
                        pButton
                        (click)="edit(rowData)"
                        class="btn btn-success btn-sm"
                        icon="fa fa-edit"
                      ></button>
                      <button
                        type="button"
                        pButton
                        (click)="confirmDelete(rowData)"
                        class="btn btn-danger btn-sm"
                        icon="fa fa-trash"
                      ></button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <div class="panel panel-default">
        <div class="panel-body view">
          <div class="content clearfix">
            <form [formGroup]="registerForm">
              <div class="form-group row clearfix">
                <div class="col-sm-12">
                  <label *ngIf="action != 'Salva'">Aggiorna listabile</label>
                  <label *ngIf="action != 'Aggiorna'">Crea una nuova listabile</label>
                  <div class="clearfix"></div>
                  <hr />
                </div>

                <div class="clearfix"></div>
                <div class="col-sm-12">
                  <div class="form-group row clearfix">
                    <div class="col-sm-3">
                      <label>
                        Nome
                        <span *ngIf="name | isFieldRequired">(*)</span>
                        :
                      </label>
                    </div>

                    <div class="col-sm-9">
                      <input
                        class="form-control"
                        pInputText
                        type="text"
                        name="name"
                        placeholder="Nome"
                        formControlName="name"
                      />
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="form-group row clearfix">
                    <div class="col-sm-3">
                      <label>Valore (*):</label>
                    </div>
                    <div class="col-sm-9">
                      <input
                        class="form-control"
                        pInputText
                        type="text"
                        name="value"
                        placeholder="Valore"
                        formControlName="value"
                      />
                    </div>
                  </div>
                  <div class="clearfix"></div>

                  <div class="btn-group pull-right">
                    <button
                      type="button"
                      class="btn btn-success"
                      *ngIf="action != 'Aggiorna'"
                      (click)="save()"
                      [disabled]="registerForm.invalid"
                    >
                      Salva
                    </button>
                    <button
                      type="button"
                      class="btn btn-success"
                      *ngIf="action != 'Salva'"
                      (click)="update()"
                      [disabled]="registerForm.invalid"
                    >
                      Aggiorna
                    </button>
                    <button type="button" class="btn btn-warning" (click)="restart()">
                      Annulla
                    </button>
                  </div>

                  <div class="clearfix"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
