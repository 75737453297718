<div class="header">
  <div class="logo">
    <a routerLink="/app/homepage"><img src="assets/images/logo.jpg" /></a>
  </div>
  <div class="menu">
    {{ appVersion }}
  </div>
  <div class="menu">Benvenuto {{ username }} ({{ primaryRole }})</div>
</div>
<div class="sidenav">
  <app-menu></app-menu>
</div>

<div class="main-wrapper">
  <router-outlet></router-outlet>
</div>
