<div *ngIf="isTableDataSourceDeterminated">
  <p-toast></p-toast>
  <p-confirmDialog icon="fa fa-question-circle" #cd>
    <footer>
      <button type="button" pButton icon="ui-icon-undo" label="No" (click)="cd.reject()"></button>
      <button type="button" pButton icon="ui-icon-check" label="Sì" (click)="cd.accept()"></button>
    </footer>
  </p-confirmDialog>

  <p-overlayPanel #op [dismissable]="true" appendTo="body" styleClass="clearfix">
    <div class="form-group cleafix">
      <input
        class="form-control"
        pInputText
        type="text"
        name="name"
        placeholder="Nome"
        [(ngModel)]="tableUtilsService.dataService.search.like.name"
      />
    </div>
    <div class="form-group cleafix">
      <input
        class="form-control"
        pInputText
        type="text"
        name="description"
        placeholder="Descrizione"
        [(ngModel)]="tableUtilsService.dataService.search.like.description"
      />
    </div>
    <div class="form-group cleafix">
      <input
        class="form-control"
        pInputText
        type="text"
        name="container_uuid"
        placeholder="Codice Gara"
        [(ngModel)]="tableUtilsService.dataService.search.like.container_uuid"
        [disabled]="tableUtilsService.dataService.search.not.container_uuid"
      />
    </div>
    <div class="form-group cleafix" style="display: flex; justify-content: space-between;">
      <label>Cataloghi senza codice gara</label>
      <p-inputSwitch
        [ngModel]="tableUtilsService.dataService.search.not.container_uuid"
        (onChange)="handleFilterSenzaCodiceGara($event)"
        inputStyleClass="form-control"
      ></p-inputSwitch>
    </div>
    <div class="form-group cleafix">
      <div class="row">
        <div class="col-sm-6">
          <button
            type="button"
            class="btn btn-success btn-block"
            (click)="tableUtilsService.reload(table); op.hide()"
          >
            Cerca
          </button>
        </div>
        <div class="col-sm-6">
          <button
            type="button"
            class="btn btn-default btn-block"
            (click)="tableUtilsService.reset(table); op.hide()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </p-overlayPanel>

  <div class="page-title-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <h3>
            Gara:
            <strong>{{ element?.name }}</strong>
            <p>
              <span>
                Descrizione:
                <strong>{{ element?.description }}</strong>
              </span>
              <span>
                Codice:
                <strong>{{ element?.codiceGara }}</strong>
              </span>
            </p>
          </h3>
        </div>

        <div class="col-sm-6">
          <div class="sections">
            <div class="row">
              <div class="item col-sm-4" (click)="view()">Gestione Gara</div>

              <div class="item col-sm-4 active">Gestione Cataloghi</div>

              <div class="item col-sm-4" (click)="addArticlesMatthias()">Gestione Articoli</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="wrapper-md">
    <div class="list-wrapper clearfix">
      <div class="content clearfix">
        <div class="row">
          <div class="col-sm-6">
            <h4>Gestione Cataloghi</h4>
          </div>
          <div *ngIf="!element.archived && !element.closed" class="col-sm-6">
            <button class="btn btn-add pull-right" type="button" (click)="newCatalogue()" label="">
              <i class="fa fa-plus-circle" aria-hidden="true"></i>
              Aggiungi Catalogo
            </button>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <form [formGroup]="registerForm">
          <div class="form-group row clearfix">
            <div [ngClass]="status || element.archived || element.closed ? 'hidden' : 'col-sm-6'">
              <div class="panel panel-default clearfix">
                <div class="panel-heading">Seleziona Cataloghi</div>
                <div class="panel-body view clearfix">
                  <div class="filter-wrapper clearfix">
                    <button
                      type="text"
                      class="btn btn-filter"
                      label="Basic"
                      appendTo="body"
                      (click)="op.toggle($event)"
                    >
                      <i class="fa fa-sliders" aria-hidden="true"></i>
                      Cerca
                    </button>

                    <div class="tags-wrapper">
                      <div class="tag-item" *ngIf="tableUtilsService.dataService.search.like.name">
                        <strong>nome:</strong>
                        {{ tableUtilsService.dataService.search.like.name }}
                        <button
                          (click)="
                            tableUtilsService.dataService.search.like.name = null;
                            tableUtilsService.reload(table)
                          "
                          class="btn-close"
                        >
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                      </div>
                      <div
                        class="tag-item"
                        *ngIf="tableUtilsService.dataService.search.like.container_uuid"
                      >
                        <strong>codice gara:</strong>
                        {{ tableUtilsService.dataService.search.like.container_uuid }}
                        <button
                          (click)="
                            tableUtilsService.dataService.search.like.container_uuid = null;
                            tableUtilsService.reload(table)
                          "
                          class="btn-close"
                        >
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                      </div>
                      <div
                        class="tag-item"
                        *ngIf="tableUtilsService.dataService.search.not.container_uuid"
                      >
                        <strong>Senza codice gara:</strong>
                        {{ tableUtilsService.dataService.search.not.container_uuid ? 'Sì' : 'No' }}
                        <button
                          (click)="
                            tableUtilsService.dataService.search.not.container_uuid = null;
                            tableUtilsService.reload(table)
                          "
                          class="btn-close"
                        >
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <p-table
                    [value]="tableUtilsService.model"
                    [loading]="tableUtilsService.loadingTable"
                    [lazy]="true"
                    (onLazyLoad)="tableUtilsService.lazyLoad($event)"
                    [paginator]="true"
                    [rowsPerPageOptions]="[10, 50, 100]"
                    [responsive]="true"
                    [rows]="tableUtilsService.dataService.search.pageSize"
                    [totalRecords]="tableUtilsService.dataService.listSize"
                    [resizableColumns]="true"
                    stateStorage="session"
                    stateKey="{{ router.url }}_sx"
                    #table
                  >
                    <ng-template pTemplate="header" let-columns class="search-filter">
                      <tr class="sort-header">
                        <th pResizableColumn [pSortableColumn]="'name'">
                          Nome
                          <p-sortIcon [field]="'name'"></p-sortIcon>
                        </th>
                        <th pResizableColumn [pSortableColumn]="'container_uuid'">
                          Codice Gara
                          <p-sortIcon [field]="'container_uuid'"></p-sortIcon>
                        </th>
                        <th class="Operations"></th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td [attr.colspan]="3">Nessun Catalogo.</td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                      <tr>
                        <td class="ui-resizable-column">
                          {{ rowData.name }}
                        </td>
                        <td class="ui-resizable-column">
                          {{ rowData.container_uuid }}
                        </td>
                        <td>
                          <div>
                            <button
                              type="button"
                              pButton
                              pTooltip="Clona"
                              tooltipPosition="top"
                              (click)="cloneCatalogue(rowData)"
                              class="btn btn-default btn-sm pull-right"
                              icon="fa fa-clone"
                            ></button>
                            <button
                              *ngIf="!rowData.container_uuid && rowData.monopage"
                              type="button"
                              pButton
                              (click)="cloneCatalogueAndFillItWithContainerPrintables(rowData)"
                              class="btn btn-warning btn-sm pull-right"
                              pTooltip="Clona e riempi il nuovo catalogo con tutti gli articolo della gara"
                              icon="fa fa-plus"
                            ></button>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>

            <div
              [ngClass]="status || element.archived || element.closed ? 'col-sm-12' : 'col-sm-6'"
            >
              <div class="clearfix"></div>
              <div class="panel panel-default clearfix">
                <div class="panel-heading">
                  Lista cataloghi in gara
                  <button class="btn btn-danger pull-right p-ml-4" (click)="deleteAll()">
                    Elimina tutti
                  </button>
                  <button
                    *ngIf="!element.closed && !element.archived"
                    class="btn btn-expand pull-right"
                    (click)="clickEvent()"
                  >
                    {{ buttonName }}
                  </button>
                </div>

                <div class="panel-body view clearfix">
                  <p-table
                    [value]="containerElementForms.value"
                    [paginator]="true"
                    [rows]="10"
                    [reorderableColumns]="true"
                    stateStorage="session"
                    stateKey="{{ router.url }}_dx"
                    [rowsPerPageOptions]="[10, 50, 100]"
                    [responsive]="true"
                    #table
                  >
                    <ng-template pTemplate="header" let-columns class="search-filter">
                      <tr class="sort-header">
                        <th style="width: 2.5em;"></th>
                        <th>
                          Cataloghi
                          <p-sortIcon field="code"></p-sortIcon>
                        </th>
                        <th></th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-rowData
                      let-columns="columns"
                      let-index="rowIndex"
                    >
                      <tr [pReorderableRow]="index">
                        <td>
                          <i class="fa fa-bars" pReorderableRowHandle></i>
                        </td>
                        <td>
                          {{ cataloguesTranscode.get(rowData.uuid) }}
                        </td>
                        <td>
                          -

                          <button
                            *ngIf="!element.archived && !element.closed"
                            type="button"
                            pButton
                            class="btn btn-sm btn-trash pull-right"
                            (click)="confirmDelete(index, rowData.uuid)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>

                          <button
                            *ngIf="!element.archived && !element.closed"
                            type="button"
                            pButton
                            class="btn btn-sm btn-success pull-right"
                            (click)="editCatalogue(rowData.uuid)"
                          >
                            <i class="fa fa-edit"></i>
                          </button>

                          <button
                            *ngIf="!element.archived && !element.closed"
                            type="button"
                            pButton
                            (click)="addArticles(rowData.uuid)"
                            tooltipPosition="top"
                            pTooltip="Aggiungi Articoli"
                            class="btn btn-primary btn-sm pull-right"
                          >
                            <i class="fa fa-file-text"></i>
                          </button>

                          <button
                            type="button"
                            pButton
                            class="btn btn-sm btn-info pull-right"
                            (click)="viewCatalogue(rowData.uuid)"
                          >
                            <i class="fa fa-eye"></i>
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
