import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractService } from '../common/abstract-service';
import { Search } from '../common/search';
import { Tipology } from '../model/tipology';
import { PRINT_TEMPLATES_API_PATH, TIPOLOGIES_API_PATH } from '../constants/constants';
import { tap, map } from 'rxjs/operators';

import { MessageService, SelectItem } from 'primeng/api';
import { ConfigurationService } from './configuration.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TipologyService extends AbstractService<Tipology> {
    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        configurationService: ConfigurationService
    ) {
        super(configurationService.getValue(TIPOLOGIES_API_PATH), httpClient, messageService);
    }

    public getId(element: Tipology) {
        return element.uuid;
    }

    public buildSearch() {
        this.search = new Search<Tipology>(Tipology);
    }

    getAllListAsItems(): Observable<SelectItem[]> {
        return this.getAllList().pipe(
            map(tipology => tipology.map(t => ({ label: t.name, value: t })))
        );
    }
}
