import { Pipe, PipeTransform } from '@angular/core';
import {
    DomSanitizer,
    SafeStyle,
    SafeResourceUrl,
    SafeScript,
    SafeHtml,
    SafeUrl
} from '@angular/platform-browser';

@Pipe({
    name: 'safe',
    pure: true
})
export class SafePipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {}

    transform(
        value: string,
        type: string
    ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(value);
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'resourceUrl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
            default:
                console.error('Please define the type as input to the Safe pipe');
                return '';
        }
    }
}
