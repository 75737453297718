<p-toast></p-toast>
<p-confirmDialog icon="fa fa-question-circle" #cd>
  <footer>
    <button type="button" pButton icon="ui-icon-undo" label="No" (click)="cd.reject()"></button>
    <button type="button" pButton icon="ui-icon-check" label="Sì" (click)="cd.accept()"></button>
  </footer>
</p-confirmDialog>

<div class="main-content clearfix with-sidebar">
  <form [formGroup]="registerForm">
    <div class="row">
      <div class="col-sm-8">
        <h4 *ngIf="element.uuid">
          <i aria-hidden="true" class="fa fa-th-list"></i>
          Aggiorna Catalogo
        </h4>
        <h4 *ngIf="!element.uuid">
          <i aria-hidden="true" class="fa fa-th-list"></i>
          Crea un nuovo Catalogo
        </h4>
      </div>
      <div class="col-sm-4">
        <button type="button" class="btn btn-default pull-right" (click)="goToList()">
          Annulla
        </button>
      </div>
    </div>
    <div class="clearfix"></div>
    <br />

    <div class="panel panel-default">
      <div class="panel-body view">
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Nome
              <span *ngIf="name | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="name"
              placeholder="Nome"
              formControlName="name"
            />
          </div>
        </div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Descrizione
              <span *ngIf="description | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <textarea
              class="form-control"
              pInputTextarea
              type="text"
              name="Descrizione"
              placeholder="..."
              formControlName="description"
            ></textarea>
          </div>
        </div>
        <div class="clearfix"></div>

        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label class="label-text">
              Codice Gara
              <span *ngIf="container_uuid | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-dropdown
              [options]="codiciGaraItems"
              [showClear]="true"
              formControlName="container_uuid"
              placeholder="Codice Gara"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label class="label-text">
              Tags
              <span *ngIf="tags | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-chips formControlName="tags"></p-chips>
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label class="label-text">Archiviato :</label>
          </div>
          <div class="col-sm-9">
            <p-inputSwitch
              (onChange)="onChangeArchived($event)"
              [ngModel]="archivedVal"
              [ngModelOptions]="{ standalone: true }"
              inputStyleClass="form-control"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label class="label-text">Stampa allegati alla fine :</label>
          </div>
          <div class="col-sm-9">
            <p-inputSwitch
              (onChange)="withAttchamentsAtEndFn($event)"
              formControlName="withAttchamentsAtEnd"
              inputStyleClass="form-control"
            ></p-inputSwitch>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label class="label-text">Stampa allegato relativo dopo ogni articolo :</label>
          </div>
          <div class="col-sm-9">
            <p-inputSwitch
              (onChange)="withAttchamentAfterSingleArticleFn($event)"
              formControlName="withAttchamentAfterSingleArticle"
              inputStyleClass="form-control"
            ></p-inputSwitch>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label class="label-text">Stampa solo allegati :</label>
          </div>
          <div class="col-sm-9">
            <p-inputSwitch
              (onChange)="onlyAttchamentsFn($event)"
              formControlName="onlyAttchaments"
              inputStyleClass="form-control"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label class="label-text">Stampa mono pagina :</label>
          </div>
          <div class="col-sm-9 row">
            <div class="col-sm-2">
              <p-inputSwitch
                (onChange)="monoPageFn($event)"
                formControlName="monopage"
                inputStyleClass="form-control"
              ></p-inputSwitch>
            </div>
            <div class="col-sm-10">
              <p-dropdown
                [options]="allTemplatesItems"
                (onChange)="PVAL_displayProperties($event)"
                [disabled]="!monopage.value"
                formControlName="monopage_template_uuid"
                placeholder="Seleziona Template..."
                id="allTemplates"
                [style]="{ width: '100%' }"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-default">
      <div class="panel-heading">Proprietà</div>

      <div class="panel-body view">
        <div formArrayName="properties">
          <div
            class="form-group cleafix"
            *ngFor="let property of propertyForms.controls; let i = index"
            [formGroupName]="i"
          >
            <ng-container *ngIf="!property.value.isDefinitionMode">
              <app-property-valorization
                [property]="property"
                [i]="i"
                [iconsItems]="iconsItems"
                [locale]="langIt"
              ></app-property-valorization>
            </ng-container>
            <ng-container *ngIf="property.value.isDefinitionMode">
              <app-property-definition
                [property]="property"
                [i]="i"
                [iconsItems]="iconsItems"
                [barcodeTypesItems]="barcodeTypesItems"
                [listableNamesItems]="listableNamesItems$ | async"
                [propertyTypesItems]="propertyTypesCatalogueItems"
                [propertyValueName]="'value'"
                [hideName]="true"
                [hideMandatory]="true"
                [hideOrder]="true"
                [locale]="langIt"
                [allTipologiesItems]="allTipologiesItems$ | async"
                (deletePropertyEvent)="deleteProperty($event)"
                (valueChangedFromChildEvent)="valueChangedFromChild($event)"
                (propertyTypeSelectedEvent)="propertyTypeSelected($event)"
              ></app-property-definition>
            </ng-container>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="btn-group">
          <button type="button" class="btn btn-success" (click)="addEmptyProperty()">
            <i class="fa fa-plus"></i>
            Aggiungi proprietà
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="fixed-sidebar">
  <div class="panel panel-default">
    <div class="panel-heading">Opzioni</div>

    <div class="panel-body view">
      <button
        type="button"
        class="btn btn-success btn-block"
        *ngIf="!element.uuid"
        (click)="save()"
        [disabled]="registerForm.invalid"
      >
        Salva
      </button>
      <button
        type="button"
        class="btn btn-success btn-block"
        *ngIf="element.uuid"
        (click)="complexUpdate()"
        [disabled]="registerForm.invalid"
      >
        Aggiorna
      </button>

      <button
        *ngIf="element.uuid"
        type="button"
        class="btn btn-danger btn-block"
        (click)="confirmDelete()"
      >
        <i class="fa fa-trash"></i>
        Elimina
      </button>
    </div>
  </div>
</div>
