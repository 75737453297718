<p-toast></p-toast>
<p-overlayPanel #op [dismissable]="true" appendTo="body" styleClass="clearfix">
  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="text"
      name="name"
      placeholder="Nome"
      [(ngModel)]="service.search.like.name"
    />
  </div>

  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="description"
      name="description"
      placeholder="Descrizione"
      [(ngModel)]="service.search.like.description"
    />
  </div>

  <div class="form-group cleafix">
    <p-dropdown
      [options]="printTemplateTypeAsItems"
      [(ngModel)]="service.search.obj.printTemplateType"
      id="printTemplateType"
      placeholder="Tipo..."
      [style]="{ width: '100%' }"
    ></p-dropdown>
  </div>

  <div class="form-group cleafix p-d-flex p-jc-between p-ai-center">
    <label>Default</label>
    <p-inputSwitch
      [(ngModel)]="service.search.obj.default_template"
      inputStyleClass="form-control"
    ></p-inputSwitch>
  </div>

  <div class="form-group cleafix" style="display: flex; justify-content: space-between;">
    <label>Archiviato</label>
    <p-inputSwitch
      (onChange)="onFilterArchived($event)"
      [ngModel]="archivedFilter"
      inputStyleClass="form-control"
    ></p-inputSwitch>
  </div>

  <div class="form-group cleafix">
    <div class="row">
      <div class="col-sm-6">
        <button type="button" class="btn btn-success btn-block" (click)="reload(table); op.hide()">
          Cerca
        </button>
      </div>
      <div class="col-sm-6">
        <button
          type="button"
          class="btn btn-default btn-block"
          (click)="archivedFilter = false; reset(table); op.hide()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</p-overlayPanel>

<div class="main-content clearfix">
  <div class="list-wrapper clearfix">
    <div class="content clearfix">
      <div class="row">
        <div class="col-sm-8">
          <h4>
            <i class="fa fa-address-card-o" aria-hidden="true"></i>
            Template
          </h4>
        </div>
        <div class="col-sm-4">
          <button type="button" [disabled]="loading" (click)="new()" class="btn btn-add pull-right">
            <i class="fa fa-plus-circle"></i>
            Aggiungi
          </button>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="clearfix"></div>
      <div class="filter-wrapper clearfix">
        <button
          type="text"
          class="btn btn-filter"
          label="Basic"
          appendTo="body"
          (click)="op.toggle($event)"
        >
          <i class="fa fa-sliders" aria-hidden="true"></i>
          Cerca
        </button>

        <div class="tags-wrapper">
          <div class="tag-item" *ngIf="service.search.like.name">
            <strong>nome:</strong>
            {{ service.search.like.name }}
            <button (click)="service.search.like.name = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.like.description">
            <strong>descrizione:</strong>
            {{ service.search.like.description }}
            <button
              (click)="service.search.like.description = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.obj.printTemplateType">
            <strong>tipo:</strong>
            {{ printTemplateTypeMap?.get(service.search.obj.printTemplateType) }}
            <button
              (click)="service.search.obj.printTemplateType = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.obj.default_template">
            <strong>default:</strong>
            Si
            <button
              (click)="service.search.obj.default_template = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="archivedFilter">
            <strong>archiviato:</strong>
            {{ archivedFilter ? 'Si' : 'No' }}
            <button
              (click)="archivedFilter = false; service.search.obj.active = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-body list">
          <div class="row">
            <p-table
              [value]="model"
              [loading]="loading"
              [lazy]="true"
              (onLazyLoad)="lazyLoad($event)"
              [paginator]="true"
              [responsive]="true"
              [rows]="service.search.pageSize"
              [totalRecords]="service.listSize"
              [resizableColumns]="true"
              stateStorage="session"
              [stateKey]="path + '_table'"
              #table
            >
              <ng-template pTemplate="header" let-columns class="search-filter">
                <tr class="sort-header">
                  <th class="RowIndex"></th>
                  <th pResizableColumn [pSortableColumn]="'name'">
                    Nome
                    <p-sortIcon [field]="'name'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'description'">
                    Descrizione
                    <p-sortIcon [field]="'description'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'printTemplateType'">
                    Tipo
                    <p-sortIcon [field]="'printTemplateType'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'default_template'">
                    Default
                    <p-sortIcon [field]="'default_template'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'active'">
                    Archiviato
                    <p-sortIcon [field]="'active'"></p-sortIcon>
                  </th>
                  <th class="Operations">Operazioni</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="colspan">Nessun risultato.</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                  <td class="RowIndex">{{ rowIndex + 1 }}</td>
                  <td class="ui-resizable-column">
                    {{ rowData.name }}
                  </td>
                  <td class="ui-resizable-column">
                    {{ rowData.description }}
                  </td>
                  <td class="ui-resizable-column">
                    {{ printTemplateTypeMap?.get(rowData.printTemplateType) }}
                  </td>
                  <td class="ui-resizable-column">
                    <i
                      class="fa fa-{{ rowData.default_template ? 'check' : 'times' }}"
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td class="ui-resizable-column">
                    <i class="fa fa-{{ !rowData.active ? 'check' : 'times' }}"></i>
                  </td>
                  <td>
                    <div class="btn-group">
                      <button
                        type="button"
                        pButton
                        (click)="print(rowData.uuid)"
                        class="btn btn-default btn-sm"
                        pTooltip="Stampa"
                        tooltipPosition="top"
                        icon="fa fa-print"
                      ></button>

                      <button
                        type="button"
                        pButton
                        (click)="edit(rowData)"
                        class="btn btn-success btn-sm"
                        pTooltip="Modifica"
                        tooltipPosition="top"
                        icon="fa fa-edit"
                      ></button>

                      <button
                        type="button"
                        pButton
                        (click)="view(rowData)"
                        class="btn btn-info btn-sm"
                        pTooltip="Visualizza"
                        tooltipPosition="top"
                        icon="fa fa-eye"
                      ></button>
                      <button
                        type="button"
                        pButton
                        pTooltip="Clona"
                        tooltipPosition="top"
                        (click)="clone(rowData)"
                        class="btn btn-default btn-sm"
                        icon="fa fa-clone"
                      ></button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
