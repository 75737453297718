<p-toast></p-toast>

<ng-container *ngIf="element">
  <div class="page-title-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <h3>
            Gara:
            <strong>{{ element?.name }}</strong>
            <p>
              <span>
                Descrizione:
                <strong>{{ element?.description }}</strong>
              </span>
              <br />
              <span>
                Codice:
                <strong>{{ element?.codiceGara }}</strong>
              </span>
            </p>
          </h3>
        </div>

        <div class="col-sm-6">
          <div class="sections">
            <div class="row">
              <div class="item col-sm-4 active">Gestione Gara</div>

              <div class="item col-sm-4" (click)="addCatalogues()">Gestione Cataloghi</div>

              <div class="item col-sm-4" (click)="addArticles()">Gestione Articoli</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-content clearfix">
    <div class="row">
      <div class="col-sm-6">
        <h4>Gestione Gara</h4>
      </div>
      <div class="col-sm-6">
        <div class="btn-group pull-right">
          <button type="button" class="btn btn-default pull-right" (click)="goToList()">
            <i class="fa fa-arrow-left"></i>
            Torna alla Lista
          </button>
          <button
            *ngIf="!element.archived && !element.closed"
            type="button"
            (click)="edit(element)"
            class="btn btn-info pull-right"
          >
            <i class="fa fa-edit"></i>
            Modifica Gara
          </button>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
    <br />
    <div class="row">
      <div class="col-sm-12">
        <div class="panel panel-default">
          <div class="panel-heading">Informazioni</div>
          <div class="panel-body view">
            <table class="table">
              <tbody>
                <tr>
                  <td width="200">Nome:</td>
                  <td>{{ element?.name }}</td>
                </tr>
                <tr>
                  <td width="200">Gara Id:</td>
                  <td>{{ element?.codiceGara }}</td>
                </tr>
                <tr>
                  <td>Descrizione:</td>
                  <td>{{ element?.description }}</td>
                </tr>
                <tr>
                  <td>Riferimenti:</td>
                  <td>{{ element?.reference }}</td>
                </tr>
                <tr>
                  <td>Ente / Tipologia:</td>
                  <td>{{ element?.entity_type }}</td>
                </tr>
                <tr>
                  <td>Settore:</td>
                  <td>{{ element?.sector }}</td>
                </tr>
                <tr>
                  <td>Indirizzo di consegna campionatura:</td>
                  <td>{{ element?.delivery_address }}</td>
                </tr>
                <tr>
                  <td>Scadenza:</td>
                  <td>{{ element?.deadline | date: 'dd/MM/yyyy' }}</td>
                </tr>
                <tr>
                  <td>Offerente:</td>
                  <td>{{ element?.bidder }}</td>
                </tr>
                <tr>
                  <td>Cliente di portafoglio:</td>
                  <td>{{ element?.portfolioCustomer ? 'Si' : 'No' }}</td>
                </tr>
                <tr></tr>
                <tr>
                  <td>Proroga:</td>
                  <td>{{ element?.prorogation | date: 'dd/MM/yyyy' }}</td>
                </tr>
                <tr>
                  <td>Note:</td>
                  <td>{{ element?.notes }}</td>
                </tr>
                <tr>
                  <td>Archiviata:</td>
                  <td>{{ element?.archived ? 'Si' : 'No' }}</td>
                </tr>
                <tr>
                  <td>Congelata:</td>
                  <td>{{ element?.closed ? 'Si' : 'No' }}</td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-sm-12">
                <label>Proprieta:</label>
                <div style="display: flex; float: right;">
                  <div style="margin-right: 10px;">
                    <label *ngIf="!openAllTabs">Apri tutto</label>
                    <label *ngIf="openAllTabs">Chiudi tutto</label>
                  </div>
                  <p-inputSwitch
                    [(ngModel)]="openAllTabs"
                    inputStyleClass="form-control"
                  ></p-inputSwitch>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="col-sm-12">
                <ng-container *ngFor="let property of element?.properties; let i = index">
                  <app-property-view
                    [openAllTabs]="openAllTabs"
                    [property]="property"
                    [i]="i"
                    [propertyValueName]="'value'"
                    [propertyTypesMap]="propertyTypesMap"
                  ></app-property-view>
                </ng-container>
              </div>
            </div>

            <div class="clearfix"></div>
          </div>
        </div>
      </div>
      <div class="col-sm-6" *ngIf="false">
        <p-tabView>
          <p-tabPanel header="Articoli">
            <div class="panel panel-default">
              <div class="panel-body view">
                <div class="row">
                  <div class="col-sm-6">
                    <h5>Articoli aggiunti alla gara</h5>
                  </div>
                  <div class="col-sm-6">
                    <button
                      type="button"
                      (click)="addArticles()"
                      class="btn btn-success pull-right"
                    >
                      <i class="fa fa-file-text"></i>
                      Gestione Articoli
                    </button>
                  </div>
                </div>
                <div class="clearfix"></div>
                <hr />
                <div class="clearfix"></div>
                <div class="table" *ngIf="printables">
                  <p-table [value]="printables">
                    <ng-template pTemplate="header" let-columns class="search-filter">
                      <tr class="sort-header">
                        <th style="width: 2.5em;"></th>
                        <th>Nome</th>
                        <th>Descrizione</th>
                        <th>Tipologia</th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-rowData
                      let-columns="columns"
                      let-index="rowIndex"
                    >
                      <tr [pReorderableRow]="index">
                        <td>
                          {{ index + 1 }}
                        </td>
                        <td>
                          {{ rowData.name }}
                        </td>
                        <td>
                          {{ rowData.description }}
                        </td>
                        <td>
                          {{ tipologiesTranscode.get(rowData?.tipology_uuid) }}
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Cataloghi">
            <div class="panel panel-default">
              <div class="panel-body view">
                <div class="row">
                  <div class="col-sm-6">
                    <h5>Cataloghi aggiunti alla gara</h5>
                  </div>
                  <div class="col-sm-6">
                    <button
                      type="button"
                      (click)="addCatalogues()"
                      class="btn btn-success pull-right"
                    >
                      <i class="fa fa-th-list"></i>
                      Gestione Cataloghi
                    </button>
                  </div>
                </div>
                <div class="clearfix"></div>
                <hr />
                <div class="clearfix"></div>

                <div class="table">
                  <p-table [value]="catalogues">
                    <ng-template pTemplate="header" let-columns class="search-filter">
                      <tr class="sort-header">
                        <th style="width: 2.5em;"></th>
                        <th>Nome</th>
                        <th>Descrizione</th>
                        <th>Stampa</th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-rowData
                      let-columns="columns"
                      let-index="rowIndex"
                    >
                      <tr [pReorderableRow]="index">
                        <td>
                          {{ index + 1 }}
                        </td>
                        <td>
                          {{ rowData.name }}
                        </td>
                        <td>
                          {{ rowData?.description }}
                        </td>
                        <td>
                          <button
                            type="button"
                            pTooltip="Stampa"
                            pButton
                            class="btn btn-sm btn-success"
                            (click)="
                              printCatalogue(
                                rowData.uuid,
                                rowData.monopage,
                                rowData.monopage_mymetype
                              )
                            "
                          >
                            <i class="fa fa-print"></i>
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</ng-container>
