<ng-container *ngIf="jsonProperties">
  <ng-container *ngFor="let prop of jsonProperties">
    <div class="tag-item">
      <strong>{{ prop.name }}:</strong>
      {{ prop.value }}
      <strong>(Proprietà)</strong>
      <button (click)="removeJsonPropertyFilter(prop.name)" class="btn-close">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </ng-container>
</ng-container>
