import { Component, OnInit } from '@angular/core';
import { Catalogue } from '../../model/catalogue';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogueService } from '../../service/catalogue.service';
import { PrintableService } from '../../service/printable.service';
import { PrintTemplateService } from '../../service/print-template.service';

import { AbstractViewComponent } from '../../common/abstract-view-component';
import { ContainerService } from '../../service/container.service';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { propertyTypesCatalogueItems } from 'src/app/model/enum/property-type';
import { Group } from '../../model/group';

@Component({
    selector: 'app-catalogue-view',
    templateUrl: './catalogue-view.component.html',
    styleUrls: ['./catalogue-view.component.css'],
})
export class CatalogueViewComponent extends AbstractViewComponent<Catalogue> implements OnInit {
    gara: string;
    returnBack = false;
    printablesTranscode: Map<string, string> = new Map<string, string>();
    templatesTranscode: Map<string, string> = new Map<string, string>();
    propertyTypesMap: Map<string, string> = new Map<string, string>();
    openAllTabs = false;
    groups: Group[] = [];
    isPrinting = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected catalogueService: CatalogueService,
        public containerService: ContainerService,
        public printableService: PrintableService,
        public printTemplateService: PrintTemplateService,
        private location: Location
    ) {
        super(router, route, catalogueService, 'catalogues');
        this.element = new Catalogue();
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.route.snapshot.paramMap.get('returnBack')) {
            this.returnBack = true;
            this.navigateToList = () => this.location.back();
        }
        propertyTypesCatalogueItems.map((t) => this.propertyTypesMap.set(t.value, t.label));
    }

    postFind() {
        if (this.element && this.element.container_uuid) {
            this.containerService
                .find(this.element.container_uuid)
                .pipe(takeUntil(this.destroy$))
                .subscribe((cnt) => {
                    // ora ce l'ho
                    this.gara = cnt.name;
                    this.printableService
                        .groups(this.element.uuid)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((groups) => (this.groups = groups));
                });
        }

        if (this.element.monopage_template_uuid) {
            this.getPrintTemplateTranscode(this.element.monopage_template_uuid);
        }

        this.element?.catalogueElements?.map((element) => {
            if (!this.printablesTranscode.get(element.printable_uuid)) {
                this.printableService
                    .find(element.printable_uuid)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((p) => {
                        this.printablesTranscode.set(p.uuid, p.name);
                        console.log('printablesTranscode', this.printablesTranscode.get(p.uuid));

                        this.element.catalogueElements = this.element.catalogueElements.sort(
                            (el1, el2) => {
                                let item1Uuid = this.printablesTranscode.get(el1.printable_uuid);
                                let item2Uuid = this.printablesTranscode.get(el2.printable_uuid);
                                if (item1Uuid < item2Uuid) {
                                    return -1;
                                }
                                if (item1Uuid > item2Uuid) {
                                    return 1;
                                }
                                return 0;
                            }
                        );
                    });
            }

            if (element?.template_uuid && !this.templatesTranscode.get(element.template_uuid)) {
                this.getPrintTemplateTranscode(element.template_uuid);
            }
        });
    }

    getPrintTemplateTranscode(templateUuid: string) {
        if (this.templatesTranscode.get(templateUuid)) {
            return;
        }
        this.printTemplateService
            .find(templateUuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((t) => this.templatesTranscode.set(t.uuid, t.name));
    }

    goToTemplate() {
        const templateUuid = this.templatesTranscode.keys().next().value;
        this.router.navigate([`/templates/view/${templateUuid}`]);
    }

    fileName(uuid: string, mimetype: string): string {
        switch (mimetype) {
            case 'application/excel':
                return uuid + '.xls';
            case 'text/csv':
                return uuid + '.csv';
            case 'application/pdf':
                return uuid + '.pdf';
            default:
        }
    }

    print() {
        this.isPrinting = true;
        this.catalogueService
            .getPdf(this.element.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (response) => {
                    let mimetype;
                    let filenameDownload;
                    if (this.element.monopage_mymetype) {
                        mimetype = this.element.monopage_mymetype;
                        filenameDownload = this.fileName(
                            this.element.uuid,
                            this.element.monopage_mymetype
                        );
                    } else {
                        mimetype = 'application/pdf';
                        filenameDownload = this.element.uuid + '.pdf';
                    }
                    const newBlob = new Blob([response], {
                        type: mimetype,
                    });
                    if ((window.navigator as any).msSaveOrOpenBlob) {
                        (window.navigator as any).msSaveOrOpenBlob(newBlob, filenameDownload);
                    } else {
                        const a = document.createElement('a');
                        a.href = URL.createObjectURL(newBlob);
                        a.setAttribute('download', filenameDownload);
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        //  window.open(fileUrl);
                    }
                    this.isPrinting = false;
                },
                (err) => {
                    this.isPrinting = false;
                    this.service.addMessage({
                        severity: 'error',
                        summary: 'Errore',
                        detail: err.message,
                    });
                }
            );
    }

    printSingleGroup(groupBy: string, name: string) {
        this.isPrinting = true;
        this.catalogueService
            .getPdfSingleGroup(this.element.uuid, groupBy, name)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (response) => {
                    let mimetype;
                    let filenameDownload;
                    if (this.element.monopage_mymetype) {
                        mimetype = this.element.monopage_mymetype;
                        filenameDownload = this.fileName(
                            this.element.uuid,
                            this.element.monopage_mymetype
                        );
                    } else {
                        mimetype = 'application/pdf';
                        filenameDownload = this.element.uuid + '.pdf';
                    }
                    const newBlob = new Blob([response], {
                        type: mimetype,
                    });
                    if ((window.navigator as any).msSaveOrOpenBlob) {
                        (window.navigator as any).msSaveOrOpenBlob(newBlob, filenameDownload);
                    } else {
                        const a = document.createElement('a');
                        a.href = URL.createObjectURL(newBlob);
                        a.setAttribute('download', filenameDownload);
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        //  window.open(fileUrl);
                    }
                    this.isPrinting = false;
                },
                (err) => {
                    this.isPrinting = false;
                    this.service.addMessage({
                        severity: 'error',
                        summary: 'Errore',
                        detail: err.message,
                    });
                }
            );
    }

    printSinglePrintable(uuidPrintable) {
        this.isPrinting = true;
        this.catalogueService
            .getPdfSinglePrintable(uuidPrintable)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (response) => {
                    let mimetype;
                    let filenameDownload;
                    if (this.element.monopage_mymetype) {
                        mimetype = this.element.monopage_mymetype;
                        filenameDownload = this.fileName(
                            this.element.uuid,
                            this.element.monopage_mymetype
                        );
                    } else {
                        mimetype = 'application/pdf';
                        filenameDownload = this.element.uuid + '.pdf';
                    }
                    const newBlob = new Blob([response], {
                        type: mimetype,
                    });
                    if ((window.navigator as any).msSaveOrOpenBlob) {
                        (window.navigator as any).msSaveOrOpenBlob(newBlob, filenameDownload);
                    } else {
                        const a = document.createElement('a');
                        a.href = URL.createObjectURL(newBlob);
                        a.setAttribute('download', filenameDownload);
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                    this.isPrinting = false;
                },
                (err) => {
                    this.isPrinting = false;
                    this.service.addMessage({
                        severity: 'error',
                        summary: 'Errore',
                        detail: err.message,
                    });
                }
            );
    }

    printCatalogueWithSinglePrintable(uuidPrintable) {
        this.isPrinting = true;
        this.catalogueService
            .getCatalogueWithSinglePrintablePdf(this.element.uuid, uuidPrintable)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (response) => {
                    let mimetype;
                    let filenameDownload;
                    if (this.element.monopage_mymetype) {
                        mimetype = this.element.monopage_mymetype;
                        filenameDownload = this.fileName(
                            this.element.uuid,
                            this.element.monopage_mymetype
                        );
                    } else {
                        mimetype = 'application/pdf';
                        filenameDownload = this.element.uuid + '.pdf';
                    }
                    const newBlob = new Blob([response], {
                        type: mimetype,
                    });
                    if ((window.navigator as any).msSaveOrOpenBlob) {
                        (window.navigator as any).msSaveOrOpenBlob(newBlob, filenameDownload);
                    } else {
                        const a = document.createElement('a');
                        a.href = URL.createObjectURL(newBlob);
                        a.setAttribute('download', filenameDownload);
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                    this.isPrinting = false;
                },
                (err) => {
                    this.isPrinting = false;
                    this.service.addMessage({
                        severity: 'error',
                        summary: 'Errore',
                        detail: err.message,
                    });
                }
            );
    }

    addArticles() {
        this.router.navigate([`/${this.path}/${this.element.uuid}/aggiungi-articoli`]);
    }

    getId() {
        return this.element.uuid;
    }
}
