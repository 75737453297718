import { Component, DoCheck, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { filtrablePropertyTypes, propertyType } from 'src/app/model/enum/property-type';
import { PropertyValue } from 'src/app/model/property-value';
import { Tipology } from 'src/app/model/tipology';
import { ListableService } from 'src/app/service/listable.service';
import { PrintableService } from 'src/app/service/printable.service';

@Component({
    selector: 'app-article-property-filter',
    templateUrl: './article-property-filter.component.html',
    styleUrls: ['./article-property-filter.component.css'],
})
export class ArticlePropertyFilterComponent implements OnInit, OnChanges {
    @Input() tipologies: Map<string, Tipology>;
    @Input() tipologyItems: SelectItem[];
    @Input() service: PrintableService;
    @Input() locale: {};
    selectedTipogy: Tipology;
    @Input() isOpOpen: boolean;
    tipologyPropertiesItems: SelectItem[];
    form: FormGroup;
    isPropertyFilterPanelOpen = false;
    constructor(protected fb: FormBuilder, protected listableService: ListableService) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            propertiesToFilter: this.fb.array([]),
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.isOpOpen?.currentValue) {
            for (let i = this.propertiesToFilter.length - 1; i > -1; i--) {
                const propertyToFilter = this.propertiesToFilter.at(i);
                if (this.service.search.json[`${propertyToFilter.value.name}_type`] === null) {
                    this.propertiesToFilter.removeAt(i);
                }
            }

            if (this.propertiesToFilter?.length === 0) {
                this.selectedTipogy = null;
            }
        }
    }

    tipologyFilterSelected(event) {
        this.selectedTipogy = event.value;
        const properties = this.tipologies.get(event.value).properties;
        this.tipologyPropertiesItems = properties
            .filter((p) => filtrablePropertyTypes.includes(p.property_type))
            .map((p) => ({ label: p.name, value: p }));
        if (this.propertiesToFilter?.length === 0) {
            this.addPropertyToFilter();
        }
    }

    tipologyPropertySelected({ value }: { value: PropertyValue }, idx: number) {
        if (value.property_type === 'LISTABLE') {
            value.extra.listableValuesUrl = this.listableService.listValuesAsItems(
                value.extra.listableName
            );
        }

        const jsonTypes: string[] = this.getJsonTypes(value.property_type);
        this.propertiesToFilter.at(idx).patchValue({
            property: value,
            jsonTypes,
            name: value.name,
            value1: null,
            value2: null,
        });
    }

    getJsonTypes(propType: propertyType): ('like' | 'obj' | 'gte' | 'lte')[] {
        switch (propType) {
            case 'STR_VALUE':
                return ['like'];
            case 'NUMBER_VALUE':
                return ['gte', 'lte'];
            case 'DATE_VALUE':
                return ['gte', 'lte'];
            case 'LISTABLE':
                return ['obj'];
        }
    }

    addPropertyToFilter() {
        const newProp = new PropertyValue();

        const newPropFg = this.fb.group({
            property: newProp,
            name: null,
            value1: null,
            value2: null,
            jsonTypes: null,
        });
        this.propertiesToFilter.push(newPropFg);
    }

    get propertiesToFilter() {
        return this.form.get('propertiesToFilter') as FormArray;
    }
}
