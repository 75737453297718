<p-accordion [multiple]="true">
  <p-accordionTab [selected]="openAllTabs">
    <p-header>
      {{ property.name }}
      <span class="label label-primary pull-right">
        {{ propertyTypesMap?.get(property.property_type) }}
      </span>
    </p-header>

    <div class="well-white clearfix">
      <div class="row clearfix">
        <div class="col-sm-3">
          <label>Nome:</label>
        </div>
        <div class="col-sm-9">
          {{ property.name }}
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-sm-3">
          <label>Label:</label>
        </div>
        <div class="col-sm-9">
          {{ property.label }}
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-sm-3">
          <label>Tipo:</label>
        </div>
        <div class="col-sm-9">
          {{ propertyTypesMap?.get(property.property_type) }}
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-sm-3">
          <label *ngIf="propertyValueName === 'default_value'; else elseBlock">
            Valore default:
          </label>
          <ng-template #elseBlock>
            <label>Valore:</label>
          </ng-template>
        </div>
        <div class="col-sm-9" [ngSwitch]="property.property_type">
          <div *ngSwitchCase="'IMAGE_VALUE'">
            <img
              *ngIf="property[propertyValueName]"
              [src]="property[propertyValueName] + '?format=300x300' | safe: 'resourceUrl'"
              style="height: 100px;"
            />
          </div>
          <div *ngSwitchCase="'ATTACH_VALUE'">
            <a href="{{ property[propertyValueName] }}" target="_blank">
              {{ property?.extra?.fileName }}
              <i class="fa fa-download"></i>
            </a>
          </div>
          <div *ngSwitchCase="'BARCODE'">
            <img
              *ngIf="property[propertyValueName]"
              [src]="property[propertyValueName] | safe: 'resourceUrl'"
              class="img-responsive"
            />
          </div>
          <div *ngSwitchCase="'MULTISELECT'" class="filter-wrapper">
            <div class="tags-wrapper" *ngIf="property.extra.choices">
              <span class="tag-item" *ngFor="let choice of property.extra.choices">
                {{ choice }}
              </span>
            </div>
          </div>
          <div *ngSwitchCase="'ICON_VALUE'">
            <i class="{{ property[propertyValueName] }}"></i>
          </div>
          <div *ngSwitchCase="'LONG_TEXT'">
            <span [innerHtml]="property.value"></span>
          </div>
          <div *ngSwitchCase="'DATE_VALUE'">
            <span *ngIf="property[propertyValueName]">
              {{ property[propertyValueName] | date: 'dd/MM/yyyy' }}
            </span>
            <span *ngIf="!property[propertyValueName]">-</span>
          </div>
          <span *ngSwitchDefault>{{ property[propertyValueName] }}</span>
        </div>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
