import { Component, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ArticleService } from '../../service/article.service';
import { AttachmentService } from '../../service/attachment.service';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ContainerService } from '../../service/container.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Location } from '@angular/common';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { PrintableEditComponent } from '../printable/printable-edit.component';
import { Printable } from '../../model/printable';
import { ConfigurationService } from '../../service/configuration.service';
import { manipulateProperties } from '../../shared-functions/forms-util';
import { ListableService } from 'src/app/service/listable.service';
import { PropertyService } from 'src/app/service/property.service';
import { takeUntil } from 'rxjs/operators';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { CONTAINERS_API_PATH } from 'src/app/constants/constants';

@Component({
    selector: 'app-article-edit',
    templateUrl: './article-edit.component.html',
    styleUrls: ['./article-edit.component.css'],
    providers: [ArticleService],
})
export class ArticleEditComponent extends PrintableEditComponent implements OnInit {
    @ViewChild('propertyList')
    propertyList: any;

    articleIndex: number;
    fromEntity: string;
    fromEntityUuid: string;
    status: boolean = false;
    buttonName = 'sidebar';
    currentWindow = 0;
    archivedVal = false;

    json = JSON;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected tipologyService: TipologyService,
        protected formBuilder: FormBuilder,
        protected articleService: ArticleService,
        protected confirmationService: ConfirmationService,
        protected configurationService: ConfigurationService,
        protected attachmentService: AttachmentService,
        protected categoryService: CategoryService,
        protected containerService: ContainerService,
        protected propertyService: PropertyService,
        public location: Location,
        protected listableService: ListableService,
        protected routingStateService: RoutingStateService,
        messageService: MessageService
    ) {
        super(
            router,
            route,
            'articles',
            tipologyService,
            formBuilder,
            articleService,
            confirmationService,
            configurationService,
            attachmentService,
            categoryService,
            listableService,
            propertyService,
            routingStateService,
            containerService,
            messageService
        );
    }

    ngOnInit() {
        super.ngOnInit();

        this.articleIndex = this.route.snapshot.queryParams?.articleIndex;
        this.fromEntity = this.route.snapshot.queryParams?.entity;
        this.fromEntityUuid = this.route.snapshot.queryParams?.entityUuid;

        if (
            this.route.snapshot.paramMap.get('returnBack') ||
            this.route.snapshot.queryParams.entity == 'gare'
        ) {
            this.navigateAfterUpdate = this.goToContainer;
            this.navigateAfterDelete = () => this.location.back();
            this.navigateToList = () => this.location.back();
        }

        this.route.params.subscribe((_) => {
            super.ngOnInit();
            this.articleIndex = this.route.snapshot.queryParams.articleIndex;
        });
    }

    postFind() {
        if (this.element.closed) this.navigateToList();
        this.archivedVal = !this.element.active;

        super.postFind();
    }

    createInstance(): Printable {
        const printable: Printable = new Printable();
        printable.objectType = 'ARTICLE';
        return printable;
    }

    clickEvent() {
        this.status = !this.status;
    }

    goToFragment(fragment: string) {
        const where = fragment
            .replace(/\s/g, '')
            .replace(/\./gi, '')
            .replace(/[^a-zA-Z ]/g, '')
            .toLowerCase();
        const headerHeight = 50; /* PUT HEADER HEIGHT HERE */
        const buffer = 25; /* MAY NOT BE NEEDED */
        const scrollToEl = document.querySelector('#' + where);
        if (scrollToEl != null) {
            const topOfElement =
                window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight - buffer;
            window.scroll({ top: topOfElement, behavior: 'smooth' });
        }
    }

    onFilterFields() {
        if (this.query) {
            const firstProperty = this.propertyList.nativeElement?.children[0].innerText;
            if (firstProperty) this.goToFragment(firstProperty);
        }
    }

    buildForm() {
        this.registerForm = this.formBuilder.group({
            internal_code: [this.element.internal_code, Validators.required],
            name: [this.element.name],
            description: [this.element.description],
            codiceGara: [
                {
                    value: this.containerUuidFromRoute ?? this.element.codiceGara,
                    disabled: this.containerUuidFromRoute,
                },
            ],
            properties: this.formBuilder.array([]),

            tags: [this.element.tags ? this.element.tags.split(';') : []],
            tipology: [this.tipologies.get(this.element.tipology_uuid), Validators.required],
            state: [this.element.state],
            active: [this.element.active],
        });
    }

    getValuesFromForm() {
        this.element.internal_code = this.registerForm.get('internal_code').value;
        this.element.name = this.registerForm.get('name').value;
        this.element.description = this.registerForm.get('description').value;
        this.element.codiceGara = this.registerForm.get('codiceGara').value;
        this.element.properties = this.registerForm.get('properties').value;
        this.element.tags =
            this.registerForm.get('tags').value.length > 0
                ? this.registerForm.get('tags').value.join(';')
                : '';
        this.element.tipology_uuid = this.registerForm.get('tipology').value.uuid;
        this.element.category_uuid = this.registerForm.get('tipology').value.category_uuid;
        this.element.state = this.registerForm.get('state').value;
        if (this.registerForm.get('active') !== null)
            this.element.active = this.registerForm.get('active')?.value;
        manipulateProperties(this.element, 'value', this.attachmentService);
    }

    navigateAfterSave() {
        const lastRoute = this.routingStateService.getPreviousUrl();
        this.router.navigate([`${lastRoute}`]);
    }

    saveError(): void {
        this.updateLoading = false;
        super.saveError();
    }

    postUpdate() {
        this.updateLoading = false;
        if (this.currentWindow == 1) {
            this.getNextArticlelData();
        } else if (this.currentWindow == 2) {
            this.getPrevArticleData();
        }
    }
    getNextArticlelData() {
        let searchCopy = {
            ...this.articleService.search,
            pageSize: 1,
            startRow: +this.articleIndex + 1,
        };

        if (this.fromEntity === 'articles' || !this.fromEntity)
            this.articleService.getSingleArticle(searchCopy).subscribe((data) => {
                const articleUuid = data[0].uuid;
                this.router.navigate([`/${this.path}/edit/${articleUuid}`], {
                    queryParams: { articleIndex: +this.articleIndex + 1 },
                });
            });
        else {
            searchCopy = { ...searchCopy, obj: {} as any };
            const url = this.getCustomArticleEndpoint();
            this.articleService.getSingleArticle(searchCopy, url).subscribe((data) => {
                if (data.length == 0) {
                    this.loading = false;
                    return;
                }
                const articleUuid = data[0].uuid;
                this.loading = false;
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.navigate([`/${this.path}/edit/${articleUuid}`], {
                    queryParams: {
                        articleIndex: +this.articleIndex + 1,
                        entity: this.fromEntity,
                        entityUuid: this.fromEntityUuid,
                    },
                });
            });
        }
    }
    getPrevArticleData() {
        if (+this.articleIndex - 1 < 0) {
            this.loading = false;
            return;
        }

        let searchCopy = {
            ...this.articleService.search,
            pageSize: 1,
            startRow: +this.articleIndex - 1,
        };

        if (this.fromEntity === 'articles' || !this.fromEntity)
            this.articleService.getSingleArticle(searchCopy).subscribe((data) => {
                const articleUuid = data[0].uuid;
                this.router.navigate([`/${this.path}/edit/${articleUuid}`], {
                    queryParams: { articleIndex: +this.articleIndex - 1 },
                });
            });
        else {
            searchCopy = { ...searchCopy, obj: {} as any };
            const url = this.getCustomArticleEndpoint();

            this.articleService.getSingleArticle(searchCopy, url).subscribe((data) => {
                const articleUuid = data[0].uuid;
                this.loading = false;
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.navigate([`/${this.path}/edit/${articleUuid}`], {
                    queryParams: {
                        articleIndex: +this.articleIndex - 1,
                        entity: this.fromEntity,
                        entityUuid: this.fromEntityUuid,
                    },
                });
            });
        }
    }
    goToNextArticle() {
        //update first
        this.complexUpdateWithoutRedirect();
        this.currentWindow = 1;
    }
    goToPrevArticle() {
        //update first
        this.complexUpdateWithoutRedirect();
        this.currentWindow = 2;
    }

    update(): void {
        this.loading = true;
        this.complexUpdateWithoutRedirectToast();
        // this.complexUpdateWithoutRedirect();
        // this.currentWindow = 1;
    }

    getCustomArticleEndpoint() {
        let url = '';

        if (this.fromEntity === 'gare') {
            this.configurationService
                .getValue(CONTAINERS_API_PATH)
                .subscribe((result) => (url = result));
            url = `${url}/${this.fromEntityUuid}/printables`;
        }
        return url;
    }

    onChangeArchived(event) {
        this.archivedVal = event.checked;
        this.registerForm.patchValue({ active: !event.checked });
    }

    goToContainer() {
        this.router.navigate([`/gare/${this.fromEntityUuid}/aggiungi-articoli`]);
    }
}
