import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ContainerService } from '../../service/container.service';

import { AbstractListComponent } from '../../common/abstract-list-component';
import { Container } from '../../model/container';
import { takeUntil, take } from 'rxjs/operators';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ListableService } from 'src/app/service/listable.service';
import { LISTABLE_SECTOR_KEY } from 'src/app/constants/constants';
import { Table } from 'primeng/table/table';
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector: 'app-container-list',
    templateUrl: './container-list.component.html',
})
export class ContainerListComponent extends AbstractListComponent<Container> implements OnInit {
    element: Container;
    listableSectorItems: SelectItem[];

    roles: any;
    primaryRole: any;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected containerService: ContainerService,
        private listableService: ListableService,
        private confirmationService: ConfirmationService,
        protected keycloakService: KeycloakService
    ) {
        super(router, containerService, 'gare');
    }

    ngOnInit() {
        this.roles = this.keycloakService.getUserRoles();
        this.setPrimaryRole();
        // super.ngOnInit();
        this.loading = true;
        this.element = new Container();
        this.getSectorItems();
    }

    setPrimaryRole() {
        if (this.roles) {
            if (this.roles.includes('admin')) {
                this.primaryRole = 'Admin';
                return;
            }
            if (this.roles.includes('user')) {
                this.primaryRole = 'Utente';
                return;
            }
            if (this.roles.includes('manager')) {
                this.primaryRole = 'Manager';
                return;
            }
        }
    }

    clone(container) {
        this.router.navigate(['/' + this.path + '/clone/' + container.codiceGara]);
    }

    getSectorItems() {
        this.listableService
            .listValuesAsItems(LISTABLE_SECTOR_KEY)
            .subscribe((settoreItems) => (this.listableSectorItems = settoreItems));
    }

    newElement(): Container {
        return super.newElement();
    }

    delete(element: Container) {
        this.service
            .delete(element.codiceGara)
            .pipe(takeUntil(this.destroy$))
            .subscribe((del) => this.loaddata(true));
    }

    public new() {
        this.router.navigate(['/' + this.path + '/new']);
    }

    public reload(datatable: Table) {
        if (!this.service.search.obj.archived) this.service.search.obj.archived = null;
        if (!this.service.search.obj.closed) this.service.search.obj.closed = null;
        super.reload(datatable);
    }

    getId() {
        return this.element.codiceGara;
    }

    createInstance(): Container {
        return new Container();
    }

    addArticles(uuid: string) {
        this.router.navigate([`/${this.path}/${uuid}/aggiungi-articoli`]);
    }

    addCatalogues(uuid: string) {
        this.router.navigate([`/${this.path}/${uuid}/aggiungi-cataloghi`]);
    }

    close(element: Container) {
        this.confirmationService.confirm({
            accept: () => {
                this.containerService
                    .closeContainer(element.codiceGara)
                    .pipe(take(1))
                    .subscribe(
                        (closedContainer) =>
                            (this.model = this.model.map((gara) =>
                                gara.codiceGara === closedContainer.codiceGara
                                    ? closedContainer
                                    : gara
                            ))
                    );
            },
        });
    }

    open(element: Container): void {
        this.confirmationService.confirm({
            accept: () => {
                this.containerService
                    .openContainer(element.codiceGara)
                    .pipe(take(1))
                    .subscribe(
                        (openedContainer) =>
                            (this.model = this.model.map((gara) =>
                                gara.codiceGara === openedContainer.codiceGara
                                    ? openedContainer
                                    : gara
                            ))
                    );
            },
        });
    }

    archive(element: Container) {
        this.confirmationService.confirm({
            accept: () => {
                this.containerService
                    .archiveContainer(element.codiceGara)
                    .pipe(take(1))
                    .subscribe(
                        (archivedContainer) =>
                            (this.model = this.model.map((gara) =>
                                gara.codiceGara === archivedContainer.codiceGara
                                    ? archivedContainer
                                    : gara
                            ))
                    );
            },
        });
    }

    unarchive(element: Container) {
        this.confirmationService.confirm({
            accept: () => {
                this.containerService
                    .unarchiveContainer(element.codiceGara)
                    .pipe(take(1))
                    .subscribe(
                        (unarchivedContainer) =>
                            (this.model = this.model.map((gara) =>
                                gara.codiceGara === unarchivedContainer.codiceGara
                                    ? unarchivedContainer
                                    : gara
                            ))
                    );
            },
        });
    }
}
