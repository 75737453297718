import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ArticleService } from '../../service/article.service';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';
import { AttachmentService } from '../../service/attachment.service';

import { PrintableViewComponent } from '../printable/printable-view.component';
import { Printable } from '../../model/printable';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-article-view',
    templateUrl: './article-view.component.html',
    styleUrls: ['./article-view.component.css'],
    providers: [ArticleService],
})
export class ArticleViewComponent extends PrintableViewComponent implements OnInit {
    returnBack = false;
    includeAttachments = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected tipologyService: TipologyService,
        protected categoryService: CategoryService,
        protected articleService: ArticleService,
        protected attachmentService: AttachmentService,
        private location: Location
    ) {
        super(
            router,
            route,
            'articles',
            tipologyService,
            categoryService,
            articleService,
            attachmentService
        );
    }

    createInstance(): Printable {
        const printable: Printable = new Printable();
        printable.objectType = 'ARTICLE';
        return printable;
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.route.snapshot.paramMap.get('returnBack')) {
            this.returnBack = true;
            this.navigateToList = () => this.location.back();
        }
    }

    printCustom(uuidTemplate) {
        if (this.includeAttachments === true) {
            this.printableService
                .getPdfWithAttachmetns(this.element.uuid, uuidTemplate)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    (response) => {
                        const filenameDownload = this.element.uuid + '.pdf';
                        const newBlob = new Blob([response], { type: 'application/pdf' });
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(newBlob, filenameDownload);
                        } else {
                            const a = document.createElement('a');
                            a.href = URL.createObjectURL(newBlob);
                            a.setAttribute('download', filenameDownload);
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                    },
                    (err) => {
                        this.service.addMessage({
                            severity: 'error',
                            summary: 'Errore',
                            detail: err.message,
                        });
                    }
                );
        } else {
            super.print(uuidTemplate);
        }
    }
}
