<p-toast></p-toast>
<div class="panel panel-default">
  <div class="panel-body view">
    <div class="row">
      <div class="col-sm-12">
        <label>Dettaglio template</label>
      </div>
    </div>
    <hr />
    <div class="clearfix"></div>

    <div class="form-group row clearfix">
      <div class="col-sm-3">
        <label>Nome:</label>
      </div>

      <div class="col-sm-9">
        {{ element.name }}
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="form-group row clearfix">
      <div class="col-sm-3">
        <label>Descrizione:</label>
      </div>
      <div class="col-sm-9">
        {{ element.description }}
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="form-group row clearfix">
      <div class="col-sm-3">
        <label>Tipo:</label>
      </div>
      <div class="col-sm-9">
        {{ printTemplateTypeMap?.get(element.printTemplateType) }}
      </div>
    </div>

    <div class="form-group row clearfix">
      <div class="col-sm-3">
        <label>Default:</label>
      </div>
      <div class="col-sm-9">
        {{ element.default_template ? 'Si' : 'No' }}
      </div>
    </div>

    <div class="form-group row clearfix" *ngIf="element.printTemplateFormat">
      <div class="col-sm-3">
        <label>Formato:</label>
      </div>
      <div class="col-sm-9">
        {{ element.printTemplateFormat }}
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="form-group row clearfix" *ngIf="false">
      <div class="col-sm-3">
        <label>Stile (CSS):</label>
      </div>

      <div class="col-sm-9">
        <ngx-monaco-editor
          class="form-control template-editor"
          [options]="editorOptions"
          [ngModel]="element.style"
        ></ngx-monaco-editor>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="form-group row clearfix" *ngIf="element.first_page">
      <div class="col-sm-3">
        <label>Prima pagina:</label>
      </div>

      <div class="col-sm-9">
        <ngx-monaco-editor
          class="form-control template-editor"
          [options]="editorOptions"
          [ngModel]="element.first_page"
        ></ngx-monaco-editor>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="form-group row clearfix" *ngIf="element.last_page">
      <div class="col-sm-3">
        <label>Ultima pagina:</label>
      </div>

      <div class="col-sm-9">
        <ngx-monaco-editor
          class="form-control template-editor"
          [options]="editorOptions"
          [ngModel]="element.last_page"
        ></ngx-monaco-editor>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="form-group row clearfix" *ngIf="element.header">
      <div class="col-sm-3">
        <label>Header:</label>
      </div>

      <div class="col-sm-9">
        <ngx-monaco-editor
          class="form-control template-editor"
          [options]="editorOptions"
          [ngModel]="element.header"
        ></ngx-monaco-editor>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="form-group row clearfix" *ngIf="element.page_content">
      <div class="col-sm-3">
        <label>Contenuto:</label>
      </div>
      <div class="col-sm-9">
        <ngx-monaco-editor
          class="form-control template-editor"
          [options]="editorOptions"
          [ngModel]="element.page_content"
        ></ngx-monaco-editor>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="form-group row clearfix" *ngIf="element.footer">
      <div class="col-sm-3">
        <label>Footer:</label>
      </div>

      <div class="col-sm-9">
        <ngx-monaco-editor
          class="form-control template-editor"
          [options]="editorOptions"
          [ngModel]="element.footer"
        ></ngx-monaco-editor>
      </div>
    </div>
    <div class="clearfix"></div>
    <div *ngIf="false" class="form-group row clearfix">
      <div class="col-sm-3">
        <label>Numero pagina:</label>
      </div>

      <div class="col-sm-9">
        {{ element.with_number ? ' si' : 'no' }}
      </div>
    </div>

    <div class="clearfix"></div>

    <hr />
    <div class="row clearfix">
      <div class="col-sm-3">
        <label>Proprieta:</label>
        <div style="display: flex; float: right;">
          <div style="margin-right: 10px;">
            <label *ngIf="!openAllTabs">Apri tutto</label>
            <label *ngIf="openAllTabs">Chiudi tutto</label>
          </div>
          <p-inputSwitch [(ngModel)]="openAllTabs" inputStyleClass="form-control"></p-inputSwitch>
        </div>
      </div>
      <div class="col-sm-9">
        <ng-container *ngFor="let property of element.properties; let i = index">
          <app-property-view
            [property]="property"
            [i]="i"
            [propertyValueName]="'default_value'"
            [openAllTabs]="openAllTabs"
            [propertyTypesMap]="propertyTypesMap"
          ></app-property-view>
        </ng-container>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="row">
      <div class="col-sm-12">
        <div class="btn-group std-btn-group">
          <button type="button" class="btn btn-default" (click)="goToList()">
            <i class="fa fa-arrow-left"></i>
            Indietro
          </button>
          <button type="button" (click)="edit(element)" class="btn btn-info right">
            <i class="fa fa-edit"></i>
            Modifica
          </button>
          <button type="button" (click)="print(element.uuid)" class="btn btn-success right">
            <i class="fa fa-print"></i>
            Stampa
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
