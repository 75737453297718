import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Listable } from 'src/app/model/listable';
import { ListableService } from 'src/app/service/listable.service';

@Component({
    selector: 'app-listable-valorization',
    templateUrl: './listable-valorization.component.html',
    styleUrls: ['./listable-valorization.component.css'],
})
export class ListableValorizationComponent implements OnInit, OnChanges, OnDestroy {
    protected destroy$: Subject<void> = new Subject<void>();
    element: Listable = new Listable();
    @Input() data$: Observable<any>;
    @Input() listableValues: SelectItem[];
    @Input() group: FormGroup;
    @Input() name: string;
    @Output() loadData = new EventEmitter<Observable<SelectItem[]>>();
    showAddListable = false;
    registerForm: FormGroup;
    constructor(private formBuilder: FormBuilder, protected listableService: ListableService) {}

    ngOnInit(): void {
        this.loadData.emit(this.data$);
        this.buildForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            this.element = new Listable();
            this.element.name = this.name;
            this.buildForm();
        }
    }

    private buildForm() {
        this.registerForm = this.formBuilder.group({
            name: [{ value: this.element.name, disabled: true }, Validators.required],
            value: [this.element.value, Validators.required],
        });
    }

    private getValuesFromForm() {
        this.element.value = this.registerForm.get('value').value;
        this.element.name = this.registerForm.get('name').value;
    }

    addValues() {
        this.showAddListable = true;
    }

    save() {
        this.getValuesFromForm();
        this.listableService
            .persist(this.element)
            .pipe(takeUntil(this.destroy$))
            .subscribe((_) => {
                this.loadData.emit(this.data$);
                this.showAddListable = false;
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
