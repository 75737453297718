<div class="wrapper-md">
  <div class="list-wrapper clearfix">
    <div class="row">
      <div class="col-sm-8">
        <h4>
          <i aria-hidden="true" class="fa fa-print"></i>
          Log di stampa
        </h4>
        <br />
      </div>
      <div class="col-sm-4">
        <div class="btn-group pull-right">
          <button type="button" class="btn btn-default" (click)="goToList()">
            <i class="fa fa-arrow-left"></i>
            Indietro
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="content clearfix">
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default">
            <div class="panel-body view">
              <table class="table">
                <tbody>
                  <tr>
                    <td>Utente:</td>
                    <td>{{ element?.principal }}</td>
                  </tr>

                  <tr>
                    <td width="200">UUID Collegato:</td>
                    <td>{{ element?.related_uuid }}</td>
                  </tr>

                  <tr>
                    <td width="200">Data ricezione:</td>
                    <td>{{ element?.received_date | date: 'dd-MM-yyyy hh:mm' }}</td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <p-accordion>
                        <p-accordionTab header="Dati precedenti">
                          <pre>
                          <code>
                            {{ element?.before_data }}
                          </code>
                                            </pre>
                        </p-accordionTab>
                      </p-accordion>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <p-accordion>
                        <p-accordionTab header="Dati successivi">
                          <pre>
                          <code>
                            {{ element?.after_data }}
                          </code>
                                            </pre>
                        </p-accordionTab>
                      </p-accordion>
                    </td>
                    <td>
                      <button
                        type="button"
                        (click)="print(element.uuid)"
                        class="btn btn-success right p-mt-3"
                      >
                        <i class="fa fa-print"></i>
                        Stampa
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
