import { Component, OnInit } from '@angular/core';
import { AbstractViewComponent } from '../../common/abstract-view-component';
import { PrintLog } from '../../model/print-log';
import { ActivatedRoute, Router } from '@angular/router';
import { PrintLogService } from 'src/app/service/print-path.service';

@Component({
    selector: 'app-print-log-view',
    templateUrl: './print-log-view.component.html',
})
export class PrintLogViewComponent extends AbstractViewComponent<PrintLog> implements OnInit {
    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected printLogService: PrintLogService
    ) {
        super(router, route, printLogService, 'print_logs');
    }

    getId() {
        return this.element.uuid;
    }

    print(uuid: string) {
        this.printLogService.getLogPdf(uuid).subscribe(
            (file: Blob) => {
                const [date, fulltime] = this.element.received_date.split('T');
                const [time] = fulltime.split('.');
                const datetime = [date, time].join('_').split(':').join('').split('-').join('');

                const fileLink = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileLink;
                link.download = `${this.element.principal} - ${datetime}.pdf`;
                link.click();
            },
            (err) => {
                this.service.addMessage({
                    severity: 'error',
                    summary: 'Errore',
                    detail: err.message,
                });
            }
        );
    }
}
