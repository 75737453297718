import { Component, OnInit, Input } from '@angular/core';
import { FormGroupDirective, ControlContainer } from '@angular/forms';
import { PropertyValue } from '../../../model/property-value';
@Component({
    selector: 'app-property-view',
    templateUrl: './property-view.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    styleUrls: ['./property-view.component.css']
})
export class PropertyViewComponent implements OnInit {
    @Input() property: PropertyValue;
    @Input() i: number;
    @Input() propertyValueName: string;
    @Input() propertyTypesMap: Map<string, string>;
    @Input() openAllTabs = false;
    constructor() {}

    ngOnInit(): void {}
}
