import { Component, OnInit, OnDestroy } from '@angular/core';
import { PropertyService, LocalStoredPatchesData } from 'src/app/service/property.service';
import { PrintablePropertyPatch } from 'src/app/model/printable-property-patch';
import { Router, ActivatedRoute } from '@angular/router';
import { ContainerService } from 'src/app/service/container.service';
import { Printable } from 'src/app/model/printable';
import { Search } from 'src/app/common/search';
import { Observable, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { MAX_PAGE_SIZE } from 'src/app/constants/constants';

@Component({
    selector: 'app-printables-bulk-view',
    templateUrl: './printables-bulk-view.component.html',
    styleUrls: ['./printables-bulk-view.component.css'],
})
export class PrintablesBulkViewComponent implements OnInit, OnDestroy {
    protected destroy$: Subject<void> = new Subject<void>();
    printablePropertiesPatch: PrintablePropertyPatch[];
    propertiesName: string[];
    printablesMap: Map<string, string> = new Map<string, string>();
    garaUuid: string;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected propertyService: PropertyService,
        protected containerService: ContainerService,
        protected messageService: MessageService,
        protected routingStateService: RoutingStateService
    ) {}

    ngOnInit(): void {
        this.garaUuid = this.route.snapshot.params['uuid'];
        this.getPrintables();
        this.getPatches();
        if (!this.printablePropertiesPatch || this.printablePropertiesPatch.length === 0) {
            setTimeout(() => {
                this.router.navigate([`gare/modifica-massiva-proprieta`]);
            }, 3000);
        }
    }

    getPrintables() {
        const search = new Search<Printable>(Printable);
        search.pageSize = MAX_PAGE_SIZE;
        const tipologyUuid = this.propertyService?.localStoredPatchesData?.tipologyUuid;
        if (tipologyUuid) {
            search.obj.tipology_uuid = tipologyUuid;
        }

        if (this.garaUuid) {
            this.containerService
                .getPrintables(this.garaUuid, search)
                .pipe(takeUntil(this.destroy$))
                .subscribe(({ printables }) =>
                    printables.map((printable) =>
                        this.printablesMap.set(printable.uuid, printable.name)
                    )
                );
        }
    }

    getPatches() {
        const serviceData: LocalStoredPatchesData = this.propertyService?.localStoredPatchesData;
        if (serviceData) {
            this.printablePropertiesPatch = [...serviceData.printablePropertiesPatches];
            this.propertiesName = [...serviceData.propertiesName];
        }
    }

    goBack() {
        this.router.navigate([`gare/modifica-massiva-proprieta`]);
    }

    salva() {
        const observables$: Observable<any>[] = this.propertyService.savePrintablePropertiesPatch();
        forkJoin(observables$)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (res) => {
                    this.propertyService.localStoredPatchesData.registerForm = null;
                    this.router.navigate([`gare/modifica-massiva-proprieta`]);
                },
                (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Errore: ',
                        detail: error.error.msg,
                    });
                }
            );
    }

    salvaGotoGara() {
        const observables$: Observable<any>[] = this.propertyService.savePrintablePropertiesPatch();
        forkJoin(observables$)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (res) => {
                    this.router.navigate([`gare/view/${this.garaUuid}`]);
                },
                (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Errore: ',
                        detail: error.error.msg,
                    });
                }
            );
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
