import { Component, OnInit } from '@angular/core';
import { AbstractViewComponent } from '../../common/abstract-view-component';
import { Container } from '../../model/container';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerService } from '../../service/container.service';
import { Printable } from 'src/app/model/printable';
import { Catalogue } from 'src/app/model/catalogue';
import { TipologyService } from 'src/app/service/tipology.service';
import { PrintableService } from 'src/app/service/printable.service';
import { CatalogueService } from 'src/app/service/catalogue.service';
import { MenuItem } from 'primeng/api/menuitem';
import { takeUntil } from 'rxjs/operators';
import { propertyTypesPrintableItems } from 'src/app/model/enum/property-type';
import { TabViewModule } from 'primeng/tabview';
import { Search } from 'src/app/common/search';
import { MAX_PAGE_SIZE } from 'src/app/constants/constants';

@Component({
    selector: 'app-container-view',
    templateUrl: './container-view.component.html',
    styleUrls: ['./container-view.component.css'],
})
export class ContainerViewComponent extends AbstractViewComponent<Container> implements OnInit {
    printables: Printable[];
    catalogues: Catalogue[];
    tipologiesTranscode: Map<string, string> = new Map<string, string>();
    templatesMap: Map<string, MenuItem[]> = new Map<string, MenuItem[]>();
    propertyTypesMap: Map<string, string> = new Map<string, string>();
    openAllTabs = false;

    buttonName = 'allarga';
    status = false;

    classToggled = false;

    public toggleField() {
        this.classToggled = !this.classToggled;
    }

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected containerService: ContainerService,
        public tipologyService: TipologyService,
        public printableService: PrintableService,
        public catalogueService: CatalogueService
    ) {
        super(router, route, containerService, 'gare');
    }

    postFind() {
        propertyTypesPrintableItems.map((t) => this.propertyTypesMap.set(t.value, t.label));
        const search: Search<Printable> = new Search(Printable);
        search.pageSize = MAX_PAGE_SIZE;
        if (false) {
            this.containerService
                .getPrintables(this.element.codiceGara, search)
                .pipe(takeUntil(this.destroy$))
                .subscribe(({ printables }) => {
                    this.printables = printables;
                    for (const printable of this.printables) {
                        if (
                            printable.tipology_uuid &&
                            !this.tipologiesTranscode.get(printable.tipology_uuid)
                        ) {
                            if (!this.templatesMap.get(printable.uuid)) {
                                this.templatesMap.set(printable.uuid, []);
                            }

                            this.tipologyService
                                .find(printable.tipology_uuid)
                                .pipe(takeUntil(this.destroy$))
                                .subscribe((tipology) => {
                                    this.tipologiesTranscode.set(tipology.uuid, tipology.name);
                                    tipology.templates.map((t) => {
                                        const tMap = this.templatesMap.get(printable.uuid);

                                        this.templatesMap.set(printable.uuid, [
                                            ...tMap,
                                            {
                                                label: t.name,
                                                icon: 'fa fa-print',
                                                command: () => {
                                                    this.printArticle(printable.uuid, t.uuid);
                                                },
                                            },
                                        ]);
                                    });
                                });
                        }
                    }
                });
        }

        this.containerService
            .getCatalogues(this.element.codiceGara)
            .pipe(takeUntil(this.destroy$))
            .subscribe((cats) => (this.catalogues = cats));
    }

    printArticle(articleUuid, templateUuid) {
        this.printableService
            .getPdf(articleUuid, templateUuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (response) => {
                    const newBlob = new Blob([response], {
                        type: 'application/pdf',
                    });
                    const fileUrl = window.URL.createObjectURL(newBlob);
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob, fileUrl.split(':')[1] + '.pdf');
                    } else {
                        window.open(fileUrl);
                    }
                },
                (err) => {
                    this.service.addMessage({
                        severity: 'error',
                        summary: 'Errore',
                        detail: err.message,
                    });
                }
            );
    }

    clickEvent() {
        this.status = !this.status;
    }

    fileName(uuid: string, mimetype: string): string {
        switch (mimetype) {
            case 'application/excel':
                return uuid + '.xls';
            case 'text/csv':
                return uuid + '.csv';
            case 'application/pdf':
                return uuid + '.pdf';
            default:
        }
    }

    printCatalogue(catalogueUuid, monopage, type) {
        this.catalogueService
            .getPdf(catalogueUuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (response) => {
                    let mimetype;
                    let filenameDownload;
                    if (monopage) {
                        mimetype = type;
                        filenameDownload = this.fileName(catalogueUuid, type);
                    } else {
                        mimetype = 'application/pdf';
                        filenameDownload = catalogueUuid + '.pdf';
                    }
                    const newBlob = new Blob([response], {
                        type: mimetype,
                    });
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob, filenameDownload);
                    } else {
                        const a = document.createElement('a');
                        a.href = URL.createObjectURL(newBlob);
                        a.setAttribute('download', filenameDownload);
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                },
                (err) =>
                    this.service.addMessage({
                        severity: 'error',
                        summary: 'Errore',
                        detail: err.message,
                    })
            );
    }

    getId() {
        console.log(this.element);
        return this.element.codiceGara;
    }

    addArticles() {
        this.router.navigate([`/${this.path}/${this.element.codiceGara}/aggiungi-articoli`]);
    }

    addCatalogues() {
        this.router.navigate([`/${this.path}/${this.element.codiceGara}/aggiungi-cataloghi`]);
    }
}
