import { Injectable } from '@angular/core';
import { PropertyValue } from '../model/property-value';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import {
    generateExtra,
    addControlToFormGroup,
    manipulatePropertyBeforeFormBuild,
} from '../shared-functions/forms-util';
import { ListableService } from '../service/listable.service';
import { AttachmentService } from './attachment.service';
import { Printable } from '../model/printable';
import { Tipology } from '../model/tipology';
import { Catalogue } from '../model/catalogue';
import { PrintTemplate } from '../model/print-template';
import { Container } from '../model/container';
import { PrintablePropertyPatch } from '../model/printable-property-patch';
import { PrintableService } from './printable.service';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api/selectitem';
import { TIPOLOGY_STYLE_MAX_COLUMNS } from 'src/app/constants/constants';

export interface LocalStoredPatchesData {
    selectedContainer: Container;
    selectedTipology: Tipology;
    selectedProperties: string[];
    registerForm: FormGroup;
    propertiesName: string[];
    printablesMap: Map<string, Printable>;
    tipologyUuid: string;
    printablePropertiesPatches: any[];
}
@Injectable({
    providedIn: 'root',
})
export class PropertyService {
    localStoredPatchesData: LocalStoredPatchesData;

    constructor(
        private listableService: ListableService,
        protected formBuilder: FormBuilder,
        private attachmentService: AttachmentService,
        private printableService: PrintableService
    ) {}

    getPropertyAsFormGroup(property: PropertyValue, propertyValueName: string): FormGroup {
        manipulatePropertyBeforeFormBuild(property, propertyValueName, this.listableService);

        let valueValidators = [];
        if (propertyValueName === 'value') {
            valueValidators = property.mandatory ? [Validators.required] : [];
        }

        const propertyAsFormGroup = this.formBuilder.group({
            property_type: [property.property_type, Validators.required],
            default_value: [property.default_value],
            value: [property.value, valueValidators],
            order_by: [property.order_by, Validators.required],
            colspan: [property?.colspan ?? TIPOLOGY_STYLE_MAX_COLUMNS],
            name: [property.name, Validators.required],
            label: [property.label, Validators.required],
            mandatory: [property.mandatory],
        });

        if (property.isDefinitionMode) {
            addControlToFormGroup(
                propertyAsFormGroup,
                'isDefinitionMode',
                new FormControl(property.isDefinitionMode)
            );
        }
        const generatedExtra = generateExtra(
            this.formBuilder,
            property.property_type,
            property.extra
        );
        if (generatedExtra !== false) {
            addControlToFormGroup(propertyAsFormGroup, 'extra', generatedExtra);
        }

        return propertyAsFormGroup;
    }

    isAttachmentType(property: PropertyValue, valueFieldName: string) {
        return (
            property.property_type === 'ATTACH_VALUE' &&
            typeof property[valueFieldName] === 'object' &&
            property[valueFieldName] !== null
        );
    }

    isImageType(property: PropertyValue, valueFieldName: string) {
        return (
            property.property_type === 'IMAGE_VALUE' &&
            typeof property[valueFieldName] === 'object' &&
            property[valueFieldName] !== null
        );
    }

    private uploadImagesAndAttachmnts(
        imagesProps: PropertyValue[],
        attachmentsProps: PropertyValue[],
        element: Printable | Tipology | PrintTemplate | Catalogue | Container,
        propertyValueName
    ): Promise<any>[] {
        const promises: Promise<any>[] = [];
        for (const image of imagesProps) {
            promises.push(
                this.attachmentService
                    .setAttachment(image[propertyValueName], element)
                    .then((img) => (image[propertyValueName] = img.url))
            );
        }
        for (const attachment of attachmentsProps) {
            promises.push(
                this.attachmentService
                    .setAttachment(attachment[propertyValueName], element)
                    .then((att) => {
                        attachment.extra = {
                            fileName: att.file_name,
                        };
                        return (attachment[propertyValueName] = att.url);
                    })
            );
        }

        return promises;
    }

    checkAndUploadImagesAndAttachmnts(
        propertyForms: FormArray,
        element: Printable | Tipology | PrintTemplate | Catalogue | Container,
        propertyValueName
    ): Promise<any>[] {
        const imagesProps = propertyForms.value.filter((p) =>
            this.isImageType(p, propertyValueName)
        );
        const attachmentsProps = propertyForms.value.filter((p) =>
            this.isAttachmentType(p, propertyValueName)
        );
        if (imagesProps?.length === 0 && attachmentsProps?.length === 0) {
            return null;
        } else {
            const promises = this.uploadImagesAndAttachmnts(
                imagesProps,
                attachmentsProps,
                element,
                propertyValueName
            );
            return promises;
        }
    }

    addProperty(
        propertyForms: FormArray,
        property: PropertyValue,
        propertyValueName: string,
        mode?: string
    ) {
        let valueController: string;
        if (mode === 'edit') {
            valueController = property.value;
        } else {
            valueController = property.default_value;
        }
        property.value = valueController;

        const pg = this.getPropertyAsFormGroup(property, propertyValueName);
        propertyForms.push(pg);
    }

    hasAnyAttachment(propertyForms: FormArray, propertyValueName: string): boolean {
        const anyAttachment = propertyForms.value.some(
            (p) =>
                this.isImageType(p, propertyValueName) ||
                this.isAttachmentType(p, propertyValueName)
        );
        return anyAttachment;
    }

    havePropertiesErrors(properties: PropertyValue[]): { haveError: boolean; msg?: string } {
        const nameProperties = properties.map((p) => p.name);
        const hasDuplicates = new Set(nameProperties).size !== nameProperties.length;
        if (hasDuplicates) {
            return {
                haveError: hasDuplicates,
                msg: 'Le proprietà devono avere dei nomi univoci!',
            };
        }

        for (const p of properties) {
            if (
                p.property_type === 'SUMMABLE' ||
                p.property_type === 'SOTTRAIBILE' ||
                p.property_type === 'MULTIPLIABLE' ||
                p.property_type === 'DIVISIBLE'
            ) {
                const doesExistOpOne = nameProperties.find((n) => n === p.extra.operandOne);
                const doesExistOpTwo = nameProperties.find((n) => n === p.extra.operandTwo);
                if (!doesExistOpOne || !doesExistOpTwo) {
                    return {
                        haveError: true,
                        msg: 'Non è possibile fare operazioni tra proprietà ch non esistono più !',
                    };
                }
            }
        }

        return { haveError: false };
    }

    /* RELATED TO PRINTABLES-BULK */

    localStorePrintablePropertiesPatch(dataToStore: LocalStoredPatchesData) {
        this.localStoredPatchesData = { ...dataToStore };
    }

    savePrintablePropertiesPatch(): Observable<any>[] {
        return this.localStoredPatchesData.printablePropertiesPatches.map(
            (printablePropertyPatch: PrintablePropertyPatch) =>
                this.printableService.patchPropertiesValue(
                    printablePropertyPatch.uuid,
                    printablePropertyPatch
                )
        );
    }
}
