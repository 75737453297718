<p-toast></p-toast>
<div class="login-wrapper">
  <div class="col-sm-4 col-sm-offset-4">
    <div class="login-content clearfix">
      <div class="login-logo">
        <img src="assets/images/logo.png" class="img-responsive" />
      </div>
      <form (click)="goto('homepage')">
        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon"><i class="fa fa-user" aria-hidden="true"></i></span>
            <input type="email" class="form-control" placeholder="Codice Fiscale" />
          </div>
        </div>

        <div class="form-group">
          <div class="input-group">
            <span class="input-group-addon">
              <i class="fa fa-unlock-alt" aria-hidden="true"></i>
            </span>
            <input
              type="password"
              class="form-control"
              id="exampleInputEmail1"
              placeholder="password"
            />
          </div>
        </div>
        <button type="submit" class="btn btn-login btn-block">Accedi</button>
      </form>
    </div>
  </div>
</div>
