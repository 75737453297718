<p-toast></p-toast>
<app-custom-loader *ngIf="showLoader"></app-custom-loader>
<p-dialog
  appendTo="body"
  [header]="previewObj.title"
  [(visible)]="isPreviewVisible"
  [style]="{
    top: '10vh',
    'margin-top': 0,
    height: '80vh',
    padding: '10px',
    'background-color': '#2b4a61'
  }"
  [contentStyle]="{ height: 'calc(80vh - 85px)', 'overflow-x': 'hidden' }"
  [closable]="true"
  [dismissableMask]="true"
  [draggable]="false"
  [modal]="true"
>
  <pdf-viewer [src]="previewObj.pdf" [render-text]="true" [original-size]="true"></pdf-viewer>
</p-dialog>

<p-dialog
  appendTo="body"
  header="Seleziona articoli"
  [(visible)]="showMultisSelecteModal"
  [style]="{
    top: '40vh',
    left: '40vw',
    height: '40vh',
    width: '70vw'
  }"
  [closable]="true"
  [dismissableMask]="true"
  [draggable]="false"
  [modal]="true"
>
  <app-select-articoli
    *ngIf="showMultisSelecteModal"
    [preSelected]="selectedArticles"
    (onSelect)="selectArticles($event)"
    [selectedContainer]="selectedContainer"
    [selectedTipology]="selectedTipology"
    [selectedProperties]="selectedProperties"
  ></app-select-articoli>
</p-dialog>

<div class="main-content clearfix">
  <div class="list-wrapper clearfix">
    <div class="row">
      <div class="col-sm-8">
        <h4>
          <i aria-hidden="true" class="fa fa-th-list"></i>
          Modifica massiva di una proprietà
        </h4>
        <br />
      </div>
      <div class="col-sm-4">
        <div class="btn-group pull-right">
          <button
            type="button"
            class="btn btn-success"
            [disabled]="registerForm.invalid || !canGoForward"
            (click)="goToRiepilogo()"
          >
            Riepilogo
          </button>
          <button
            type="button"
            class="btn btn-success"
            [disabled]="registerForm.invalid || !canGoForward"
            (click)="massiveUpdate()"
          >
            Aggiorna
          </button>
          <button type="button" class="btn btn-warning" (click)="goBack()">Annulla</button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="panel panel-default">
      <div class="panel-body view">
        <div class="search-panel">
          <div class="row">
            <div class="col-sm-3">
              <p-dropdown
                [options]="containersAsItems$ | async"
                [(ngModel)]="selectedContainer"
                (onChange)="containerSelected($event)"
                placeholder="Seleziona Gara..."
                [style]="{ width: '100%' }"
              ></p-dropdown>
            </div>

            <div class="col-sm-3">
              <p-dropdown
                [options]="tipologiesAsItems$ | async"
                [(ngModel)]="selectedTipology"
                (onChange)="tipologySelected($event)"
                [disabled]="!selectedContainer"
                placeholder="Seleziona
                            Tipologia..."
                [style]="{ width: '100%' }"
              ></p-dropdown>
            </div>

            <div class="col-sm-3">
              <p-multiSelect
                [options]="propertiesAsItems"
                [(ngModel)]="selectedProperties"
                [selectedItemsLabel]="'{0} proprietà selezionate'"
                (onChange)="propertySelected($event)"
                [disabled]="!selectedTipology"
                [defaultLabel]="'Seleziona Proprietà...'"
                [style]="{ width: '100%' }"
              ></p-multiSelect>
            </div>

            <div class="col-sm-3">
              <div
                class="custom-input ui-inputtext"
                [ngClass]="!selectedProperties ? 'ui-state-disabled' : ''"
                (click)="toggleMultiSelectModal()"
              >
                <div class="placeholder" *ngIf="selectedArticles.length === 0">Articolo...</div>
                <div class="selected" *ngIf="selectedArticles.length > 0">
                  <ng-container *ngIf="selectedArticles.length <= 3">
                    <ng-container *ngFor="let item of selectedArticles; let last = last">
                      {{ item.name }}
                      <span *ngIf="!last">,</span>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="selectedArticles.length > 3">
                    <ng-container
                      *ngFor="let item of selectedArticles; let last = last; let i = index"
                    >
                      <ng-container *ngIf="i <= 2">
                        {{ item.name }}
                        <span *ngIf="i < 2">,</span>
                      </ng-container>
                    </ng-container>
                    <span>...</span>
                  </ng-container>
                </div>

                <div class="counter">
                  {{ selectedArticles.length }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="registerForm">
    <div class="panel panel-default" formArrayName="printables">
      <div class="panel-body view">
        <ng-container *ngIf="newPrintablesForTable.length > 0; else elseBlock">
          <div
            class="ui-table ui-widget ui-table-responsive ui-table-resizable ui-table-resizable-fit"
          >
            <div class="ui-table-wrapper">
              <div class="fixed-header-table">
                <p-table
                  [value]="printables.controls"
                  [paginator]="true"
                  [rows]="20"
                  [rowsPerPageOptions]="[10, 20, 50, 100]"
                  [totalRecords]="newPrintablesForTable.length"
                  [resizableColumns]="true"
                  [globalFilterFields]="['description', 'value.uuid']"
                  class="table table-striped massiva-table"
                  #table
                >
                  <ng-template pTemplate="header" let-columns class="ui-table-thead">
                    <tr class="sort-header">
                      <th class="ui-sortable-column ui-resizable-column" width="10px"></th>
                      <th class="ui-sortable-column ui-resizable-column" width="100px">
                        <span>Articolo</span>
                      </th>
                      <ng-template
                        ngFor
                        let-property
                        [ngForOf]="printables.controls[0].value.properties | orderBy: 'name'"
                      >
                        <th
                          *ngIf="selectedProperties.includes(property.name)"
                          class="ui-sortable-column ui-resizable-column"
                        >
                          <span>{{ property.label | titlecase }}</span>
                        </th>
                      </ng-template>
                      <th class="ui-sortable-column ui-resizable-column" width="50px">Preview</th>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="body"
                    let-rowData
                    let-rowIndex="rowIndex"
                    class="ui-table-tbody"
                  >
                    <tr [formGroupName]="rowIndex">
                      <td class="ui-resizable-column" width="60px">{{ rowIndex + 1 }}</td>

                      <td class="ui-resizable-column">
                        {{ printablesMap?.get(rowData.value.uuid)?.name }}
                        <br />
                        {{ printablesMap?.get(rowData.value.uuid)?.description }}
                        <br />
                        {{ printablesMap?.get(rowData.value.uuid)?.uuid }}
                      </td>

                      <ng-container formArrayName="properties">
                        <ng-template
                          ngFor
                          let-property
                          let-j="index"
                          [ngForOf]="rowData.controls.properties.controls | orderBy: 'value.name'"
                        >
                          <td
                            class="ui-resizable-column"
                            width="400px"
                            *ngIf="selectedProperties.includes(property.value.name)"
                            [formGroupName]="j"
                          >
                            <app-property-valorization
                              [property]="property"
                              [locale]="langIt"
                              [i]="rowIndex"
                              [iconsItems]="iconsItems"
                              [hideLabel]="true"
                              [printableUuid]="rowData?.value?.uuid"
                            ></app-property-valorization>
                          </td>
                        </ng-template>
                      </ng-container>

                      <td>
                        <button
                          type="button"
                          pButton
                          class="btn btn-sm btn-secondary"
                          (click)="
                            openPreviewModal(
                              rowData.value.uuid,
                              printablesMap?.get(rowData.value.uuid)?.name +
                                ' - ' +
                                printablesMap?.get(rowData.value.uuid)?.description
                            )
                          "
                        >
                          <i class="fa fa-print"></i>
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #elseBlock>
          <div class="col-sm-12">
            <div class="alert alert-warning text-center">Nessun articolo trovato!</div>
          </div>
        </ng-template>
      </div>
    </div>
  </form>
</div>
