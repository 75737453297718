import { Component } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ArticleService } from '../../service/article.service';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';

import { PrintableListComponent } from '../printable/printable-list.component';
import { Printable } from '../../model/printable';
import { ListableService } from 'src/app/service/listable.service';
import { PRINT_PATH } from 'src/app/constants/constants';
import { ConfigurationService } from 'src/app/service/configuration.service';

@Component({
    selector: 'app-article-list',
    templateUrl: './article-list.component.html',
    styleUrls: ['./article-list.component.css'],
    providers: [ArticleService],
})
export class ArticleListComponent extends PrintableListComponent {
    archivedFilter = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected articleService: ArticleService,
        protected categoryService: CategoryService,
        protected tipologyService: TipologyService,
        protected listableService: ListableService,
        private configurationService: ConfigurationService
    ) {
        super(
            router,
            route,
            'articles',
            articleService,
            categoryService,
            tipologyService,
            listableService
        );
    }

    ngOnInit() {
        this.archivedFilter = this.service.search.obj.active === false;
    }

    createInstance(): Printable {
        const printable: Printable = new Printable();
        printable.objectType = 'ARTICLE';
        return printable;
    }

    setArchivedFilter(archivedFilter: any) {
        this.archivedFilter = archivedFilter;
    }

    fillCategory(): void {
        this.service.search.obj.category_uuid = this.selectedCategory?.uuid || null;
    }

    clearCategory(): void {
        this.selectedCategory = null;
        this.service.search.obj.category_uuid = null;
    }

    fillTipology(): void {
        this.service.search.obj.tipology_uuid = this.selectedTipology?.uuid || null;
    }

    clearTipology(): void {
        this.selectedTipology = null;
        this.service.search.obj.tipology_uuid = null;
    }

    filterSelected(payload) {
        const { category, tipology } = payload;
        this.selectedCategory = category;
        this.selectedTipology = tipology;

        this.fillTipology();
        this.fillCategory();
    }
}
