<p-toast></p-toast>
<div class="loading-indicator" *ngIf="loading">
  <p-progressSpinner strokeWidth="3"></p-progressSpinner>
</div>
<div class="main-content clearfix" *ngIf="!loading">
  <div class="list-wrapper clearfix">
    <div class="content clearfix">
      <div class="panel panel-default">
        <div class="panel-body view">
          <div class="form-group row clearfix">
            <div class="col-sm-12">
              <label><strong>Aggiungi Pagina</strong></label>
              <div class="clearfix"></div>
              <hr />
            </div>

            <div class="clearfix"></div>
            <div class="col-sm-12">
              <form [formGroup]="registerForm">
                <p-confirmDialog icon="fa fa-question-circle" #cd>
                  <footer>
                    <button
                      type="button"
                      pButton
                      icon="ui-icon-undo"
                      label="No"
                      (click)="cd.reject()"
                    ></button>
                    <button
                      type="button"
                      pButton
                      icon="ui-icon-check"
                      label="Sì"
                      (click)="cd.accept()"
                    ></button>
                  </footer>
                </p-confirmDialog>
                <div class="form-group row clearfix">
                  <div class="col-sm-3">
                    <label class="label-text">
                      Cod. Art. Interno
                      <span *ngIf="internal_code | isFieldRequired">(*)</span>
                      :
                    </label>
                  </div>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      pInputText
                      placeholder="Cod. Art. Interno"
                      formControlName="internal_code"
                    />
                  </div>
                </div>
                <div class="clearfix"></div>

                <div class="form-group row clearfix">
                  <div class="col-sm-3">
                    <label class="label-text">
                      Nome
                      <span *ngIf="name | isFieldRequired">(*)</span>
                      :
                    </label>
                  </div>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      pInputText
                      placeholder="Nome"
                      formControlName="name"
                    />
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="form-group row clearfix">
                  <div class="col-sm-3">
                    <label class="label-text">
                      Descrizione
                      <span *ngIf="description | isFieldRequired">(*)</span>
                      :
                    </label>
                  </div>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      pInputText
                      placeholder="Descrizione"
                      formControlName="description"
                    />
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="form-group row clearfix">
                  <div class="col-sm-3">
                    <label class="label-text">
                      Codice Gara
                      <span *ngIf="codiceGara | isFieldRequired">(*)</span>
                      :
                    </label>
                  </div>
                  <div class="col-sm-9">
                    <p-dropdown
                      [options]="codiciGaraItems"
                      [showClear]="true"
                      formControlName="codiceGara"
                      placeholder="Codice Gara"
                      [style]="{ width: '100%' }"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="form-group row clearfix">
                  <div class="col-sm-3">
                    <label class="label-text">
                      tags
                      <span *ngIf="tags | isFieldRequired">(*)</span>
                      :
                    </label>
                  </div>
                  <div class="col-sm-9">
                    <p-chips formControlName="tags"></p-chips>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="form-group row clearfix">
                  <div class="col-sm-3">
                    <label class="label-text">
                      Tipologia
                      <span *ngIf="tipology | isFieldRequired">(*)</span>
                      :
                    </label>
                  </div>
                  <div class="col-sm-9">
                    <div class="ui-inputgroup">
                      <span
                        class="ui-inputgroup-addon btn-update"
                        style="cursor: pointer;"
                        (click)="confirmRefresh()"
                      >
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                      </span>
                      <p-dropdown
                        [options]="tipologyItems"
                        (onChange)="PVAL_displayProperties($event)"
                        formControlName="tipology"
                        placeholder="Tipologia"
                        id="tipology"
                        [style]="{ width: '100%' }"
                      ></p-dropdown>
                    </div>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="form-group row clearfix">
                  <div class="col-sm-3">
                    <label class="label-text">
                      Stato
                      <span *ngIf="state | isFieldRequired">(*)</span>
                      :
                    </label>
                  </div>
                  <div class="col-sm-9">
                    <p-dropdown
                      [options]="stateItems"
                      formControlName="state"
                      placeholder="Stato"
                      id="state"
                      [style]="{ width: '100%' }"
                    ></p-dropdown>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div class="form-group row clearfix">
                  <div class="col-sm-3">
                    <label class="label-text">Archiviata :</label>
                  </div>
                  <div class="col-sm-9">
                    <p-inputSwitch
                      (onChange)="onChangeArchived($event)"
                      [ngModel]="archivedVal"
                      [ngModelOptions]="{ standalone: true }"
                      inputStyleClass="form-control"
                    ></p-inputSwitch>
                  </div>
                </div>

                <div class="clearfix"></div>
                <div formArrayName="properties">
                  <div class="row p-grid">
                    <div
                      class="form-group cleafix"
                      [class]="
                        property.value?.colspan ? 'p-col-' + property.value?.colspan : 'p-col-12'
                      "
                      *ngFor="let property of propertyForms.controls; let i = index"
                      [formGroupName]="i"
                    >
                      <app-property-valorization
                        [property]="property"
                        [i]="i"
                        [iconsItems]="iconsItems"
                        [locale]="langIt"
                      ></app-property-valorization>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </form>
              <div class="clearfix"></div>
              <div class="btn-group pull-right">
                <button
                  type="button"
                  class="btn btn-success"
                  *ngIf="!element.uuid"
                  (click)="save()"
                  [disabled]="registerForm.invalid"
                >
                  Salva
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  *ngIf="element.uuid"
                  (click)="complexUpdate()"
                  [disabled]="registerForm.invalid"
                >
                  Aggiorna
                </button>
                <button type="button" class="btn btn-warning" (click)="goToList()">Annulla</button>
                <button
                  *ngIf="element.uuid"
                  type="button"
                  class="btn btn-danger"
                  (click)="confirmDelete()"
                >
                  <i class="fa fa-trash"></i>
                  Elimina
                </button>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
