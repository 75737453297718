import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Tipology } from 'src/app/model/tipology';
import { PrintableService } from 'src/app/service/printable.service';
import { ArticlePropertyFilterComponent } from '../article-property-filter/article-property-filter.component';
import { CategoryService } from 'src/app/service/category.service';
import { Category } from 'src/app/model/category';
import { TipologyService } from 'src/app/service/tipology.service';

@Component({
    selector: 'app-article-list-filters',
    templateUrl: './article-list-filters.component.html',
    styleUrls: ['./article-list-filters.component.css'],
})
export class ArticleListFiltersComponent implements OnInit {
    @Input() service: PrintableService;
    @Input() tipologies: Map<string, Tipology> = new Map<string, Tipology>();
    @Input() categoryItems: Category[];
    @Input() tipologyItems: Tipology[];
    @Input() locale = {};
    @Input() archivedFilter: boolean;

    @Output() setArchivedFilter = new EventEmitter<boolean>();
    @Output() cerca = new EventEmitter<any>();
    // tslint:disable-next-line: no-output-native
    @Output() reset = new EventEmitter<any>();
    @Output() filterSelected = new EventEmitter<any>();

    @Input() clearTipology: Function;

    @Input() clearCategory: Function;

    @ViewChild('op') overlayPanel: OverlayPanel;
    @ViewChild('propertyFilter') propertyFilter: ArticlePropertyFilterComponent;
    constructor(public categoryService: CategoryService, public tipologyService: TipologyService) {}

    @Input() selectedCategory: any;
    @Input() selectedTipology: any;

    ngOnInit(): void {
        console.log('TYPOLOGY ITEMS ON INIT IN ARTILE LIST FINTERS', this.tipologyItems);
    }

    filterTipology(event = null): void {
        this.tipologyService.buildSearch();
        this.tipologyService.search.like.name = event.query;
        this.tipologyService.search.pageSize = 20;

        this.tipologyService.getList().subscribe(
            (list) => (this.tipologyItems = list),
            () => {
                console.log('Errore nel filraggio delle tipologie');
            }
        );
    }

    filterCategory(event = null): void {
        this.categoryService.buildSearch();
        this.categoryService.search.like.description = event.query;
        this.categoryService.search.pageSize = 20;

        this.categoryService.getList().subscribe(
            (list) => (this.categoryItems = list),
            () => {
                console.log('Errore nel filraggio dei piani');
            }
        );
    }

    onFilterArchived(event: any) {
        this.setArchivedFilter.emit(event.checked);
        this.service.search.obj.active = event.checked ? false : null;
    }

    emitSelectedFilters(payload: any) {
        this.filterSelected.emit({
            category: this.selectedCategory,
            tipology: this.selectedTipology,
            ...payload,
        });
    }
}
