import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractService } from '../common/abstract-service';
import { Search } from '../common/search';
import { PrintLog } from '../model/print-log';
import { PRINT_LOG_API_PATH } from '../constants/constants';

import { MessageService } from 'primeng/api';
import { ConfigurationService } from './configuration.service';

@Injectable({
    providedIn: 'root',
})
export class PrintLogService extends AbstractService<PrintLog> {
    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        configurationService: ConfigurationService
    ) {
        super(configurationService.getValue(PRINT_LOG_API_PATH), httpClient, messageService);
    }

    public getId(element: PrintLog) {
        return element.uuid;
    }

    public buildSearch() {
        this.search = new Search<PrintLog>(PrintLog);
    }

    public getLogPdf(logUuid: string) {
        return this.httpClient.get(`${this.url}/pdf/${logUuid}`, { responseType: 'blob' });
    }
}
