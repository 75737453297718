import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isFieldRequired',
    pure: true
})
export class IsFieldRequiredPipe implements PipeTransform {
    transform(field: any, ...args: any[]): any {
        return field && field.validator && field.validator('')
            ? field.validator('').required
            : false;
    }
}
