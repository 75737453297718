import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { CatalogueService } from '../../service/catalogue.service';
import { PrintableService } from '../../service/printable.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { AbstractEditComponent } from '../../common/abstract-edit-component';

import { Catalogue } from 'src/app/model/catalogue';
import { PrintTemplateService } from 'src/app/service/print-template.service';
import { ContainerService } from 'src/app/service/container.service';
import { Location } from '@angular/common';
import { takeUntil, map } from 'rxjs/operators';
import { PropertyValorization } from '../property/interfaces/property-valorization-interface';
import { PropertyService } from 'src/app/service/property.service';
import { FONT_AWESOME_ICONS } from '../../model/enum/icons';
import { PrintTemplate } from 'src/app/model/print-template';
import { PropertyValue } from 'src/app/model/property-value';
import { PropertyDefinition } from '../property/interfaces/property-definition-interface';
import {
    manipulateProperties,
    generateExtra,
    addControlToFormGroup,
} from 'src/app/shared-functions/forms-util';
import { AttachmentService } from 'src/app/service/attachment.service';
import { BARCODE_TYPES } from '../../constants/barcode-types';
import { propertyTypesCatalogueItems } from '../../model/enum/property-type';
import { Observable } from 'rxjs';
import { ListableService } from 'src/app/service/listable.service';
import { Search } from 'src/app/common/search';
import { TipologyService } from 'src/app/service/tipology.service';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-catalogue-edit',
    templateUrl: './catalogue-edit.component.html',
    styleUrls: ['./catalogue-edit.component.css'],
    providers: [PrintableService],
})
export class CatalogueEditComponent extends AbstractEditComponent<Catalogue>
    implements OnInit, PropertyValorization, PropertyDefinition {
    element: Catalogue;
    registerForm: FormGroup;
    containerUuidFromRoute: string = null;
    templatesMap: Map<string, PrintTemplate> = new Map<string, PrintTemplate>();
    allTemplatesItems: SelectItem[] = [];
    allTipologiesItems$: Observable<SelectItem[]>;
    codiciGaraItems: SelectItem[] = [];

    /*PROPERTY DEFINITION */
    iconsItems: SelectItem[] = FONT_AWESOME_ICONS;
    barcodeTypesItems: SelectItem[] = BARCODE_TYPES;
    propertyTypesCatalogueItems: SelectItem[] = propertyTypesCatalogueItems;
    listableNamesItems$: Observable<SelectItem[]> = this.listableService.listNamesAsItems();

    archivedVal = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected catalogueService: CatalogueService,
        private formBuilder: FormBuilder,
        public printableService: PrintableService,
        public printTemplateService: PrintTemplateService,
        private confirmationService: ConfirmationService,
        public injector: Injector,
        public containerService: ContainerService,
        public propertyService: PropertyService,
        public attachmentService: AttachmentService,
        public listableService: ListableService,
        public location: Location,
        public tipologyService: TipologyService,
        protected routingStateService: RoutingStateService,
        messageService: MessageService
    ) {
        super(router, route, catalogueService, routingStateService, 'catalogues', messageService);
        this.element = new Catalogue();
    }

    ngOnInit() {
        this.containerUuidFromRoute = this.route.snapshot.paramMap.get('containerUuid');
        super.ngOnInit();
        this.getSelectableItems();
        this.buildForm();
        if (this.route.snapshot.paramMap.get('returnBack')) {
            this.navigateAfterUpdate = () => this.location.back();
            this.navigateAfterDelete = () => this.location.back();
            this.navigateToList = () => this.location.back();
        }
        this.getAllTemplates();
        this.getAllTipologies();
        this.getCodiciGara();
    }

    private getCodiciGara() {
        this.containerService
            .getAllCodicigaraAsItems()
            .pipe(takeUntil(this.destroy$))
            .subscribe((i) => (this.codiciGaraItems = i));
    }

    postFind() {
        if (this.router.url.includes('catalogues/clone')) {
            this.element.uuid = null;
            this.element.name = this.element.name ? this.element.name + ' CLONE' : null;
        }
        this.buildForm();
        if (this.element.properties) {
            this.propertyForms.clear();
            this.PVAL_displayPropertyListEditMode(this.element.properties);
        }
        this.archivedVal = !this.element.active;
    }

    postCreate() {
        this.element.active = true;
    }

    private buildForm() {
        this.registerForm = this.formBuilder.group({
            name: [this.element.name, Validators.required],
            description: [this.element.description],
            container_uuid: [
                {
                    value: this.containerUuidFromRoute ?? this.element.container_uuid,
                    disabled: this.containerUuidFromRoute,
                },
            ],
            properties: this.formBuilder.array([]),
            tags: [this.element.tags ? this.element.tags.split(';') : []],
            withAttchamentsAtEnd: [this.element.withAttchamentsAtEnd],
            withAttchamentAfterSingleArticle: [this.element.withAttchamentAfterSingleArticle],
            onlyAttchaments: [this.element.onlyAttchaments],
            monopage: [this.element.monopage],
            monopage_template_uuid: [this.element.monopage_template_uuid],
            active: [this.element.active],
        });
    }

    private getValuesFromForm() {
        this.element.name = this.name.value;
        this.element.description = this.description.value;
        this.element.container_uuid = this.container_uuid.value;
        this.element.properties = this.propertyForms.value;
        this.element.tags = this.tags.value.length > 0 ? this.tags.value.join(';') : '';
        this.element.withAttchamentsAtEnd = this.registerForm.get('withAttchamentsAtEnd').value;
        this.element.withAttchamentAfterSingleArticle = this.registerForm.get(
            'withAttchamentAfterSingleArticle'
        ).value;
        this.element.onlyAttchaments = this.registerForm.get('onlyAttchaments').value;
        this.element.monopage = this.monopage.value;
        this.element.monopage_template_uuid = this.monopage_template_uuid.value;
        this.element.active = this.active.value;

        manipulateProperties(this.element, 'value', this.attachmentService);
    }

    /*PROPERTY VALORIZATION */

    PVAL_displayProperties(event) {
        const template = this.templatesMap.get(event.value);
        if (!template.properties || template.properties?.length === 0) {
            return;
        }
        if (this.propertyForms) {
            let myArray: Array<PropertyValue> = this.propertyForms.value;
            myArray = myArray.filter((p) => p.isDefinitionMode);
            this.propertyForms.clear();
            this.PVAL_displayPropertyListEditMode(myArray);
        }
        this.PVAL_displayPropertyListDefault(template.properties);
    }

    PVAL_displayPropertyListDefault(properties) {
        properties.map((p) => {
            this.propertyService.addProperty(this.propertyForms, p, 'value', 'default');
        });
    }

    PVAL_displayPropertyListEditMode(properties) {
        properties.map((p) => {
            this.propertyService.addProperty(this.propertyForms, p, 'value', 'edit');
        });
    }

    /*PROPERTY DEFINITION*/

    getSelectableItems() {}

    displayPropertyListDefault() {}

    addEmptyProperty() {
        const orderBy = this.propertyForms?.length + 1;
        const emptyProp = new PropertyValue();
        emptyProp.isDefinitionMode = true;
        emptyProp.order_by = orderBy;
        this.propertyService.addProperty(this.propertyForms, emptyProp, 'value');
    }

    deleteProperty(i) {
        this.propertyForms.removeAt(i);
    }

    propertyTypeSelected({ event, indexInFormArray }) {
        const generatedExtra = generateExtra(
            this.formBuilder,
            this.propertyForms.at(indexInFormArray).get('property_type').value,
            null
        );
        if (generatedExtra) {
            const formGroup = this.propertyForms.at(indexInFormArray) as FormGroup;
            formGroup.removeControl('extra');
            addControlToFormGroup(formGroup, 'extra', generatedExtra);
        }

        this.propertyForms.at(indexInFormArray).patchValue({ value: null });
    }

    valueChangedFromChild({ value, index }) {
        this.propertyForms.at(index).patchValue({ value });
    }

    /*PROPERTY COMMON */

    filterOutAttachmentProps() {
        this.element.properties = this.element.properties.filter(
            (p) =>
                !this.propertyService.isImageType(p, 'value') &&
                !this.propertyService.isAttachmentType(p, 'value')
        );
    }

    preSave() {
        this.getValuesFromForm();
        const haveError = this.propertiesErrorHandling();
        this.filterOutAttachmentProps();
        this.element.properties.sort((a, b) => a.order_by - b.order_by);

        return !haveError;
    }

    navigateAfterSave() {
        const lastRoute = this.routingStateService.getPreviousUrl();
        this.router.navigate([`${lastRoute}`]);
    }

    postSave() {
        const anyAttachment = this.propertyService.hasAnyAttachment(this.propertyForms, 'value');
        if (anyAttachment) {
            this.navigateAfterSave = () => {};
            this.complexUpdate();
        }
    }

    preUpdate() {
        this.getValuesFromForm();
        const haveError = this.propertiesErrorHandling();
        this.element.properties.sort((a, b) => a.order_by - b.order_by);
        return !haveError;
    }

    complexUpdate() {
        const promises = this.propertyService.checkAndUploadImagesAndAttachmnts(
            this.propertyForms,
            this.element,
            'value'
        );
        if (promises) {
            Promise.all(promises).then((_) => {
                super.update();
            });
        } else {
            super.update();
        }
    }

    propertiesErrorHandling() {
        const errorCheck = this.propertyService.havePropertiesErrors(this.element.properties);
        if (errorCheck.haveError) {
            this.addError(errorCheck.msg);
        }
        return errorCheck.haveError;
    }

    getId() {
        return this.element.uuid;
    }

    createInstance(): Catalogue {
        return new Catalogue();
    }

    confirmDelete() {
        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () => {
                this.delete();
            },
        });
    }
    withAttchamentsAtEndFn(event) {
        if (event.checked) {
            this.withAttchamentAfterSingleArticleClear();
            this.onlyAttchamentsClear();
            this.monoPageAfterOnlyAttchamentsOn();
        }
    }

    withAttchamentsAtEndClear() {
        this.element.withAttchamentsAtEnd = false;
        this.registerForm.patchValue({ withAttchamentsAtEnd: false });
    }

    withAttchamentAfterSingleArticleFn(event) {
        if (event.checked) {
            this.withAttchamentsAtEndClear();
            this.onlyAttchamentsClear();
            this.monoPageClear();
        }
    }

    withAttchamentAfterSingleArticleClear() {
        this.element.withAttchamentAfterSingleArticle = false;
        this.registerForm.patchValue({
            withAttchamentAfterSingleArticle: false,
        });
    }

    onlyAttchamentsFn(event) {
        if (event.checked) {
            this.withAttchamentsAtEndClear();
            this.withAttchamentAfterSingleArticleClear();
            this.monoPageClear();
        }
    }

    onlyAttchamentsClear() {
        this.element.onlyAttchaments = false;
        this.registerForm.patchValue({ onlyAttchaments: false });
    }

    monoPageFn(event) {
        if (event.checked) {
            this.onlyAttchamentsClear();
            this.withAttchamentAfterSingleArticleClear();
        } else {
            this.monoPageClear();
        }
    }
    monoPageAfterOnlyAttchamentsOn() {
        if (this.element.monopage == false) {
            this.element.monopage = true;
            this.registerForm.patchValue({ monopage: true });
        }
    }
    monoPageClear() {
        this.element.monopage = false;
        this.registerForm.patchValue({ monopage: false });
        this.element.monopage_template_uuid = null;
        this.registerForm.patchValue({ monopage_template_uuid: null });
    }

    getAllTemplates() {
        const search = new Search<PrintTemplate>(PrintTemplate);
        search.obj.printTemplateType = 'CATALOGUE';

        this.printTemplateService
            .getAllList(search)
            .pipe(takeUntil(this.destroy$))
            .subscribe((templates) => {
                this.allTemplatesItems = [
                    ...templates.map((t) => {
                        this.templatesMap.set(t.uuid, t);
                        return {
                            label: t.name,
                            value: t.uuid,
                        };
                    }),
                ];
            });
    }

    getAllTipologies() {
        this.allTipologiesItems$ = this.tipologyService.getAllList().pipe(
            map((tipologies) => {
                return [
                    ...tipologies.map((t) => ({
                        label: t.name,
                        value: t.uuid,
                    })),
                ];
            })
        );
    }

    get name() {
        return this.registerForm.get('name');
    }

    get description() {
        return this.registerForm.get('description');
    }

    get container_uuid() {
        return this.registerForm.get('container_uuid');
    }

    get tags() {
        return this.registerForm.get('tags');
    }

    get active() {
        return this.registerForm.get('active');
    }

    get monopage() {
        return this.registerForm.get('monopage');
    }

    get monopage_template_uuid() {
        return this.registerForm.get('monopage_template_uuid');
    }
    get propertyForms() {
        return this.registerForm.get('properties') as FormArray;
    }

    onChangeArchived(event) {
        this.archivedVal = event.checked;
        this.registerForm.patchValue({ active: !event.checked });
    }
}
