<p-toast></p-toast>
<p-confirmDialog icon="fa fa-question-circle" #cd>
  <footer>
    <button type="button" pButton icon="ui-icon-undo" label="No" (click)="cd.reject()"></button>
    <button type="button" pButton icon="ui-icon-check" label="Sì" (click)="cd.accept()"></button>
  </footer>
</p-confirmDialog>

<p-dialog
  appendTo="body"
  [header]="previewObj.title"
  [(visible)]="isPreviewVisible"
  [style]="{
    top: '10vh',
    'margin-top': 0,
    height: '80vh',
    padding: '10px',
    'background-color': '#2b4a61'
  }"
  [contentStyle]="{ height: 'calc(80vh - 85px)', 'overflow-x': 'hidden' }"
  [closable]="true"
  [dismissableMask]="true"
  [draggable]="false"
  [modal]="true"
>
  <pdf-viewer [src]="previewObj.pdf" [render-text]="true" [original-size]="true"></pdf-viewer>
</p-dialog>

<app-article-list-filters
  #articleFilters
  [service]="tableUtilsService.dataService"
  [tipologies]="tipologies"
  [categoryItems]="categoryItems"
  [tipologyItems]="tipologyItems"
  [locale]="langIt"
  (cerca)="cerca(table)"
  (reset)="tableUtilsService.reset(table)"
></app-article-list-filters>

<div class="page-title-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <h3>
          Gara:
          <strong>{{ element?.name }}</strong>
          <p>
            <span>
              Descrizione:
              <strong>{{ element?.description }}</strong>
            </span>
            <span>
              Codice:
              <strong>{{ element?.codiceGara }}</strong>
            </span>
          </p>
        </h3>
      </div>

      <div class="col-sm-6">
        <div class="sections">
          <div class="row">
            <div class="item col-sm-4" (click)="view()">Gestione Gara</div>

            <div class="item col-sm-4" (click)="addCatalogues()">Gestione Cataloghi</div>

            <div class="item col-sm-4 active">Gestione Articoli</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="wrapper-md">
  <div class="list-wrapper clearfix">
    <div class="content clearfix">
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-sm-12">
          <h4>
            Gestione Articoli
            <span *ngIf="containerElements | isFieldRequired">(*)</span>
            <button
              *ngIf="!element.archived && !element.closed"
              class="btn btn-add pull-right"
              type="button"
              (click)="newArticle()"
              label=""
            >
              <i class="fa fa-plus-circle" aria-hidden="true"></i>
              Aggiungi Articolo
            </button>
          </h4>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="form-group row clearfix">
        <div [ngClass]="status || element.closed || element.archived ? 'hidden' : 'col-sm-6'">
          <div class="panel panel-default clearfix">
            <div class="panel-heading">Seleziona Articoli / Pagine</div>
            <div class="panel-body view clearfix">
              <div class="filter-wrapper clearfix">
                <button
                  type="text"
                  class="btn btn-filter"
                  label="Basic"
                  appendTo="body"
                  (click)="articleFilters.overlayPanel.toggle($event)"
                >
                  <i class="fa fa-sliders" aria-hidden="true"></i>
                  Cerca
                </button>

                <div class="tags-wrapper">
                  <div
                    class="tag-item"
                    *ngIf="tableUtilsService.dataService.search.like.internal_code"
                  >
                    <strong>Cod. Art. Interno:</strong>
                    {{ tableUtilsService.dataService.search.like.internal_code }}
                    <button
                      (click)="
                        tableUtilsService.dataService.search.like.internal_code = null;
                        tableUtilsService.reload(table)
                      "
                      class="btn-close"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div class="tag-item" *ngIf="tableUtilsService.dataService.search.like.name">
                    <strong>Cod. Articolo:</strong>
                    {{ tableUtilsService.dataService.search.like.name }}
                    <button
                      (click)="
                        tableUtilsService.dataService.search.like.name = null;
                        tableUtilsService.reload(table)
                      "
                      class="btn-close"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div class="tag-item" *ngIf="tableUtilsService.dataService.search.like.tags">
                    <strong>Tags:</strong>
                    {{ tableUtilsService.dataService.search.like.tags }}
                    <button
                      (click)="
                        tableUtilsService.dataService.search.like.tags = null;
                        tableUtilsService.reload(table)
                      "
                      class="btn-close"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div
                    class="tag-item"
                    *ngIf="tableUtilsService.dataService.search.like.description"
                  >
                    <strong>Descrizione:</strong>
                    {{ tableUtilsService.dataService.search.like.description }}
                    <button
                      (click)="
                        tableUtilsService.dataService.search.like.description = null;
                        tableUtilsService.reload(table)
                      "
                      class="btn-close"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div
                    class="tag-item"
                    *ngIf="tableUtilsService.dataService.search.like.codiceGara"
                  >
                    <strong>Codice Gara:</strong>
                    {{ tableUtilsService.dataService.search.like.codiceGara }}
                    <button
                      (click)="
                        tableUtilsService.dataService.search.like.codiceGara = null;
                        tableUtilsService.reload(table)
                      "
                      class="btn-close"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div
                    class="tag-item"
                    *ngIf="tableUtilsService.dataService.search.obj.category_uuid"
                  >
                    <strong>Categoria:</strong>
                    {{ tableUtilsService.dataService.search.obj.category_uuid }}
                    <button
                      (click)="
                        tableUtilsService.dataService.search.obj.category_uuid = null;
                        tableUtilsService.reload(table)
                      "
                      class="btn-close"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div
                    class="tag-item"
                    *ngIf="tableUtilsService.dataService.search.obj.tipology_uuid"
                  >
                    <strong>Tipologia:</strong>
                    {{ tableUtilsService.dataService.search.obj.tipology_uuid }}
                    <button
                      (click)="
                        tableUtilsService.dataService.search.obj.tipology_uuid = null;
                        tableUtilsService.reload(table)
                      "
                      class="btn-close"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div class="tag-item" *ngIf="tableUtilsService.dataService.search.obj.state">
                    <strong>Stato:</strong>
                    {{ tableUtilsService.dataService.search.obj.state }}
                    <button
                      (click)="
                        tableUtilsService.dataService.search.obj.state = null;
                        tableUtilsService.reload(table)
                      "
                      class="btn-close"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>

                  <app-article-property-tag-filter
                    [service]="tableUtilsService.dataService"
                    (reload)="tableUtilsService.reload(table)"
                  ></app-article-property-tag-filter>
                </div>
              </div>
              <p-table
                [value]="tableUtilsService.model"
                [loading]="tableUtilsService.loadingTable"
                [lazy]="true"
                (onLazyLoad)="tableUtilsService.lazyLoad($event)"
                [paginator]="true"
                [rowsPerPageOptions]="[10, 50, 100]"
                [responsive]="true"
                [rows]="tableUtilsService.dataService.search.pageSize"
                [totalRecords]="tableUtilsService.dataService.listSize"
                [resizableColumns]="true"
                stateStorage="session"
                stateKey="{{ router.url }}_sx"
                #table
              >
                <ng-template pTemplate="header" let-columns class="search-filter">
                  <tr class="sort-header">
                    <th pResizableColumn [pSortableColumn]="'internal_code'">
                      Cod. Art. Interno
                      <p-sortIcon [field]="'internal_code'"></p-sortIcon>
                    </th>
                    <th pResizableColumn [pSortableColumn]="'name'">
                      Cod. Articolo
                      <p-sortIcon [field]="'name'"></p-sortIcon>
                    </th>
                    <th pResizableColumn [pSortableColumn]="'codiceGara'">
                      Descrizione
                      <p-sortIcon [field]="'codiceGara'"></p-sortIcon>
                    </th>
                    <th pResizableColumn [pSortableColumn]="'codiceGara'">
                      Codice Gara
                      <p-sortIcon [field]="'codiceGara'"></p-sortIcon>
                    </th>
                    <th style="width: 18%;" class="Operations"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="3">Nessun Articolo / Pagina.</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                  <tr>
                    <td class="ui-resizable-column">
                      {{ rowData.internal_code }}
                    </td>

                    <td class="ui-resizable-column">
                      {{ rowData.name }}
                    </td>

                    <td class="ui-resizable-column">
                      {{ rowData.description }}
                    </td>

                    <td class="ui-resizable-column">
                      {{ rowData.codiceGara }}
                    </td>
                    <td>
                      <div style="float: right;">
                        <button
                          type="button"
                          pButton
                          class="btn btn-sm btn-info pull-right"
                          (click)="viewArticle(rowData.uuid)"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                        <button
                          type="button"
                          pButton
                          pTooltip="Clona"
                          tooltipPosition="top"
                          (click)="cloneArticle(rowData)"
                          class="btn btn-default btn-sm"
                          icon="fa fa-clone"
                        ></button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>

        <div
          class=""
          [ngClass]="status || element.archived || element.closed ? 'col-sm-12' : 'col-sm-6'"
        >
          <div class="panel panel-default clearfix">
            <div class="panel-heading">
              <button
                *ngIf="!element.archived && !element.closed"
                class="btn btn-expand pull-right"
                (click)="clickEvent()"
              >
                {{ buttonName }}
              </button>
              Lista Articoli in gara
            </div>
            <div class="panel-body view clearfix">
              <p-table
                #dt
                [value]="containerElementForms.value"
                [paginator]="true"
                [rows]="10"
                [reorderableColumns]="true"
                stateStorage="session"
                stateKey="{{ router.url }}_dx"
                [rowsPerPageOptions]="[10, 50, 100]"
                [responsive]="true"
              >
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th></th>
                    <th>
                      <input
                        pInputText
                        type="text"
                        (input)="dt.filter($event.target.value, 'uuid', 'contains')"
                        placeholder="Articolo"
                        class="form-control"
                      />
                    </th>

                    <th>
                      <input
                        pInputText
                        type="text"
                        (input)="dt.filter($event.target.value, 'description', 'contains')"
                        placeholder="Descrizione"
                        class="form-control"
                      />
                    </th>
                    <th></th>
                  </tr>

                  <tr class="sort-header">
                    <th style="width: 2.5em;"></th>
                    <th style="width: 200px;">Articolo</th>
                    <th>Descrizione</th>
                    <th></th>
                  </tr>
                </ng-template>
                <ng-template
                  pTemplate="body"
                  let-rowData
                  let-columns="columns"
                  let-index="rowIndex"
                >
                  <tr>
                    <td>
                      <i
                        pTooltip="Articolo"
                        *ngIf="rowData.objectType === 'ARTICLE'"
                        aria-hidden="true"
                        class="fa fa-file-text"
                      ></i>
                      <i
                        pTooltip="Pagina"
                        *ngIf="rowData.objectType === 'PAGE'"
                        aria-hidden="true"
                        class="fa fa-file section-icon"
                      ></i>
                    </td>
                    <td style="width: 2.5em;">
                      {{ printablesTranscode?.get(rowData.uuid) }}
                    </td>
                    <td>
                      {{ rowData.description }}
                    </td>
                    <td class="p-d-flex">
                      <button
                        *ngIf="!element.archived && !element.closed"
                        type="button"
                        class="btn btn-sm btn-trash pull-right"
                        pButton
                        (click)="confirmDelete(index, rowData.uuid)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>

                      <button
                        *ngIf="!element.archived && !element.closed"
                        type="button"
                        pButton
                        pTooltip="Clona"
                        tooltipPosition="top"
                        (click)="clone(rowData)"
                        class="btn btn-sm btn-default pull-right"
                      >
                        <i class="fa fa-clone"></i>
                      </button>

                      <button
                        *ngIf="!element.archived && !element.closed"
                        type="button"
                        pButton
                        class="btn btn-sm btn-success pull-right"
                        (click)="
                          editArticle(rowData.uuid, {
                            articleIndex: +index,
                            entity: 'gare',
                            entityUuid: element.codiceGara
                          })
                        "
                      >
                        <i class="fa fa-edit"></i>
                      </button>

                      <button
                        type="button"
                        pButton
                        class="btn btn-sm btn-info pull-right"
                        (click)="viewArticle(rowData.uuid)"
                      >
                        <i class="fa fa-eye"></i>
                      </button>

                      <button
                        type="button"
                        pButton
                        class="btn btn-sm btn-secondary pull-right"
                        (click)="
                          openPreviewModal(rowData.uuid, printablesTranscode?.get(rowData.uuid))
                        "
                      >
                        <i class="fa fa-print"></i>
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
