<p-toast></p-toast>
<p-confirmDialog icon="fa fa-question-circle" #cd>
  <footer>
    <button type="button" pButton icon="ui-icon-undo" label="No" (click)="cd.reject()"></button>
    <button type="button" pButton icon="ui-icon-check" label="Sì" (click)="cd.accept()"></button>
  </footer>
</p-confirmDialog>
<div class="main-content clearfix">
  <div class="panel panel-default">
    <div class="panel-body view">
      <div class="row">
        <div class="col-sm-12">
          <label>Dettaglio tipologia</label>
          <div class="clearfix"></div>
          <hr />
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="row clearfix">
        <div class="col-sm-3">
          <label>Nome:</label>
        </div>

        <div class="col-sm-9">
          {{ element.name }}
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="form-group row clearfix">
        <div class="col-sm-3">
          <label>Contenuto:</label>
        </div>
        <div class="col-sm-9">
          <ngx-monaco-editor
            class="form-control template-editor"
            [options]="editorOptions"
            [ngModel]="element.page_content"
          ></ngx-monaco-editor>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-sm-3">
          <label>Categoria:</label>
        </div>
        <div class="col-sm-9">
          {{ categoriesTranscode?.get(element.category_uuid) }}
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="row clearfix">
        <div class="col-sm-3">
          <label>Templates:</label>
        </div>
        <div class="col-sm-9">
          <div class="btn-group">
            <div class="btn btn-info" *ngFor="let template of element.templates">
              {{ template.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-sm-3">
          <label>Template Default:</label>
        </div>
        <div class="col-sm-9">
          {{ element.default_template?.name }}
        </div>
      </div>
      <div class="clearfix"></div>
      <div>
        <br />
        <div class="clearfix"></div>

        <hr />
        <div class="row clearfix">
          <div class="col-sm-3">
            <label>Proprieta:</label>
            <div style="display: flex; float: right;">
              <div style="margin-right: 10px;">
                <label *ngIf="!openAllTabs">Apri tutto</label>
                <label *ngIf="openAllTabs">Chiudi tutto</label>
              </div>
              <p-inputSwitch
                [(ngModel)]="openAllTabs"
                inputStyleClass="form-control"
              ></p-inputSwitch>
            </div>
          </div>
          <div class="col-sm-9">
            <ng-container *ngFor="let property of element.properties; let i = index">
              <app-property-view
                [property]="property"
                [i]="i"
                [propertyValueName]="'default_value'"
                [openAllTabs]="openAllTabs"
                [propertyTypesMap]="propertyTypesMap"
              ></app-property-view>
            </ng-container>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <div class="row">
          <div class="col-sm-12">
            <div class="btn-group std-btn-group">
              <button type="button" class="btn btn-default" (click)="goToList()">
                <i class="fa fa-arrow-left"></i>
                Indietro
              </button>
              <button type="button" (click)="edit(element)" class="btn btn-info right">
                <i class="fa fa-edit"></i>
                Modifica
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
