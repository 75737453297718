<p-toast></p-toast>

<div class="main-content clearfix">
  <div class="list-wrapper clearfix">
    <div class="content clearfix">
      <div class="row">
        <div class="col-sm-3">
          <div class="card">
            <a routerLink="/gare/list">
              <i class="fa fa-tachometer section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Gare</p>
            </a>
            <button type="button" class="btn btn-add" routerLink="/gare/new">
              <i class="fa fa-plus-circle"></i>
              Aggiungi
            </button>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="card">
            <a routerLink="/catalogues/list">
              <i class="fa fa-th-list section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Cataloghi</p>
            </a>
            <button type="button" class="btn btn-add" routerLink="/catalogues/new">
              <i class="fa fa-plus-circle"></i>
              Aggiungi
            </button>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="card">
            <a routerLink="/articles/list">
              <i class="fa fa-file-text section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Articoli</p>
            </a>
            <button type="button" class="btn btn-add" routerLink="/articles/new">
              <i class="fa fa-plus-circle"></i>
              Aggiungi
            </button>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="card">
            <a routerLink="/pages/list">
              <i class="fa fa-file section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Pagine</p>
            </a>
            <button type="button" class="btn btn-add" routerLink="/pages/new">
              <i class="fa fa-plus-circle"></i>
              Aggiungi
            </button>
          </div>
        </div>
        <div class="col-sm-3" *ngIf="roles.includes('manager') || roles.includes('admin')">
          <div class="card">
            <a routerLink="/tipologies/list">
              <i class="fa fa fa-book section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Tipologie</p>
            </a>
            <button type="button" class="btn btn-add" routerLink="/tipologies/new">
              <i class="fa fa-plus-circle"></i>
              Aggiungi
            </button>
          </div>
        </div>
        <div class="col-sm-3" *ngIf="roles.includes('manager') || roles.includes('admin')">
          <div class="card">
            <a routerLink="/templates/list">
              <i class="fa fa-address-card-o section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Templates</p>
            </a>
            <button
              type="button"
              routerLink="/templates/list"
              class="btn btn-add"
              routerLink="/templates/new"
            >
              <i class="fa fa-plus-circle"></i>
              Aggiungi
            </button>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card">
            <a routerLink="/listables/list">
              <i class="fa fa-list section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Listabili</p>
            </a>
            <button type="button" class="btn btn-add" routerLink="/listables/list">
              <i class="fa fa-plus-circle"></i>
              Aggiungi
            </button>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="card">
            <a routerLink="/categories/list">
              <i class="fa fa-tasks section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Categorie</p>
            </a>
            <button type="button" class="btn btn-add" routerLink="/categories/list">
              <i class="fa fa-plus-circle"></i>
              Aggiungi
            </button>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="card">
            <a routerLink="/gare/modifica-massiva-proprieta">
              <i class="fa fa-pencil section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Modifica massiva articoli di gara</p>
            </a>
            <button type="button" class="btn btn-add" routerLink="/gare/modifica-massiva-proprieta">
              <i class="fa fa-plus-circle"></i>
              Modifica
            </button>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="card">
            <a routerLink="/system_event_logs/list">
              <i class="fa fa-copy section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Log di sistema</p>
            </a>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="card">
            <a routerLink="/print_logs/list">
              <i class="fa fa-print section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Log di stampa</p>
            </a>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="card">
            <a routerLink="/import">
              <i class="fa fa-upload section-icon" aria-hidden="true"></i>
              <div class="clearfix"></div>
              <p class="category">Importazioni</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
