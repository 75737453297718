import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ContainerService } from 'src/app/service/container.service';
import { PrintableService } from 'src/app/service/printable.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { SelectItem } from 'primeng/api/selectitem';
import { Tipology } from 'src/app/model/tipology';
import { TipologyService } from 'src/app/service/tipology.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Printable } from 'src/app/model/printable';
import { map } from 'rxjs/operators';
import { Container } from 'src/app/model/container';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { PropertyService } from 'src/app/service/property.service';
import { PropertyValue } from 'src/app/model/property-value';
import { FONT_AWESOME_ICONS } from '../../../model/enum/icons';
import { PrintablePropertyPatch } from 'src/app/model/printable-property-patch';
import { langIt } from 'src/app/constants/calendar-it';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { Table } from 'primeng/table/table';
import { ArticleService } from 'src/app/service/article.service';
import { MessageService } from 'primeng/api';
import { AttachmentService } from 'src/app/service/attachment.service';

@Component({
    selector: 'app-printables-bulk-edit',
    templateUrl: './printables-bulk-edit.component.html',
    styleUrls: ['./printables-bulk-edit.component.css'],
})
export class PrintablesBulkEditComponent implements OnInit, OnDestroy {
    protected destroy$: Subject<void> = new Subject<void>();
    containersAsItems$: Observable<SelectItem[]>;
    tipologiesAsItems$: Observable<SelectItem[]>;
    propertiesAsItems: SelectItem[];
    articlesAsItems: any[] = [];
    selectedContainer: Container;
    selectedTipology: Tipology;
    selectedProperties: string[];
    selectedArticles: any[] = [];
    searchedContainer: Container;
    searchedTipology: Tipology;
    searchedProperties: string[];
    printables$: Observable<Printable[]>;
    propertiesName: string[];
    registerForm: FormGroup;
    iconsItems: SelectItem[] = FONT_AWESOME_ICONS;
    printablesMap: Map<string, Printable> = new Map<string, Printable>();
    public langIt = { ...langIt };
    canGoForward = false;

    @ViewChild('table') table: Table;

    isPreviewVisible: boolean = false;
    previewObj: any = {};

    showMultisSelecteModal: boolean = false;
    selectedFromModal: any[] = [];

    newPrintablesForTable: Printable[] = [];

    showLoader: boolean = false;

    constructor(
        protected router: Router,
        protected location: Location,
        protected containerService: ContainerService,
        protected tipologyService: TipologyService,
        protected printableService: PrintableService,
        protected articleService: ArticleService,
        private formBuilder: FormBuilder,
        protected propertyService: PropertyService,
        protected routingStateService: RoutingStateService,
        protected messageService: MessageService,
        private attachmentsService: AttachmentService
    ) {}

    ngOnInit(): void {
        this.getContainers();
        this.builForm();

        const lastRoute = this.routingStateService.getPreviousUrl();
        if (lastRoute.includes('gare/modifica-massiva-proprieta/')) {
            this.getDataFromService();
            this.canGoForward = true;
        }
        this.printableService.getTemplate();
    }

    toggleMultiSelectModal(): void {
        if (this.selectedProperties) {
            this.showMultisSelecteModal = !this.showMultisSelecteModal;
        }
    }
    selectArticles(articles: any[]): void {
        const selectedArticles = articles;
        this.selectedArticles = selectedArticles;
        this.toggleMultiSelectModal();

        this.registerForm.get('printables').reset();
        this.builForm();
        this.buildArticleOptions(articles);

        this.canGoForward = true;
        this.setSearchedVariables();

        this.newPrintablesForTable = [];
        this.newPrintablesForTable = articles.map((printable) => {
            this.printablesMap.set(printable.uuid, printable);
            const printableFormGroup = this.addPrintableForm(printable);
            // printable.properties = printable.properties.filter((p) =>
            //     this.selectedProperties.includes(p.name)
            // );
            this.propertiesName = printable.properties.map((p) => {
                this.addPropertyForm(printableFormGroup, p);
                return p.name;
            });
            return printable;
        });
    }

    getDataFromService() {
        const dataFromService = {
            ...this.propertyService.localStoredPatchesData,
        };
        console.log('dataFromService', dataFromService);

        if (dataFromService && Object.entries(dataFromService)?.length > 0) {
            if (dataFromService?.selectedContainer) {
                this.selectedContainer = dataFromService.selectedContainer;
                this.searchedContainer = dataFromService.selectedContainer;
                this.getTipologies();
                if (dataFromService?.selectedTipology) {
                    this.selectedTipology = dataFromService.selectedTipology;
                    this.searchedTipology = dataFromService.selectedTipology;
                    this.getProperties();
                    if (dataFromService?.selectedProperties?.length > 0) {
                        this.selectedProperties = [...dataFromService.selectedProperties];
                        this.searchedProperties = [...dataFromService.selectedProperties];
                    }
                }
            }
            this.printablesMap = dataFromService.printablesMap;
            this.propertiesName = dataFromService.propertiesName;

            this.selectArticles(dataFromService.printablePropertiesPatches);
            this.showMultisSelecteModal = false;
        }
    }

    getContainers() {
        this.containersAsItems$ = this.containerService
            .containersAsItems()
            .pipe(
                map((containers) =>
                    containers.filter(
                        (container) => !container.value.closed && !container.value.archived
                    )
                )
            );
    }

    getTipologies() {
        this.tipologiesAsItems$ = this.tipologyService.getAllListAsItems();
    }

    getProperties() {
        if (this.selectedTipology) {
            this.propertiesAsItems = this.selectedTipology.properties.map((p) => ({
                label: p.label,
                value: p.name,
            }));
            this.propertiesAsItems.sort((a, b) => a.label.localeCompare(b.label));
        }
    }

    containerSelected(event) {
        if (event.value) {
            this.clearSelectedTipology();
            this.clearSelectedProperties();
            this.clearSelectedArticle();
            this.selectedContainer = event.value;
            this.getTipologies();
        }
    }

    tipologySelected(event) {
        if (event.value) {
            this.clearSelectedProperties();
            this.clearSelectedArticle();
            this.selectedTipology = event.value;
            this.getProperties();
        }
    }

    propertySelected(event) {
        if (event.value) {
            this.clearSelectedArticle();
            this.selectedProperties = [...event.value];
        }
    }

    buildArticleOptions(articles: Printable[]) {
        console.log('articles', articles);

        // this.articlesAsItems = [
        //     { label: 'Tutti', value: '' },
        //     ...articles.map((article) => ({
        //         label: article.name,
        //         value: article.uuid,
        //     })),
        // ];

        this.articlesAsItems = articles;
    }

    private builForm() {
        this.registerForm = this.formBuilder.group({
            printables: this.formBuilder.array([]),
        });
    }

    private addPrintableForm(printable: Printable) {
        const printableAsFormGroup = this.formBuilder.group({
            uuid: [printable.uuid, Validators.required],
            description: [printable.description],
            tags: [printable.tags],
            version: [printable.version],
            category_uuid: [printable.category_uuid],
            tipology_uuid: [printable.tipology_uuid],
            state: [printable.state],
            properties: this.formBuilder.array([]),
            codiceGara: [printable.codiceGara],
            internal_code: [printable.internal_code],
            name: [printable.name],
        });

        this.printables.push(printableAsFormGroup);
        return printableAsFormGroup;
    }

    private addPropertyForm(printableGroup: FormGroup, property: PropertyValue) {
        const pgProperties = printableGroup.get('properties') as FormArray;
        this.propertyService.addProperty(pgProperties, property, 'value', 'edit');
    }

    goBack() {
        this.location.back();
    }

    setSearchedVariables() {
        this.searchedContainer = { ...this.selectedContainer };
        this.searchedTipology = { ...this.selectedTipology };
        this.searchedProperties = [...this.selectedProperties];
    }

    goToRiepilogo() {
        const printablePatches: PrintablePropertyPatch[] = this.printables.value;
        this.propertyService.localStorePrintablePropertiesPatch({
            selectedContainer: this.searchedContainer,
            selectedTipology: this.searchedTipology,
            selectedProperties: this.searchedProperties,
            registerForm: this.registerForm,
            propertiesName: this.propertiesName,
            printablesMap: this.printablesMap,
            tipologyUuid: this.searchedTipology.uuid,
            printablePropertiesPatches: printablePatches,
        });
        this.router.navigate([
            `gare/modifica-massiva-proprieta/${this.searchedContainer.codiceGara}`,
        ]);
    }

    async massiveUpdate() {
        this.showLoader = true;
        const toUpdateArr = [];

        const filteredToUpdate = [];
        const filterUpdated = [];

        for await (const printable of this.registerForm.value.printables) {
            const localProperties = printable.properties;
            const filtered = localProperties.filter(
                (inF: any) => inF.property_type === 'ATTACH_VALUE'
            );
            for await (const filter of filtered) {
                if (filter.value) {
                    if (filter.value.name && filter.extra) {
                        if (filter.value.name !== filter.extra.fileName) {
                            filteredToUpdate.push(filter);
                            this.attachmentsService
                                .setAttachment(filter.value, printable)
                                .then((res) => {
                                    filterUpdated.push(filter);
                                    filter.extra['fileName'] = filter.value.name;
                                    filter.value = res.url;

                                    printable.properties = [...localProperties];
                                });
                        }
                    }
                }
            }
            toUpdateArr.push(this.printableService.update(printable));
        }

        const interval = setInterval(() => {
            if (filteredToUpdate.length === filterUpdated.length) {
                clearInterval(interval);
                forkJoin(toUpdateArr).subscribe({
                    next: () => {
                        this.updatePrintableVersion(this.registerForm.get('printables'));
                        this.addInfo('Articoli Modificati');

                        this.showLoader = false;
                    },
                    error: () => {
                        this.addError('Impossibile aggiornare. Riprovare.');
                        this.showLoader = false;
                    },
                });
            }
        }, 500);
    }

    updatePrintableVersion(printables: AbstractControl) {
        const updatedVersionPrintables = printables.value.map((printable: Printable) => ({
            ...printable,
            version: printable.version + 1,
        }));
        printables.patchValue(updatedVersionPrintables);
    }

    addInfo(message: string) {
        this.messageService.add({
            severity: 'success',
            summary: 'Informazioni: ',
            detail: message,
        });
    }

    addError(message: string) {
        this.messageService.add({
            severity: 'error',
            summary: 'Errore',
            detail: message,
        });
    }

    clearPrintables() {
        if (this.printables) {
            this.printables.clear();
            this.propertiesName = null;
        }
    }

    clearSelectedTipology() {
        this.selectedTipology = null;
    }

    clearSelectedProperties() {
        this.selectedProperties = null;
    }

    clearSelectedArticle() {
        this.selectedArticles = [];
        this.newPrintablesForTable = [];
    }

    get printables() {
        return this.registerForm.get('printables') as FormArray;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openPreviewModal(articleUuid: string, title: string) {
        if (!this.printableService.printTemplate)
            return this.addError('Template di default di tipo Articolo non presente');

        this.printableService
            .getPdf(articleUuid, this.printableService.printTemplate)
            .subscribe((pdf: Blob) => {
                this.previewObj = {
                    title,
                    pdf: window.URL.createObjectURL(pdf),
                };
                this.isPreviewVisible = true;
            });
    }
}
