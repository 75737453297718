import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';

import { AbstractService } from '../common/abstract-service';
import { Search } from '../common/search';
import { PrintTemplate } from '../model/print-template';
import { CATEGORIES_API_PATH, PRINT_PATH, PRINT_TEMPLATES_API_PATH } from '../constants/constants';
import { Observable, of } from 'rxjs';

import { MessageService, SelectItem } from 'primeng/api';
import { ConfigurationService } from './configuration.service';

@Injectable({
    providedIn: 'root'
})
export class PrintTemplateService extends AbstractService<PrintTemplate> {
    printpath: string;

    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        configurationService: ConfigurationService
    ) {
        super(configurationService.getValue(PRINT_TEMPLATES_API_PATH), httpClient, messageService);
        configurationService.getValue(PRINT_PATH).subscribe(result => (this.printpath = result));
    }

    getPdf(uuidTemplate: string): Observable<any> {
        return this.httpClient.get(this.printpath + '/printemplates/' + uuidTemplate, {
            responseType: 'blob'
        });
    }

    public getId(element: PrintTemplate) {
        return element.uuid;
    }

    public buildSearch() {
        this.search = new Search<PrintTemplate>(PrintTemplate);
    }

    getAllListAsItems(search?: Search<PrintTemplate>): Observable<SelectItem[]> {
        return this.getAllList(search).pipe(
            map(template => template.map(t => ({ label: t.name, value: t })))
        );
    }

    getAllUuidListAsItems(search?: Search<PrintTemplate>): Observable<SelectItem[]> {
        return this.getAllList(search).pipe(
            map(template => template.map(t => ({ label: t.name, value: t.uuid })))
        );
    }
}
