import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractService } from '../common/abstract-service';
import { Search } from '../common/search';
import { Catalogue } from '../model/catalogue';
import { ATTACHMENTS_API_PATH, CATALOGUES_API_PATH, PRINT_PATH } from '../constants/constants';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { ConfigurationService } from './configuration.service';

@Injectable({
    providedIn: 'root',
})
export class CatalogueService extends AbstractService<Catalogue> {
    printpath: string;

    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        configurationService: ConfigurationService
    ) {
        super(configurationService.getValue(CATALOGUES_API_PATH), httpClient, messageService);
        configurationService.getValue(PRINT_PATH).subscribe((result) => (this.printpath = result));
    }

    public getId(element: Catalogue) {
        return element.uuid;
    }

    public buildSearch() {
        this.search = new Search<Catalogue>(Catalogue);
    }

    getPdfSinglePrintable(uuidPrintable?: string): Observable<any> {
        // const optional = uuidPrintable ? `?printable_uuid=${uuidPrintable}` : '';
        const path = `${this.printpath}/printables/${uuidPrintable}`;

        return this.httpClient.get(path, {
            responseType: 'blob',
        });
    }

    getPdf(uuidPrintable?: string): Observable<any> {
        // const optional = uuidPrintable ? `?printable_uuid=${uuidPrintable}` : '';
        const path = `${this.printpath}/catalogues/${uuidPrintable}`;

        return this.httpClient.get(path, {
            responseType: 'blob',
        });
    }

    getPdfSingleGroup(uuidCatalogue: string, groupBy?: string, name?: string): Observable<any> {
        const path = `${this.printpath}/catalogues/${uuidCatalogue}?groupable_name=${groupBy}&groupable_value=${name}`;
        return this.httpClient.get(path, {
            responseType: 'blob',
        });
    }

    getCatalogueWithSinglePrintablePdf(
        uuidCatalogue?: string,
        uuidPrintable?: string
    ): Observable<any> {
        // const optional = uuidPrintable ? `?printable_uuid=${uuidPrintable}` : '';
        const path = `${this.printpath}/catalogues/${uuidCatalogue}?printable_uuid=${uuidPrintable}`;

        return this.httpClient.get(path, {
            responseType: 'blob',
        });
    }
}
