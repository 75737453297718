import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PrintTemplateService } from '../../service/print-template.service';

import { AbstractEditComponent } from '../../common/abstract-edit-component';
import {
    PrintTemplate,
    PrintTemplateFormat,
    printTemplateFormatAsItems,
    PrintTemplateType,
    printTemplateTypeAsItems,
} from '../../model/print-template';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import {
    generateExtra,
    addControlToFormGroup,
    manipulateProperties,
} from 'src/app/shared-functions/forms-util';
import { PropertyDefinition } from '../property/interfaces/property-definition-interface';
import { PropertyService } from 'src/app/service/property.service';
import { PropertyValue } from 'src/app/model/property-value';
import { TipologyService } from 'src/app/service/tipology.service';
import { FONT_AWESOME_ICONS } from '../../model/enum/icons';
import { BARCODE_TYPES } from '../../constants/barcode-types';
import { ListableService } from 'src/app/service/listable.service';
import { Observable } from 'rxjs';
import { propertyTypesTemplateItems } from '../../model/enum/property-type';
import { AttachmentService } from 'src/app/service/attachment.service';
import { RoutingStateService } from 'src/app/service/routing-state.service';

@Component({
    selector: 'app-print-template-edit',
    templateUrl: './print-template-edit.component.html',
    styleUrls: ['./print-template-edit.component.css'],
})
export class PrintTemplateEditComponent extends AbstractEditComponent<PrintTemplate>
    implements OnInit, PropertyDefinition {
    element: PrintTemplate;
    registerForm: FormGroup;
    iconsItems: SelectItem[] = FONT_AWESOME_ICONS;
    barcodeTypesItems: SelectItem[] = BARCODE_TYPES;
    printTemplateTypeAsItems: SelectItem[] = [...printTemplateTypeAsItems];
    printTemplateFormatAsItems: SelectItem[] = [...printTemplateFormatAsItems];
    propertyTypesTemplateItems: SelectItem[] = propertyTypesTemplateItems;
    listableNamesItems$: Observable<SelectItem[]> = this.listableService.listNamesAsItems();

    editorOptions = {
        language: 'html',
        minimap: {
            enabled: false,
        },
        automaticLayout: true,
    };
    cssEditorOptions = {
        language: 'css',
        minimap: {
            enabled: false,
        },
        automaticLayout: true,
    };

    archivedVal = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected tipologyService: TipologyService,
        protected printTemplateService: PrintTemplateService,
        private formBuilder: FormBuilder,
        private confirmationService: ConfirmationService,
        protected propertyService: PropertyService,
        protected listableService: ListableService,
        protected attachmentService: AttachmentService,
        protected routingStateService: RoutingStateService,
        messageService: MessageService
    ) {
        super(
            router,
            route,
            printTemplateService,
            routingStateService,
            'templates',
            messageService
        );
        this.element = new PrintTemplate();
    }

    ngOnInit() {
        super.ngOnInit();
        this.getSelectableItems();
        this.buildForm();
    }

    postFind() {
        if (this.router.url.includes('templates/clone')) {
            this.element.uuid = null;
            this.element.name = this.element.name ? this.element.name + ' CLONE' : null;
        }
        this.buildForm();
        this.displayPropertyListDefault();
        this.archivedVal = !this.element.active;
    }

    postCreate() {
        this.element.active = true;
    }

    getSelectableItems() {}

    private buildForm() {
        this.registerForm = this.formBuilder.group({
            name: [this.element.name, Validators.required],
            description: [this.element.description, Validators.required],
            printTemplateType: [this.element.printTemplateType, Validators.required],
            default_template: [this.element.default_template],
            // printTemplateFormat: [this.element.printTemplateFormat, Validators.required],
            properties: this.formBuilder.array([]),
            first_page: [this.element.first_page],
            last_page: [this.element.last_page],
            header: [this.element.header, Validators.required],
            footer: [this.element.footer, Validators.required],
            style: [this.element.style],
            with_number: [
                this.element.with_number === undefined ? false : this.element.with_number,
            ],
            page_content: [this.element.page_content, Validators.required],
            objectType: [this.element.objectType],
            active: [this.element.active],
        });

        if (this.element?.printTemplateType === 'CATALOGUE') {
            this.handleTypeCatalogue(this.element.printTemplateFormat);
        } else {
            this.handleTypePrintable();
        }

        switch (this.element?.printTemplateFormat) {
            case 'PDF':
                this.handleFormatPdf();
                break;
            case 'CSV':
                this.handleFormatCsv();
                break;
            case 'XLS':
                this.handleFormatXls();
                break;
            default:
                this.handleFormatPdf();
        }
    }

    public handleTypeCatalogue(value?: PrintTemplateFormat) {
        this.registerForm.addControl(
            'printTemplateFormat',
            new FormControl(value ?? null, Validators.required)
        );
    }

    public handleTypePrintable() {
        this.registerForm.removeControl('printTemplateFormat');
        this.handleFormatPdf();
    }

    public handleFormatCsv() {
        this.registerForm.removeControl('first_page');
        this.registerForm.removeControl('last_page');
        this.registerForm.removeControl('header');
        this.registerForm.removeControl('footer');
        this.registerForm.addControl('page_content', new FormControl(this.element.page_content));
        this.removeTemplateAttachmentProperty();
    }

    public handleFormatPdf() {
        this.registerForm.addControl('first_page', new FormControl(this.element.first_page));
        this.registerForm.addControl('last_page', new FormControl(this.element.last_page));
        this.registerForm.addControl(
            'header',
            new FormControl(this.element.header, Validators.required)
        );
        this.registerForm.addControl(
            'footer',
            new FormControl(this.element.footer, Validators.required)
        );
        this.registerForm.addControl('page_content', new FormControl(this.element.page_content));
        this.removeTemplateAttachmentProperty();
    }

    public handleFormatXls() {
        this.registerForm.removeControl('first_page');
        this.registerForm.removeControl('last_page');
        this.registerForm.removeControl('header');
        this.registerForm.removeControl('footer');
        this.registerForm.removeControl('page_content');
        this.addTemplateAttachmentProperty();
    }

    public handleTypeSelect({ value }: { value: PrintTemplateType }) {
        if (value === 'CATALOGUE') {
            this.handleTypeCatalogue();
        } else {
            this.handleTypePrintable();
        }
    }

    public handleFormatSelect({ value }: { value: PrintTemplateFormat }) {
        switch (value) {
            case 'PDF':
                this.handleFormatPdf();
                break;
            case 'CSV':
                this.handleFormatCsv();
                break;
            case 'XLS':
                this.handleFormatXls();
                break;
        }
    }

    private addTemplateAttachmentProperty() {
        const orderBy = this.propertyForms?.length + 1;
        const attachmentProperty = new PropertyValue();
        attachmentProperty.order_by = orderBy;
        attachmentProperty.property_type = 'ATTACH_VALUE';
        attachmentProperty.name = 'template';
        attachmentProperty.label = 'template';
        this.propertyService.addProperty(this.propertyForms, attachmentProperty, 'default_value');
    }

    private removeTemplateAttachmentProperty() {
        const templatePropIdx = this.propertyForms.controls.findIndex(
            (c) => c.value.name === 'template'
        );

        if (templatePropIdx > -1) {
            this.propertyForms.removeAt(templatePropIdx);
        }
    }

    private getValuesFromForm() {
        this.element.description = this.registerForm.get('description')?.value ?? null;
        this.element.name = this.registerForm.get('name')?.value ?? null;
        this.element.printTemplateType = this.registerForm.get('printTemplateType')?.value ?? null;
        this.element.default_template = this.registerForm.get('default_template')?.value ?? null;
        this.element.printTemplateFormat = this.printTemplateFormat?.value ?? null;
        this.element.properties = this.propertyForms?.value ?? null;
        this.element.first_page = this.registerForm.get('first_page')?.value ?? null;
        this.element.last_page = this.registerForm.get('last_page')?.value ?? null;
        this.element.style = this.registerForm.get('style')?.value ?? null;
        this.element.header = this.registerForm.get('header')?.value ?? null;
        this.element.footer = this.registerForm.get('footer')?.value ?? null;
        this.element.with_number = this.registerForm.get('with_number')?.value ?? null;
        this.element.page_content = this.registerForm.get('page_content')?.value ?? null;
        this.element.objectType = this.registerForm.get('objectType')?.value ?? null;
        this.element.active = this.registerForm.get('active')?.value;
        manipulateProperties(this.element, 'default_value', this.attachmentService);
    }

    displayPropertyListDefault() {
        this.propertyForms.clear();
        this.element?.properties?.map((p) => {
            this.propertyService.addProperty(this.propertyForms, p, 'default_value', 'default');
        });
    }

    addEmptyProperty() {
        const orderBy = this.propertyForms?.length + 1;
        const emptyProp = new PropertyValue();
        emptyProp.order_by = orderBy;
        this.propertyService.addProperty(this.propertyForms, emptyProp, 'default_value');
    }

    deleteProperty(i) {
        this.propertyForms.removeAt(i);
    }

    propertyTypeSelected({ event, indexInFormArray }) {
        const generatedExtra = generateExtra(
            this.formBuilder,
            this.propertyForms.at(indexInFormArray).get('property_type').value,
            null
        );
        if (generatedExtra) {
            const formGroup = this.propertyForms.at(indexInFormArray) as FormGroup;
            formGroup.removeControl('extra');
            addControlToFormGroup(formGroup, 'extra', generatedExtra);
        }

        this.propertyForms.at(indexInFormArray).patchValue({ default_value: null });
    }

    filterOutAttachmentProps() {
        this.element.properties = this.element.properties.filter(
            (p) =>
                !this.propertyService.isImageType(p, 'default_value') &&
                !this.propertyService.isAttachmentType(p, 'default_value')
        );
    }

    preSave() {
        this.getValuesFromForm();
        const haveError = this.propertiesErrorHandling();
        this.filterOutAttachmentProps();
        this.element.properties.sort((a, b) => a.order_by - b.order_by);
        return !haveError;
    }

    postSave() {
        const anyAttachment = this.propertyService.hasAnyAttachment(
            this.propertyForms,
            'default_value'
        );
        if (anyAttachment) {
            this.navigateAfterSave = () => {};
            this.complexUpdate();
        }
    }

    preUpdate() {
        this.getValuesFromForm();
        const haveError = this.propertiesErrorHandling();
        this.element.properties.sort((a, b) => a.order_by - b.order_by);
        return !haveError;
    }

    complexUpdate() {
        const promises = this.propertyService.checkAndUploadImagesAndAttachmnts(
            this.propertyForms,
            this.element,
            'default_value'
        );
        if (promises) {
            Promise.all(promises).then((_) => super.update());
        } else {
            super.update();
        }
    }

    propertiesErrorHandling() {
        const errorCheck = this.propertyService.havePropertiesErrors(this.element.properties);
        if (errorCheck.haveError) {
            this.addError(errorCheck.msg);
        }
        return errorCheck.haveError;
    }

    valueChangedFromChild({ value, index }) {
        this.propertyForms.at(index).patchValue({ default_value: value });
    }

    public new() {
        this.router.navigate(['/' + this.path + '/list']);
    }

    getId() {
        return this.element.uuid;
    }

    createInstance(): PrintTemplate {
        return new PrintTemplate();
    }

    confirmDelete() {
        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () => {
                this.delete();
            },
        });
    }

    get description() {
        return this.registerForm.get('description');
    }
    get printTemplateType() {
        return this.registerForm.get('printTemplateType');
    }
    get default_template() {
        return this.registerForm.get('default_template');
    }
    get printTemplateFormat() {
        return this.registerForm.get('printTemplateFormat');
    }
    get name() {
        return this.registerForm.get('name');
    }
    get first_page() {
        return this.registerForm.get('first_page');
    }
    get last_page() {
        return this.registerForm.get('last_page');
    }
    get style() {
        return this.registerForm.get('style');
    }
    get header() {
        return this.registerForm.get('header');
    }
    get footer() {
        return this.registerForm.get('footer');
    }
    get with_number() {
        return this.registerForm.get('with_number');
    }
    get page_content() {
        return this.registerForm.get('page_content');
    }
    get objectType() {
        return this.registerForm.get('objectType');
    }
    get propertyForms() {
        return this.registerForm.get('properties') as FormArray;
    }

    onChangeArchived(event) {
        this.archivedVal = event.checked;
        this.registerForm.patchValue({ active: !event.checked });
    }
}
