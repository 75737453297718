import { AbstractService } from './abstract-service';
import { Router, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/api';
import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { langIt } from '../constants/calendar-it';
import { RoutingStateService } from '../service/routing-state.service';
import { MessageService } from 'primeng/api';

export abstract class AbstractEditComponent<T> implements OnInit, OnDestroy {
    public msgs: Message[] = [];

    public editMode = false;
    public element: T = null;
    protected destroy$: Subject<void> = new Subject<void>();

    public langIt = { ...langIt };

    public noRedirectLoading = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        public service: AbstractService<T>,
        protected routingStateService: RoutingStateService,
        public path: string,
        private messageService: MessageService
    ) {}

    ngOnInit() {
        const id: string = this.route.snapshot.params.id;
        if (id) {
            this.editMode = true;
            this.service
                .find(id)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    (element) => {
                        this.element = element as T;
                        this.postFind();
                    },
                    (error) => {
                        this.addError(
                            'Errore nel caricamento dei dati.' + (error?.msg ?? error ?? '')
                        );
                    }
                );
        } else {
            this.editMode = false;
            this.element = this.createInstance();
            this.postCreate();
        }
    }

    postCreate() {}

    postFind() {}

    preSave(): boolean {
        return true;
    }

    preUpdate(): boolean {
        return true;
    }

    postSave() {}

    postUpdate() {}

    postDelete() {}

    save() {
        this.clearMsgs();
        this.editMode = false;
        if (!this.preSave()) {
            return;
        }
        this.service
            .persist(this.element)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (element) => {
                    this.addInfo('Salvataggio completato con successo. ');
                    this.element = element as T;
                    this.postSave();
                    this.navigateAfterSave();
                },
                (error) => {
                    this.addError(
                        'Impossibile completare il salvataggio. ' + (error?.msg ?? error ?? '')
                    );
                    this.saveError();
                }
            );
    }

    saveError() {}

    update() {
        // console.log(JSON.stringify(this.element));
        this.clearMsgs();
        this.editMode = false;
        if (!this.preUpdate()) {
            return;
        }
        this.service
            .update(this.element)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (element) => {
                    this.addInfo('Modifica completata con successo. ');
                    this.element = element as T;
                    this.postUpdate();
                    this.navigateAfterUpdate();
                },
                (error) => {
                    this.addError(
                        'Impossibile completare la modifica. ' + (error?.msg ?? error ?? '')
                    );
                    this.saveError();
                }
            );
    }

    updateWithoutRedirect() {
        this.clearMsgs();
        this.editMode = false;
        if (!this.preUpdate()) {
            return;
        }
        this.service
            .update(this.element)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (element) => {
                    this.addInfo('Modifica completata con successo. ');
                    this.element = element as T;
                    this.postUpdate();
                },
                (error) => {
                    this.addError(
                        'Impossibile completare la modifica. ' + (error?.msg ?? error ?? '')
                    );
                    this.saveError();
                }
            );
    }

    updateWithoutRedirectToast() {
        this.noRedirectLoading = true;
        this.clearMsgs();
        this.editMode = false;
        if (!this.preUpdate()) {
            return;
        }
        this.service
            .update(this.element)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (element) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Successo',
                        detail: 'Aggiornamento articolo completato con successo.',
                    });
                    this.element = element as T;
                    this.postUpdate();
                    this.noRedirectLoading = false;
                },
                () => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Errore',
                        detail: 'Aggiornamento non avvenuto. Riprovare.',
                    });
                    this.saveError();
                    this.noRedirectLoading = false;
                }
            );
    }

    delete() {
        this.clearMsgs();
        this.editMode = false;
        this.service
            .delete(this.getId())
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (element) => {
                    this.postDelete();
                    this.navigateAfterDelete();
                    this.addInfo('Eliminazione completata con successo. ');
                },
                (error) => {
                    this.addError(
                        `Impossibile completare l'eliminazione. ` + (error?.msg ?? error ?? '')
                    );
                }
            );
    }

    goToList() {
        this.clearMsgs();
        const lastRoute = this.routingStateService.getPreviousUrl();
        if (lastRoute !== '/homepage') {
            this.router.navigate([`${lastRoute}`]);
        } else {
            this.navigateToList();
        }
    }

    public isEditMode(): boolean {
        return this.editMode;
    }

    public clearMsgs() {
        this.msgs = [];
    }

    public addInfo(message: string) {
        this.msgs.push({
            severity: 'info',
            summary: 'Informazioni: ',
            detail: message,
        });
    }

    public addWarn(message: string) {
        this.msgs.push({
            severity: 'warn',
            summary: 'Attenzione: ',
            detail: message,
        });
    }

    public addError(message: string) {
        this.msgs.push({
            severity: 'error',
            summary: 'Errore: ',
            detail: message,
        });
        this.service.addMessage({
            severity: 'error',
            summary: 'Errore',
            detail: message,
        });
    }

    abstract createInstance(): T;

    abstract getId();

    navigateAfterDelete() {
        this.router.navigate(['/' + this.path + '/list']);
    }

    navigateAfterUpdate() {
        this.router.navigate(['/' + this.path + '/view', this.getId()]);
    }

    navigateAfterSave() {
        this.router.navigate(['/' + this.path + '/view', this.getId()]);
    }

    navigateToList() {
        this.router.navigate(['/' + this.path + '/list']);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
