<div class="advanced-filter-wrapper">
  <div class="row">
    <div class="clearfix"></div>
    <div class="col-sm-12">
      <button
        pButton
        type="button"
        (click)="isPropertyFilterPanelOpen = !isPropertyFilterPanelOpen"
        class="btn filter-advanced"
      >
        <i class="fa fa-plus"></i>
        Filtri Avanzati
      </button>

      <hr />
    </div>
  </div>
  <div class="clearfix"></div>

  <ng-container *ngIf="isPropertyFilterPanelOpen">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group cleafix">
          <p-dropdown
            [options]="tipologyItems"
            [ngModel]="selectedTipogy"
            (onChange)="tipologyFilterSelected($event)"
            id="tipology_uuid"
            placeholder="Tipologia"
            [style]="{ width: '100%' }"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <form [formGroup]="form">
      <ng-container formArrayName="propertiesToFilter">
        <ng-container
          *ngFor="let control of propertiesToFilter.controls; let i = index"
          [formGroupName]="i"
        >
          <div class="row">
            <ng-container *ngIf="tipologyPropertiesItems">
              <div class="col-sm-5">
                <div class="form-group cleafix">
                  <p-dropdown
                    [options]="tipologyPropertiesItems"
                    [ngModel]="control.value.property"
                    [ngModelOptions]="{ standalone: true }"
                    (onChange)="tipologyPropertySelected($event, i)"
                    id="propertyName"
                    placeholder="Proprietà"
                    [style]="{ width: '100%' }"
                  ></p-dropdown>
                </div>
              </div>
            </ng-container>

            <div class="col-sm-6">
              <div class="form-group cleafix">
                <ng-container [ngSwitch]="control.value.property.property_type">
                  <ng-container *ngSwitchCase="'LISTABLE'">
                    <div
                      *ngIf="
                        control.value.property.extra.listableValuesUrl | async as listableValues
                      "
                    >
                      <p-dropdown
                        [options]="listableValues"
                        placeholder="Scegli Valore..."
                        formControlName="value1"
                        id="listable"
                        [style]="{ width: '100%' }"
                      ></p-dropdown>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'NUMBER_VALUE'">
                    <div class="row">
                      <div class="col-sm-6">
                        <input
                          pInputText
                          type="number"
                          name="propertyValue"
                          placeholder="numero"
                          class="form-control"
                          formControlName="value1"
                        />
                      </div>
                      <div class="col-sm-6">
                        <input
                          pInputText
                          type="number"
                          class="form-control"
                          name="propertyValue"
                          placeholder="numero"
                          formControlName="value2"
                        />
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'DATE_VALUE'">
                    <div class="row">
                      <div class="col-sm-6">
                        <p-calendar
                          [locale]="locale"
                          [dateFormat]="'dd/mm/yy'"
                          showButtonBar="true"
                          clearButtonStyleClass="btn ui-button-text-only"
                          todayButtonStyleClass="btn ui-button-text-only"
                          inputStyleClass="form-control"
                          [style]="{ width: '100%' }"
                          formControlName="value1"
                        ></p-calendar>
                      </div>
                      <div class="col-sm-6">
                        <p-calendar
                          [locale]="locale"
                          [dateFormat]="'dd/mm/yy'"
                          showButtonBar="true"
                          clearButtonStyleClass="btn ui-button-text-only"
                          todayButtonStyleClass="btn ui-button-text-only"
                          inputStyleClass="form-control"
                          [style]="{ width: '100%' }"
                          formControlName="value2"
                        ></p-calendar>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <input
                      class="form-control"
                      pInputText
                      type="propertyValue"
                      name="propertyValue"
                      placeholder="Valore Proprietà"
                      formControlName="value1"
                    />
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <div class="col-sm-1">
              <button
                *ngIf="false && propertiesToFilter?.length > 0"
                pButton
                type="button"
                (click)="addPropertyToFilter()"
                class="btn btn-add btn-md btn-block"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </ng-container>
</div>
