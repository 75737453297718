import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PrintableService } from '../../service/printable.service';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';
import { AttachmentService } from '../../service/attachment.service';

import { AbstractViewComponent } from '../../common/abstract-view-component';
import { Printable } from '../../model/printable';
import { Category } from 'src/app/model/category';
import { Tipology } from 'src/app/model/tipology';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { propertyTypesPrintableItems } from 'src/app/model/enum/property-type';

export abstract class PrintableViewComponent extends AbstractViewComponent<Printable>
    implements OnInit {
    currentCategory: Category = new Category();
    currentTipology: Tipology = new Tipology();
    attachments: Map<string, Observable<any>> = new Map<string, Observable<any>>();
    propertyTypesMap: Map<string, string> = new Map<string, string>();
    openAllTabs = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        public path: string,
        protected tipologyService: TipologyService,
        protected categoryService: CategoryService,
        protected printableService: PrintableService,
        protected attachmentService: AttachmentService
    ) {
        super(router, route, printableService, path);
        this.element = new Printable();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    postFind() {
        propertyTypesPrintableItems.map((t) => this.propertyTypesMap.set(t.value, t.label));
        this.tipologyService
            .find(this.element.tipology_uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((tipology) => (this.currentTipology = tipology));
        this.categoryService
            .find(this.element.category_uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((category) => (this.currentCategory = category));
    }

    print(uuidTemplate) {
        this.printableService
            .getPdf(this.element.uuid, uuidTemplate)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (response) => {
                    const filenameDownload = this.element.uuid + '.pdf';
                    const newBlob = new Blob([response], { type: 'application/pdf' });
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob, filenameDownload);
                    } else {
                        const a = document.createElement('a');
                        a.href = URL.createObjectURL(newBlob);
                        a.setAttribute('download', filenameDownload);
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                },
                (err) => {
                    this.service.addMessage({
                        severity: 'error',
                        summary: 'Errore',
                        detail: err.message,
                    });
                }
            );
    }

    getId() {
        return this.element.uuid;
    }
}
