import { Directive, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PrintableService } from '../../service/printable.service';
import { AttachmentService } from '../../service/attachment.service';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';
import { ListableService } from '../../service/listable.service';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormArray,
    AbstractControl,
    FormControl,
} from '@angular/forms';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';

import { AbstractEditComponent } from '../../common/abstract-edit-component';
import { Printable } from '../../model/printable';
import { Tipology } from 'src/app/model/tipology';
import { statesAsArray } from '../../model/enum/state';
import { ConfigurationService } from '../../service/configuration.service';

import { ATTACHMENTS_API_PATH } from '../../constants/constants';
import { FONT_AWESOME_ICONS } from '../../model/enum/icons';
import { manipulateProperties } from '../../shared-functions/forms-util';
import { PropertyValue } from 'src/app/model/property-value';
import { PropertyService } from '../../service/property.service';
import { takeUntil } from 'rxjs/operators';
import { PropertyValorization } from '../../components/property/interfaces/property-valorization-interface';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { ContainerService } from 'src/app/service/container.service';
@Directive()
export abstract class PrintableEditComponent extends AbstractEditComponent<Printable>
    implements OnInit, PropertyValorization {
    element: Printable;
    registerForm: FormGroup;
    tipologyItems: SelectItem[];
    stateItems: SelectItem[] = [];
    codiciGaraItems: SelectItem[] = [];
    iconsItems: SelectItem[] = FONT_AWESOME_ICONS;
    tipologies: Map<string, Tipology> = new Map<string, Tipology>();
    categoryItems: SelectItem[];
    persistedSelectedTipology: string;
    loading: boolean;
    attachmentUrl: string;
    query: string;
    myGroup: any;
    printableFields: Map<string, string>;
    containerUuidFromRoute: string = null;

    archivedVal = false;

    updateLoading: boolean = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        public path: string,
        protected tipologyService: TipologyService,
        protected formBuilder: FormBuilder,
        protected printableService: PrintableService,
        protected confirmationService: ConfirmationService,
        protected configurationService: ConfigurationService,
        protected attachmentService: AttachmentService,
        protected categoryService: CategoryService,
        protected listableService: ListableService,
        protected propertyService: PropertyService,
        protected routingStateService: RoutingStateService,
        protected containerService: ContainerService,
        messageService: MessageService
    ) {
        super(router, route, printableService, routingStateService, path, messageService);
        this.element = new Printable();
        configurationService
            .getValue(ATTACHMENTS_API_PATH)
            .pipe(takeUntil(this.destroy$))
            .subscribe((atturl) => (this.attachmentUrl = atturl));
        this.printableFields = new Map<string, string>();
        this.myGroup = new FormGroup({
            query: new FormControl(),
        });
        if (this.route.snapshot.paramMap.has('containerUuid')) {
            this.containerUuidFromRoute = this.route.snapshot.paramMap.get('containerUuid');
        }
        if (this.route.snapshot.queryParamMap.has('containerUuid')) {
            this.containerUuidFromRoute = this.route.snapshot.queryParamMap.get('containerUuid');
        }
    }

    ngOnInit() {
        super.ngOnInit();
        this.loading = true;
        this.buildForm();

        if (this.router.url.includes('articles/new') || this.router.url.includes('pages/new')) {
            this.postFind();
        }
    }

    postFind() {
        this.tipologyService
            .getAllList()
            .pipe(takeUntil(this.destroy$))
            .subscribe((tipology) => {
                this.tipologyItems = [];
                tipology.map((t) => {
                    this.tipologies.set(t.uuid, t);
                    this.tipologyItems.push({ label: t.name, value: t });
                });
                this.buildForm();
                if (this.element.tipology_uuid) {
                    this.propertyForms.clear();
                    this.PVAL_displayPropertyListEditMode(this.element.properties);
                }
                this.loading = false;
            });

        this.stateItems = statesAsArray.map((s) => ({ label: s, value: s }));

        this.containerService
            .getAllCodicigaraAsItems()
            .pipe(takeUntil(this.destroy$))
            .subscribe((i) => (this.codiciGaraItems = i));

        this.categoryService
            .getAllList()
            .pipe(takeUntil(this.destroy$))
            .subscribe((category) => {
                this.categoryItems = [];
                category.map((c) => {
                    this.categoryItems.push({ label: c.name, value: c.uuid });
                });
            });

        this.persistedSelectedTipology = this.element.tipology_uuid;

        if (this.router.url.includes('articles/clone') || this.router.url.includes('pages/clone')) {
            this.element.uuid = null;
            this.element.codiceGara = this.containerUuidFromRoute;
            this.element.name = this.element.name ? this.element.name + ' CLONE' : null;
        }
        this.setPrintableFields();
        this.archivedVal = !this.element.active;
    }

    postCreate() {
        this.element.active = true;
    }

    /** for the menu on the right */
    setPrintableFields() {
        this.printableFields = new Map<string, string>();
        this.printableFields.set('internal_code', 'Cod. Art. Interno'.toLowerCase());
        this.printableFields.set('name', 'Cod. Articolo'.toLowerCase());
        this.printableFields.set('description', 'Descrizione Articolo'.toLowerCase());
        this.printableFields.set('codicegara', 'CodiceGara'.toLowerCase());
        this.printableFields.set('tags', 'Tags'.toLowerCase());
        this.printableFields.set('tipology', 'Tipologia'.toLowerCase());
        this.printableFields.set('archived', 'Archiviato'.toLowerCase());
        this.printableFields.set('state', 'Stato'.toLowerCase());

        const propsFromForm: PropertyValue[] = this.propertyForms?.getRawValue();
        propsFromForm?.map((property) => {
            this.printableFields.set(
                property.name.replace(/\s/g, '').toLowerCase(),
                property.label.toLowerCase()
            );
        });

        this.printableFields = new Map([...this.printableFields.entries()]);
    }

    buildForm() {
        this.registerForm = this.formBuilder.group({
            internal_code: [this.element.internal_code, Validators.required],
            name: [this.element.name],
            description: [this.element.description],
            codiceGara: [this.element.codiceGara],
            properties: this.formBuilder.array([]),
            tags: [this.element.tags ? this.element.tags.split(';') : []],
            tipology: [this.tipologies.get(this.element.tipology_uuid), Validators.required],
            state: [this.element.state],
            active: [this.element.active],
        });
    }

    getValuesFromForm() {
        this.element.internal_code = this.internal_code.value;
        this.element.name = this.name.value;
        this.element.description = this.description.value;
        this.element.codiceGara = this.codiceGara.value;
        this.element.properties = this.propertyForms.value;
        this.element.tags = this.tags.value.length > 0 ? this.tags.value.join(';') : '';
        this.element.tipology_uuid = this.tipology.value.uuid;
        this.element.category_uuid = this.tipology.value.category_uuid;
        this.element.state = this.state.value;
        if (this.registerForm.get('active') !== null)
            this.element.active = this.registerForm.get('active')?.value;
        manipulateProperties(this.element, 'value', this.attachmentService);
    }

    PVAL_displayProperties(event) {
        if (event.value.uuid === this.persistedSelectedTipology) {
            this.refreshPropertyList();
        } else {
            this.propertyForms.clear();
            this.PVAL_displayPropertyListDefault(event);
        }
        this.setPrintableFields();
    }

    confirmRefresh() {
        this.confirmationService.confirm({
            message: `Sei sicuro di voler aggiungere eventuali nuove proprietà della tipologia all'interno dell'articolo ?`,
            accept: () => {
                this.refreshPropertyList();
            },
        });
    }

    refreshPropertyList() {
        if (!this.element.properties) {
            return;
        }
        this.propertyForms.clear();
        const addedPropsNames: string[] = [];
        const removedPropsNames: string[] = [];

        // this code deletes zombie props
        this.element.properties = this.element.properties.filter((thisProperty) => {
            const isPropertyStillAlive = this.tipologies
                .get(this.element.tipology_uuid)
                .properties.some((tipologyProperty) => thisProperty.name === tipologyProperty.name);

            if (!isPropertyStillAlive) {
                removedPropsNames.push(thisProperty.name);
            }
            return isPropertyStillAlive;
        });

        this.PVAL_displayPropertyListEditMode(this.element.properties);

        this.tipologies.get(this.element.tipology_uuid).properties.map((tipologyProperty) => {
            const isPropAlreadyIn = this.element.properties.some(
                (thisProperty) => thisProperty.name === tipologyProperty.name
            );
            if (!isPropAlreadyIn) {
                this.propertyService.addProperty(
                    this.propertyForms,
                    tipologyProperty,
                    'value',
                    'default'
                );
                addedPropsNames.push(tipologyProperty.name);
            } else {
                const newProperty = tipologyProperty;
                const currentProperty = this.element.properties.find(
                    (p) => p.name === newProperty.name
                );
                this.changePropertiesFields(newProperty, currentProperty);
            }
        });
        const myArray: Array<AbstractControl> = this.propertyForms.controls;
        myArray.sort((a, b) => a.value.order_by - b.value.order_by);
        myArray.map((control, idx) => {
            this.propertyForms.removeAt(idx);
            this.propertyForms.insert(idx, control);
        });

        let detail = '';
        if (addedPropsNames.length > 0) {
            detail += 'Campi aggiunti: ';
            addedPropsNames.map((ap) => (detail += ` ${ap}`));
        }
        if (removedPropsNames.length > 0) {
            detail += ' Campi rimossi: ';
            removedPropsNames.map((rp) => (detail += ` ${rp}`));
        }
        this.service.addMessage({
            severity: 'success',
            summary: 'Proprietà aggiornate',
            life: 10000,
            detail,
        });
    }

    private changePropertiesFields(newProperty: PropertyValue, currentProperty: PropertyValue) {
        const diffFields = Object.keys(newProperty).filter(
            (k) => newProperty[k] !== currentProperty[k]
        );
        diffFields.map((df) => {
            switch (df) {
                case 'default_value': {
                    currentProperty.default_value = newProperty.default_value;
                    const formArrayIdx = this.propertyForms.controls.findIndex(
                        (c) => c.value.name === currentProperty.name
                    );
                    this.propertyForms.removeAt(formArrayIdx);
                    this.propertyService.addProperty(
                        this.propertyForms,
                        currentProperty,
                        'value',
                        'edit'
                    );
                    break;
                }
                case 'order_by': {
                    currentProperty.order_by = newProperty.order_by;
                    const formArrayIdx = this.propertyForms.controls.findIndex(
                        (c) => c.value.name === currentProperty.name
                    );
                    this.propertyForms.removeAt(formArrayIdx);
                    this.propertyService.addProperty(
                        this.propertyForms,
                        currentProperty,
                        'value',
                        'edit'
                    );
                    break;
                }
                case 'colspan': {
                    currentProperty.colspan = newProperty.colspan;
                    const formArrayIdx = this.propertyForms.controls.findIndex(
                        (c) => c.value.name === currentProperty.name
                    );
                    this.propertyForms.removeAt(formArrayIdx);
                    this.propertyService.addProperty(
                        this.propertyForms,
                        currentProperty,
                        'value',
                        'edit'
                    );
                    break;
                }
                case 'label': {
                    currentProperty.label = newProperty.label;
                    const formArrayIdx = this.propertyForms.controls.findIndex(
                        (c) => c.value.name === currentProperty.name
                    );
                    this.propertyForms.removeAt(formArrayIdx);
                    this.propertyService.addProperty(
                        this.propertyForms,
                        currentProperty,
                        'value',
                        'edit'
                    );
                    break;
                }
                case 'extra': {
                    currentProperty.extra = newProperty.extra;
                    const formArrayIdx = this.propertyForms.controls.findIndex(
                        (c) => c.value.name === currentProperty.name
                    );
                    this.propertyForms.removeAt(formArrayIdx);
                    this.propertyService.addProperty(
                        this.propertyForms,
                        currentProperty,
                        'value',
                        'edit'
                    );
                    break;
                }
                case 'mandatory': {
                    currentProperty.mandatory = newProperty.mandatory;
                    const formArrayIdx = this.propertyForms.controls.findIndex(
                        (c) => c.value.name === currentProperty.name
                    );
                    this.propertyForms.removeAt(formArrayIdx);
                    this.propertyService.addProperty(
                        this.propertyForms,
                        currentProperty,
                        'value',
                        'edit'
                    );
                    break;
                }
                case 'property_type': {
                    currentProperty = newProperty;
                    const formArrayIdx = this.propertyForms.controls.findIndex(
                        (c) => c.value.name === currentProperty.name
                    );
                    this.propertyForms.removeAt(formArrayIdx);
                    this.propertyService.addProperty(
                        this.propertyForms,
                        newProperty,
                        'value',
                        'default'
                    );
                    break;
                }
                default:
                    break;
            }
            this.propertyForms.updateValueAndValidity();
        });
    }

    PVAL_displayPropertyListDefault(event) {
        event.value.properties.map((p) => {
            this.propertyService.addProperty(this.propertyForms, p, 'value', 'default');
        });
    }

    PVAL_displayPropertyListEditMode(properties) {
        properties.map((p) => {
            this.propertyService.addProperty(this.propertyForms, p, 'value', 'edit');
        });
        this.setPrintableFields();
    }

    filterOutAttachmentProps() {
        this.element.properties = this.element.properties.filter(
            (p) =>
                !this.propertyService.isImageType(p, 'value') &&
                !this.propertyService.isAttachmentType(p, 'value')
        );
    }

    preSave() {
        this.loading = true;
        this.getValuesFromForm();
        this.filterOutAttachmentProps();
        return true;
    }

    postSave() {
        this.loading = false;
        const anyAttachment = this.propertyService.hasAnyAttachment(this.propertyForms, 'value');
        if (anyAttachment) {
            this.navigateAfterSave = () => {};
            this.complexUpdate();
        }
    }

    preUpdate() {
        this.getValuesFromForm();
        return true;
    }

    complexUpdate() {
        this.loading = true;

        const promises = this.propertyService.checkAndUploadImagesAndAttachmnts(
            this.propertyForms,
            this.element,
            'value'
        );
        if (promises) {
            Promise.all(promises).then((_) => {
                this.loading = false;
                super.update();
            });
        } else {
            this.loading = false;
            super.update();
        }
    }

    complexUpdateWithoutRedirect() {
        this.loading = true;

        const promises = this.propertyService.checkAndUploadImagesAndAttachmnts(
            this.propertyForms,
            this.element,
            'value'
        );
        if (promises) {
            Promise.all(promises).then((_) => {
                super.updateWithoutRedirect();
                // this.loading = false;
            });
        } else {
            // this.loading = false;
            super.updateWithoutRedirect();
        }
        return;
    }

    complexUpdateWithoutRedirectToast() {
        const promises = this.propertyService.checkAndUploadImagesAndAttachmnts(
            this.propertyForms,
            this.element,
            'value'
        );
        if (promises) {
            Promise.all(promises)
                .then((_) => {
                    super.updateWithoutRedirectToast();
                    // this.loading = false;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        } else {
            // this.loading = false;
            super.updateWithoutRedirectToast();
            this.loading = false;
        }
        return;
    }

    saveError() {
        this.loading = false;
    }

    getId() {
        return this.element.uuid;
    }

    confirmDelete() {
        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () => {
                this.delete();
            },
        });
    }

    get internal_code() {
        return this.registerForm.get('internal_code');
    }

    get name() {
        return this.registerForm.get('name');
    }

    get description() {
        return this.registerForm.get('description');
    }

    get codiceGara() {
        return this.registerForm.get('codiceGara');
    }

    get tags() {
        return this.registerForm.get('tags');
    }

    get tipology() {
        return this.registerForm.get('tipology');
    }

    get state() {
        return this.registerForm.get('state');
    }

    get propertyForms() {
        return this.registerForm.get('properties') as FormArray;
    }

    onChangeArchived(event) {
        this.archivedVal = event.checked;
        this.registerForm.patchValue({ active: !event.checked });
    }
}
