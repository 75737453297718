import { Component } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../service/page.service';
import { AttachmentService } from '../../service/attachment.service';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';
import { FormBuilder } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';

import { PrintableEditComponent } from '../printable/printable-edit.component';
import { Printable } from '../../model/printable';
import { ConfigurationService } from '../../service/configuration.service';
import { ListableService } from 'src/app/service/listable.service';
import { PropertyService } from 'src/app/service/property.service';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { ContainerService } from 'src/app/service/container.service';

@Component({
    selector: 'app-page-edit',
    templateUrl: './page-edit.component.html',
    styleUrls: ['./page-edit.component.css'],
})
export class PageEditComponent extends PrintableEditComponent {
    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected tipologyService: TipologyService,
        protected formBuilder: FormBuilder,
        protected pageService: PageService,
        protected confirmationService: ConfirmationService,
        protected configurationService: ConfigurationService,
        protected attachmentService: AttachmentService,
        protected categoryService: CategoryService,
        protected listableService: ListableService,
        protected propertyService: PropertyService,
        protected routingStateService: RoutingStateService,
        protected containerService: ContainerService,
        messageService: MessageService
    ) {
        super(
            router,
            route,
            'pages',
            tipologyService,
            formBuilder,
            pageService,
            confirmationService,
            configurationService,
            attachmentService,
            categoryService,
            listableService,
            propertyService,
            routingStateService,
            containerService,
            messageService
        );
    }

    createInstance(): Printable {
        const printable: Printable = new Printable();
        printable.objectType = 'PAGE';
        return printable;
    }
}
