import { Component, Injector, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { takeUntil } from 'rxjs/operators';
import { Search } from 'src/app/common/search';
import { MAX_PAGE_SIZE } from 'src/app/constants/constants';
import { statesAsArray } from 'src/app/model/enum/state';
import { Printable } from 'src/app/model/printable';
import { Tipology } from 'src/app/model/tipology';
import { CategoryService } from 'src/app/service/category.service';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { TipologyService } from 'src/app/service/tipology.service';
import { AbstractEditComponent } from '../../common/abstract-edit-component';
import { Container } from '../../model/container';
import { objectTypesAsArray } from '../../model/enum/object-type';
import { ContainerService } from '../../service/container.service';
import { PrintableService } from '../../service/printable.service';
import { TableUtilsService } from '../../service/table-utils.service';
import { ArticleListFiltersComponent } from '../filters/article-list-filters/article-list-filters.component';

@Component({
    selector: 'app-container-add-printables',
    templateUrl: './container-add-printables.component.html',
    providers: [PrintableService],
})
export class ContainerAddPrintablesComponent extends AbstractEditComponent<Container>
    implements OnInit, OnDestroy {
    @ViewChild('articleFilters', { static: false })
    articleFiltersCmp: ArticleListFiltersComponent;
    @ViewChild('dt') datatable: Table;

    element: Container;
    registerForm: FormGroup;
    objectTypeItems: SelectItem[] = [];
    selectedPrintables: Map<string, boolean> = new Map<string, boolean>();
    printablesTranscode: Map<string, string> = new Map<string, string>();
    tipologies: Map<string, Tipology> = new Map<string, Tipology>();
    categoryItems: SelectItem[];
    tipologyItems: SelectItem[];
    stateItems: SelectItem[] = [];
    status = false;
    buttonName = 'allarga';
    public tableUtilsService;

    isPreviewVisible: boolean = false;
    previewObj: any = {};

    classToggled = false;

    public toggleField() {
        this.classToggled = !this.classToggled;
    }

    constructor(
        public router: Router,
        protected route: ActivatedRoute,
        protected containerService: ContainerService,
        private formBuilder: FormBuilder,
        public printableService: PrintableService,
        private confirmationService: ConfirmationService,
        public injector: Injector,
        public tipologyService: TipologyService,
        protected routingStateService: RoutingStateService,
        protected categoryService: CategoryService,
        messageService: MessageService
    ) {
        super(router, route, containerService, routingStateService, 'gare', messageService);
        this.element = new Container();
        objectTypesAsArray.map((s) => {
            this.objectTypeItems.push({ label: s, value: s });
        });
    }

    ngOnInit() {
        this.tableInit();
        super.ngOnInit();
        this.tableUtilsService.loadingTable = true;
        this.getTipologies();
        this.getCategories();
        this.getStates();
        this.buildForm();
        this.tableUtilsService.dataService.loadSavedFilters();
        this.printableService.getTemplate();

        const savedStatus = localStorage.getItem('savedAddStatus');
        console.log('savedstatus', savedStatus);
        if (savedStatus !== null) {
            this.status = JSON.parse(savedStatus);
        }
    }

    clickEvent() {
        this.status = !this.status;

        localStorage.setItem('savedAddStatus', JSON.stringify(this.status));
    }

    getStates() {
        statesAsArray.map((s) => {
            this.stateItems.push({ label: s, value: s });
        });
    }

    getCategories() {
        this.categoryService
            .getAllList()
            .pipe(takeUntil(this.destroy$))
            .subscribe((category) => {
                this.categoryItems = [];
                category.map((c) => {
                    this.categoryItems.push({ label: c.name, value: c.uuid });
                });
            });
    }

    getTipologies() {
        this.tipologyService
            .getAllList()
            .pipe(takeUntil(this.destroy$))
            .subscribe((tipology) => {
                this.tipologyItems = [];
                tipology.map((t) => {
                    this.tipologies.set(t.uuid, t);
                    this.tipologyItems.push({ label: t.name, value: t.uuid });
                });
            });
    }

    postFind() {
        this.buildForm();
        const search: Search<Printable> = new Search(Printable);
        search.pageSize = MAX_PAGE_SIZE;
        this.containerService
            .getPrintables(this.element.codiceGara, search)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ({ printables, listSize }) => {
                    printables.map((e) => this.addcontainerElement(e));
                    this.datatable.reset();

                    const reqCount = Math.round(listSize / MAX_PAGE_SIZE);
                    for (let i = 1; i <= reqCount; i++) {
                        search.pageSize = MAX_PAGE_SIZE;
                        search.startRow = search.startRow + MAX_PAGE_SIZE;
                        this.containerService
                            .getPrintables(this.element.codiceGara, search)
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(
                                ({ printables, listSize }) => {
                                    printables.map((e) => this.addcontainerElement(e));
                                },
                                (error) => this.addError(error?.msg ?? error ?? '')
                            );
                    }
                },
                (error) => this.addError(error?.msg ?? error ?? '')
            );
    }

    cerca(table: Table) {
        this.tableUtilsService.dataService.checkAndRemoveZombieJsonTypes();
        this.tableUtilsService.dataService.removeEmptyPropertyFilters(
            this.articleFiltersCmp.propertyFilter.form
        );
        this.tableUtilsService.dataService.applyPropertyFilters(
            this.articleFiltersCmp.propertyFilter.form
        );
        this.tableUtilsService.reload(table);
    }

    nextStep() {
        this.router.navigate([`/${this.path}/${this.element.codiceGara}/aggiungi-cataloghi`]);
    }

    tableInit() {
        this.tableUtilsService = new TableUtilsService(this.injector, PrintableService);
        this.tableUtilsService.dataService.search.obj.objectType = 'ARTICLE';
        this.tableUtilsService.postLoadData = () => {};
    }

    private buildForm() {
        this.registerForm = this.formBuilder.group({
            containerElements: this.formBuilder.array([], Validators.required),
        });
    }

    get containerElementForms() {
        return this.registerForm.get('containerElements') as FormArray;
    }

    addcontainerElement(containerElement: Printable) {
        this.selectedPrintables.set(containerElement.uuid, true);
        const element = this.formBuilder.group(containerElement);

        this.containerElementForms.push(element);
        this.sortContainerElements();

        this.getTranscodes(containerElement);
    }

    private sortContainerElements() {
        const myArray: Array<AbstractControl> = this.containerElementForms.controls;
        myArray?.sort((a, b) => {
            if (a.value.name < b.value.name) {
                return -1;
            }
            if (a.value.name > b.value.name) {
                return 1;
            }
            return 0;
        });
        myArray?.map((control, idx) => {
            this.containerElementForms.removeAt(idx);
            this.containerElementForms.insert(idx, control);
        });
    }

    deletecontainerElement(i, uuid) {
        this.containerElementForms.removeAt(i);
        this.containerService
            .deletePrintable(this.element.codiceGara, uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (_) => {},
                (error) => this.addError(error?.msg ?? error ?? '')
            );
        this.selectedPrintables.set(uuid, false);
    }

    confirmDelete(i, uuid) {
        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () => this.deletecontainerElement(i, uuid),
        });
    }

    addNewContainerElement(printable: Printable) {
        let containerElement = new Printable();
        containerElement = {
            ...printable,
        };

        this.containerService
            .addPrintable(this.element.codiceGara, containerElement)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (_) => {},
                (error) => this.addError(error?.msg ?? error ?? '')
            );

        this.addcontainerElement(containerElement);
    }

    private getValuesFromForm() {
        this.element.printables = this.registerForm.get('containerElements').value;
    }

    getTranscodes(catalogueElement: Printable) {
        if (!this.printablesTranscode.get(catalogueElement.uuid)) {
            this.printableService
                .find(catalogueElement.uuid)
                .pipe(takeUntil(this.destroy$))
                .subscribe((p) => this.printablesTranscode.set(p.uuid, p.name));
        }
    }

    preSave() {
        this.getValuesFromForm();
        return true;
    }

    preUpdate() {
        this.getValuesFromForm();

        return true;
    }

    getId() {
        return this.element.codiceGara;
    }

    public view() {
        this.router.navigate(['/' + this.path + '/view', this.getId()]);
    }

    createInstance(): Container {
        return new Container();
    }

    get containerElements() {
        return this.registerForm.get('containerElements');
    }

    public newArticle() {
        this.router.navigate([
            'articles/new',
            { containerUuid: this.element.codiceGara, returnBack: true },
        ]);
    }

    viewArticle(articleUuid) {
        this.router.navigate([`articles/view/${articleUuid}`, { returnBack: true }]);
    }

    editArticle(articleUuid, metadata: any) {
        this.router.navigate([`articles/edit/${articleUuid}`, { returnBack: true }], {
            queryParams: metadata,
        });
    }

    cloneArticle(article: Printable) {
        const uuid = article.uuid;
        this.printableService
            .clonePrintable(article, this.element.codiceGara)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (newArticle) => {
                    this.addNewContainerElement(newArticle);
                    this.tableUtilsService.loaddata();
                },
                (error) => {
                    this.router.navigate(['/articles/clone/' + uuid], {
                        queryParams: { containerUuid: article.codiceGara },
                    });
                }
            );
    }

    addCatalogues() {
        this.router.navigate([`/${this.path}/${this.element.codiceGara}/aggiungi-cataloghi`]);
    }

    printArticle(articleUuid, templateUuid) {
        this.printableService
            .getPdf(articleUuid, templateUuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (response) => {
                    const newBlob = new Blob([response], {
                        type: 'application/pdf',
                    });
                    const fileUrl = window.URL.createObjectURL(newBlob);
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob, fileUrl.split(':')[1] + '.pdf');
                    } else {
                        window.open(fileUrl);
                    }
                },
                (err) => {
                    this.service.addMessage({
                        severity: 'error',
                        summary: 'Errore',
                        detail: err.message,
                    });
                }
            );
    }

    clone(printable) {
        this.router.navigate(['/articles/clone/' + printable.uuid], {
            queryParams: { containerUuid: this.element.codiceGara },
        });
    }

    ngOnDestroy() {
        this.tableUtilsService.dataService.saveFilters();
    }

    openPreviewModal(articleUuid: string, title: string) {
        if (!this.printableService.printTemplate)
            return this.addError('Template di default di tipo Articolo non presente');

        this.printableService
            .getPdf(articleUuid, this.printableService.printTemplate)
            .subscribe((pdf: Blob) => {
                this.previewObj = {
                    title,
                    pdf: window.URL.createObjectURL(pdf),
                };
                this.isPreviewVisible = true;
            });
    }
}
