<p-toast></p-toast>
<p-confirmDialog icon="fa fa-question-circle" #cd>
  <footer>
    <button type="button" pButton icon="ui-icon-undo" label="No" (click)="cd.reject()"></button>
    <button type="button" pButton icon="ui-icon-check" label="Sì" (click)="cd.accept()"></button>
  </footer>
</p-confirmDialog>
<div class="main-content clearfix">
  <div class="panel panel-default">
    <div class="panel-body view">
      <form [formGroup]="registerForm">
        <div class="row">
          <div class="col-sm-12">
            <label *ngIf="element.uuid">Aggiorna Tipologia</label>
            <label *ngIf="!element.uuid">Crea una nuova Tipologia</label>
            <div class="clearfix"></div>
            <hr />
          </div>
        </div>
        <div class="clearfix"></div>

        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Nome
              <span *ngIf="name | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="name"
              placeholder="Nome"
              formControlName="name"
            />
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Contenuto
              <span *ngIf="page_content | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <ngx-monaco-editor
              class="form-control template-editor"
              name="page_content"
              [options]="editorOptions"
              formControlName="page_content"
            ></ngx-monaco-editor>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Categoria
              <span *ngIf="category | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-dropdown
              [options]="categoryItems"
              formControlName="category"
              placeholder="Categoria"
              id="category"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Templates
              <span *ngIf="templates | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-multiSelect
              [options]="templateItems"
              defaultLabel="Scegli uno o più templates"
              formControlName="templates"
              placeholder="Templates"
              id="templates"
              [style]="{ width: '100%' }"
            ></p-multiSelect>
          </div>
        </div>

        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Template Default
              <span *ngIf="templates | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-multiSelect
              [options]="templateDefaultItems"
              (onChange)="onTemplateDefaultChange($event)"
              [(ngModel)]="selectedTemplateDefault"
              defaultLabel="Scegli un template"
              placeholder="Template Default"
              id="default_template"
              [style]="{ width: '100%' }"
              selectionLimit="1"
              [ngModelOptions]="{ standalone: true }"
            ></p-multiSelect>
          </div>
        </div>

        <div class="clearfix"></div>

        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Numero Colonne Layout
              <span *ngIf="max_columns | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="max_columns"
              placeholder="Numero Colonne"
              formControlName="max_columns"
              disabled="true"
            />
          </div>
        </div>

        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>Archiviata :</label>
          </div>

          <div class="col-sm-9">
            <p-inputSwitch
              (onChange)="onChangeArchived($event)"
              [ngModel]="archivedVal"
              [ngModelOptions]="{ standalone: true }"
              inputStyleClass="form-control"
              inputStyleClass="form-control"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="clearfix"></div>
        <div formArrayName="properties">
          <div *ngFor="let property of propertyForms.controls; let i = index" [formGroupName]="i">
            <app-property-definition
              [property]="property"
              [i]="i"
              [iconsItems]="iconsItems"
              [barcodeTypesItems]="barcodeTypesItems"
              [listableNamesItems]="listableNamesItems$ | async"
              [propertyTypesItems]="propertyTypesPrintableItems"
              [propertyValueName]="'default_value'"
              [locale]="langIt"
              [allCurrentNumericPropsItems]="allCurrentNumericPropsItems"
              (deletePropertyEvent)="deleteProperty($event)"
              (valueChangedFromChildEvent)="valueChangedFromChild($event)"
              (propertyTypeSelectedEvent)="propertyTypeSelected($event)"
            ></app-property-definition>
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="btn-group">
          <button type="button" class="btn btn-success" (click)="addEmptyProperty()">
            <i class="fa fa-plus"></i>
            Aggiungi proprietà
          </button>
        </div>
        <div class="clearfix">
          <div class="btn-group pull-right">
            <button
              type="button"
              class="btn btn-success"
              *ngIf="!element.uuid"
              (click)="save()"
              [disabled]="registerForm.invalid"
            >
              Salva
            </button>
            <button
              type="button"
              class="btn btn-success"
              *ngIf="element.uuid"
              (click)="complexUpdate()"
              [disabled]="registerForm.invalid"
            >
              Aggiorna
            </button>
            <button type="button" class="btn btn-warning" (click)="goToList()">Annulla</button>
            <button
              *ngIf="element.uuid"
              type="button"
              class="btn btn-danger"
              (click)="confirmDelete()"
            >
              <i class="fa fa-trash"></i>
              Elimina
            </button>
          </div>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</div>
