import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Search } from '../common/search';
import { Printable } from '../model/printable';

import { MessageService } from 'primeng/api';
import { ConfigurationService } from './configuration.service';
import { PrintableService } from './printable.service';

@Injectable({
    providedIn: 'root'
})
export class PageService extends PrintableService {
    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        configurationService: ConfigurationService
    ) {
        super(httpClient, messageService, configurationService);
    }

    public buildSearch() {
        this.search = new Search<Printable>(Printable);
        this.search.obj.objectType = 'PAGE';
    }
}
