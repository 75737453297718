import { SelectItem } from 'primeng/api';

export const BARCODE_TYPES: SelectItem[] = [
    { label: 'UPC_A', value: 'UPC_A' },
    { label: 'UPC_E', value: 'UPC_E' },
    { label: 'EAN_8', value: 'EAN_8' },
    { label: 'EAN_13', value: 'EAN_13' },
    { label: 'CODE_39', value: 'CODE_39' },
    { label: 'CODE_93', value: 'CODE_93' },
    { label: 'CODE_128', value: 'CODE_128' },
    { label: 'CODABAR', value: 'CODABAR' },
    { label: 'ITF', value: 'ITF' },
    { label: 'RSS_14', value: 'RSS_14' },
    { label: 'RSS_EXPANDED', value: 'RSS_EXPANDED' },
    { label: 'QR_CODE', value: 'QR_CODE' },
    { label: 'DATA_MATRIX', value: 'DATA_MATRIX' },
    { label: 'AZTEC', value: 'AZTEC' },
    { label: 'PDF_417', value: 'PDF_417' }
];
