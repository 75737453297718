export class Attachment {
    public uuid: string;
    public table_name: string;
    public table_uuid: string;
    public mime_type: string;
    public file_name: string;
    public url: string;
    public size: string;

    constructor() {}
}
