import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
    roles: string[];
    constructor(private keycloakService: KeycloakService, private router: Router) {}

    ngOnInit() {
        this.roles = this.keycloakService.getUserRoles();
    }

    showMenu(): boolean {
        if (location != null && location.href != null) {
            return !(location.href.indexOf('login') > 0);
        }
        return true;
    }

    logout() {
        this.keycloakService.logout(window.location.origin);
    }
}
