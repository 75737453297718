// Abilitare in produzione
// export const CONFIG_PATH = 'emplyee-requests-ng/assets/config.json';

import { environment } from '../../environments/environment';

export const MAX_PAGE_SIZE = 50;
export const CONFIG_PATH = environment.base_path + '/assets/config.json';

export const ATTACHMENTS_API_PATH = 'attachments_path';
export const PRINTABLES_API_PATH = 'printables_path';
export const CATALOGUES_API_PATH = 'catalogues_path';
export const CONTAINERS_API_PATH = 'containers_path';
export const CATEGORIES_API_PATH = 'categories_path';
export const LISTABLES_API_PATH = 'listables_path';
export const PRINT_TEMPLATES_API_PATH = 'print_templates_path';
export const PRINT_PATH = 'print_path';
export const BARCODE_PATH = 'barcode_path';
export const TIPOLOGIES_API_PATH = 'tipologies_path';
export const SYSTEM_EVENT_LOG_API_PATH = 'system_event_logs_path';
export const PRINT_LOG_API_PATH = 'print_logs_path';

export const LISTABLE_SECTOR_KEY = 'settore';
export const TIPOLOGY_STYLE_MAX_COLUMNS = 12;
