import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';
import { AttachmentService } from '../../service/attachment.service';
import { PrintTemplateService } from '../../service/print-template.service';
import { PrintableService } from '../../service/printable.service';
import { ListableService } from '../../service/listable.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import {
    manipulateProperties,
    generateExtra,
    addControlToFormGroup,
} from '../../shared-functions/forms-util';

import { AbstractEditComponent } from '../../common/abstract-edit-component';
import { Tipology } from '../../model/tipology';
import { propertyTypesPrintableItems } from '../../model/enum/property-type';
import { FONT_AWESOME_ICONS } from '../../model/enum/icons';
import { BARCODE_TYPES } from '../../constants/barcode-types';
import { Observable } from 'rxjs';
import { PropertyService } from 'src/app/service/property.service';
import { takeUntil } from 'rxjs/operators';
import { PropertyValue } from 'src/app/model/property-value';
import { PropertyDefinition } from '../../components/property/interfaces/property-definition-interface';
import { Category } from 'src/app/model/category';
import { Search } from 'src/app/common/search';
import { PrintTemplate } from 'src/app/model/print-template';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { TIPOLOGY_STYLE_MAX_COLUMNS } from 'src/app/constants/constants';

@Component({
    selector: 'app-tipology-edit',
    templateUrl: './tipology-edit.component.html',
    styleUrls: ['./tipology-edit.component.css'],
})
export class TipologyEditComponent extends AbstractEditComponent<Tipology>
    implements OnInit, PropertyDefinition {
    element: Tipology;
    registerForm: FormGroup;
    categoryItems: SelectItem[];

    templateItems: SelectItem[];
    templateDefaultItems: SelectItem[] = [];

    selectedTemplateDefault: PrintTemplate[] = [];

    iconsItems: SelectItem[] = FONT_AWESOME_ICONS;
    barcodeTypesItems: SelectItem[] = BARCODE_TYPES;
    propertyTypesPrintableItems: SelectItem[] = propertyTypesPrintableItems;
    allCurrentNumericPropsItems: SelectItem[];
    listableNamesItems$: Observable<SelectItem[]> = this.listableService.listNamesAsItems();
    editorOptions = {
        language: 'html',
        minimap: {
            enabled: false,
        },
        automaticLayout: true,
    };
    categories: Map<string, Category> = new Map<string, Category>();

    archivedVal = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected tipologyService: TipologyService,
        protected categoryService: CategoryService,
        protected printTemplateService: PrintTemplateService,
        protected printableService: PrintableService,
        protected attachmentService: AttachmentService,
        private formBuilder: FormBuilder,
        private confirmationService: ConfirmationService,
        protected listableService: ListableService,
        protected propertyService: PropertyService,
        protected routingStateService: RoutingStateService,
        messageService: MessageService
    ) {
        super(router, route, tipologyService, routingStateService, 'tipologies', messageService);
        this.element = new Tipology();
    }

    ngOnInit() {
        super.ngOnInit();
        this.getSelectableItems();
        this.buildForm();
    }

    postFind() {
        if (this.router.url.includes('tipologies/clone')) {
            this.element.uuid = null;
            this.element.name = this.element.name ? this.element.name + ' CLONE' : null;
        }
        this.buildForm();
        this.displayPropertyListDefault();
        this.archivedVal = !this.element.active;

        this.displayTemplateListDefault();
    }

    postCreate() {
        this.element.active = true;
    }

    getSelectableItems() {
        this.categoryService
            .getAllListAsItems()
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                this.categories = { ...res.map };
                this.categoryItems = [...res.items];
            });

        const search = new Search<PrintTemplate>(PrintTemplate);
        search.obj.printTemplateType = 'PRINTABLE';

        this.printTemplateService
            .getAllListAsItems(search)
            .pipe(takeUntil(this.destroy$))
            .subscribe((tItems) => {
                this.templateItems = [...tItems];
            });
    }

    private buildForm() {
        this.registerForm = this.formBuilder.group({
            name: [this.element.name, Validators.required],
            max_columns: [TIPOLOGY_STYLE_MAX_COLUMNS, Validators.required],
            page_content: [this.element.page_content],
            category: [this.element.category_uuid, Validators.required],
            properties: this.formBuilder.array([]),
            templates: [this.element.templates, Validators.required],
            default_template: [this.element.default_template, Validators.required],
            active: [this.element.active],
        });
        this.selectedTemplateDefault = this.element.default_template
            ? [this.element.default_template]
            : [];
        this.formTemplatesChange();
    }

    onTemplateDefaultChange(event) {
        this.selectedTemplateDefault = event.value;
        this.template_defaultItems.patchValue(this.selectedTemplateDefault[0]);
    }

    private getValuesFromForm() {
        this.element.name = this.name.value;
        this.element.max_columns = this.max_columns.value;
        this.element.page_content = this.page_content.value;
        this.element.category_uuid = this.category.value;
        this.element.properties = this.propertyForms.value;
        this.element.templates = this.templates.value;
        this.element.default_template = this.selectedTemplateDefault[0];
        this.element.active = this.registerForm.get('active').value;
        manipulateProperties(this.element, 'default_value', this.attachmentService);
    }

    displayPropertyListDefault() {
        this.propertyForms.clear();
        this.element.properties.map((p) => {
            this.propertyService.addProperty(this.propertyForms, p, 'default_value', 'default');
        });
        this.calculateAllCurrentNumericPropsItems();
    }
    displayTemplateListDefault() {
        this.templates.value.map((element) => {
            this.templateDefaultItems.push({ label: element.name, value: element });
        });
    }
    calculateAllCurrentNumericPropsItems() {
        this.allCurrentNumericPropsItems = [];
        for (const c of this.propertyForms.controls) {
            const p: PropertyValue = c.value;
            if (
                p.property_type === 'NUMBER_VALUE' ||
                p.property_type === 'SUMMABLE' ||
                p.property_type === 'SOTTRAIBILE' ||
                p.property_type === 'MULTIPLIABLE' ||
                p.property_type === 'DIVISIBLE'
            ) {
                this.allCurrentNumericPropsItems.push({
                    label: p.label,
                    value: p.name,
                });
            }
        }
    }

    addEmptyProperty() {
        this.calculateAllCurrentNumericPropsItems();

        const orderBy = this.propertyForms?.length + 1;
        const emptyProp = new PropertyValue();
        emptyProp.order_by = orderBy;
        this.propertyService.addProperty(this.propertyForms, emptyProp, 'default_value');
    }

    deleteProperty(i) {
        this.propertyForms.removeAt(i);
    }

    propertyTypeSelected({ event, indexInFormArray }) {
        const generatedExtra = generateExtra(
            this.formBuilder,
            this.propertyForms.at(indexInFormArray).get('property_type').value,
            null
        );
        const formGroup = this.propertyForms.at(indexInFormArray) as FormGroup;
        formGroup.removeControl('extra');
        if (generatedExtra) {
            addControlToFormGroup(formGroup, 'extra', generatedExtra);
        }

        this.propertyForms.at(indexInFormArray).patchValue({ default_value: null });
    }

    filterOutAttachmentProps() {
        this.element.properties = this.element.properties.filter(
            (p) =>
                !this.propertyService.isImageType(p, 'default_value') &&
                !this.propertyService.isAttachmentType(p, 'default_value')
        );
    }

    preSave() {
        this.getValuesFromForm();
        const haveError = this.propertiesErrorHandling();
        this.filterOutAttachmentProps();
        this.element.properties.sort((a, b) => a.order_by - b.order_by);
        return !haveError;
    }

    postSave() {
        const anyAttachment = this.propertyService.hasAnyAttachment(
            this.propertyForms,
            'default_value'
        );
        if (anyAttachment) {
            this.navigateAfterSave = () => {};
            this.complexUpdate();
        }
    }

    preUpdate() {
        this.getValuesFromForm();
        const haveError = this.propertiesErrorHandling();
        this.element.properties.sort((a, b) => a.order_by - b.order_by);
        return !haveError;
    }
    // TODO se upload immagine + allegato scoppia il BE
    complexUpdate() {
        const promises = this.propertyService.checkAndUploadImagesAndAttachmnts(
            this.propertyForms,
            this.element,
            'default_value'
        );
        if (promises) {
            Promise.all(promises).then((_) => super.update());
        } else {
            super.update();
        }
    }

    propertiesErrorHandling() {
        const errorCheck = this.propertyService.havePropertiesErrors(this.element.properties);
        if (errorCheck.haveError) {
            this.addError(errorCheck.msg);
        }
        return errorCheck.haveError;
    }

    valueChangedFromChild({ value, index }) {
        this.propertyForms.at(index).patchValue({ default_value: value });
    }

    getId() {
        return this.element.uuid;
    }

    createInstance(): Tipology {
        return new Tipology();
    }

    confirmDelete() {
        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () => {
                this.delete();
            },
        });
    }

    get name() {
        return this.registerForm.get('name');
    }
    get max_columns() {
        return this.registerForm.get('max_columns');
    }
    get page_content() {
        return this.registerForm.get('page_content');
    }
    get category() {
        return this.registerForm.get('category');
    }
    get templates() {
        return this.registerForm.get('templates');
    }
    get template_defaultItems() {
        return this.registerForm.get('default_template');
    }
    get propertyForms() {
        return this.registerForm.get('properties') as FormArray;
    }
    formTemplatesChange() {
        this.templates.valueChanges.subscribe((items) => {
            let newDefaultItems: SelectItem[] = [];
            items.forEach((element) => {
                newDefaultItems.push({ label: element.name, value: element });
            });
            this.templateDefaultItems = newDefaultItems;
        });
    }
    onChangeArchived(event) {
        this.archivedVal = event.checked;
        this.registerForm.patchValue({ active: !event.checked });
    }
}
