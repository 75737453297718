import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './components/homepage/homepage.component';
import { ArticleListComponent } from './components/article/article-list.component';
import { CategoryListComponent } from './components/category/category-list.component';
import { TipologyListComponent } from './components/tipology/tipology-list.component';
import { TipologyEditComponent } from './components/tipology/tipology-edit.component';
import { ArticleViewComponent } from './components/article/article-view.component';
import { ArticleEditComponent } from './components/article/article-edit.component';
import { PageListComponent } from './components/page/page-list.component';
import { PageViewComponent } from './components/page/page-view.component';
import { PageEditComponent } from './components/page/page-edit.component';
import { PrintTemplateListComponent } from './components/print-template/print-template-list.component';
import { PrintTemplateEditComponent } from './components/print-template/print-template-edit.component';
import { TipologyViewComponent } from './components/tipology/tipology-view.component';
import { PrintTemplateViewComponent } from './components/print-template/print-template-view.component';
import { CatalogueListComponent } from './components/catalogue/catalogue-list.component';
import { CatalogueEditComponent } from './components/catalogue/catalogue-edit.component';
import { CatalogueViewComponent } from './components/catalogue/catalogue-view.component';
import { ContainerListComponent } from './components/container/container-list.component';
import { ContainerEditComponent } from './components/container/container-edit.component';
import { ContainerViewComponent } from './components/container/container-view.component';
import { ContainerAddPrintablesComponent } from './components/container/container-add-printables.component';
import { ContainerAddCataloguesComponent } from './components/container/container-add-catalogues.component';
import { ListableListComponent } from './components/listable/listable-list.component';
import { CatalogueAddPrintablesComponent } from './components/catalogue/catalogue-add-printables/catalogue-add-printables.component';
import { PrintablesBulkEditComponent } from './components/container/printables-bulk-edit/printables-bulk-edit.component';
import { PrintablesBulkViewComponent } from './components/container/printables-bulk-view/printables-bulk-view.component';
import { AuthGuardGuard } from './guards/auth-guard.guard';
import { NoUserGuard } from './guards/no-user-guard.guard';
import { SystemEventLogListComponent } from './components/system-event-log/system-event-log-list.component';
import { SystemEventLogViewComponent } from './components/system-event-log/system-event-log-view.component';
import { PrintLogViewComponent } from './components/print-log/print-log-view.component';
import { PrintLogListComponent } from './components/print-log/print-log-list.component';
import { ImportazioniComponent } from './components/importazioni/importazioni.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuardGuard],
        children: [
            {
                path: 'homepage',
                component: HomepageComponent,
            },
            {
                path: 'catalogues/list',
                component: CatalogueListComponent,
            },
            {
                path: 'catalogues/view/:id',
                component: CatalogueViewComponent,
            },
            {
                path: 'catalogues/edit/:id',
                component: CatalogueEditComponent,
            },
            {
                path: 'catalogues/:id/aggiungi-articoli',
                component: CatalogueAddPrintablesComponent,
            },
            {
                path: 'catalogues/clone/:id',
                component: CatalogueEditComponent,
            },
            {
                path: 'catalogues/new',
                component: CatalogueEditComponent,
            },
            {
                path: 'articles/list',
                component: ArticleListComponent,
            },
            {
                path: 'articles/view/:id',
                component: ArticleViewComponent,
            },
            {
                path: 'articles/edit/:id',
                component: ArticleEditComponent,
            },
            {
                path: 'articles/clone/:id',
                component: ArticleEditComponent,
            },
            {
                path: 'articles/new',
                component: ArticleEditComponent,
            },
            {
                path: 'pages/list',
                component: PageListComponent,
            },
            {
                path: 'pages/view/:id',
                component: PageViewComponent,
            },
            {
                path: 'pages/edit/:id',
                component: PageEditComponent,
            },
            {
                path: 'pages/clone/:id',
                component: PageEditComponent,
            },
            {
                path: 'pages/new',
                component: PageEditComponent,
            },
            {
                path: 'categories/list',
                component: CategoryListComponent,
            },
            {
                path: 'listables/list',
                component: ListableListComponent,
            },
            {
                path: 'tipologies/list',
                canActivate: [NoUserGuard],
                component: TipologyListComponent,
            },
            {
                path: 'tipologies/edit/:id',
                canActivate: [NoUserGuard],
                component: TipologyEditComponent,
            },
            {
                path: 'tipologies/clone/:id',
                canActivate: [NoUserGuard],
                component: TipologyEditComponent,
            },
            {
                path: 'tipologies/view/:id',
                canActivate: [NoUserGuard],
                component: TipologyViewComponent,
            },
            {
                path: 'tipologies/new',
                canActivate: [NoUserGuard],
                component: TipologyEditComponent,
            },

            {
                path: 'templates',
                canActivate: [NoUserGuard],
                children: [
                    {
                        path: 'list',
                        component: PrintTemplateListComponent,
                    },
                    {
                        path: 'edit/:id',
                        component: PrintTemplateEditComponent,
                    },
                    {
                        path: 'clone/:id',
                        component: PrintTemplateEditComponent,
                    },
                    {
                        path: 'view/:id',
                        component: PrintTemplateViewComponent,
                    },
                    {
                        path: 'new',
                        component: PrintTemplateEditComponent,
                    },
                ],
            },
            {
                path: 'gare/list',
                component: ContainerListComponent,
            },
            {
                path: 'gare/new',
                component: ContainerEditComponent,
            },
            {
                path: 'gare/view/:id',
                component: ContainerViewComponent,
            },
            {
                path: 'gare/clone/:id',
                component: ContainerEditComponent,
            },
            {
                path: 'gare/edit/:id',
                component: ContainerEditComponent,
            },
            {
                path: 'gare/:id/aggiungi-articoli',
                component: ContainerAddPrintablesComponent,
            },
            {
                path: 'gare/:id/aggiungi-cataloghi',
                component: ContainerAddCataloguesComponent,
            },
            {
                path: 'gare/modifica-massiva-proprieta',
                component: PrintablesBulkEditComponent,
            },
            {
                path: 'gare/modifica-massiva-proprieta/:uuid',
                component: PrintablesBulkViewComponent,
            },

            {
                path: 'system_event_logs',
                children: [
                    {
                        path: 'list',
                        component: SystemEventLogListComponent,
                    },
                    {
                        path: 'view/:id',
                        component: SystemEventLogViewComponent,
                    },
                ],
            },

            {
                path: 'print_logs',
                children: [
                    {
                        path: 'list',
                        component: PrintLogListComponent,
                    },
                    {
                        path: 'view/:id',
                        component: PrintLogViewComponent,
                    },
                ],
            },

            { path: 'import', component: ImportazioniComponent },

            { path: '**', redirectTo: '/homepage' },
        ],
    },
];

export const routing = RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled' });
