import { Directive, ElementRef, Input, SimpleChange } from '@angular/core';

@Directive({
    selector: '[appStopEditorFocus]',
})
export class StopEditorFocusDirective {
    @Input('p-editor-model') content: string;

    ngOnChanges(changes: { [property: string]: SimpleChange }) {
        let change = changes['content'];
        let elemPosition =
            this.element.nativeElement.getBoundingClientRect().top + document.body.scrollTop;
        let clientHeight = document.documentElement.clientHeight;

        if (change.isFirstChange() || elemPosition > clientHeight) {
            this.element.nativeElement.style.display = 'none';
            setTimeout(() => {
                this.element.nativeElement.style.display = '';
            });
        }
    }

    constructor(private element: ElementRef) {}
}
