import { CatalogueElement } from './catalogue-element';
import { PropertyValue } from './property-value';

export class Catalogue {
    public uuid: string;
    public name: string;
    public description: string;
    public properties: PropertyValue[];
    public tags: string;
    public version: number;
    public container_uuid: string;

    public active: boolean;

    public catalogueElements: CatalogueElement[];

    public withAttchamentsAtEnd: boolean;
    public withAttchamentAfterSingleArticle: boolean;
    public onlyAttchaments: boolean;
    public monopage: boolean;
    public monopage_template_uuid: string;
    public monopage_mymetype: string;

    constructor() {}
}
