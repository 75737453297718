import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { routing } from './app-routing';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { HomepageComponent } from './components/homepage/homepage.component';
import { MenuComponent } from './components/menu/menu.component';
import { ArticleEditComponent } from './components/article/article-edit.component';
import { ArticleListComponent } from './components/article/article-list.component';
import { ArticleViewComponent } from './components/article/article-view.component';
import { CategoryListComponent } from './components/category/category-list.component';
import { TipologyListComponent } from './components/tipology/tipology-list.component';

import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SpinnerModule } from 'primeng/spinner';
import { ConfirmationService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { CardModule } from 'primeng/card';
import { ChipsModule } from 'primeng/chips';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { PanelModule } from 'primeng/panel';
import { AccordionModule } from 'primeng/accordion';
import { SplitButtonModule } from 'primeng/splitbutton';
// import { EditorModule } from '@tinymce/tinymce-angular';
import { EditorModule } from 'primeng/editor';
import { MonacoEditorModule } from 'ngx-monaco-editor';

import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { PrintTemplateListComponent } from './components/print-template/print-template-list.component';
import { AddTrailCommaPipe } from './pipe/add-trail-comma.pipe';
import { PrintTemplateEditComponent } from './components/print-template/print-template-edit.component';
import { TipologyEditComponent } from './components/tipology/tipology-edit.component';
import { LoginComponent } from './components/login/login.component';
import { TipologyViewComponent } from './components/tipology/tipology-view.component';
import { PrintTemplateViewComponent } from './components/print-template/print-template-view.component';
import { ConfigurationService } from './service/configuration.service';
import { initializer } from './utils/app-init';
import { FileUploadComponent } from './components/attachment/file-upload.component';
import { AttachmentUploadComponent } from './components/attachment/attachment-upload.component';
import { CatalogueListComponent } from './components/catalogue/catalogue-list.component';
import { CatalogueEditComponent } from './components/catalogue/catalogue-edit.component';
import { CatalogueViewComponent } from './components/catalogue/catalogue-view.component';
import { PageViewComponent } from './components/page/page-view.component';
import { PageEditComponent } from './components/page/page-edit.component';
import { PageListComponent } from './components/page/page-list.component';
import { IsFieldRequiredPipe } from './pipe/is-field-required.pipe';
import { ExistingImagesListComponent } from './components/attachment/existing-images-list.component';
import { ExistingAttachmentsListComponent } from './components/attachment/existing-attachments-list.component';
import { ContainerListComponent } from './components/container/container-list.component';
import { ContainerEditComponent } from './components/container/container-edit.component';
import { ContainerAddPrintablesComponent } from './components/container/container-add-printables.component';
import { ContainerAddCataloguesComponent } from './components/container/container-add-catalogues.component';
import { ListableListComponent } from './components/listable/listable-list.component';
import { ContainerViewComponent } from './components/container/container-view.component';
import { SafePipe } from './pipe/safe.pipe';
import { PropertyValorizationComponent } from './components/property/property-valorization/property-valorization.component';
import { PropertyDefinitionComponent } from './components/property/property-definition/property-definition.component';
import { PropertyViewComponent } from './components/property/property-view/property-view.component';
import { CatalogueAddPrintablesComponent } from './components/catalogue/catalogue-add-printables/catalogue-add-printables.component';
import { PrintablesBulkEditComponent } from './components/container/printables-bulk-edit/printables-bulk-edit.component';
import { TabViewModule } from 'primeng/tabview';
import { PrintablesBulkViewComponent } from './components/container/printables-bulk-view/printables-bulk-view.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ArticlePropertyFilterComponent } from './components/filters/article-property-filter/article-property-filter.component';
import { ArticlePropertyTagFilterComponent } from './components/filters/article-property-tag-filter/article-property-tag-filter.component';
import { ArticleListFiltersComponent } from './components/filters/article-list-filters/article-list-filters.component';
import { SearchPipe } from './pipe/search.pipe';
import { SpecialcharsPipe } from './pipe/specialchars.pipe';
import { ListableValorizationComponent } from './components/property/listable-valorization/listable-valorization.component';
import { OrderBy } from './pipe/order-by.pipe';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SystemEventLogListComponent } from './components/system-event-log/system-event-log-list.component';
import { SystemEventLogViewComponent } from './components/system-event-log/system-event-log-view.component';
import { PrintLogListComponent } from './components/print-log/print-log-list.component';
import { PrintLogViewComponent } from './components/print-log/print-log-view.component';
import { ImportazioniComponent } from './components/importazioni/importazioni.component';
import { StopEditorFocusDirective } from './directives/stop-editor-focus.directive';
import { OrderModule } from 'ngx-order-pipe';
import { SelectArticoliComponent } from './components/container/printables-bulk-edit/select-articoli/select-articoli.component';
import { CustomLoaderComponent } from './shared/custom-loader/custom-loader.component';

@NgModule({
    declarations: [
        LoginComponent,
        AppComponent,
        HomepageComponent,
        MenuComponent,
        ArticleEditComponent,
        ArticleListComponent,
        ArticleViewComponent,
        CategoryListComponent,
        TipologyListComponent,
        TipologyEditComponent,
        TipologyViewComponent,
        PrintTemplateListComponent,
        PrintTemplateViewComponent,
        AddTrailCommaPipe,
        PrintTemplateEditComponent,
        FileUploadComponent,
        AttachmentUploadComponent,
        CatalogueViewComponent,
        CatalogueEditComponent,
        CatalogueListComponent,
        ContainerListComponent,
        ListableListComponent,
        PageViewComponent,
        PageEditComponent,
        PageListComponent,
        IsFieldRequiredPipe,
        ExistingImagesListComponent,
        ExistingAttachmentsListComponent,
        ContainerEditComponent,
        ContainerAddPrintablesComponent,
        ContainerAddCataloguesComponent,
        ContainerViewComponent,
        SafePipe,
        PropertyValorizationComponent,
        PropertyDefinitionComponent,
        PropertyViewComponent,
        CatalogueAddPrintablesComponent,
        PrintablesBulkEditComponent,
        PrintablesBulkViewComponent,
        ArticlePropertyFilterComponent,
        ArticlePropertyTagFilterComponent,
        ArticleListFiltersComponent,
        SearchPipe,
        SpecialcharsPipe,
        ListableValorizationComponent,
        // OrderBy,
        SystemEventLogListComponent,
        SystemEventLogViewComponent,
        PrintLogListComponent,
        PrintLogViewComponent,
        ImportazioniComponent,
        StopEditorFocusDirective,
        SelectArticoliComponent,
        CustomLoaderComponent,
    ],
    imports: [
        KeycloakAngularModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CheckboxModule,
        ColorPickerModule,
        ConfirmDialogModule,
        DialogModule,
        AutoCompleteModule,
        DropdownModule,
        InputSwitchModule,
        InputTextareaModule,
        InputTextModule,
        OverlayPanelModule,
        ToggleButtonModule,
        RadioButtonModule,
        SpinnerModule,
        MessageModule,
        ToastModule,
        FileUploadModule,
        SelectButtonModule,
        CalendarModule,
        TableModule,
        MultiSelectModule,
        TabViewModule,
        CardModule,
        EditorModule,
        ChipsModule,
        ProgressSpinnerModule,
        TooltipModule,
        PanelModule,
        AccordionModule,
        SplitButtonModule,

        MonacoEditorModule.forRoot(),
        routing,
        ReactiveFormsModule,
        FormsModule,
        PdfViewerModule,
        OrderModule,
    ],
    providers: [
        MessageService,
        ConfirmationService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService, ConfigurationService],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
