import { objectType } from './enum/object-type';
export class CatalogueElement {
    public uuid: string;

    public printable_uuid: string;
    public printable_type: objectType;
    public template_uuid: string;
    public page_break_before: boolean;
    public page_break_after: boolean;
    public orderBy: number;

    constructor() {}
}
