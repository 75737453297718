import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

import { AbstractListComponent } from '../../common/abstract-list-component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Listable } from '../../model/listable';
import { ListableService } from '../../service/listable.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-listable-list',
    templateUrl: './listable-list.component.html',
    styleUrls: ['./listable-list.component.css'],
})
export class ListableListComponent extends AbstractListComponent<Listable> implements OnInit {
    element: Listable;
    action = 'Salva';
    registerForm: FormGroup;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected listableService: ListableService,
        private formBuilder: FormBuilder,
        private confirmationService: ConfirmationService
    ) {
        super(router, listableService, 'listables');
    }

    ngOnInit() {
        this.loading = true;
        this.element = new Listable();
        this.buildForm();
    }

    private buildForm() {
        this.registerForm = this.formBuilder.group({
            name: [this.element.name, Validators.required],
            value: [this.element.value],
        });
    }

    private getValuesFromForm() {
        this.element.value = this.registerForm.get('value').value;
        this.element.name = this.registerForm.get('name').value;
    }

    newElement(): Listable {
        return super.newElement();
    }

    restart() {
        this.action = 'Salva';
        this.element = new Listable();
        this.buildForm();
    }

    save() {
        this.getValuesFromForm();
        this.service
            .persist(this.element)
            .pipe(takeUntil(this.destroy$))
            .subscribe((user) => {
                this.restart();
                this.loaddata(true);
            });
    }

    update() {
        this.getValuesFromForm();
        this.action = 'Salva';
        this.service
            .update(this.element)
            .pipe(takeUntil(this.destroy$))
            .subscribe((user) => {
                this.restart();
                this.loaddata(true);
            });
    }

    edit(element: Listable) {
        this.element = element;
        this.buildForm();
        this.action = 'Aggiorna';
        return;
    }

    delete(element: Listable) {
        this.service
            .delete(element.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((del) => this.loaddata(true));
    }

    confirmDelete(element: Listable) {
        this.action = 'Salva';
        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () => {
                this.delete(element);
            },
        });
    }

    public new() {
        this.router.navigate(['/' + this.path + '/list']);
    }

    getId() {
        return this.element.uuid;
    }

    createInstance(): Listable {
        return new Listable();
    }

    get description() {
        return this.registerForm.get('description');
    }

    get name() {
        return this.registerForm.get('name');
    }
}
