import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { AbstractService } from '../common/abstract-service';
import { Search } from '../common/search';
import { Attachment } from '../model/attachment';
import { PRINTABLES_API_PATH, ATTACHMENTS_API_PATH, BARCODE_PATH } from '../constants/constants';
import { of, Observable, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { Printable } from '../model/printable';

import { MessageService } from 'primeng/api';
import { ConfigurationService } from './configuration.service';
import { Catalogue } from '../model/catalogue';
import { Tipology } from '../model/tipology';
import { PrintTemplate } from '../model/print-template';
import { Container } from '../model/container';

@Injectable({
    providedIn: 'root',
})
export class AttachmentService extends AbstractService<Attachment> {
    private attachmentUrl: string;
    private barcodeUrl: string;

    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        configurationService: ConfigurationService,
        private domSanitizer: DomSanitizer
    ) {
        super(configurationService.getValue(ATTACHMENTS_API_PATH), httpClient, messageService);
        configurationService
            .getValue(ATTACHMENTS_API_PATH)
            .subscribe((result) => (this.attachmentUrl = result));
        configurationService
            .getValue(BARCODE_PATH)
            .subscribe((result) => (this.barcodeUrl = result));
    }

    public getId(element: Attachment) {
        return element.uuid;
    }

    public buildSearch() {
        this.search = new Search<Attachment>(Attachment);
    }

    public async persistAttachment(
        element: Printable | Tipology | PrintTemplate | Catalogue | Container,
        tableName: string,
        documento: Attachment,
        blob: any
    ): Promise<Printable> {
        return new Promise((resolve, reject) => {
            if (documento) {
                let uuid;
                if (!element['uuid']) {
                    uuid = element['codiceGara'];
                } else {
                    uuid = element['uuid'];
                }
                const formData: FormData = new FormData();

                formData.append('file', blob);
                formData.append('name', documento.file_name);
                formData.append('mime_type', documento.mime_type);
                formData.append('table_name', tableName);
                formData.append('table_uuid', uuid);
                formData.append('size', documento.size);
                this.httpClient.post<any>(this.attachmentUrl, formData).subscribe(
                    (res) => {
                        console.log(res);
                        // resolve(JSON.parse(res));
                        resolve(res);
                    },
                    (err) => {
                        console.log(err);
                        reject(err);
                    }
                );
            } else {
                console.log('no attachment');
            }
        });
    }

    private getTableName(element): string {
        let tableName = '';
        if (element instanceof PrintTemplate) {
            tableName = 'printable';
        } else if (element instanceof Tipology) {
            tableName = 'tipology';
        } else if (element instanceof PrintTemplate) {
            tableName = 'print_template';
        } else if (element instanceof Catalogue) {
            tableName = 'catalogue';
        } else if (element instanceof Container) {
            tableName = 'container';
        }

        return tableName;
    }

    setAttachment(
        attachmenttoUpload: any,
        element: Printable | Tipology | PrintTemplate | Catalogue | Container
    ): Promise<any> {
        const obs: Observable<any>[] = [];
        const attachment = new Attachment();
        attachment.file_name = attachmenttoUpload.name;
        attachment.mime_type = attachmenttoUpload.type;
        attachment.size = attachmenttoUpload.size;
        const tableName = this.getTableName(element);
        const promise = this.persistAttachment(element, tableName, attachment, attachmenttoUpload);

        return promise;
    }

    deleteAttachment(uuid: string): Observable<any> {
        return this.httpClient.delete(`${this.attachmentUrl}/${uuid}`);
    }

    unwrapAttachmentUuidFromUrl(url: string): string {
        const uuid = url.match(/attachments\/(.*)\/download/)[1];
        return uuid;
    }

    getAttachmentAsBlob(uuid: string): Observable<any> {
        return this.httpClient.get(this.attachmentUrl + '/' + uuid + '/download', {
            responseType: 'blob',
        });
    }

    getAttachmentPath(uuid): Observable<any> {
        let attachmentsPath: Observable<any>;
        return this.getAttachment(uuid).pipe(
            map((attachment) => {
                attachmentsPath = this.getAttachmentAsBlob(attachment.uuid);
                attachmentsPath = attachmentsPath.pipe(
                    map((blob) =>
                        this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))
                    )
                );
                return attachmentsPath;
            })
        );
    }

    public getAttachment(uuid: string): Observable<Attachment> {
        let params = new HttpParams();
        params = this.applyRestrictions(params, this.search);

        return this.httpClient
            .get<HttpResponse<Attachment[]>>(this.url + '/' + uuid, {
                observe: 'response',
                params,
            })
            .pipe(
                map((res) => {
                    this.listSize =
                        res.headers.get('listSize') != null ? +res.headers.get('listSize') : 0;
                    const ts: any = res.body;
                    this.postList(ts);
                    return ts;
                }),
                catchError(this.handleError)
            );
    }

    public generateUrlBarcode(code: string, type: string, height: number, width: number) {
        const url = `${this.barcodeUrl}/?code=${code}&amp;type=${type}&amp;height=${height}&amp;width=${width}`;
        return url;
    }
}
