import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ContainerService } from '../../service/container.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { AbstractEditComponent } from '../../common/abstract-edit-component';
import { Container } from '../../model/container';
import { PropertyDefinition } from '../property/interfaces/property-definition-interface';
import { AttachmentService } from 'src/app/service/attachment.service';
import { PropertyService } from 'src/app/service/property.service';
import {
    manipulateProperties,
    generateExtra,
    addControlToFormGroup,
} from 'src/app/shared-functions/forms-util';
import { Observable } from 'rxjs';
import { ListableService } from 'src/app/service/listable.service';
import { propertyTypesTemplateItems } from '../../model/enum/property-type';
import { FONT_AWESOME_ICONS } from '../../model/enum/icons';
import { BARCODE_TYPES } from '../../constants/barcode-types';
import { PropertyValue } from 'src/app/model/property-value';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { LISTABLE_SECTOR_KEY } from 'src/app/constants/constants';

@Component({
    selector: 'app-container-edit',
    templateUrl: './container-edit.component.html',
})
export class ContainerEditComponent extends AbstractEditComponent<Container>
    implements OnInit, PropertyDefinition {
    element: Container;
    registerForm: FormGroup;
    iconsItems: SelectItem[] = FONT_AWESOME_ICONS;
    barcodeTypesItems: SelectItem[] = BARCODE_TYPES;
    propertyTypesTemplateItems: SelectItem[] = propertyTypesTemplateItems;
    listableNamesItems$: Observable<SelectItem[]> = this.listableService.listNamesAsItems();
    listableSectorItems: SelectItem[];

    private oldCode: string = null;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected containerService: ContainerService,
        private formBuilder: FormBuilder,
        private confirmationService: ConfirmationService,
        private attachmentService: AttachmentService,
        private propertyService: PropertyService,
        private listableService: ListableService,
        protected routingStateService: RoutingStateService,
        messageService: MessageService
    ) {
        super(router, route, containerService, routingStateService, 'gare', messageService);
        this.element = new Container();
    }

    ngOnInit() {
        super.ngOnInit();
        this.getSectorItems();
        this.buildForm();
    }

    postFind() {
        // if (this.element.archived || this.element.closed) this.navigateToList();
        this.oldCode = this.element.codiceGara;

        if (this.router.url.includes('gare/clone')) {
            this.element.codiceGara = null;
            this.element.name = this.element.name ? this.element.name + ' CLONE' : null;
        }
        this.buildForm();
        this.displayPropertyListDefault();
    }

    newDate(field: string) {
        if (field) {
            return new Date(field);
        }
        return null;
    }

    private buildForm() {
        this.registerForm = this.formBuilder.group({
            name: [this.element.name, Validators.required],
            codiceGara: [this.element.codiceGara, Validators.required],
            description: [this.element.description],
            reference: [this.element.reference],
            entity_type: [this.element.entity_type],
            sector: [this.element.sector],
            delivery_address: [this.element.delivery_address],
            deadline: [this.newDate(this.element.deadline)],
            bidder: [this.element.bidder],
            notes: [this.element.notes],
            prorogation: [this.element.prorogation],
            portfolioCustomer: [this.element.portfolioCustomer],
            properties: this.formBuilder.array([]),
        });
    }

    private getValuesFromForm() {
        this.element.name = this.registerForm.get('name').value;
        this.element.codiceGara = this.registerForm.get('codiceGara').value;
        this.element.description = this.registerForm.get('description').value;
        this.element.reference = this.registerForm.get('reference').value;
        this.element.entity_type = this.registerForm.get('entity_type').value;
        this.element.sector = this.registerForm.get('sector').value;
        this.element.delivery_address = this.registerForm.get('delivery_address').value;
        this.element.deadline = this.registerForm.get('deadline').value;
        this.element.bidder = this.registerForm.get('bidder').value;
        this.element.notes = this.registerForm.get('notes').value;
        this.element.prorogation = this.registerForm.get('prorogation').value;
        this.element.portfolioCustomer = this.registerForm.get('portfolioCustomer').value;
        this.element.properties = this.propertyForms.value;
        manipulateProperties(this.element, 'value', this.attachmentService);
    }

    getSelectableItems() {}

    getSectorItems() {
        this.listableService
            .listValuesAsItems(LISTABLE_SECTOR_KEY)
            .subscribe((settoreItems) => (this.listableSectorItems = settoreItems));
    }

    displayPropertyListDefault() {
        this.propertyForms.clear();
        this.element?.properties?.map((p) => {
            this.propertyService.addProperty(this.propertyForms, p, 'default_value', 'edit');
        });
    }

    addEmptyProperty() {
        const orderBy = this.propertyForms?.length + 1;
        const emptyProp = new PropertyValue();
        emptyProp.order_by = orderBy;
        this.propertyService.addProperty(this.propertyForms, emptyProp, 'default_value');
    }

    deleteProperty(i) {
        this.propertyForms.removeAt(i);
    }

    propertyTypeSelected({ event, indexInFormArray }) {
        const generatedExtra = generateExtra(
            this.formBuilder,
            this.propertyForms.at(indexInFormArray).get('property_type').value,
            null
        );
        if (generatedExtra) {
            const formGroup = this.propertyForms.at(indexInFormArray) as FormGroup;
            formGroup.removeControl('extra');
            addControlToFormGroup(formGroup, 'extra', generatedExtra);
        }
        this.propertyForms.at(indexInFormArray).patchValue({ value: null });
    }

    filterOutAttachmentProps() {
        this.element.properties = this.element.properties.filter(
            (p) =>
                !this.propertyService.isImageType(p, 'value') &&
                !this.propertyService.isAttachmentType(p, 'value')
        );
    }

    preCommon() {
        this.getValuesFromForm();
        this.element.properties.sort((a, b) => a.order_by - b.order_by);
    }

    preSave() {
        this.preCommon();
        const haveError = this.propertiesErrorHandling();
        this.filterOutAttachmentProps();
        return !haveError;
    }

    postSave() {
        const anyAttachment = this.propertyService.hasAnyAttachment(this.propertyForms, 'value');
        if (anyAttachment) {
            this.navigateAfterSave = () => {};
            this.complexUpdate();
        }
    }

    preUpdate() {
        this.preCommon();
        const haveError = this.propertiesErrorHandling();
        return !haveError;
    }

    // TODO toast se codice gara gia in uso
    complexUpdate() {
        const promises = this.propertyService.checkAndUploadImagesAndAttachmnts(
            this.propertyForms,
            this.element,
            'value'
        );
        if (promises) {
            Promise.all(promises).then((_) => this.updateContainer());
        } else {
            this.updateContainer();
        }
    }

    updateContainer(): void {
        this.getValuesFromForm();
        this.containerService.customUpdateContainer(this.oldCode, this.element).subscribe({
            next: (container) => {
                this.element = container;
                this.postUpdate();
                this.navigateAfterUpdate();
            },
            error: (error) => {
                this.addError('Impossibile completare la modifica. ' + (error?.msg ?? error ?? ''));
                this.saveError();
            },
        });
    }

    propertiesErrorHandling() {
        const errorCheck = this.propertyService.havePropertiesErrors(this.element.properties);
        if (errorCheck.haveError) {
            this.addError(errorCheck.msg);
        }
        return errorCheck.haveError;
    }

    valueChangedFromChild({ value, index }) {
        this.propertyForms.at(index).patchValue({ value });
    }

    getId() {
        return this.element.codiceGara;
    }

    createInstance(): Container {
        return new Container();
    }

    confirmDelete() {
        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () => {
                this.delete();
            },
        });
    }

    addArticles() {
        this.router.navigate([`/${this.path}/${this.element.codiceGara}/aggiungi-articoli`]);
    }

    addCatalogues() {
        this.router.navigate([`/${this.path}/${this.element.codiceGara}/aggiungi-cataloghi`]);
    }

    get name() {
        return this.registerForm.get('name');
    }

    get codiceGara() {
        return this.registerForm.get('codiceGara');
    }

    get description() {
        return this.registerForm.get('description');
    }

    get reference() {
        return this.registerForm.get('reference');
    }

    get entity_type() {
        return this.registerForm.get('entity_type');
    }

    get sector() {
        return this.registerForm.get('sector');
    }

    get delivery_address() {
        return this.registerForm.get('delivery_address');
    }

    get deadline() {
        return this.registerForm.get('deadline');
    }

    get bidder() {
        return this.registerForm.get('bidder');
    }

    get notes() {
        return this.registerForm.get('notes');
    }

    get prorogation() {
        return this.registerForm.get('prorogation');
    }

    get portfolioCustomer() {
        return this.registerForm.get('portfolioCustomer');
    }

    get propertyForms() {
        return this.registerForm.get('properties') as FormArray;
    }
}
