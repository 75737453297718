import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { SystemEventLogService } from '../../service/system-event-log.service';

import { AbstractListComponent } from '../../common/abstract-list-component';
import { SystemEventLog } from '../../model/system-event-log';
import { takeUntil } from 'rxjs/operators';
import { Table } from 'primeng/table/table';

@Component({
    selector: 'app-system-event-log-list',
    templateUrl: './system-event-log-list.component.html',
})
export class SystemEventLogListComponent extends AbstractListComponent<SystemEventLog>
    implements OnInit {
    element: SystemEventLog;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected systemEventLogService: SystemEventLogService
    ) {
        super(router, systemEventLogService, 'system_event_logs');
    }

    ngOnInit() {
        this.loading = true;
        this.element = new SystemEventLog();
    }

    newElement(): SystemEventLog {
        return super.newElement();
    }

    delete(element: SystemEventLog) {
        this.service
            .delete(element.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.loaddata(true));
    }

    public reload(datatable: Table) {
        super.reload(datatable);
        this.service.search = {
            ...this.service.search,
            from: {
                ...this.service.search.from,
                received_date: this.service.search.from?.received_date?.split('T')[0],
            },
            to: {
                ...this.service.search.to,
                received_date: this.service.search.to?.received_date?.split('T')[0],
            },
        };
    }

    getId() {
        return this.element.uuid;
    }

    createInstance(): SystemEventLog {
        return new SystemEventLog();
    }

    appendHourToDate() {
        this.service.search = {
            ...this.service.search,
            from: {
                ...this.service.search.from,
                received_date: this.service.search.from.received_date
                    ? `${this.service.search?.from?.received_date}T00:00:00`
                    : null,
            },
            to: {
                ...this.service.search.to,
                received_date: this.service.search.to.received_date
                    ? `${this.service.search?.to?.received_date}T23:59:59`
                    : null,
            },
        };
    }
}
