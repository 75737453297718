<p-toast></p-toast>
<div class="loading-indicator" *ngIf="loading">
  <p-progressSpinner strokeWidth="3"></p-progressSpinner>
</div>
<ng-container *ngIf="updateLoading">
  <app-custom-loader></app-custom-loader>
</ng-container>
<div class="main-content clearfix" [ngClass]="status ? '' : 'with-sidebar'" *ngIf="!loading">
  <div class="list-wrapper clearfix">
    <div class="row">
      <div class="col-sm-6">
        <h4>
          <i aria-hidden="true" class="fa fa-file-text"></i>
          Scheda Articolo
        </h4>
        <br />
      </div>
      <div class="col-sm-6">
        <div class="btn-group pull-right">
          <button
            type="button"
            class="btn btn-success"
            *ngIf="!element.uuid"
            (click)="save()"
            [disabled]="registerForm.invalid"
          >
            Salva
          </button>
          <button
            type="button"
            class="btn btn-success"
            *ngIf="element.uuid"
            (click)="update()"
            [disabled]="registerForm.invalid"
          >
            <!-- complexUpdateWithoutRedirect -->
            Aggiorna
            <i class="pi pi-spinner" *ngIf="noRedirectLoading"></i>
          </button>
          <button type="button" class="btn btn-warning" (click)="navigateToList()">Annulla</button>
          <button
            *ngIf="element.uuid"
            type="button"
            class="btn btn-danger"
            (click)="confirmDelete()"
          >
            <i class="fa fa-trash"></i>
            Elimina
          </button>

          <button
            *ngIf="fromEntity === 'gare'"
            type="button"
            class="btn btn-warning"
            (click)="goToContainer()"
          >
            <i class="fa fa-arrow-left"></i>
            Torna alla gestione articoli
          </button>

          <button class="btn btn-default pull-right" (click)="clickEvent()">
            {{ buttonName }}
          </button>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="content clearfix">
      <div class="row">
        <div class="col-sm-12">
          <form [formGroup]="registerForm">
            <div class="panel panel-default">
              <div class="panel-heading">Informazioni</div>

              <div class="panel-body view">
                <div class="col-sm-12">
                  <div class="clearfix"></div>
                  <div class="form-group row clearfix">
                    <div class="clearfix"></div>
                    <div class="col-sm-12">
                      <p-confirmDialog icon="fa fa-question-circle" #cd>
                        <footer>
                          <button
                            type="button"
                            pButton
                            icon="ui-icon-undo"
                            label="No"
                            (click)="cd.reject()"
                          ></button>
                          <button
                            type="button"
                            pButton
                            icon="ui-icon-check"
                            label="Sì"
                            (click)="cd.accept()"
                          ></button>
                        </footer>
                      </p-confirmDialog>
                      <div class="row">
                        <div class="form-group col-sm-4 clearfix">
                          <label class="label-text" [attr.id]="'codartinterno'">
                            Cod. Art. Interno
                            <span *ngIf="internal_code | isFieldRequired">(*)</span>
                            :
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            pInputText
                            placeholder="Cod. Art. Interno"
                            formControlName="internal_code"
                          />
                        </div>

                        <div class="form-group col-sm-4 clearfix">
                          <label class="label-text" [attr.id]="'codarticolo'">
                            Cod. Articolo
                            <span *ngIf="name | isFieldRequired">(*)</span>
                            :
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            pInputText
                            placeholder="Cod. Articolo"
                            formControlName="name"
                          />
                        </div>

                        <div class="form-group col-sm-4 clearfix">
                          <label class="label-text" [attr.id]="'descrizionearticolo'">
                            Descrizione articolo
                            <span *ngIf="description | isFieldRequired">(*)</span>
                            :
                          </label>
                          <textarea
                            rows="4"
                            class="form-control"
                            placeholder="descrizione"
                            formControlName="description"
                            [style]="{
                              height: '100px',
                              'max-height': '100px',
                              'max-width': '100%'
                            }"
                            pInputTextarea
                            [autoResize]="true"
                          ></textarea>
                        </div>

                        <div class="form-group col-sm-4 clearfix">
                          <label class="label-text" [attr.id]="'codicegara'">
                            Codice Gara
                            <span *ngIf="codiceGara | isFieldRequired">(*)</span>
                            :
                          </label>
                          <p-dropdown
                            [options]="codiciGaraItems"
                            [showClear]="true"
                            formControlName="codiceGara"
                            placeholder="Codice Gara"
                            [style]="{ width: '100%' }"
                          ></p-dropdown>
                        </div>

                        <div class="form-group col-sm-4 clearfix">
                          <label class="label-text" [attr.id]="'tags'">
                            Tags
                            <span *ngIf="tags | isFieldRequired">(*)</span>
                            :
                          </label>

                          <p-chips formControlName="tags"></p-chips>
                        </div>

                        <div class="form-group col-sm-4 clearfix">
                          <label class="label-text" [attr.id]="'stato'">
                            Stato
                            <span *ngIf="state | isFieldRequired">(*)</span>
                            :
                          </label>

                          <p-dropdown
                            [options]="stateItems"
                            formControlName="state"
                            placeholder="Stato"
                            id="state"
                            [style]="{ width: '100%' }"
                          ></p-dropdown>
                        </div>

                        <div class="form-group col-sm-4 clearfix">
                          <label class="label-text" [attr.id]="'tipologia'">
                            Tipologia
                            <span *ngIf="tipology | isFieldRequired">(*)</span>
                            :
                          </label>

                          <div class="ui-inputgroup">
                            <span
                              class="ui-inputgroup-addon btn-update"
                              style="cursor: pointer;"
                              (click)="confirmRefresh()"
                            >
                              <i class="fa fa-refresh" aria-hidden="true"></i>
                            </span>
                            <p-dropdown
                              [options]="tipologyItems"
                              (onChange)="PVAL_displayProperties($event)"
                              formControlName="tipology"
                              placeholder="Tipologia"
                              id="tipology"
                              [style]="{ width: '100%' }"
                            ></p-dropdown>
                          </div>
                        </div>

                        <div class="form-group col-sm-4 clearfix">
                          <label class="label-text">Archiviato :</label>

                          <div>
                            <p-inputSwitch
                              (onChange)="onChangeArchived($event)"
                              [ngModel]="archivedVal"
                              [ngModelOptions]="{ standalone: true }"
                              inputStyleClass="form-control"
                              id="archived"
                            ></p-inputSwitch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="panel panel-default">
              <div class="panel-heading">Proprietà</div>

              <div class="panel-body view">
                <div formArrayName="properties">
                  <div class="row p-grid">
                    <div
                      class="form-group cleafix p-col-4"
                      *ngFor="
                        let property of propertyForms.controls | orderBy: 'value.order_by';
                        let i = index
                      "
                      [formGroupName]="i"
                    >
                      <app-property-valorization
                        [property]="property"
                        [locale]="langIt"
                        [i]="i"
                        [iconsItems]="iconsItems"
                      ></app-property-valorization>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="fixed-sidebar" [ngClass]="status ? 'hidden' : ''">
            <div class="panel panel-default">
              <div class="panel-heading">Opzioni</div>

              <div
                *ngIf="element.uuid"
                class="p-d-flex"
                style="padding: 15px 15px 0; justify-content: space-between;"
              >
                <button
                  type="button"
                  class="btn btn-group"
                  (click)="goToPrevArticle()"
                  [disabled]="articleIndex === 0"
                  [disabled]="registerForm.invalid"
                >
                  Prev
                </button>

                <button
                  type="button"
                  class="btn btn-group"
                  (click)="goToNextArticle()"
                  [disabled]="registerForm.invalid"
                >
                  Next
                </button>
              </div>

              <div class="panel-body view">
                <form [formGroup]="myGroup" (ngSubmit)="onFilterFields()">
                  <div class="form-group">
                    <input
                      [(ngModel)]="query"
                      placeholder="cerca nel link rapido"
                      [style]="{ width: '100%' }"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </form>

                <ul class="list-group-properties" #propertyList>
                  <li
                    *ngIf="printableFields.get('internal_code').includes(query) || !query"
                    class="list-group-item-properties"
                  >
                    <a
                      (click)="goToFragment('codartinterno')"
                      [ngStyle]="{
                        'font-weight': registerForm.get('internal_code')?.value?.length
                          ? 'bold'
                          : 'initial',
                        color: registerForm.get('internal_code')?.value?.length
                          ? 'black'
                          : 'inherit'
                      }"
                    >
                      {{ printableFields.get('internal_code') }}
                    </a>
                  </li>

                  <li
                    *ngIf="printableFields.get('name').includes(query) || !query"
                    class="list-group-item-properties"
                  >
                    <a
                      (click)="goToFragment('codarticolo')"
                      [ngStyle]="{
                        'font-weight': registerForm.get('name')?.value?.length ? 'bold' : 'initial',
                        color: registerForm.get('name')?.value?.length ? 'black' : 'inherit'
                      }"
                    >
                      {{ printableFields.get('name') }}
                    </a>
                  </li>

                  <li
                    *ngIf="printableFields.get('description').includes(query) || !query"
                    class="list-group-item-properties"
                  >
                    <a
                      (click)="goToFragment('descrizionearticolo')"
                      [ngStyle]="{
                        'font-weight': registerForm.get('description')?.value?.length
                          ? 'bold'
                          : 'initial',
                        color: registerForm.get('description')?.value?.length ? 'black' : 'inherit'
                      }"
                    >
                      {{ printableFields.get('description') }}
                    </a>
                  </li>

                  <li
                    *ngIf="printableFields.get('codicegara').includes(query) || !query"
                    class="list-group-item-properties"
                  >
                    <a
                      (click)="goToFragment('codicegara')"
                      [ngStyle]="{
                        'font-weight': registerForm.get('codiceGara')?.value?.length
                          ? 'bold'
                          : 'initial',
                        color: registerForm.get('codiceGara')?.value?.length ? 'black' : 'inherit'
                      }"
                    >
                      {{ printableFields.get('codicegara') }}
                    </a>
                  </li>

                  <li
                    *ngIf="printableFields.get('tags').includes(query) || !query"
                    class="list-group-item-properties"
                  >
                    <a
                      (click)="goToFragment('tags')"
                      [ngStyle]="{
                        'font-weight': registerForm.get('tags')?.value?.length ? 'bold' : 'initial',
                        color: registerForm.get('tags')?.value?.length ? 'black' : 'inherit'
                      }"
                    >
                      {{ printableFields.get('tags') }}
                    </a>
                  </li>

                  <li
                    *ngIf="printableFields.get('state').includes(query) || !query"
                    class="list-group-item-properties"
                  >
                    <a
                      (click)="goToFragment('stato')"
                      [ngStyle]="{
                        'font-weight': registerForm.get('state')?.value?.length
                          ? 'bold'
                          : 'initial',
                        color: registerForm.get('state')?.value?.length ? 'black' : 'inherit'
                      }"
                    >
                      {{ printableFields.get('state') }}
                    </a>
                  </li>

                  <li
                    *ngIf="printableFields.get('tipology').includes(query) || !query"
                    class="list-group-item-properties"
                  >
                    <a
                      (click)="goToFragment('tipologia')"
                      [ngStyle]="{
                        'font-weight': registerForm.get('tipology')?.value?.uuid
                          ? 'bold'
                          : 'initial',
                        color: registerForm.get('tipology').value?.uuid ? 'black' : 'inherit'
                      }"
                    >
                      {{ printableFields.get('tipology') }}
                    </a>
                  </li>

                  <li
                    *ngIf="printableFields.get('archived').includes(query) || !query"
                    class="list-group-item-properties"
                  >
                    <a
                      (click)="goToFragment('archived')"
                      [ngStyle]="{
                        'font-weight': !registerForm.get('active')?.value ? 'bold' : 'initial',
                        color: !registerForm.get('active')?.value ? 'black' : 'inherit'
                      }"
                    >
                      {{ printableFields.get('archived') }}
                    </a>
                  </li>

                  <ng-container
                    *ngFor="let prop of propertyForms.value | orderBy: 'name'; let i = index"
                  >
                    <li
                      class="list-group-item-properties"
                      *ngIf="printableFields.get(prop.name.toLowerCase())"
                    >
                      <div
                        *ngIf="
                          printableFields.get(prop.name.toLowerCase()).includes(query) || !query
                        "
                      >
                        <a
                          (click)="goToFragment(prop.name)"
                          [ngStyle]="{
                            'font-weight':
                              propertyForms.at(i)?.value?.length ||
                              propertyForms.at(i)?.value?.value ||
                              propertyForms.at(i)?.value?.uuid
                                ? 'bold'
                                : 'initial',
                            color:
                              propertyForms.at(i)?.value?.length ||
                              propertyForms.at(i)?.value?.value ||
                              propertyForms.at(i)?.value?.uuid
                                ? 'black'
                                : 'inherit'
                          }"
                        >
                          {{ printableFields.get(prop.name.toLowerCase()) }}
                        </a>
                      </div>
                    </li>
                  </ng-container>

                  <!-- <ng-template
                    ngFor
                    let-prop
                    [ngForOf]="propertyForms.value "
                    let-i="index"
                  >
                    <li
                      class="list-group-item-properties"
                      *ngIf="printableFields.get(prop.name.toLowerCase())"
                    >
                      <div
                        *ngIf="
                          printableFields.get(prop.name.toLowerCase()).includes(query) || !query
                        "
                      >
                        <a
                          (click)="goToFragment(prop.name)"
                          [ngStyle]="{
                            'font-weight':
                              propertyForms.at(i)?.value?.length ||
                              propertyForms.at(i)?.value?.value ||
                              propertyForms.at(i)?.value?.uuid
                                ? 'bold'
                                : 'initial',
                            color:
                              propertyForms.at(i)?.value?.length ||
                              propertyForms.at(i)?.value?.value ||
                              propertyForms.at(i)?.value?.uuid
                                ? 'black'
                                : 'inherit'
                          }"
                        >
                          {{ printableFields.get(prop.name.toLowerCase()) }}
                        </a>
                      </div>
                    </li>
                  </ng-template> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
