<ng-container *ngIf="service">
  <p-overlayPanel #op [dismissable]="true" appendTo="body" styleClass="clearfix large">
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group cleafix">
          <input
            class="form-control"
            pInputText
            type="text"
            name="internal_code"
            placeholder="Cod. Art. Interno"
            [(ngModel)]="service.search.like.internal_code"
          />
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group cleafix">
          <input
            class="form-control"
            pInputText
            type="text"
            name="name"
            placeholder="Cod. Articolo"
            [(ngModel)]="service.search.like.name"
          />
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group cleafix">
          <input
            class="form-control"
            pInputText
            type="description"
            name="description"
            placeholder="Descrizione"
            [(ngModel)]="service.search.like.description"
          />
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group cleafix">
          <input
            class="form-control"
            pInputText
            type="codiceGara"
            name="codiceGara"
            placeholder="Codice Gara"
            [(ngModel)]="service.search.like.codiceGara"
          />
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group cleafix">
          <p-autoComplete
            [ngModel]="selectedCategory"
            [suggestions]="categoryItems"
            (completeMethod)="filterCategory($event)"
            field="name"
            placeholder="Categoria..."
            (onSelect)="emitSelectedFilters({ category: $event })"
            (onClear)="clearCategory()"
            [style]="{ width: '100%' }"
            [dropdown]="true"
          ></p-autoComplete>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group cleafix">
          <p-autoComplete
            [(ngModel)]="selectedTipology"
            [suggestions]="tipologyItems"
            (completeMethod)="filterTipology($event)"
            field="name"
            placeholder="Tipologia..."
            (onSelect)="emitSelectedFilters({ tipology: $event })"
            (onClear)="clearTipology()"
            [style]="{ width: '100%' }"
            [dropdown]="true"
          ></p-autoComplete>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group p-d-flex p-jc-between p-ai-center">
          <label>Congelato</label>
          <p-inputSwitch
            [(ngModel)]="service.search.obj.closed"
            inputStyleClass="form-control"
          ></p-inputSwitch>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group p-d-flex p-jc-between p-ai-center">
          <label>Archiviato</label>
          <p-inputSwitch
            (onChange)="onFilterArchived($event)"
            [ngModel]="archivedFilter"
            inputStyleClass="form-control"
          ></p-inputSwitch>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 offset-sm-4">
        <div class="form-group cleafix">
          <button
            type="button"
            class="btn btn-success btn-md btn-block"
            (click)="cerca.emit(); op.hide()"
          >
            Cerca
          </button>
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group cleafix">
          <button
            type="button"
            class="btn btn-default btn-md btn-block"
            (click)="
              onFilterArchived({ checked: false });
              reset.emit();
              clearCategory();
              clearTipology();
              op.hide()
            "
          >
            Reset
          </button>
        </div>
      </div>
    </div>

    <app-article-property-filter
      #propertyFilter
      [service]="service"
      [tipologies]="tipologies"
      [tipologyItems]="tipologyItems"
      [locale]="locale"
      [isOpOpen]="op.visible"
    ></app-article-property-filter>
  </p-overlayPanel>
</ng-container>
