import { Injector, Type } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { TipologyService } from '../service/tipology.service';
/*THIS SERVICE IS NOT INJECTABLE, instanceable only with the 'new' operator */
export class TableUtilsService {
    public errorMessage: string;
    public colspan = 3;
    public loadingTable: boolean;
    public model: any[] = [];
    public dataService;
    public tipologyService;

    constructor(public injector: Injector, public DataServiceClass: Type<any>) {
        this.dataService = this.injector.get(DataServiceClass);
        this.tipologyService = this.injector.get(TipologyService);
    }

    lazyLoad(event: LazyLoadEvent, datatable?: any) {
        this.dataService.search.startRow = event.first;
        this.dataService.search.pageSize = event.rows;

        this.preLoad(event);
        this.loaddata(false, datatable);
    }

    preLoad(event: LazyLoadEvent, datatable?: any) {
        if (event.sortField) {
            this.dataService.search.orderBy =
                event.sortField + (event.sortOrder > 0 ? ' ASC' : ' DESC');
        }
        this.manageFilters(event);
    }

    manageFilters(event: LazyLoadEvent) {}

    loaddata(firstReload: boolean, datatable?: any) {
        this.preLoaddata();
        this.dataService.getList().subscribe(
            (dataList) => {
                this.model = dataList as any[];
                this.loadingTable = false;
                this.postLoadData();
            },
            (error) => {
                this.errorMessage = error;
                this.loadingTable = false;
            }
        );
    }

    postLoadData() {}

    preLoaddata() {}

    refresh(datatable: Table) {
        // this.clearMsgs();
        datatable.reset();
    }

    reload(datatable: Table) {
        this.dataService.search.startRow = 0;
        this.refresh(datatable);
    }

    reset(datatable: Table) {
        this.dataService.buildSearch();
        this.refresh(datatable);
    }
}
