import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Search } from '../common/search';
import { Printable } from '../model/printable';

import { MessageService } from 'primeng/api';
import { ConfigurationService } from './configuration.service';
import { PrintableService } from './printable.service';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MAX_PAGE_SIZE, PRINTABLES_API_PATH } from '../constants/constants';

@Injectable({
    providedIn: 'root',
})
export class ArticleService extends PrintableService {
    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        configurationService: ConfigurationService
    ) {
        super(httpClient, messageService, configurationService);
    }

    public buildSearch() {
        this.search = new Search<Printable>(Printable);
        // this.search.obj.objectType = 'ARTICLE';
    }

    public getSingleArticle(search: Search<Printable>, url = this.url) {
        let params = new HttpParams();

        params = this.applyRestrictions(params, search);

        return this.httpClient
            .get<HttpResponse<Printable[]>>(url, {
                observe: 'response',
                params,
            })
            .pipe(
                map((res) => {
                    const ts: any = res.body;
                    this.postList(ts);
                    return ts;
                }),
                catchError(this.handleError)
            );
    }

    getMaxArticles(): Observable<Printable[]> {
        let localSearch = new Search<Printable>(Printable);
        localSearch = { ...this.search };
        localSearch.pageSize = MAX_PAGE_SIZE;
        localSearch.startRow = 0;

        let params = new HttpParams();
        params = this.applyRestrictions(params, localSearch);
        return this.httpClient.get<Printable[]>(this.url, { params });
    }
}
