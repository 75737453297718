import { Component, OnInit, Input } from '@angular/core';
import { FormGroupDirective, ControlContainer, FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api/selectitem';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-property-valorization',
    templateUrl: './property-valorization.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    styleUrls: ['./property-valorization.component.css'],
})
export class PropertyValorizationComponent implements OnInit {
    @Input() property: FormGroup;
    @Input() i: number;
    @Input() iconsItems: SelectItem[];
    @Input() locale: {};
    @Input() hideLabel;
    @Input() printableUuid: string;

    listableValues: SelectItem[];
    visible: boolean = false;

    constructor(private parentF: FormGroupDirective) {}

    ngOnInit(): void {
        console.log('property', this.property);

        setTimeout(() => (this.visible = true), 0);

        if (!this.locale) {
            throw new Error('Locale for calendar must be defined!');
        }
    }

    listableValuesLoad(obs$: Observable<SelectItem[]>) {
        obs$.subscribe((listableValues) => (this.listableValues = [...listableValues]));
    }
}
