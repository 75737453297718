import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '../../service/category.service';
import { ConfirmationService } from 'primeng/api';

import { AbstractListComponent } from '../../common/abstract-list-component';
import { Category } from '../../model/category';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.css'],
})
export class CategoryListComponent extends AbstractListComponent<Category> implements OnInit {
    element: Category;
    action = 'Salva';
    registerForm: FormGroup;
    archivedFilter = false;
    archivedVal = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected categoryService: CategoryService,
        private formBuilder: FormBuilder,
        private confirmationService: ConfirmationService
    ) {
        super(router, categoryService, 'categories');
    }

    ngOnInit() {
        this.loading = true;
        this.element = new Category();
        this.element.active = true;
        this.buildForm();
        this.archivedFilter = this.service.search.obj.active === false;
    }

    private buildForm() {
        this.registerForm = this.formBuilder.group({
            name: [this.element.name, Validators.required],
            description: [this.element.description],
            active: [this.element.active],
        });
    }

    private getValuesFromForm() {
        this.element.description = this.registerForm.get('description').value;
        this.element.name = this.registerForm.get('name').value;
        this.element.active = this.registerForm.get('active').value;
    }

    newElement(): Category {
        return super.newElement();
    }

    restart() {
        this.action = 'Salva';
        this.element = new Category();
        this.buildForm();
    }

    save() {
        this.getValuesFromForm();
        this.service
            .persist(this.element)
            .pipe(takeUntil(this.destroy$))
            .subscribe((user) => {
                this.restart();
                this.loaddata(true);
            });
    }

    update() {
        this.getValuesFromForm();
        this.action = 'Salva';
        this.service
            .update(this.element)
            .pipe(takeUntil(this.destroy$))
            .subscribe((user) => {
                this.restart();
                this.loaddata(true);
            });
    }

    edit(element: Category) {
        this.element = element;
        this.archivedVal = !this.element.active;
        this.buildForm();
        this.action = 'Aggiorna';
        return;
    }

    delete(element: Category) {
        this.service
            .delete(element.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((del) => this.loaddata(true));
    }

    confirmDelete(element: Category) {
        this.action = 'Salva';
        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () => {
                this.delete(element);
            },
        });
    }

    public new() {
        this.router.navigate(['/' + this.path + '/list']);
    }

    getId() {
        return this.element.uuid;
    }

    createInstance(): Category {
        return new Category();
    }

    get description() {
        return this.registerForm.get('description');
    }
    get name() {
        return this.registerForm.get('name');
    }

    onFilterArchived(event: any) {
        this.archivedFilter = event.checked;
        this.service.search.obj.active = event.checked ? false : null;
    }

    onChangeArchived(event) {
        console.log(event);
        this.archivedVal = event.checked;
        this.registerForm.patchValue({ active: !event.checked });
    }
}
