<p-toast></p-toast>
<p-overlayPanel #op [dismissable]="true" appendTo="body" styleClass="clearfix">
  <div class="col-sm-12">
    <div class="form-group row cleafix">
      <input
        class="form-control"
        pInputText
        type="text"
        name="name"
        placeholder="Nome"
        [(ngModel)]="service.search.like.name"
      />
    </div>

    <div class="form-group row cleafix">
      <input
        class="form-control"
        pInputText
        type="text"
        name="container_uuid"
        placeholder="Codice Gara"
        [(ngModel)]="service.search.like.container_uuid"
        [disabled]="service.search.not.container_uuid"
      />
    </div>

    <div class="form-group row cleafix">
      <input
        class="form-control"
        pInputText
        type="tags"
        name="tags"
        placeholder="Tags"
        [(ngModel)]="service.search.like.tags"
      />
    </div>

    <div class="form-group row cleafix">
      <input
        class="form-control"
        pInputText
        type="description"
        name="description"
        placeholder="Descrizione"
        [(ngModel)]="service.search.like.description"
      />
    </div>

    <div class="form-group cleafix" style="display: flex; justify-content: space-between;">
      <label>Cataloghi senza codice gara</label>
      <p-inputSwitch
        [ngModel]="service.search.not.container_uuid"
        (onChange)="handleFilterSenzaCodiceGara($event)"
        inputStyleClass="form-control"
      ></p-inputSwitch>
    </div>

    <div class="form-group cleafix" style="display: flex; justify-content: space-between;">
      <label>Archiviato</label>
      <p-inputSwitch
        (onChange)="onFilterArchived($event)"
        [ngModel]="archivedFilter"
        inputStyleClass="form-control"
      ></p-inputSwitch>
    </div>
  </div>

  <div class="form-group cleafix">
    <div class="row">
      <div class="col-sm-6">
        <button type="button" class="btn btn-success btn-block" (click)="reload(table); op.hide()">
          Cerca
        </button>
      </div>
      <div class="col-sm-6">
        <button
          type="button"
          class="btn btn-default btn-block"
          (click)="archivedFilter = false; reset(table); op.hide()"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</p-overlayPanel>

<div class="main-content clearfix">
  <div class="list-wrapper clearfix">
    <div class="content clearfix">
      <div class="row">
        <div class="col-sm-8">
          <h4>
            <i aria-hidden="true" class="fa fa-th-list"></i>
            Cataloghi
          </h4>
        </div>
        <div class="col-sm-4">
          <button class="btn btn-add pull-right" type="button" (click)="new()" label="">
            <i class="fa fa-plus-circle" aria-hidden="true"></i>
            Aggiungi
          </button>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="clearfix"></div>
      <div class="filter-wrapper clearfix">
        <button
          type="text"
          class="btn btn-filter"
          label="Basic"
          appendTo="body"
          (click)="op.toggle($event)"
        >
          <i class="fa fa-sliders" aria-hidden="true"></i>
          Cerca
        </button>

        <div class="tags-wrapper">
          <div class="tag-item" *ngIf="service.search.like.name">
            <strong>nome:</strong>
            {{ service.search.like.name }}
            <button (click)="service.search.like.name = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.like.container_uuid">
            <strong>codice gara:</strong>
            {{ service.search.like.container_uuid }}
            <button
              (click)="service.search.like.container_uuid = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.like.tags">
            <strong>tags:</strong>
            {{ service.search.like.tags }}
            <button (click)="service.search.like.tags = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="tag-item" *ngIf="service.search.like.description">
            <strong>descrizione:</strong>
            {{ service.search.like.description }}
            <button
              (click)="service.search.like.description = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.not.container_uuid">
            <strong>senza codice gara:</strong>
            {{ service.search.not.container_uuid ? 'Sì' : 'No' }}
            <button
              (click)="service.search.not.container_uuid = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="archivedFilter">
            <strong>archiviato:</strong>
            {{ archivedFilter ? 'Si' : 'No' }}
            <button
              (click)="archivedFilter = false; service.search.obj.active = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-body list">
          <div class="row">
            <p-table
              [value]="model"
              [lazy]="true"
              [loading]="loading"
              (onLazyLoad)="lazyLoad($event)"
              [paginator]="true"
              [responsive]="true"
              [rows]="service.search.pageSize"
              [totalRecords]="service.listSize"
              [resizableColumns]="true"
              stateStorage="session"
              [stateKey]="path + '_table'"
              #table
            >
              <ng-template pTemplate="header" let-columns class="search-filter">
                <tr class="sort-header">
                  <th class="RowIndex"></th>
                  <th pResizableColumn [pSortableColumn]="'name'">
                    Nome
                    <p-sortIcon [field]="'name'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'container_uuid'">
                    Codice Gara
                    <p-sortIcon [field]="'container_uuid'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'description'">
                    Descrizione
                    <p-sortIcon [field]="'description'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'tags'">
                    Tags
                    <p-sortIcon [field]="'tags'"></p-sortIcon>
                  </th>
                  <th pResizableColumn [pSortableColumn]="'active'">
                    Archiviato
                    <p-sortIcon [field]="'active'"></p-sortIcon>
                  </th>
                  <th pResizableColumn></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="colspan">Nessun risultato.</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                  <td class="RowIndex">{{ rowIndex + 1 }}</td>
                  <td class="ui-resizable-column">
                    {{ rowData.name }}
                  </td>
                  <td class="ui-resizable-column">
                    {{ rowData.container_uuid }}
                  </td>
                  <td class="ui-resizable-column">
                    {{ rowData.description }}
                  </td>
                  <td class="ui-resizable-column">
                    {{ rowData.tags }}
                  </td>
                  <td class="ui-resizable-column">
                    <i class="fa fa-{{ !rowData.active ? 'check' : 'times' }}"></i>
                  </td>
                  <td class="ui-resizable-column">
                    <div class="btn-group">
                      <button
                        type="button"
                        pButton
                        pTooltip="Modifica"
                        tooltipPosition="top"
                        (click)="edit(rowData)"
                        class="btn btn-success btn-sm"
                        icon="fa fa-edit"
                      ></button>
                      <button
                        type="button"
                        pButton
                        (click)="addArticles(rowData.uuid)"
                        tooltipPosition="top"
                        pTooltip="Aggiungi Articoli"
                        icon="fa fa-file-text"
                        class="btn btn-primary btn-sm"
                      ></button>
                      <button
                        type="button"
                        pButton
                        pTooltip="Visualizza"
                        tooltipPosition="top"
                        (click)="view(rowData)"
                        class="btn btn-info btn-sm"
                        icon="fa fa-eye"
                      ></button>
                      <button
                        type="button"
                        pButton
                        pTooltip="Clona"
                        tooltipPosition="top"
                        (click)="clone(rowData)"
                        class="btn btn-default btn-sm"
                        icon="fa fa-clone"
                      ></button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
