import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root',
})
export class NoUserGuard extends KeycloakAuthGuard {
    constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
        super(router, keycloakAngular);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!this.authenticated) {
                this.keycloakAngular.login();
                return;
            }

            const allowedRoles = ['admin', 'manager'];
            if (!this.roles || this.roles.length === 0) {
                resolve(false);
            }
            let granted = false;
            for (const allowedRole of allowedRoles) {
                if (this.roles.indexOf(allowedRole) > -1) {
                    granted = true;
                    break;
                }
            }
            resolve(granted);
        });
    }
}
