import { Validators } from '@angular/forms';
import { Printable } from '../model/printable';
import { Tipology } from '../model/tipology';
import { PrintTemplate } from '../model/print-template';
import { Catalogue } from '../model/catalogue';
import { Container } from '../model/container';
import { PropertyValue } from '../model/property-value';
import { ListableService } from '../service/listable.service';

export function manipulatePropertyBeforeFormBuild(
    property: PropertyValue,
    propertyValueName: string,
    listableService: ListableService
) {
    if (property.property_type === 'LISTABLE' && property?.extra?.listableName) {
        property.extra.listableValuesUrl = listableService.listValuesAsItems(
            property.extra.listableName
        );
    } else if (property.property_type === 'DATE_VALUE') {
        property[propertyValueName] = property[propertyValueName]
            ? new Date(property[propertyValueName])
            : new Date();
    }
}

export function manipulateProperties(
    element: Printable | Tipology | PrintTemplate | Catalogue | Container,
    propertyName,
    attachmentService
) {
    element.properties.map((p) => {
        if (p.property_type === 'BARCODE') {
            p[propertyName] = attachmentService.generateUrlBarcode(
                p.extra.code,
                p.extra.type,
                p.extra.width,
                p.extra.height
            );
        } else if (
            p.property_type === 'BOOL_VALUE' &&
            (p[propertyName] === undefined || p[propertyName] === null)
        ) {
            p[propertyName] = false;
        } else if (
            p.property_type === 'SUMMABLE' ||
            p.property_type === 'SOTTRAIBILE' ||
            p.property_type === 'MULTIPLIABLE' ||
            p.property_type === 'DIVISIBLE'
        ) {
            const operandOneProperty = element.properties.find(
                (tempP) => tempP.name === p.extra.operandOne
            );
            const operandTwoProperty = element.properties.find(
                (tempP) => tempP.name === p.extra.operandTwo
            );

            if (!operandOneProperty || !operandTwoProperty) {
                return;
            }
            if (p.property_type === 'SUMMABLE') {
                p.value = `${p?.extra?.operandOne} + ${p?.extra?.operandTwo}`;
                p.default_value = `${p?.extra?.operandOne} + ${p?.extra?.operandTwo}`;
            } else if (p.property_type === 'SOTTRAIBILE') {
                p.value = `${p?.extra?.operandOne} - ${p?.extra?.operandTwo}`;
                p.default_value = `${p?.extra?.operandOne} - ${p?.extra?.operandTwo}`;
            } else if (p.property_type === 'MULTIPLIABLE') {
                p.value = `${p?.extra?.operandOne} * ${p?.extra?.operandTwo}`;
                p.default_value = `${p?.extra?.operandOne} * ${p?.extra?.operandTwo}`;
            } else if (p.property_type === 'DIVISIBLE') {
                p.value = `${p?.extra?.operandOne} / ${p?.extra?.operandTwo}`;
                p.default_value = `${p?.extra?.operandOne} / ${p?.extra?.operandTwo}`;
            }
        } else if (p.property_type === 'GLOBAL_SUMMABLE') {
            if (p.property_type === 'GLOBAL_SUMMABLE') {
                p.value = `${p?.extra?.operandOne}`;
                p.default_value = `${p?.extra?.operandOne}`;
            }
        } else if (p.property_type === 'GROUPABLE') {
            if (p.property_type === 'GROUPABLE') {
                p.value = `${p?.extra?.propertyName}`;
                p.default_value = `${p?.extra?.propertyName}`;
            }
        } else if (p.property_type === 'GROUP_SUMMABLE') {
            if (p.property_type === 'GROUP_SUMMABLE') {
                p.value = `Ragruppa per ${p?.extra?.propertyName} Operazione sulla proprietà: ${p?.extra?.operandOne}`;
                p.default_value = `Ragruppa per ${p?.extra?.propertyName} Operazione sulla proprietà: ${p?.extra?.operandOne}`;
            }
        } else if (p.property_type == 'LONG_TEXT') {
            if (p.value) {
                p.value = p.value.replace(/&nbsp;/g, ' ');
                if (p.value.includes('<br>')) {
                    p.value = p.value.replaceAll('<br>', '<br/>');
                }
                if (p.value.includes('</br>')) {
                    p.value = p.value.replaceAll('</br>', '<br/>');
                }
            }
        }
    });
}

function isNotObjectEmpty(obj) {
    return (
        obj !== null &&
        obj !== undefined &&
        Object.entries(obj).length > 0 &&
        obj.constructor === Object
    );
}

export function generateExtra(formBuilder, propertyType, persistedExtra) {
    let extra;
    switch (propertyType) {
        case 'BARCODE': {
            const newExtra = isNotObjectEmpty(persistedExtra)
                ? persistedExtra
                : {
                      code: ['', Validators.required],
                      width: [0, Validators.required],
                      height: [0, Validators.required],
                      type: ['', Validators.required],
                  };
            extra = formBuilder.group(newExtra);
            return extra;
        }
        case 'LONG_TEXT': {
            const newExtra = isNotObjectEmpty(persistedExtra)
                ? persistedExtra
                : {
                      max_length: null,
                  };
            extra = formBuilder.group(newExtra);
            return extra;
        }
        case 'ATTACH_VALUE': {
            const newExtra = isNotObjectEmpty(persistedExtra)
                ? persistedExtra
                : {
                      fileName: 'fileName',
                  };
            extra = formBuilder.group(newExtra);
            return extra;
        }
        case 'MULTISELECT': {
            const newExtra = isNotObjectEmpty(persistedExtra)
                ? {
                      choices: [persistedExtra.choices],
                      choicesAsSelectItems: [
                          persistedExtra.choices.map((c) => ({
                              label: c,
                              value: c,
                          })),
                      ],
                  }
                : {
                      choices: [[]],
                      choicesAsSelectItems: [[]],
                  };
            extra = formBuilder.group(newExtra);
            return extra;
        }
        case 'LISTABLE': {
            const newExtra = isNotObjectEmpty(persistedExtra)
                ? {
                      listableName: persistedExtra.listableName,
                      listableValuesUrl: persistedExtra.listableValuesUrl,
                  }
                : {
                      listableName: null,
                      listableValuesUrl: null,
                  };
            extra = formBuilder.group(newExtra);
            return extra;
        }
        case 'MULTIPLIABLE':
        case 'DIVISIBLE':
        case 'SOTTRAIBILE':
        case 'SUMMABLE': {
            const newExtra = isNotObjectEmpty(persistedExtra)
                ? {
                      operandOne: [persistedExtra.operandOne, Validators.required],
                      operandTwo: [persistedExtra.operandTwo, Validators.required],
                  }
                : {
                      operandOne: [null, Validators.required],
                      operandTwo: [null, Validators.required],
                  };
            extra = formBuilder.group(newExtra);
            return extra;
        }
        case 'GLOBAL_SUMMABLE': {
            const newExtra = isNotObjectEmpty(persistedExtra)
                ? {
                      operandOne: [persistedExtra.operandOne, Validators.required],
                      tipology_uuid: [persistedExtra.tipology_uuid, Validators.required],
                  }
                : {
                      operandOne: [null, Validators.required],
                      tipology_uuid: [null, Validators.required],
                  };
            extra = formBuilder.group(newExtra);
            return extra;
        }
        case 'GROUPABLE': {
            const newExtra = isNotObjectEmpty(persistedExtra)
                ? {
                      propertyName: [persistedExtra.propertyName, Validators.required],
                      tipology_uuid: [persistedExtra.tipology_uuid, Validators.required],
                  }
                : {
                      propertyName: [null, Validators.required],
                      tipology_uuid: [null, Validators.required],
                  };
            extra = formBuilder.group(newExtra);
            return extra;
        }
        case 'GROUP_SUMMABLE': {
            const newExtra = isNotObjectEmpty(persistedExtra)
                ? {
                      propertyName: [persistedExtra.propertyName, Validators.required],
                      operandOne: [persistedExtra.operandOne, Validators.required],
                      tipology_uuid: [persistedExtra.tipology_uuid, Validators.required],
                  }
                : {
                      propertyName: [null, Validators.required],
                      operandOne: [null, Validators.required],
                      tipology_uuid: [null, Validators.required],
                  };
            extra = formBuilder.group(newExtra);
            return extra;
        }
        default:
            return false;
    }
}

export function updateExtra(propertyType, oldExtra, newExtra) {
    let extra;
    switch (propertyType) {
        case 'BARCODE': {
            extra = {
                ...newExtra,
                code: oldExtra.code,
            };
            return extra;
        }

        default:
            return { ...newExtra };
    }
}

export function addControlToFormGroup(formGroup, controlName, control) {
    formGroup.addControl(controlName, control);
}
