<div class="navbar" *ngIf="showMenu()">
  <ul>
    <li routerLink="/homepage" routerLinkActive="active">
      <a>
        <i class="fa fa-home" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Homepage</p>
      </a>
    </li>
    <li routerLink="/gare/list" routerLinkActive="active">
      <a>
        <i class="fa fa-tachometer" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Gare</p>
      </a>
    </li>
    <li routerLink="/catalogues/list" routerLinkActive="active">
      <a>
        <i class="fa fa-th-list" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Cataloghi</p>
      </a>
    </li>
    <li routerLink="/articles/list" routerLinkActive="active">
      <a>
        <i class="fa fa-file-text" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Articoli</p>
      </a>
    </li>
    <li routerLink="/pages/list" routerLinkActive="active">
      <a>
        <i class="fa fa-file" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Pagine</p>
      </a>
    </li>
    <li
      routerLink="/tipologies/list"
      routerLinkActive="active"
      *ngIf="roles.includes('manager') || roles.includes('admin')"
    >
      <a>
        <i class="fa fa fa-book" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Tipologie</p>
      </a>
    </li>
    <li
      routerLink="/templates/list"
      routerLinkActive="active"
      *ngIf="roles.includes('manager') || roles.includes('admin')"
    >
      <a>
        <i class="fa fa-address-card-o" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Templates</p>
      </a>
    </li>
    <li routerLink="/listables/list" routerLinkActive="active">
      <a>
        <i class="fa fa-list" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Listabili</p>
      </a>
    </li>
    <li routerLink="/categories/list" routerLinkActive="active">
      <a>
        <i class="fa fa-tasks" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Categorie</p>
      </a>
    </li>
    <li routerLink="/gare/modifica-massiva-proprieta" routerLinkActive="active">
      <a>
        <i class="fa fa-pencil section-icon" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Massiva</p>
      </a>
    </li>

    <li routerLink="/system_event_logs/list" routerLinkActive="active">
      <a>
        <i class="fa fa-copy section-icon" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Log di sistema</p>
      </a>
    </li>

    <li routerLink="/print_logs/list" routerLinkActive="active">
      <a>
        <i class="fa fa-print section-icon" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Log di stampa</p>
      </a>
    </li>

    <li routerLink="/import" routerLinkActive="active">
      <a>
        <i class="fa fa-upload section-icon" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Importazioni</p>
      </a>
    </li>

    <li (click)="logout()">
      <a>
        <i class="fa fa-sign-out" aria-hidden="true"></i>
        <div class="clearfix"></div>
        <p class="category">Logout</p>
      </a>
    </li>
  </ul>
</div>
