import { propertyType } from './enum/property-type';

export class PropertyValue {
    public property_type: propertyType;
    public value: any;
    public default_value: any;
    public order_by: number;
    public name: string;
    public label: string;
    public mandatory: boolean;
    public extra: any;
    public isDefinitionMode?: boolean;
    public colspan: number;
    public styleClass: string;

    constructor() {}
}
