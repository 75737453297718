<div [formGroup]="property" [attr.id]="property.value?.name | special">
  <div class="form-group clearfix" id="home0">
    <div *ngIf="!hideLabel">
      <label>
        {{ property.value?.label }}
        <span *ngIf="property.controls.value | isFieldRequired">(*)</span>
        :
      </label>
    </div>
    <div [ngSwitch]="property.value.property_type">
      <input
        *ngSwitchCase="'STR_VALUE'"
        class="form-control"
        pInputText
        type="text"
        name="value"
        placeholder="value"
        formControlName="value"
      />
      <p-calendar
        *ngSwitchCase="'DATE_VALUE'"
        [locale]="locale"
        [dateFormat]="'dd/mm/yy'"
        showButtonBar="true"
        clearButtonStyleClass="btn ui-button-text-only"
        todayButtonStyleClass="btn ui-button-text-only"
        inputStyleClass="form-control"
        [style]="{ width: '100%' }"
        formControlName="value"
      ></p-calendar>
      <div *ngSwitchCase="'LONG_TEXT'">
        <p-editor
          formControlName="value"
          [style]="{ height: '320px' }"
          appStopEditorFocus
          [p-editor-model]="model"
          #model
        >
          <p-header>
            <span class="ql-formats">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button type="button" class="ql-italic" aria-label="Italic"></button>
              <button type="button" class="ql-underline" aria-label="Underline"></button>
              <button type="button" class="ql-list" aria-label="List" value="bullet"></button>
            </span>
          </p-header>
        </p-editor>

        <!-- <editor
          formControlName="value"
          [init]="{
            entity_encoding: 'numeric',
            plugins: 'table lists',

            menubar: 'false',
            element_format: 'xhtml',
            height: 200,
            toolbar:
              'table | removeformat | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist'
          }"
          *ngIf="visible"
        ></editor> -->

        <!-- <textarea
          class="form-control"
          pInputTextarea
          type="text"
          name="value"
          placeholder="value"
          formControlName="value"
          maxlength="{{ property?.value?.extra?.max_length }}"
        ></textarea> -->
        <div *ngIf="property.value?.extra?.max_length" formGroupName="extra">
          <span
            *ngIf="property.value.extra?.max_length && property.value?.value"
            class="pull-right char"
          >
            <em>
              {{ property.value.value.length + ' / ' + property.value.extra?.max_length }}
            </em>
          </span>
        </div>
      </div>
      <p-inputSwitch
        *ngSwitchCase="'BOOL_VALUE'"
        formControlName="value"
        inputStyleClass="form-control"
      ></p-inputSwitch>
      <input
        *ngSwitchCase="'NUMBER_VALUE'"
        pInputText
        type="number"
        name="value"
        placeholder="numero"
        formControlName="value"
      />
      <p-colorPicker
        *ngSwitchCase="'COLOR_VALUE'"
        formControlName="value"
        name="color"
      ></p-colorPicker>
      <p-chips *ngSwitchCase="'TUPLE_VALUE'" formControlName="value"></p-chips>
      <p-dropdown
        *ngSwitchCase="'ICON_VALUE'"
        [options]="iconsItems"
        formControlName="value"
        filter="true"
        [style]="{ width: '100%' }"
        appendTo="body"
      >
        <ng-template let-icon pTemplate="selectedItem">
          <i class="{{ icon.value }}"></i>
          <span style="vertical-align: middle; margin-left: 0.5em;">
            {{ icon.label }}
          </span>
        </ng-template>
        <ng-template let-icon pTemplate="item">
          <div class="ui-helper-clearfix" style="position: relative; height: 25px;">
            <div>
              <i class="{{ icon.value }}"></i>
              {{ icon.label }}
            </div>
          </div>
        </ng-template>
      </p-dropdown>
      <div *ngSwitchCase="'IMAGE_VALUE'">
        <app-file-upload
          [(currentImageUrl)]="property.value.value"
          [indexOnArrayForm]="i"
          formControlName="value"
          [printableUuid]="printableUuid"
        ></app-file-upload>
      </div>
      <div *ngSwitchCase="'ATTACH_VALUE'">
        <app-attachment-upload
          [(currentAttachmentUrl)]="property.value.value"
          [(currentAttachmentName)]="property.value.extra.fileName"
          [indexOnArrayForm]="i"
          formControlName="value"
          [property]="property"
        ></app-attachment-upload>
      </div>
      <div *ngSwitchCase="'BARCODE'">
        <div formGroupName="extra">
          <input
            class="form-control"
            pInputText
            type="text"
            name="code"
            placeholder="codice"
            formControlName="code"
          />
        </div>
      </div>
      <p-multiSelect
        *ngSwitchCase="'MULTISELECT'"
        [options]="property?.value?.extra?.choicesAsSelectItems"
        defaultLabel="Scegli uno o più valori"
        formControlName="value"
        id="multiselect"
        [style]="{ width: '100%' }"
      ></p-multiSelect>
      <div *ngSwitchCase="'LISTABLE'">
        <app-listable-valorization
          [data$]="property?.value?.extra?.listableValuesUrl"
          [listableValues]="listableValues"
          [group]="property"
          [name]="property?.value?.extra?.listableName"
          (loadData)="listableValuesLoad($event)"
        ></app-listable-valorization>
      </div>
      <input
        *ngSwitchCase="'SUMMABLE'"
        value="{{ property.value.extra?.operandOne + ' + ' + property.value.extra?.operandTwo }}"
        pInputText
        disabled
      />
      <input
        *ngSwitchCase="'SOTTRAIBILE'"
        value="{{ property.value.extra?.operandOne + ' - ' + property.value.extra?.operandTwo }}"
        pInputText
        disabled
      />
      <input
        *ngSwitchCase="'MULTIPLIABLE'"
        value="{{ property.value.extra?.operandOne + ' * ' + property.value.extra?.operandTwo }}"
        pInputText
        disabled
      />
      <input
        *ngSwitchCase="'DIVISIBLE'"
        value="{{ property.value.extra?.operandOne + ' / ' + property.value.extra?.operandTwo }}"
        pInputText
        disabled
      />
      <input
        *ngSwitchCase="'GLOBAL_SUMMABLE'"
        value="{{ property.value.extra?.operandOne }}"
        pInputText
        disabled
      />
      <input
        *ngSwitchCase="'GROUPABLE'"
        value="raggruppa per {{property?.value?.extra?.propertyName}"
        pInputText
        disabled
      />
      <input
        *ngSwitchCase="'GROUP_SUMMABLE'"
        value="raggruppa per {{
          property?.value?.extra?.propertyName
        }} Operazione sulla proprietà: {{ property.value.extra?.operandOne }}"
        pInputText
        disabled
      />
      <div *ngSwitchDefault class="col-sm-12">N.A.</div>
    </div>
  </div>
</div>
