import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PrintTemplateService } from '../../service/print-template.service';

import { AbstractViewComponent } from '../../common/abstract-view-component';
import { PrintTemplate, PrintTemplateFormat, PrintTemplateType } from '../../model/print-template';
import { FormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { propertyTypesPrintableItems } from 'src/app/model/enum/property-type';
import { printTemplateTypeMap } from '../../model/print-template';

@Component({
    selector: 'app-print-template-view',
    templateUrl: './print-template-view.component.html',
    styleUrls: ['./print-template-view.component.css'],
})
export class PrintTemplateViewComponent extends AbstractViewComponent<PrintTemplate>
    implements OnInit {
    element: PrintTemplate;
    propertyTypesMap: Map<string, string> = new Map<string, string>();
    registerForm: FormGroup;
    objectTypeItems: SelectItem[] = [];
    printTemplateTypeMap: Map<PrintTemplateType, string> = printTemplateTypeMap;
    openAllTabs = false;
    editorOptions = {
        language: 'html',
        minimap: {
            enabled: false,
        },
        automaticLayout: true,
        readOnly: true,
    };

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected printTemplateService: PrintTemplateService
    ) {
        super(router, route, printTemplateService, 'templates');
        this.element = new PrintTemplate();
    }

    ngOnInit() {
        super.ngOnInit();
        propertyTypesPrintableItems.map((t) => this.propertyTypesMap.set(t.value, t.label));
    }

    fileName(uuid: string, printTemplateFormat: PrintTemplateFormat): string {
        switch (printTemplateFormat) {
            case 'PDF':
                return uuid + '.pdf';
            case 'CSV':
                return uuid + '.csv';
            case 'XLS':
                return uuid + '.xls';
            default:
        }
    }

    mimeType(printTemplateFormat: PrintTemplateFormat): string {
        switch (printTemplateFormat) {
            case 'PDF':
                return 'application/pdf';
            case 'CSV':
                return 'text/csv';
            case 'XLS':
                return 'application/excel';
            default:
        }
    }

    print(uuidTemplate) {
        this.printTemplateService
            .getPdf(uuidTemplate)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (response) => {
                    const filenameDownload = this.fileName(
                        this.element.uuid,
                        this.element.printTemplateFormat
                    );
                    const newBlob = new Blob([response], {
                        type: this.mimeType(this.element.printTemplateFormat),
                    });
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob, filenameDownload);
                    } else {
                        const a = document.createElement('a');
                        a.href = URL.createObjectURL(newBlob);
                        a.setAttribute('download', filenameDownload);
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                },
                (err) => {
                    this.service.addMessage({
                        severity: 'error',
                        summary: 'Errore',
                        detail: err.message,
                    });
                }
            );
    }

    getId() {
        return this.element.uuid;
    }

    createInstance(): PrintTemplate {
        return new PrintTemplate();
    }
}
