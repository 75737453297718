import { Component } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../service/page.service';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';

import { PrintableListComponent } from '../printable/printable-list.component';
import { Printable } from '../../model/printable';
import { ListableService } from 'src/app/service/listable.service';
import { statesAsArray } from 'src/app/model/enum/state';

@Component({
    selector: 'app-page-list',
    templateUrl: './page-list.component.html',
    styleUrls: ['./page-list.component.css'],
})
export class PageListComponent extends PrintableListComponent {
    archivedFilter = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected pageService: PageService,
        protected categoryService: CategoryService,
        protected tipologyService: TipologyService,
        protected listableService: ListableService
    ) {
        super(
            router,
            route,
            'pages',
            pageService,
            categoryService,
            tipologyService,
            listableService
        );
    }

    ngOnInit() {
        this.archivedFilter = this.service.search.obj.active === false;

        statesAsArray.map((s) => {
            this.stateItems.push({ label: s, value: s });
        });
    }

    createInstance(): Printable {
        const printable: Printable = new Printable();
        printable.objectType = 'PAGE';
        return printable;
    }

    onFilterArchived(event: any) {
        this.archivedFilter = event.checked;
        this.service.search.obj.active = event.checked ? false : null;
    }
}
