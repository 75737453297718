import { Component, OnInit } from '@angular/core';
import { RoutingStateService } from './service/routing-state.service';
import { KeycloakService } from 'keycloak-angular';
import { ConfigurationService } from './service/configuration.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    title = 'eggs-gestione-cataloghi-ng';
    username: string;
    roles: string[];
    primaryRole: string;
    appVersion: string;

    constructor(
        protected routingStateService: RoutingStateService,
        protected keycloakService: KeycloakService,
        protected configurationService: ConfigurationService
    ) {
        routingStateService.loadRouting();
    }

    ngOnInit() {
        this.username = this.keycloakService.getUsername();
        this.roles = this.keycloakService.getUserRoles();

        console.log('USER PROFILE', this.roles);
        this.configurationService.getValue('version').subscribe((version) => {
            this.appVersion = version ?? null;
        });
        this.setPrimaryRole();
    }

    setPrimaryRole() {
        if (this.roles) {
            if (this.roles.includes('admin')) {
                this.primaryRole = 'Admin';
                return;
            }
            if (this.roles.includes('user')) {
                this.primaryRole = 'Utente';
                return;
            }
            if (this.roles.includes('manager')) {
                this.primaryRole = 'Manager';
                return;
            }
        }
    }
}
