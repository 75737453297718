<p-toast></p-toast>
<ng-container *ngIf="isTableDataSourceDeterminated">
  <p-confirmDialog icon="fa fa-question-circle" #cd>
    <footer>
      <button type="button" pButton icon="ui-icon-undo" label="No" (click)="cd.reject()"></button>
      <button type="button" pButton icon="ui-icon-check" label="Sì" (click)="cd.accept()"></button>
    </footer>
  </p-confirmDialog>

  <p-overlayPanel #op [dismissable]="true" appendTo="body" styleClass="clearfix">
    <div class="form-group cleafix">
      <input
        class="form-control"
        pInputText
        type="text"
        name="name"
        placeholder="Nome"
        [(ngModel)]="tableUtilsService.dataService.search.like.name"
      />
    </div>
    <div class="form-group cleafix">
      <input
        class="form-control"
        pInputText
        type="text"
        name="description"
        placeholder="Descrizione"
        [(ngModel)]="tableUtilsService.dataService.search.like.description"
      />
    </div>
    <div class="form-group cleafix">
      <p-dropdown
        [options]="codiciGaraItems"
        [(ngModel)]="tableUtilsService.dataService.search.obj.codiceGara"
        placeholder="Codice Gara"
        [style]="{ width: '100%' }"
      ></p-dropdown>
    </div>
    <div class="form-group cleafix">
      <div class="row">
        <div class="col-sm-6">
          <button
            type="button"
            class="btn btn-success btn-block"
            (click)="tableUtilsService.reload(table); op.hide()"
          >
            Cerca
          </button>
        </div>
        <div class="col-sm-6">
          <button
            type="button"
            class="btn btn-default btn-block"
            (click)="tableUtilsService.reset(table); op.hide()"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </p-overlayPanel>

  <div class="main-content clearfix">
    <div class="list-wrapper clearfix">
      <div class="content clearfix">
        <form [formGroup]="registerForm">
          <div class="form-group row clearfix">
            <div class="col-sm-6">
              <h4>
                Seleziona Articoli / Pagine
                <span *ngIf="catalogueElements | isFieldRequired">(*)</span>
              </h4>
              <div class="clearfix"></div>
              <br />
              <div class="clearfix"></div>
              <div class="panel panel-default clearfix">
                <div class="panel-body view clearfix">
                  <div class="clearfix"></div>
                  <div class="filter-wrapper clearfix">
                    <button
                      type="text"
                      class="btn btn-filter"
                      label="Basic"
                      appendTo="body"
                      (click)="op.toggle($event)"
                    >
                      <i class="fa fa-sliders" aria-hidden="true"></i>
                      Cerca
                    </button>

                    <div class="tags-wrapper">
                      <div class="tag-item" *ngIf="tableUtilsService.dataService.search.like.name">
                        <strong>nome:</strong>
                        {{ tableUtilsService.dataService.search.like.name }}
                        <button
                          (click)="
                            tableUtilsService.dataService.search.like.name = null;
                            tableUtilsService.reload(table)
                          "
                          class="btn-close"
                        >
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                      </div>

                      <div
                        class="tag-item"
                        *ngIf="tableUtilsService.dataService.search.like.description"
                      >
                        <strong>Descrizione:</strong>
                        {{ tableUtilsService.dataService.search.like.description }}
                        <button
                          (click)="
                            tableUtilsService.dataService.search.like.description = null;
                            tableUtilsService.reload(table)
                          "
                          class="btn-close"
                        >
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                      </div>

                      <div
                        class="tag-item"
                        *ngIf="tableUtilsService.dataService.search.obj.codiceGara"
                      >
                        <strong>codice gara:</strong>
                        {{ tableUtilsService.dataService.search.obj.codiceGara }}
                        <button
                          (click)="
                            tableUtilsService.dataService.search.obj.codiceGara = null;
                            tableUtilsService.reload(table)
                          "
                          class="btn-close"
                        >
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p-table
                      [value]="tableUtilsService.model"
                      [loading]="tableUtilsService.loadingTable"
                      [lazy]="true"
                      (onLazyLoad)="tableUtilsService.lazyLoad($event)"
                      [paginator]="true"
                      [rowsPerPageOptions]="[10, 50, 100]"
                      [responsive]="true"
                      [rows]="tableUtilsService.dataService.search.pageSize"
                      [totalRecords]="tableUtilsService.dataService.listSize"
                      [resizableColumns]="true"
                      #table
                    >
                      <ng-template pTemplate="header" let-columns class="search-filter">
                        <tr class="sort-header">
                          <th pResizableColumn [pSortableColumn]="'name'">
                            Nome
                            <p-sortIcon [field]="'name'"></p-sortIcon>
                          </th>

                          <th pResizableColumn [pSortableColumn]="'codiceGara'">
                            Codice Gara
                            <p-sortIcon [field]="'codiceGara'"></p-sortIcon>
                          </th>

                          <th class="Operations">
                            <ng-container *ngIf="element.monopage; else elseBlock">
                              <div
                                class="clearfix"
                                [pTooltip]="
                                  enableAddAllButton
                                    ? 'Aggiungi tutti gli articoli della gara filtrata'
                                    : 'Bisogna filtrare prima una gara'
                                "
                              >
                                <button
                                  pButton
                                  type="button"
                                  (click)="addAllPrintables()"
                                  [disabled]="!enableAddAllButton"
                                  class="btn btn-add pull-right"
                                >
                                  <i class="fa fa-plus"></i>
                                  Tutti
                                </button>
                              </div>
                            </ng-container>
                            <ng-template #elseBlock>Templates</ng-template>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td [attr.colspan]="tableUtilsService.colspan">
                            Nessun Articolo / Pagina.
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                        <tr>
                          <td class="ui-resizable-column">
                            {{ rowData.name }}
                          </td>
                          <td class="ui-resizable-column">
                            {{ rowData.codiceGara }}
                          </td>
                          <td>
                            <ng-container *ngIf="!element.monopage">
                              <div *ngFor="let template of rowData.templates">
                                <div class="template-row">
                                  {{ template.name }}
                                  <button
                                    type="button"
                                    pButton
                                    (click)="addNewCatalogueElement(rowData, template.uuid)"
                                    class="btn-add-catalogue"
                                    icon="fa fa-plus"
                                  ></button>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="element.monopage">
                              <div class="template-row">
                                <button
                                  [disabled]="!element.monopage_template_uuid"
                                  type="button"
                                  pButton
                                  (click)="
                                    addNewCatalogueElement(rowData, element.monopage_template_uuid)
                                  "
                                  class="btn-add-catalogue"
                                  icon="fa fa-plus"
                                ></button>
                              </div>
                            </ng-container>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <h4>Contenuto Catalogo</h4>
              <div class="clearfix"></div>
              <br />
              <div class="clearfix"></div>
              <div class="panel panel-default clearfix">
                <div class="panel-body view clearfix">
                  <p>Ordina le pagine selezionate nella sequenza desiderata</p>

                  <p-table [value]="catalogueElementForms.value" [reorderableColumns]="true">
                    <ng-template pTemplate="header" let-columns class="search-filter">
                      <tr class="sort-header">
                        <th style="width: 2.5em;"></th>
                        <th>
                          Articolo
                        </th>
                        <th>
                          {{ !element.monopage ? 'Template' : '' }}
                        </th>
                        <th>
                          {{ !element.monopage ? 'Salta pagina prima' : '' }}
                        </th>
                        <th>
                          {{ !element.monopage ? 'Salta pagina dopo' : '' }}
                        </th>
                        <th></th>
                      </tr>
                    </ng-template>
                    <ng-template
                      pTemplate="body"
                      let-rowData
                      let-columns="columns"
                      let-index="rowIndex"
                    >
                      <tr [pReorderableRow]="index">
                        <td>
                          <i class="fa fa-bars" pReorderableRowHandle></i>
                        </td>
                        <td>
                          {{ printablesTranscode?.get(rowData.printable_uuid) }}
                        </td>
                        <td>
                          {{
                            !element.monopage ? templatesTranscode?.get(rowData.template_uuid) : ''
                          }}
                        </td>
                        <td>
                          <p-inputSwitch
                            *ngIf="!element.monopage"
                            [(ngModel)]="rowData.page_break_before"
                            [ngModelOptions]="{ standalone: true }"
                            inputStyleClass="form-control"
                          ></p-inputSwitch>
                        </td>
                        <td>
                          <p-inputSwitch
                            *ngIf="!element.monopage"
                            [(ngModel)]="rowData.page_break_after"
                            [ngModelOptions]="{ standalone: true }"
                            inputStyleClass="form-control"
                          ></p-inputSwitch>
                        </td>
                        <td>
                          <button
                            type="button"
                            pButton
                            class="btn btn-sm btn-trash pull-right"
                            (click)="deletecatalogueElement(index)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>

                  <div class="clearfix"></div>
                  <br />
                  <div class="clearfix"></div>
                  <div class="btn-group pull-right">
                    <button
                      type="button"
                      class="btn btn-success"
                      *ngIf="element.uuid"
                      (click)="update()"
                      [disabled]="registerForm.invalid"
                    >
                      Aggiorna
                    </button>
                    <button type="button" class="btn btn-warning" (click)="goBack()">
                      <i class="fa fa-arrow-left"></i>
                      Indietro
                    </button>
                    <button type="button" class="btn btn-default" (click)="view()">
                      Torna al riepilogo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
