import { AbstractService } from './abstract-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/api';
import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export abstract class AbstractViewComponent<T> implements OnInit, OnDestroy {
    public msgs: Message[] = [];

    public editMode = false;
    public element: T = null;
    protected destroy$: Subject<void> = new Subject<void>();

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected service: AbstractService<T>,
        public path: string
    ) {}

    ngOnInit() {
        const id: string = this.route.snapshot.params['id'];
        if (id) {
            this.editMode = true;
            this.service
                .find(id)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    (element) => {
                        this.element = element as T;
                        this.postFind();
                    },
                    (error) => {
                        this.addError(
                            'Errore nel caricamento dei dati.' + (error?.msg ?? error ?? '')
                        );
                    }
                );
        } else {
            this.addError('Errore nel caricamento dei dati.');
        }
    }

    postFind() {}

    goToList() {
        this.clearMsgs();
        this.navigateToList();
    }

    public clearMsgs() {
        this.msgs = [];
    }

    public addInfo(message: string) {
        this.msgs.push({
            severity: 'info',
            summary: 'Informazioni: ',
            detail: message,
        });
    }

    public addWarn(message: string) {
        this.msgs.push({
            severity: 'warn',
            summary: 'Attenzione: ',
            detail: message,
        });
    }

    public addError(message: string) {
        this.msgs.push({
            severity: 'error',
            summary: 'Errore: ',
            detail: message,
        });
        this.service.addMessage({
            severity: 'error',
            summary: 'Errore',
            detail: message,
        });
    }

    abstract getId();

    navigateToList() {
        this.router.navigate(['/' + this.path + '/list']);
    }

    public edit(element: T) {
        this.element = element;
        this.router.navigate(['/' + this.path + '/edit', this.getId()]);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
