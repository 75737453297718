import { Listable } from './listable';
import { Printable } from './printable';
import { PropertyValue } from './property-value';
export class Container {
    public codiceGara: string;
    public name: string;
    public description: string;
    public properties: PropertyValue[];
    public reference: string;
    public entity_type: string;
    public sector: Listable;
    public delivery_address: string;
    public deadline: string;
    public bidder: string;
    public notes: string;
    public prorogation: string;
    public portfolioCustomer: boolean;
    public closed: boolean;
    public archived: boolean;

    public printables?: Printable[];

    constructor() {}
}
