import { Component, OnInit } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent {
    constructor(protected router: Router) {}
    goto(link: string) {
        console.log('link: ' + link);
        this.router.navigate(['/' + link]);
    }
}
