<p-dialog
  appendTo="body"
  header="Scegli un allegato"
  [(visible)]="isDialogVisible"
  [modal]="true"
  [draggable]="false"
  [contentStyle]="{ 'max-height': '50vh' }"
  [positionTop]="180"
  [style]="{ width: '850px', minWidth: '200px' }"
  [maximizable]="false"
  [baseZIndex]="10000"
>
  <app-existing-attachments-list
    *ngIf="isDialogVisible"
    (selectImage)="selectAttachment($event)"
  ></app-existing-attachments-list>
  <p-footer>
    <button
      type="button"
      (click)="isDialogVisible = false"
      style="padding: 0px 15px;"
      class="btn btn-danger"
    >
      Chiudi
    </button>
  </p-footer>
</p-dialog>

<div class="upload-box">
  <div class="btn-wrp" *ngIf="currentAttachmentUrl">
    <button (click)="confirmDelete()" class="btn-trash" type="button">
      <i class="fa fa-trash"></i>
    </button>
  </div>

  <span class="fake-btn" *ngIf="!currentAttachmentUrl">Seleziona allegato</span>
  <span class="file-msg" *ngIf="!currentAttachmentUrl">clicca o trascina il file qui</span>
  <div style="width: 100%;" *ngIf="currentAttachmentUrl">
    <span class="file-msg" *ngIf="currentAttachmentUrl.name">
      {{ currentAttachmentUrl.name }}
    </span>
    <div style="width: 100%;" class="image-box" *ngIf="!currentAttachmentUrl.name">
      <span style="width: 100%;" *ngIf="currentAttachmentName">
        {{ currentAttachmentName }}
      </span>
    </div>
  </div>

  <form [formGroup]="fileForm">
    <input formControlName="file" id="fileInput" class="file-input" type="file" />
  </form>

  <div class="btn-wrp">
    <button class="btn btn-default btn-catalogue" (click)="showDialog()" type="button">
      Prendi da articolo esistente
    </button>
  </div>
</div>

<p-confirmDialog icon="fa fa-question-circle" #cdDeleteAttachment [key]="generatedKey">
  <footer>
    <button
      type="button"
      pButton
      icon="fa fa-undo"
      class="btn-danger"
      label="No"
      (click)="cdDeleteAttachment.reject()"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      classyy="btn-success"
      label="Sì"
      (click)="cdDeleteAttachment.accept()"
    ></button>
  </footer>
</p-confirmDialog>
