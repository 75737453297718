<p-toast></p-toast>
<p-confirmDialog icon="fa fa-question-circle" #cd>
  <footer>
    <button type="button" pButton icon="ui-icon-undo" label="No" (click)="cd.reject()"></button>
    <button type="button" pButton icon="ui-icon-check" label="Sì" (click)="cd.accept()"></button>
  </footer>
</p-confirmDialog>

<div class="main-content clearfix">
  <form [formGroup]="registerForm">
    <div class="row">
      <div class="col-sm-8">
        <h4 *ngIf="element.uuid">
          <i aria-hidden="true" class="fa fa-address-card-o"></i>
          Aggiorna Template
        </h4>
        <h4 *ngIf="!element.uuid">
          <i aria-hidden="true" class="fa fa-address-card-o"></i>
          Crea un nuovo Template
        </h4>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="clearfix"></div>
    <div class="panel panel-default">
      <div class="panel-body view">
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Nome
              <span *ngIf="name | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="name"
              placeholder="Nome"
              formControlName="name"
            />
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Descrizione
              <span *ngIf="description | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="description"
              placeholder="Descrizione"
              formControlName="description"
            />
          </div>
        </div>

        <div class="clearfix"></div>

        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Tipo
              <span *ngIf="printTemplateType | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-dropdown
              [autoDisplayFirst]="false"
              [options]="printTemplateTypeAsItems"
              (onChange)="handleTypeSelect($event)"
              formControlName="printTemplateType"
              placeholder="Scegli Tipo...."
              id="printTemplateType"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
        </div>

        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Default
              <span *ngIf="default_template | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-inputSwitch
              formControlName="default_template"
              inputStyleClass="form-control"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="form-group row clearfix">
          <div class="col-sm-3">
            <label>Archiviato :</label>
          </div>

          <div class="col-sm-9">
            <p-inputSwitch
              (onChange)="onChangeArchived($event)"
              [ngModel]="archivedVal"
              [ngModelOptions]="{ standalone: true }"
              inputStyleClass="form-control"
              inputStyleClass="form-control"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="clearfix"></div>
        <div class="form-group row clearfix" *ngIf="printTemplateFormat">
          <div class="col-sm-3">
            <label>
              Formato
              <span *ngIf="printTemplateFormat | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-dropdown
              [options]="printTemplateFormatAsItems"
              (onChange)="handleFormatSelect($event)"
              formControlName="printTemplateFormat"
              placeholder="Scegli Formato...."
              id="printTemplateFormat"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix" *ngIf="false">
          <div class="col-sm-3">
            <label>
              Stile (CSS)
              <span *ngIf="style | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <ngx-monaco-editor
              class="form-control template-editor"
              [options]="cssEditorOptions"
              name="style"
              formControlName="style"
            ></ngx-monaco-editor>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix" *ngIf="first_page">
          <div class="col-sm-3">
            <label>
              Prima pagina
              <span *ngIf="first_page | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <ngx-monaco-editor
              class="form-control template-editor"
              [options]="editorOptions"
              name="first_page"
              formControlName="first_page"
            ></ngx-monaco-editor>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix" *ngIf="last_page">
          <div class="col-sm-3">
            <label>
              Ultima pagina
              <span *ngIf="last_page | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <ngx-monaco-editor
              class="form-control template-editor"
              [options]="editorOptions"
              name="last_page"
              formControlName="last_page"
            ></ngx-monaco-editor>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix" *ngIf="header">
          <div class="col-sm-3">
            <label>
              Header
              <span *ngIf="header | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <ngx-monaco-editor
              class="form-control template-editor"
              [options]="editorOptions"
              type="text"
              name="header"
              formControlName="header"
            ></ngx-monaco-editor>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix" *ngIf="page_content">
          <div class="col-sm-3">
            <label>
              Contenuto
              <span *ngIf="page_content | isFieldRequired">(*)</span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <ngx-monaco-editor
              class="form-control template-editor"
              [options]="editorOptions"
              name="page_content"
              formControlName="page_content"
            ></ngx-monaco-editor>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group row clearfix" *ngIf="footer">
          <div class="col-sm-3">
            <label>
              Footer
              <span *ngIf="footer | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <ngx-monaco-editor
              class="form-control template-editor"
              [options]="editorOptions"
              name="footer"
              formControlName="footer"
            ></ngx-monaco-editor>
          </div>
        </div>
        <div class="clearfix"></div>
        <div *ngIf="false" class="form-group row clearfix">
          <div class="col-sm-3">
            <label>
              Numero pagina
              <span *ngIf="with_number | isFieldRequired">(*)</span>
              :
            </label>
          </div>

          <div class="col-sm-9">
            <p-inputSwitch formControlName="with_number"></p-inputSwitch>
          </div>
        </div>

        <div class="clearfix"></div>
        <div formArrayName="properties">
          <div *ngFor="let property of propertyForms.controls; let i = index" [formGroupName]="i">
            <app-property-definition
              [property]="property"
              [i]="i"
              [iconsItems]="iconsItems"
              [barcodeTypesItems]="barcodeTypesItems"
              [listableNamesItems]="listableNamesItems$ | async"
              [propertyTypesItems]="propertyTypesTemplateItems"
              [propertyValueName]="'default_value'"
              [locale]="langIt"
              (deletePropertyEvent)="deleteProperty($event)"
              (valueChangedFromChildEvent)="valueChangedFromChild($event)"
              (propertyTypeSelectedEvent)="propertyTypeSelected($event)"
            ></app-property-definition>
          </div>
        </div>

        <div class="clearfix"></div>

        <div class="row">
          <div class="col-sm-12">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-success"
                [disabled]="printTemplateType.value !== 'CATALOGUE'"
                (click)="addEmptyProperty()"
              >
                <i class="fa fa-plus"></i>
                Aggiungi proprietà
              </button>
            </div>
            <div class="btn-group pull-right">
              <button
                type="button"
                class="btn btn-success"
                *ngIf="!element.uuid"
                (click)="save()"
                [disabled]="registerForm.invalid"
              >
                Salva
              </button>
              <button
                type="button"
                class="btn btn-success"
                *ngIf="element.uuid"
                (click)="complexUpdate()"
                [disabled]="registerForm.invalid"
              >
                Aggiorna
              </button>
              <button type="button" class="btn btn-default" (click)="goToList()">Annulla</button>
              <button
                *ngIf="element.uuid"
                type="button"
                class="btn btn-danger"
                (click)="confirmDelete()"
              >
                Elimina
              </button>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </form>
</div>
