import { Component, OnInit } from '@angular/core';
import { PrintableService } from 'src/app/service/printable.service';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/api/selectitem';
import { Tipology } from 'src/app/model/tipology';
import { TipologyService } from 'src/app/service/tipology.service';
import { PropertyService } from 'src/app/service/property.service';
import { langIt } from 'src/app/constants/calendar-it';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { MessageService } from 'primeng/api';
import * as R from 'ramda';
import { ContainerService } from 'src/app/service/container.service';
import { Container } from 'src/app/model/container';
import { map } from 'rxjs/operators';

@Component({
    selector: 'importazioni',
    templateUrl: './importazioni.component.html',
    styles: [
        '.loading-overlay {position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 1000; display: flex; justify-content: center; align-items: center; background: #28282899;}',
    ],
})
export class ImportazioniComponent implements OnInit {
    tipologiesAsItems$: Observable<SelectItem[]>;
    containersAsItems$: Observable<SelectItem[]>;
    downloadPropertiesAsItems: SelectItem[];
    uploadPropertiesAsItems: SelectItem[];

    selectedTipologyDownload: Tipology;
    selectedTipologyUpload: Tipology;
    selectedContainer: Container;

    selectedUploadProperties: string[];
    selectedDownloadProperties: string[];

    isLoading = false;
    isFileVerified = false;

    chosenFile: File = null;

    public langIt = { ...langIt };

    constructor(
        protected tipologyService: TipologyService,
        protected containerService: ContainerService,
        protected printableService: PrintableService,
        protected propertyService: PropertyService,
        protected routingStateService: RoutingStateService,
        protected messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.getTipologies();
        this.getContainers();
    }

    getTipologies() {
        this.tipologiesAsItems$ = this.tipologyService.getAllListAsItems();
    }

    getContainers() {
        this.containersAsItems$ = this.containerService
            .getAllListAsItems()
            .pipe(
                map((containers) => [{ label: 'Seleziona Gara...', value: null }, ...containers])
            );
    }

    getProperties() {
        const formatProps = R.pipe(
            R.defaultTo([]),
            R.map(({ label, name }) => ({ label, value: name })),
            R.sort((a: any, b: any) => a.label.localeCompare(b.label))
        );

        this.downloadPropertiesAsItems = formatProps(this.selectedTipologyDownload?.properties);
        this.uploadPropertiesAsItems = formatProps(this.selectedTipologyUpload?.properties);
    }

    tipologyDownloadSelected(event) {
        if (event.value) {
            this.clearSelectedDownloadProperties();
            this.selectedTipologyDownload = event.value;
            this.getProperties();
        }
    }

    tipologyUploadSelected(event) {
        if (event.value) {
            this.clearSelectedUploadProperties();
            this.selectedTipologyUpload = event.value;
            this.getProperties();
        }
    }

    containerSelected(event) {
        if (event.value) {
            this.selectedContainer = event.value;
        }
    }

    uploadPropertySelected(event) {
        if (event.value) {
            this.selectedUploadProperties = [...event.value];
        }
    }

    downloadPropertySelected(event) {
        if (event.value) {
            this.selectedDownloadProperties = [...event.value];
        }
    }

    addInfo(message: string) {
        this.messageService.add({
            severity: 'success',
            summary: 'Informazioni: ',
            detail: message,
        });
    }

    addError(message: string) {
        this.messageService.add({
            severity: 'error',
            summary: 'Errore',
            detail: message,
        });
    }

    // Download

    clearSelectedDownloadProperties() {
        this.selectedUploadProperties = null;
    }

    download() {
        const isPropSelected = R.pipe(
            R.prop('name'),
            R.includes(R.__, this.selectedDownloadProperties)
        );

        const selectedPropObjs = R.filter(isPropSelected, this.selectedTipologyDownload.properties);

        const payload = {
            tipology_uuid: this.selectedTipologyDownload.uuid,
            properties: selectedPropObjs,
        };

        this.isLoading = true;
        this.printableService.downloadTemplate(payload).subscribe({
            next: (resp) => {
                this.isLoading = false;
                const url = URL.createObjectURL(resp);

                const link = document.createElement('a');
                link.href = url;
                link.download = `Template_${payload.tipology_uuid}.xlsx`;
                link.click();

                this.selectedTipologyDownload = null;
                this.selectedDownloadProperties = [];
            },
            error: (err) => {
                this.isLoading = false;
                err.error.text().then((errText: string) => {
                    this.addError(errText ? JSON.parse(errText).msg : 'Errore');
                });
            },
        });
    }

    // Upload

    onChooseFile(event: any) {
        if (!this.selectedTipologyUpload?.uuid) return this.addError('Seleziona una tipologia');

        this.chosenFile = event.target.files[0];
        this.verify();
    }

    verify() {
        const payload = new FormData();
        payload.append('file', this.chosenFile);
        payload.append('filename', this.chosenFile.name);
        payload.append('mime_type', this.chosenFile.type);
        payload.append('tipology_uuid', this.selectedTipologyUpload.uuid);
        payload.append('container_uuid', this.selectedContainer?.codiceGara || null);

        this.isLoading = true;
        this.printableService.verify(payload).subscribe({
            next: (resp) => {
                //error
                this.isLoading = false;
                if (Object.keys(resp).length) {
                    this.isFileVerified = false;
                    this.addError(Object.values(resp)[0]);
                } else {
                    this.isFileVerified = true;
                    this.addInfo('Verifica avvenuta con successo');
                }
            },
            error: () => {
                this.isLoading = false;
                this.addError('Importazione avvenuta senza successo');
            },
        });
    }

    clearFile() {
        this.chosenFile = null;
    }

    upload() {
        if (!this.chosenFile || !this.selectedTipologyUpload) return;

        const payload = new FormData();
        payload.append('file', this.chosenFile);
        payload.append('filename', this.chosenFile.name);
        payload.append('mime_type', this.chosenFile.type);
        payload.append('tipology_uuid', this.selectedTipologyUpload.uuid);
        payload.append('container_uuid', this.selectedContainer?.codiceGara || null);
        payload.append(
            'tipology_propertiesvalues',
            (this.selectedUploadProperties || []).join(',')
        );

        this.isLoading = true;
        this.printableService.upload(payload).subscribe({
            next: (_) => {
                this.isLoading = this.isFileVerified = false;
                this.addInfo('Importazione avvenuta con successo');
                this.selectedTipologyUpload = null;
                this.selectedUploadProperties = [];
                this.selectedContainer = null;
                this.chosenFile = null;
            },
            error: (_) => {
                this.isLoading = false;
                this.addError('Importazione avvenuta senza successo');
            },
        });
    }

    clearSelectedUploadProperties() {
        this.selectedUploadProperties = null;
    }
}
