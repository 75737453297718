import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '../../service/page.service';
import { TipologyService } from '../../service/tipology.service';
import { CategoryService } from '../../service/category.service';
import { AttachmentService } from '../../service/attachment.service';

import { PrintableViewComponent } from '../printable/printable-view.component';
import { Printable } from '../../model/printable';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-page-view',
    templateUrl: './page-view.component.html',
    styleUrls: ['./page-view.component.css']
})
export class PageViewComponent extends PrintableViewComponent implements OnInit {
    includeAttachments = false;
    openAllTabs = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected tipologyService: TipologyService,
        protected categoryService: CategoryService,
        protected pageService: PageService,
        protected attachmentService: AttachmentService
    ) {
        super(
            router,
            route,
            'pages',
            tipologyService,
            categoryService,
            pageService,
            attachmentService
        );
    }

    createInstance(): Printable {
        const printable: Printable = new Printable();
        printable.objectType = 'PAGE';
        return printable;
    }

    printCustom(uuidTemplate) {
        if (this.includeAttachments === true) {
            this.printableService
                .getPdfWithAttachmetns(this.element.uuid, uuidTemplate)
                .pipe(takeUntil(this.destroy$))
                .subscribe(
                    response => {
                        const newBlob = new Blob([response], { type: 'application/pdf' });
                        const fileUrl = window.URL.createObjectURL(newBlob);
                        if (window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(
                                newBlob,
                                fileUrl.split(':')[1] + '.pdf'
                            );
                        } else {
                            window.open(fileUrl);
                        }
                    },
                    err => {
                        this.service.addMessage({
                            severity: 'error',
                            summary: 'Errore',
                            detail: err.message
                        });
                    }
                );
        } else {
            super.print(uuidTemplate);
        }
    }
}
