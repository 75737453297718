import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.css'],
})
export class HomepageComponent implements OnInit {
    roles: string[];
    constructor(private keycloakService: KeycloakService) {}

    ngOnInit() {
        this.roles = this.keycloakService.getUserRoles();
    }
}
