<p-dialog
  appendTo="body"
  [(visible)]="showAddListable"
  modal="true"
  closable="true"
  [showHeader]="true"
  [draggable]="false"
  class="col-picker-dialog"
  #pdialog
>
  <div class="panel panel-default">
    <div class="panel-body view">
      <div class="content clearfix">
        <form [formGroup]="registerForm">
          <div class="form-group row clearfix">
            <div class="col-sm-12">
              <label>Crea una nuova listabile</label>
              <div class="clearfix"></div>
              <hr />
            </div>

            <div class="clearfix"></div>
            <div class="col-sm-12">
              <div class="form-group row clearfix">
                <div class="col-sm-3">
                  <label>
                    Nome
                    <span *ngIf="registerForm.get('name') | isFieldRequired">(*)</span>
                    :
                  </label>
                </div>

                <div class="col-sm-9">
                  <input
                    class="form-control"
                    pInputText
                    type="text"
                    name="name"
                    placeholder="Nome"
                    formControlName="name"
                  />
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="form-group row clearfix">
                <div class="col-sm-3">
                  <label>Valore (*):</label>
                </div>
                <div class="col-sm-9">
                  <input
                    class="form-control"
                    pInputText
                    type="text"
                    name="value"
                    placeholder="Valore"
                    formControlName="value"
                  />
                </div>
              </div>
              <div class="clearfix"></div>

              <div class="btn-group pull-right">
                <button
                  type="button"
                  class="btn btn-success"
                  (click)="save()"
                  [disabled]="registerForm.invalid"
                >
                  Salva
                </button>
              </div>

              <div class="clearfix"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</p-dialog>

<div class="ui-inputgroup" [formGroup]="group">
  <span class="ui-inputgroup-addon btn-update" style="cursor: pointer;" (click)="addValues()">
    <i class="fa fa-plus" aria-hidden="true"></i>
  </span>
  <p-multiSelect
    [options]="listableValues"
    defaultLabel="Scegli uno o più valori"
    formControlName="value"
    id="listable"
    [style]="{ width: '100%' }"
  ></p-multiSelect>
</div>
