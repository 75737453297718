import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractService } from '../common/abstract-service';
import { Search } from '../common/search';
import { Category } from '../model/category';
import { CATEGORIES_API_PATH } from '../constants/constants';

import { MessageService, SelectItem } from 'primeng/api';
import { ConfigurationService } from './configuration.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CategoryService extends AbstractService<Category> {
    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        configurationService: ConfigurationService
    ) {
        super(configurationService.getValue(CATEGORIES_API_PATH), httpClient, messageService);
    }

    public getId(element: Category) {
        return element.uuid;
    }

    public buildSearch() {
        this.search = new Search<Category>(Category);
    }

    getAllListAsItems(): Observable<{
        map: Map<string, Category>;
        items: SelectItem[];
    }> {
        return this.getAllList().pipe(
            map(category => ({
                map: (() => {
                    const m = new Map<string, Category>();
                    category.map(c => m.set(c.uuid, c));
                    return m;
                })(),
                items: category.map(c => ({ label: c.name, value: c.uuid }))
            }))
        );
    }
}
