import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ContainerService } from '../../service/container.service';
import { CatalogueService } from '../../service/catalogue.service';
import { PrintableService } from '../../service/printable.service';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';

import { AbstractEditComponent } from '../../common/abstract-edit-component';

import { objectTypesAsArray } from '../../model/enum/object-type';
import { Container } from '../../model/container';
import { Catalogue } from 'src/app/model/catalogue';
import { TableUtilsService } from '../../service/table-utils.service';
import { Printable } from 'src/app/model/printable';
import { takeUntil } from 'rxjs/operators';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { Search } from 'src/app/common/search';
import { MAX_PAGE_SIZE } from 'src/app/constants/constants';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-container-add-catalogues',
    templateUrl: './container-add-catalogues.component.html',
    providers: [CatalogueService],
})
export class ContainerAddCataloguesComponent extends AbstractEditComponent<Container>
    implements OnInit {
    @ViewChild('table', { static: false }) table: Table;
    element: Container;
    registerForm: FormGroup;
    objectTypeItems: SelectItem[] = [];
    selectedCatalogues: Map<string, boolean> = new Map<string, boolean>();
    public tableUtilsService;
    isTableDataSourceDeterminated = false;
    currentContainerArticles: Array<Printable>;
    containersTranscode: Map<string, string> = new Map<string, string>();
    cataloguesTranscode: Map<string, string> = new Map<string, string>();
    buttonName = 'allarga';
    status: boolean = false;

    classToggled = false;

    public toggleField() {
        this.classToggled = !this.classToggled;
    }

    constructor(
        public router: Router,
        protected route: ActivatedRoute,
        public containerService: ContainerService,
        private formBuilder: FormBuilder,
        public catalogueService: CatalogueService,
        private confirmationService: ConfirmationService,
        private printableService: PrintableService,
        public injector: Injector,
        protected routingStateService: RoutingStateService,
        messageService: MessageService
    ) {
        super(router, route, containerService, routingStateService, 'gare', messageService);
        this.element = new Container();
        objectTypesAsArray.map((s) => {
            this.objectTypeItems.push({ label: s, value: s });
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.buildForm();

        const savedStatus = localStorage.getItem('savedStatus');
        if (savedStatus !== null) {
            this.status = JSON.parse(savedStatus);
        }
    }

    clickEvent() {
        this.status = !this.status;

        localStorage.setItem('savedStatus', JSON.stringify(this.status));
    }

    postFind() {
        this.buildForm();
        this.getCataloguesFromDb();

        const search: Search<Printable> = new Search(Printable);
        search.pageSize = MAX_PAGE_SIZE;
        this.containerService
            .getPrintables(this.element.codiceGara, search)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ({ printables, listSize }) => {
                    this.currentContainerArticles = [...printables];
                    this.tableInit();

                    const reqCount = Math.round(listSize / MAX_PAGE_SIZE);
                    for (let i = 1; i <= reqCount; i++) {
                        search.pageSize = MAX_PAGE_SIZE;
                        search.startRow = search.startRow + MAX_PAGE_SIZE;
                        this.containerService
                            .getPrintables(this.element.codiceGara, search)
                            .pipe(takeUntil(this.destroy$))
                            .subscribe(
                                ({ printables, listSize }) => {
                                    this.currentContainerArticles = [
                                        ...this.currentContainerArticles,
                                        ...printables,
                                    ];
                                },
                                (error) => this.addError(error?.msg ?? error ?? '')
                            );
                    }
                },
                (error) => this.addError(error?.msg ?? error ?? '')
            );
    }

    getCataloguesFromDb() {
        this.containerService.getCatalogues(this.element.codiceGara).subscribe(
            (catalogues) => {
                catalogues.map((c) => {
                    this.addcontainerElement(c);
                });
            },
            (error) => this.addError(error?.msg ?? error ?? '')
        );
    }

    tableInit() {
        this.tableUtilsService = new TableUtilsService(this.injector, CatalogueService);
        this.tableUtilsService.dataService.search.not.container_uuid = true;
        this.tableUtilsService.loadingTable = true;
        this.isTableDataSourceDeterminated = true;

        this.tableUtilsService.postLoadData = () => {
            this.tableUtilsService.model.map((catalogue) => {
                if (catalogue.container_uuid) {
                    this.containerService
                        .find(catalogue.container_uuid)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(
                            (_) => this.containersTranscode.set(_.codiceGara, _.name),
                            (error) => this.addError(error?.msg ?? error ?? '')
                        );
                }
            });
        };
    }

    private buildForm() {
        this.registerForm = this.formBuilder.group({
            containerElements: this.formBuilder.array([], Validators.required),
        });
    }

    get containerElementForms() {
        return this.registerForm.get('containerElements') as FormArray;
    }

    addcontainerElement(containerElement: Catalogue) {
        this.selectedCatalogues.set(containerElement.uuid, true);
        const element = this.formBuilder.group(containerElement);

        this.containerElementForms.push(element);
        this.sortContainerElements();

        this.getTranscodes(containerElement);
    }

    private sortContainerElements() {
        const myArray: Array<AbstractControl> = this.containerElementForms.controls;
        myArray?.sort((a, b) => {
            if (a.value.name < b.value.name) {
                return -1;
            }
            if (a.value.name > b.value.name) {
                return 1;
            }
            return 0;
        });
        myArray?.map((control, idx) => {
            this.containerElementForms.removeAt(idx);
            this.containerElementForms.insert(idx, control);
        });
    }

    deletecontainerElement(i, uuid) {
        this.containerElementForms.removeAt(i);
        this.containerService
            .deleteCatalogue(this.element.codiceGara, uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                () => {
                    this.tableUtilsService.reload(this.table);
                },
                (error) => this.addError(error?.msg ?? error ?? '')
            );
        this.selectedCatalogues.set(uuid, false);
    }

    addNewContainerElement(catalogue: any) {
        let containerElement = new Catalogue();
        containerElement = {
            ...catalogue,
        };

        this.containerService
            .addCatalogue(this.element.codiceGara, containerElement)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (_) => {},
                (error) => this.addError(error?.msg ?? error ?? '')
            );

        this.addcontainerElement(containerElement);
    }

    private getValuesFromForm() {
        const catalogues = this.registerForm.get('containerElements').value;
        catalogues.map((catalogue) => {
            this.catalogueService.update({
                ...catalogue,
                container_uuid: this.element.codiceGara,
            });
        });
    }

    getTranscodes(catalogueElement: Catalogue) {
        if (!this.cataloguesTranscode.get(catalogueElement.uuid)) {
            this.catalogueService
                .find(catalogueElement.uuid)
                .pipe(takeUntil(this.destroy$))
                .subscribe((c) => this.cataloguesTranscode.set(c.uuid, c.name));
        }
    }

    preSave() {
        this.getValuesFromForm();

        return true;
    }

    preUpdate() {
        this.getValuesFromForm();

        return true;
    }

    getId() {
        return this.element.codiceGara;
    }

    public view() {
        this.router.navigate(['/' + this.path + '/view', this.getId()]);
    }

    public edit() {
        this.router.navigate(['/' + this.path + '/edit', this.getId()]);
    }

    addArticlesMatthias() {
        this.router.navigate([`/${this.path}/${this.element.codiceGara}/aggiungi-articoli`]);
    }

    public addArticles(uuid: string) {
        this.router.navigate([`/catalogues/${uuid}/aggiungi-articoli`]);
    }

    createInstance(): Container {
        return new Container();
    }

    public newCatalogue() {
        this.router.navigate([
            'catalogues/new',
            { containerUuid: this.element.codiceGara, returnBack: true },
        ]);
    }

    get containerElements() {
        return this.registerForm.get('containerElements');
    }

    viewCatalogue(catalogueUuid) {
        this.router.navigate([`catalogues/view/${catalogueUuid}`, { returnBack: true }]);
    }

    editCatalogue(catalogueUuid) {
        this.router.navigate([`catalogues/edit/${catalogueUuid}`, { returnBack: true }]);
    }

    confirmDelete(i, uuid) {
        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () => this.deletecontainerElement(i, uuid),
        });
    }

    public cloneCatalogueAndFillItWithContainerPrintables(catalogueToClone: Catalogue) {
        this.containerService
            .cloneCatalogue(this.element.codiceGara, catalogueToClone.uuid)
            .subscribe(
                (newCatalogue) => {
                    this.addcontainerElement(newCatalogue);
                    this.tableUtilsService.loaddata();
                },
                (error) => this.addError(error?.msg ?? error ?? '')
            );
    }

    private persistCloneCatalogue(catalogueToClone: Catalogue) {
        catalogueToClone.uuid = null;
        catalogueToClone.container_uuid = this.element.codiceGara;
        catalogueToClone.name = catalogueToClone.name + ' CLONE';
        this.catalogueService
            .persist(catalogueToClone)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (newCatalogue) => {
                    this.addcontainerElement(newCatalogue);
                    this.tableUtilsService.loaddata();
                },
                (error) => this.addError(error?.msg ?? error ?? '')
            );
    }

    cloneCatalogue(catalogueToClone: Catalogue) {
        this.confirmationService.confirm({
            message: `Articoli e Pagine, non presenti nella gara, del catalogo
            selezionato verranno clonati e inseriti automaticamente dentro la gara, sei sicuro?`,
            accept: () => {
                this.copyArticlesFromCatalogue(catalogueToClone);
                this.persistCloneCatalogue(catalogueToClone);
            },
        });
    }

    private copyArticlesFromCatalogue(catalogueToClone: Catalogue) {
        catalogueToClone.catalogueElements
            ?.filter(
                // to have unique values (catal. by def. may have more ref. to a given article)
                (v, i, a) => a.findIndex((t) => t.printable_uuid === v.printable_uuid) === i
            )
            .filter((element) => {
                const isAlreadyIn = this.currentContainerArticles.find(
                    (a) => a.uuid === element.printable_uuid
                );
                if (!isAlreadyIn) {
                    this.printableService
                        .find(element.printable_uuid)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((realArticle) => {
                            realArticle.uuid = null;
                            this.printableService
                                .clonePrintable(realArticle, this.element.codiceGara)
                                .pipe(takeUntil(this.destroy$))
                                .subscribe((newArticle) => {
                                    this.containerService
                                        .addPrintable(this.element.codiceGara, newArticle)
                                        .pipe(takeUntil(this.destroy$))
                                        .subscribe();
                                    this.currentContainerArticles.push(newArticle);
                                });
                        });
                }
                return !isAlreadyIn;
            });
    }

    printCatalogue(catalogueUuid) {
        this.catalogueService
            .getPdf(catalogueUuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (response) => {
                    const newBlob = new Blob([response], {
                        type: 'application/pdf',
                    });
                    const fileUrl = window.URL.createObjectURL(newBlob);
                    // if (window.navigator.msSaveOrOpenBlob) {
                    //     window.navigator.msSaveOrOpenBlob(newBlob, fileUrl.split(':')[1] + '.pdf');
                    // } else {
                    //     window.open(fileUrl);
                    // }
                },
                (err) =>
                    this.service.addMessage({
                        severity: 'error',
                        summary: 'Errore',
                        detail: err.message,
                    })
            );
    }

    public handleFilterSenzaCodiceGara(event) {
        if (event.checked) {
            this.tableUtilsService.dataService.search.not.container_uuid = true;
            this.tableUtilsService.dataService.search.like.container_uuid = null;
        } else {
            this.tableUtilsService.dataService.search.not.container_uuid = null;
        }
    }

    deleteAll() {
        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () =>
                this.containerService
                    .deleteAllCatalogues(this.element.codiceGara)
                    .subscribe(() => this.containerElementForms.clear()),
        });
    }
}
