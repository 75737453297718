<div class="well-priority clearfix" [formGroup]="property">
  <div class="row">
    <div class="header-priority">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-8">
            Proprietà
          </div>
          <div class="col-sm-4">
            <button
              type="button"
              class="btn btn-danger pull-right"
              label="cancella"
              (click)="deleteProperty(i)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
    <br />
    <div class="col-sm-6">
      <div class="form-group row clearfix" *ngIf="!hideName">
        <div class="col-sm-3">
          <label>
            Nome
            <span *ngIf="property.controls.name | isFieldRequired">(*)</span>
            :
          </label>
        </div>
        <div class="col-sm-9">
          <input
            class="form-control"
            pInputText
            type="text"
            name="propertyName"
            placeholder="nome"
            formControlName="name"
          />
        </div>
      </div>

      <div class="form-group row clearfix">
        <div class="col-sm-3">
          <label>
            Label
            <span *ngIf="property.controls.label | isFieldRequired">(*)</span>
            :
          </label>
        </div>
        <div class="col-sm-9">
          <input
            class="form-control"
            pInputText
            type="text"
            name="propertyLabel"
            placeholder="label"
            formControlName="label"
          />
        </div>
      </div>

      <div class="form-group row clearfix">
        <div class="col-sm-3">
          <label>
            Tipo
            <span *ngIf="property.controls.property_type | isFieldRequired">(*)</span>
            :
          </label>
        </div>
        <div class="col-sm-9">
          <p-dropdown
            [options]="propertyTypesItems"
            (onChange)="propertyTypeSelected($event, i)"
            formControlName="property_type"
            placeholder="tipo"
            id="property_type"
            [style]="{ width: '100%' }"
          ></p-dropdown>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div
        *ngIf="property.value[propertyValueName] !== undefined"
        [ngSwitch]="property.value.property_type"
      >
        <div class="form-group row clearfix" *ngSwitchCase="'STR_VALUE'">
          <div class="col-sm-3">
            <label>
              <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
              <span *ngIf="propertyValueName === 'value'">Valore</span>
              <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                (*)
              </span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <input
              class="form-control"
              pInputText
              type="text"
              name="{{ propertyValueName }}"
              placeholder="valore di default"
              formControlName="{{ propertyValueName }}"
            />
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'DATE_VALUE'">
          <div class="col-sm-3">
            <label>
              <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
              <span *ngIf="propertyValueName === 'value'">Valore</span>
              <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                (*)
              </span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-calendar
              [locale]="locale"
              [dateFormat]="'dd/mm/yy'"
              showButtonBar="true"
              clearButtonStyleClass="btn ui-button-text-only"
              todayButtonStyleClass="btn ui-button-text-only"
              inputStyleClass="form-control"
              [style]="{ width: '100%' }"
              formControlName="{{ propertyValueName }}"
            ></p-calendar>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'LISTABLE'">
          <div class="extra" formGroupName="extra">
            <div class="col-sm-3">
              <label>
                <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
                <span *ngIf="propertyValueName === 'value'">Valore</span>
                <span>(*)</span>
                :
              </label>
            </div>
            <div class="col-sm-9" *ngIf="listableNamesItems">
              <p-dropdown
                required="true"
                [options]="listableNamesItems"
                placeholder="Scegli listabile..."
                formControlName="listableName"
                id="listableNames"
                [style]="{ width: '100%' }"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'LONG_TEXT'">
          <div class="col-sm-3">
            <label>
              <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
              <span *ngIf="propertyValueName === 'value'">Valore</span>
              <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                (*)
              </span>
              :
            </label>
          </div>
          <div class="col-sm-9 form-group">
            <textarea
              class="form-control"
              pInputTextarea
              type="text"
              name="{{ propertyValueName }}"
              placeholder="valore di default"
              formControlName="{{ propertyValueName }}"
            ></textarea>
          </div>
          <div class="extra" formGroupName="extra">
            <div class="col-sm-3">
              <label>Lunghezza massimo del testo:</label>
            </div>
            <div class="col-sm-9 form-group">
              <p-spinner
                name="max_length"
                [min]="1"
                pKeyFilter="int"
                inputStyleClass="form-control"
                [size]="'100%'"
                placeholder="numero massimo di caratteri"
                formControlName="max_length"
              ></p-spinner>
            </div>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'BOOL_VALUE'">
          <div class="col-sm-3">
            <label>
              <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
              <span *ngIf="propertyValueName === 'value'">Valore</span>
              <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                (*)
              </span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-inputSwitch
              formControlName="{{ propertyValueName }}"
              inputStyleClass="form-control"
            ></p-inputSwitch>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'NUMBER_VALUE'">
          <div class="col-sm-3">
            <label>
              <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
              <span *ngIf="propertyValueName === 'value'">Valore</span>
              <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                (*)
              </span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <input
              pInputText
              type="number"
              name="{{ propertyValueName }}"
              placeholder="numero"
              formControlName="{{ propertyValueName }}"
            />
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'COLOR_VALUE'">
          <div class="col-sm-3">
            <label>
              <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
              <span *ngIf="propertyValueName === 'value'">Valore</span>
              <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                (*)
              </span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-colorPicker formControlName="{{ propertyValueName }}" name="color"></p-colorPicker>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'TUPLE_VALUE'">
          <div class="col-sm-3">
            <label>
              <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
              <span *ngIf="propertyValueName === 'value'">Valore</span>
              <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                (*)
              </span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-chips formControlName="{{ propertyValueName }}"></p-chips>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'ICON_VALUE'">
          <div class="col-sm-3">
            <label>
              <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
              <span *ngIf="propertyValueName === 'value'">Valore</span>
              <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                (*)
              </span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <p-dropdown
              [options]="iconsItems"
              formControlName="{{ propertyValueName }}"
              filter="true"
              [style]="{ width: '100%' }"
            >
              <ng-template let-icon pTemplate="selectedItem">
                <i class="{{ icon.value }}"></i>
                <span style="vertical-align: middle; margin-left: 0.5em;">{{ icon.label }}</span>
              </ng-template>
              <ng-template let-icon pTemplate="item">
                <div class="ui-helper-clearfix" style="position: relative; height: 25px;">
                  <div>
                    <i class="{{ icon.value }}"></i>
                    {{ icon.label }}
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'IMAGE_VALUE'">
          <div class="col-sm-3">
            <label>
              <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
              <span *ngIf="propertyValueName === 'value'">Valore</span>
              <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                (*)
              </span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <app-file-upload
              [currentImageUrl]="property.value[propertyValueName]"
              (changeValue)="valueChangedFromChild($event, i)"
              [indexOnArrayForm]="i"
              formControlName="{{ propertyValueName }}"
            ></app-file-upload>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'ATTACH_VALUE'">
          <div class="col-sm-3">
            <label>
              <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
              <span *ngIf="propertyValueName === 'value'">Valore</span>
              <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                (*)
              </span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <app-attachment-upload
              [currentAttachmentUrl]="property.value[propertyValueName]"
              [(currentAttachmentName)]="property.value.extra.fileName"
              (changeValue)="valueChangedFromChild($event, i)"
              [indexOnArrayForm]="i"
              formControlName="{{ propertyValueName }}"
            ></app-attachment-upload>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'BARCODE'">
          <div class="clearfix"></div>
          <div class="extra" formGroupName="extra">
            <div class="col-sm-3">
              <label>Tipo di codice a barre (*):</label>
            </div>
            <div class="col-sm-9 form-group">
              <p-dropdown
                [options]="barcodeTypesItems"
                formControlName="type"
                placeholder="Tipo"
                id="tipology"
                [style]="{ width: '100%' }"
              ></p-dropdown>
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-3">
              <label>Codice di esempio (*):</label>
            </div>
            <div class="col-sm-9 form-group">
              <input
                class="form-control"
                pInputText
                type="text"
                name="code"
                formControlName="code"
              />
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-3">
              <label>Larghezza codice a barre (px):</label>
            </div>
            <div class="col-sm-9 form-group">
              <p-spinner
                name="width"
                [min]="1"
                pKeyFilter="int"
                inputStyleClass="form-control"
                [size]="'100%'"
                placeholder="px"
                formControlName="width"
              ></p-spinner>
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-3">
              <label>Altezza codice a barre (px):</label>
            </div>
            <div class="col-sm-9 form-group">
              <p-spinner
                name="height"
                [min]="1"
                pKeyFilter="int"
                inputStyleClass="form-control"
                [size]="'100%'"
                placeholder="px"
                formControlName="height"
              ></p-spinner>
            </div>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'MULTISELECT'">
          <div class="extra" formGroupName="extra">
            <div class="col-sm-3">
              <label>
                Valori da cui scegliere
                <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                  (*)
                </span>
                :
              </label>
            </div>
            <div class="col-sm-9">
              <div class="ui-inputgroup">
                <i
                  class="ui-inputgroup-addon fa fa-question-circle"
                  pTooltip="Premere invio dopo ogni valore inserito"
                  aria-hidden="true"
                ></i>
                <p-chips class="p-chips-custom" formControlName="choices"></p-chips>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'SUMMABLE'">
          <div class="extra" formGroupName="extra">
            <div class="col-sm-3">
              <label>
                Proprietà da sommare
                <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                  (*)
                </span>
                :
              </label>
            </div>
            <div class="col-sm-9">
              <p-dropdown
                [options]="allCurrentNumericPropsItems"
                formControlName="operandOne"
                placeholder="Operando..."
                [style]="{ width: '40%' }"
              ></p-dropdown>
              +
              <p-dropdown
                [options]="allCurrentNumericPropsItems"
                formControlName="operandTwo"
                placeholder="Operando..."
                [style]="{ width: '40%' }"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'SOTTRAIBILE'">
          <div class="extra" formGroupName="extra">
            <div class="col-sm-3">
              <label>
                Proprietà da sottrarre
                <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                  (*)
                </span>
                :
              </label>
            </div>
            <div class="col-sm-9">
              <p-dropdown
                [options]="allCurrentNumericPropsItems"
                formControlName="operandOne"
                placeholder="Operando..."
                [style]="{ width: '40%' }"
              ></p-dropdown>
              -
              <p-dropdown
                [options]="allCurrentNumericPropsItems"
                formControlName="operandTwo"
                placeholder="Operando..."
                [style]="{ width: '40%' }"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'MULTIPLIABLE'">
          <div class="extra" formGroupName="extra">
            <div class="col-sm-3">
              <label>
                Proprietà da moltiplicare
                <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                  (*)
                </span>
                :
              </label>
            </div>
            <div class="col-sm-9">
              <p-dropdown
                [options]="allCurrentNumericPropsItems"
                formControlName="operandOne"
                placeholder="Operando..."
                [style]="{ width: '40%' }"
              ></p-dropdown>
              *
              <p-dropdown
                [options]="allCurrentNumericPropsItems"
                formControlName="operandTwo"
                placeholder="Operando..."
                [style]="{ width: '40%' }"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'DIVISIBLE'">
          <div class="extra" formGroupName="extra">
            <div class="col-sm-3">
              <label>
                Proprietà da dividere
                <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                  (*)
                </span>
                :
              </label>
            </div>
            <div class="col-sm-9">
              <p-dropdown
                [options]="allCurrentNumericPropsItems"
                formControlName="operandOne"
                placeholder="Operando..."
                [style]="{ width: '40%' }"
              ></p-dropdown>
              /
              <p-dropdown
                [options]="allCurrentNumericPropsItems"
                formControlName="operandTwo"
                placeholder="Operando..."
                [style]="{ width: '40%' }"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'GLOBAL_SUMMABLE'">
          <div class="extra" formGroupName="extra">
            <div class="col-sm-3">
              <label>
                Tipologia:
                <span>(*)</span>
                :
              </label>
            </div>
            <div class="col-sm-9 form-group">
              <p-dropdown
                [options]="allTipologiesItems"
                (onChange)="tipologySelected($event)"
                formControlName="tipology_uuid"
                placeholder="Seleziona tipologia da cui prendere le proprietà..."
                [style]="{ width: '80%' }"
              ></p-dropdown>
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-3">
              <label>
                Proprietà da sommare
                <span>
                  (*)
                </span>
                :
              </label>
            </div>
            <div class="col-sm-9 form-group">
              <p-dropdown
                [options]="allCurrentNumericPropsItems"
                [disabled]="!allCurrentNumericPropsItems"
                formControlName="operandOne"
                placeholder="Operando..."
                [style]="{ width: '80%' }"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'GROUPABLE'">
          <div class="extra" formGroupName="extra">
            <div class="col-sm-3">
              <label>
                Tipologia:
                <span>(*)</span>
                :
              </label>
            </div>
            <div class="col-sm-9 form-group">
              <p-dropdown
                [options]="allTipologiesItems"
                (onChange)="groupableTipologySelected($event)"
                formControlName="tipology_uuid"
                placeholder="Seleziona tipologia da cui prendere la proprietà..."
                [style]="{ width: '80%' }"
              ></p-dropdown>
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-3">
              <label>
                Proprietà su cui raggruppare
                <span>
                  (*)
                </span>
                :
              </label>
            </div>
            <div class="col-sm-9 form-group">
              <p-dropdown
                [options]="allPropsOfSelectedTipologyItems"
                [disabled]="!allPropsOfSelectedTipologyItems"
                formControlName="propertyName"
                placeholder="Scegli proprietà..."
                [style]="{ width: '80%' }"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchCase="'GROUP_SUMMABLE'">
          <div class="extra" formGroupName="extra">
            <div class="col-sm-3">
              <label>
                Tipologia:
                <span>(*)</span>
                :
              </label>
            </div>
            <div class="col-sm-9 form-group">
              <p-dropdown
                [options]="allTipologiesItems"
                (onChange)="groupSummableTipologySelected($event)"
                formControlName="tipology_uuid"
                placeholder="Seleziona tipologia da cui prendere la proprietà..."
                [style]="{ width: '80%' }"
              ></p-dropdown>
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-3">
              <label>
                Proprietà su cui raggruppare
                <span>
                  (*)
                </span>
                :
              </label>
            </div>
            <div class="col-sm-9 form-group">
              <p-dropdown
                [options]="allPropsOfSelectedTipologyItems"
                [disabled]="!allPropsOfSelectedTipologyItems"
                formControlName="propertyName"
                placeholder="Scegli proprietà..."
                [style]="{ width: '80%' }"
              ></p-dropdown>
            </div>
            <div class="clearfix"></div>
            <div class="col-sm-3">
              <label>
                Proprietà da sommare
                <span>
                  (*)
                </span>
                :
              </label>
            </div>
            <div class="col-sm-9 form-group">
              <p-dropdown
                [options]="allCurrentNumericPropsItems"
                [disabled]="!allCurrentNumericPropsItems"
                formControlName="operandOne"
                placeholder="Operando..."
                [style]="{ width: '80%' }"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <div class="form-group row clearfix" *ngSwitchDefault>
          <div class="col-sm-3">
            <label>
              <span *ngIf="propertyValueName === 'default_value'">Valore default</span>
              <span *ngIf="propertyValueName === 'value'">Valore</span>
              <span *ngIf="property.controls[propertyValueName] | isFieldRequired">
                (*)
              </span>
              :
            </label>
          </div>
          <div class="col-sm-9">
            <div class="col-sm-12">N.A.</div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group row clearfix" *ngIf="!hideMandatory">
            <div class="col-sm-6">
              <label>
                Obbligatorio valorizzare
                <span *ngIf="property.controls.mandatory | isFieldRequired">(*)</span>
                :
              </label>
            </div>

            <div class="col-sm-6">
              <p-inputSwitch
                formControlName="mandatory"
                inputStyleClass="form-control"
              ></p-inputSwitch>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group row clearfix" *ngIf="!hideOrder">
            <div class="col-sm-6">
              <label>
                Posizione ordine:
                <span *ngIf="property.controls.order_by | isFieldRequired">
                  (*)
                </span>
                :
              </label>
            </div>
            <div class="col-sm-6">
              <input pInputText type="number" name="order_by" min="0" formControlName="order_by" />
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="form-group row clearfix" *ngIf="!hideOrder">
            <div class="col-sm-6">
              <label>
                Lunghezza Colonna:
                <span *ngIf="property.controls.colspan | isFieldRequired">
                  (*)
                </span>
                :
              </label>
            </div>
            <div class="col-sm-6">
              <input
                pInputText
                type="number"
                name="propertyName"
                min="0"
                [max]="TIPOLOGY_STYLE_MAX_COLUMNS"
                formControlName="colspan"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
