import { Component, ElementRef, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileUploadComponent,
            multi: true,
        },
    ],
    styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent implements ControlValueAccessor {
    @Input() currentImageUrl;
    @Input() indexOnArrayForm;
    @Input() printableUuid: string;
    @Output() currentImageUrlChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() changeValue: EventEmitter<string> = new EventEmitter<string>(); // this is useful only for tipology

    onChange: (file: any) => void;
    isDialogVisible = false;

    @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
        const file = event && event.item(0);
        console.log('file', file);

        this.onChange(file);
    }

    constructor(
        private host: ElementRef<HTMLInputElement>,
        protected confirmationService: ConfirmationService
    ) {}

    writeValue(value: null) {
        this.host.nativeElement.value = '';
    }

    registerOnChange(fn: (file: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: (file: any) => void) {}

    remove() {
        this.currentImageUrl = '';
        this.changeValue.emit('');
        this.currentImageUrlChange.emit('');
    }

    confirmDelete() {
        let key = `deleteImage-${this.indexOnArrayForm}`;
        if (this.printableUuid) {
            key += `-${this.printableUuid}`;
        }

        this.confirmationService.confirm({
            message: 'Confermi la cancellazione?',
            key,
            rejectLabel: 'No',
            acceptLabel: 'Sì',
            accept: () => this.remove(),
        });
    }

    showDialog() {
        this.isDialogVisible = true;
    }

    selectImage(event) {
        console.log('event', event);

        const imageUrl = event.target.src;
        this.onChange(imageUrl);
        this.currentImageUrl = imageUrl;
        this.changeValue.emit(imageUrl);
        this.currentImageUrlChange.emit(imageUrl);
        this.isDialogVisible = false;
    }
}
