import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AbstractListComponent } from '../../common/abstract-list-component';
import { PrintLog } from '../../model/print-log';
import { takeUntil } from 'rxjs/operators';
import { Table } from 'primeng/table/table';
import { PrintLogService } from 'src/app/service/print-path.service';

@Component({
    selector: 'app-print-log-list',
    templateUrl: './print-log-list.component.html',
})
export class PrintLogListComponent extends AbstractListComponent<PrintLog> implements OnInit {
    element: PrintLog;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected printLogService: PrintLogService
    ) {
        super(router, printLogService, 'print_logs');
    }

    ngOnInit() {
        this.loading = true;
        this.element = new PrintLog();
    }

    newElement(): PrintLog {
        return super.newElement();
    }

    delete(element: PrintLog) {
        this.service
            .delete(element.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.loaddata(true));
    }

    public reload(datatable: Table) {
        super.reload(datatable);
        this.service.search = {
            ...this.service.search,
            from: {
                ...this.service.search.from,
                received_date: this.service.search.from?.received_date?.split('T')[0],
            },
            to: {
                ...this.service.search.to,
                received_date: this.service.search.to?.received_date?.split('T')[0],
            },
        };
    }

    getId() {
        return this.element.uuid;
    }

    createInstance(): PrintLog {
        return new PrintLog();
    }

    appendHourToDate() {
        this.service.search = {
            ...this.service.search,
            from: {
                ...this.service.search.from,
                received_date: this.service.search.from.received_date
                    ? `${this.service.search?.from?.received_date}T00:00:00`
                    : null,
            },
            to: {
                ...this.service.search.to,
                received_date: this.service.search.to.received_date
                    ? `${this.service.search?.to?.received_date}T23:59:59`
                    : null,
            },
        };
    }
}
