import { Component, OnInit, Injector, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { CatalogueService } from '../../../service/catalogue.service';
import { PrintableService } from '../../../service/printable.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';

import { AbstractEditComponent } from '../../../common/abstract-edit-component';

import { Catalogue } from 'src/app/model/catalogue';
import { CatalogueElement } from 'src/app/model/catalogue-element';
import { objectTypesAsArray } from '../../../model/enum/object-type';
import { TableUtilsService } from '../../../service/table-utils.service';
import { PrintTemplateService } from 'src/app/service/print-template.service';
import { ContainerService } from 'src/app/service/container.service';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Table } from 'primeng/table/table';
import { RoutingStateService } from 'src/app/service/routing-state.service';
import { Printable } from 'src/app/model/printable';
import { Search } from 'src/app/common/search';
import { MAX_PAGE_SIZE } from 'src/app/constants/constants';
@Component({
    selector: 'app-catalogue-add-printables',
    templateUrl: './catalogue-add-printables.component.html',
    styleUrls: ['./catalogue-add-printables.component.css'],
})
export class CatalogueAddPrintablesComponent extends AbstractEditComponent<Catalogue>
    implements OnInit {
    element: Catalogue;
    registerForm: FormGroup;
    objectTypeItems: SelectItem[] = [];
    codiciGaraItems: SelectItem[] = [];
    containerUuid: string = null;
    tableUtilsService: TableUtilsService;
    isTableDataSourceDeterminated = false;
    printablesTranscode: Map<string, string> = new Map<string, string>();
    templatesTranscode: Map<string, string> = new Map<string, string>();
    enableAddAllButton = false;
    @ViewChild('table') table: Table;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected catalogueService: CatalogueService,
        protected formBuilder: FormBuilder,
        public printableService: PrintableService,
        public printTemplateService: PrintTemplateService,
        public injector: Injector,
        public containerService: ContainerService,
        public location: Location,
        protected routingStateService: RoutingStateService,
        messageService: MessageService
    ) {
        super(router, route, catalogueService, routingStateService, 'catalogues', messageService);
        this.element = new Catalogue();
        objectTypesAsArray.map((s) => {
            this.objectTypeItems.push({ label: s, value: s });
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.getCodiciGara();
        this.buildForm();
        this.containerUuid = this.route.snapshot.paramMap.get('containerUuid');
        if (this.route.snapshot.paramMap.get('returnBack')) {
            this.navigateAfterUpdate = () => this.location.back();
            this.navigateAfterDelete = () => this.location.back();
            this.navigateToList = () => this.location.back();
        }
    }

    postFind() {
        this.containerUuid = this.element.container_uuid;
        this.buildForm();
        this.element?.catalogueElements?.map((e) => this.addcatalogueElement(e));
        if (!this.containerUuid) {
            this.tableInitStd();
        } else {
            this.tableInitContainer();
        }
    }

    private getCodiciGara() {
        this.containerService
            .getAllCodicigaraAsItems()
            .pipe(takeUntil(this.destroy$))
            .subscribe((i) => (this.codiciGaraItems = i));
    }

    protected buildForm() {
        this.registerForm = this.formBuilder.group({
            catalogueElements: this.formBuilder.array([], Validators.required),
        });
    }

    protected getValuesFromForm() {
        this.element.catalogueElements = this.catalogueElementForms.value;
    }

    get catalogueElementForms() {
        return this.registerForm.get('catalogueElements') as FormArray;
    }

    protected tableInitStd() {
        this.tableInitCommon();
        this.tableUtilsService.loaddata = () => this.customLoadDataTableStd();
    }

    protected tableInitContainer() {
        this.tableInitCommon();
        this.tableUtilsService.loaddata = () => this.customLoadDataTableContainer();
    }

    private tableInitCommon() {
        this.tableUtilsService = new TableUtilsService(this.injector, PrintableService);
        if (this.element.monopage) {
            this.tableUtilsService.dataService.search.obj.objectType = 'ARTICLE';
        }
        this.tableUtilsService.loadingTable = true;
        this.isTableDataSourceDeterminated = true;
        const oldReload = this.tableUtilsService.reload.bind(this.tableUtilsService);
        this.tableUtilsService.reload = (table: Table) => {
            oldReload(table);
            if (this.tableUtilsService.dataService.search.obj.codiceGara) {
                this.enableAddAllButton = true;
            } else {
                this.enableAddAllButton = false;
            }
        };
    }

    protected customLoadDataTableStd() {
        this.tableUtilsService.dataService
            .getList()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (dataList) => {
                    this.tableUtilsService.model = dataList as any[];
                    this.tableUtilsService.loadingTable = false;
                    this.postLoadDataTable();
                },
                (error) => {
                    this.tableUtilsService.errorMessage = error;
                    this.tableUtilsService.loadingTable = false;
                }
            );
    }

    protected customLoadDataTableContainer() {
        const search: Search<Printable> = { ...this.tableUtilsService.dataService.search };
        this.containerService
            .getPrintables(this.containerUuid, search)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ({ printables, listSize }) => {
                    this.tableUtilsService.dataService.listSize = listSize;
                    this.tableUtilsService.model = printables as any[];
                    this.tableUtilsService.loadingTable = false;
                    this.postLoadDataTable();
                },
                (error) => {
                    this.tableUtilsService.errorMessage = error;
                    this.tableUtilsService.loadingTable = false;
                }
            );
    }

    postLoadDataTable() {
        this.tableUtilsService.model.map((value) => {
            this.tableUtilsService.tipologyService
                .find(value.tipology_uuid)
                .pipe(takeUntil(this.destroy$))
                .subscribe((tipology) => {
                    value.templates = [...tipology.templates];
                });
        });
    }

    addcatalogueElement(catalogueElement: CatalogueElement) {
        const element = this.formBuilder.group(catalogueElement);
        if (
            this.catalogueElementForms.value.some(
                (catalogue) => catalogue.printable_uuid === catalogueElement.printable_uuid
            )
        ) {
            console.log('ALREADY EXISTS');
            return;
        }
        this.catalogueElementForms.push(element);
        this.getTranscodes(catalogueElement);
    }

    deletecatalogueElement(i) {
        this.catalogueElementForms.removeAt(i);
    }

    addNewCatalogueElement(printable: Printable, templateUuid?: string) {
        let catalogueElement = new CatalogueElement();
        catalogueElement = {
            ...catalogueElement,
            printable_uuid: printable.uuid,
            printable_type: printable.objectType,
            template_uuid: templateUuid ?? null,
        };
        this.addcatalogueElement(catalogueElement);
    }

    addAllPrintables() {
        const search: Search<Printable> = { ...this.tableUtilsService.dataService.search };
        search.pageSize = MAX_PAGE_SIZE;
        this.containerService
            .getPrintables(search.obj.codiceGara, search)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                ({ printables }) => {
                    printables.map((printable) => {
                        this.addNewCatalogueElement(printable);
                    });
                },
                (error) => {}
            );
    }

    preSave() {
        this.getValuesFromForm();
        return true;
    }

    preUpdate() {
        this.getValuesFromForm();
        this.element.catalogueElements.map((e, i) => (e.orderBy = i));
        return true;
    }

    getId() {
        return this.element.uuid;
    }

    createInstance(): Catalogue {
        return new Catalogue();
    }

    public view() {
        this.router.navigate(['/' + this.path + '/view', this.getId()]);
    }

    public goBack() {
        const lastRoute = this.routingStateService.getPreviousUrl();
        if (lastRoute !== '/homepage') {
            this.router.navigate([`${lastRoute}`]);
        } else {
            this.view();
        }
    }

    getTranscodes(catalogueElement: CatalogueElement) {
        if (
            catalogueElement.printable_uuid &&
            !this.printablesTranscode.get(catalogueElement.printable_uuid)
        ) {
            this.printableService
                .find(catalogueElement.printable_uuid)
                .pipe(takeUntil(this.destroy$))
                .subscribe((p) => this.printablesTranscode.set(p.uuid, p.name));
        }

        if (
            catalogueElement.template_uuid &&
            !this.templatesTranscode.get(catalogueElement.template_uuid)
        ) {
            this.printTemplateService
                .find(catalogueElement.template_uuid)
                .pipe(takeUntil(this.destroy$))
                .subscribe((t) => this.templatesTranscode.set(t.uuid, t.name));
        }
    }

    get catalogueElements() {
        return this.registerForm.get('catalogueElements');
    }
}
