import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addTrailComma'
})
export class AddTrailCommaPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        return value ? `${value}, ` : '';
    }
}
