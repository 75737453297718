<div class="wrapper-md">
  <div class="list-wrapper clearfix">
    <div class="row">
      <div class="col-sm-8">
        <h4>
          <i aria-hidden="true" class="fa fa-copy"></i>
          Log di sistema
        </h4>
        <br />
      </div>
      <div class="col-sm-4">
        <div class="btn-group pull-right">
          <button type="button" class="btn btn-default" (click)="goToList()">
            <i class="fa fa-arrow-left"></i>
            Indietro
          </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="content clearfix">
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default">
            <div class="panel-body view">
              <table class="table">
                <tbody>
                  <tr>
                    <td width="200">Data ricezione:</td>
                    <td>{{ element?.received_date | date: 'dd-MM-yyyy hh:mm' }}</td>
                  </tr>

                  <tr>
                    <td width="200">Oggetto:</td>
                    <td>{{ element?.obj }}</td>
                  </tr>

                  <tr>
                    <td>Tipo operazione:</td>
                    <td>{{ element?.operation_type }}</td>
                  </tr>

                  <tr>
                    <td>Utente:</td>
                    <td>{{ element?.principal }}</td>
                  </tr>

                  <tr>
                    <td>Data:</td>
                    <td>
                      <pre>
                          <code>
                            {{ element?.data }}
                          </code>
                                            </pre>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
