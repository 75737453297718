import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { propertyType } from 'src/app/model/enum/property-type';
import { PrintableService } from 'src/app/service/printable.service';
import * as moment from 'moment';
@Component({
    selector: 'app-article-property-tag-filter',
    templateUrl: './article-property-tag-filter.component.html',
    styleUrls: ['./article-property-tag-filter.component.css'],
})
export class ArticlePropertyTagFilterComponent implements OnInit {
    @Input() service: PrintableService;
    @Output() reload: EventEmitter<any> = new EventEmitter();
    constructor() {}

    ngOnInit(): void {}

    removeJsonPropertyFilter(propName: string) {
        for (const property in this.service.search.json) {
            if (
                this.service.search.json[property] !== null &&
                this.unwrapPropertyName(property) === propName
            ) {
                this.service.search.json[property] = null;
                this.service.search.json[`${propName}_type`] = null;
            }
        }
        this.reload.emit();
    }

    unwrapPropertyName(wrappedName: string): string {
        if (wrappedName.endsWith('_type')) {
            return null;
        } else {
            return wrappedName.substring(0, wrappedName.lastIndexOf('_'));
        }
    }

    findPropertyType(property: string): propertyType {
        for (const [key, value] of Object.entries(this.service.search.json)) {
            if (key === `${property}_type`) {
                return value;
            }
        }
    }

    get jsonProperties(): { name: string; value: string; type: propertyType }[] {
        let jsonProperties: { name: string; value: string; type: propertyType }[] = [];
        for (const property in this.service.search.json) {
            if (this.service.search.json[property] !== null) {
                const unWrappedPropertyName = this.unwrapPropertyName(property);
                if (!this.unwrapPropertyName) {
                    return;
                }
                const prop = {
                    name: unWrappedPropertyName,
                    value: this.service.search.json[property],
                    type: this.findPropertyType(unWrappedPropertyName),
                };
                if (prop.name) {
                    const alreadyPresentIdx = jsonProperties.findIndex((p) => p.name === prop.name);
                    if (prop.type === 'DATE_VALUE') {
                        prop.value = moment(prop.value).format('DD/MM/YYYY');
                    }
                    if (alreadyPresentIdx > -1) {
                        jsonProperties[alreadyPresentIdx].value += ` - ${prop.value}`;
                    } else {
                        jsonProperties = [...jsonProperties, prop];
                    }
                }
            }
        }

        return jsonProperties;
    }
}
