<p-overlayPanel #op [dismissable]="true" appendTo="body" styleClass="clearfix">
  <div class="form-group cleafix">
    <div class="row">
      <div class="col-sm-6">
        <p-calendar
          [locale]="langIt"
          dateFormat="yy-mm-dd"
          dataType="string"
          showButtonBar="true"
          clearButtonStyleClass="btn ui-button-text-only"
          todayButtonStyleClass="btn ui-button-text-only"
          inputStyleClass="form-control"
          [style]="{ width: '100%' }"
          [(ngModel)]="service.search.from.received_date"
          placeholder="(Data ricezione) da"
        ></p-calendar>
      </div>

      <div class="col-sm-6">
        <p-calendar
          [locale]="langIt"
          dateFormat="yy-mm-dd"
          dataType="string"
          showButtonBar="true"
          clearButtonStyleClass="btn ui-button-text-only"
          todayButtonStyleClass="btn ui-button-text-only"
          inputStyleClass="form-control"
          [style]="{ width: '100%' }"
          [(ngModel)]="service.search.to.received_date"
          placeholder="(Data ricezione) a"
        ></p-calendar>
      </div>
    </div>
  </div>

  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="text"
      name="principal"
      placeholder="Utente"
      [(ngModel)]="service.search.like.principal"
    />
  </div>

  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="text"
      name="related_uuid"
      placeholder="UUID Collegato"
      [(ngModel)]="service.search.obj.related_uuid"
    />
  </div>

  <div class="form-group cleafix">
    <div class="row">
      <div class="col-sm-6">
        <button
          type="button"
          class="btn btn-success btn-block"
          (click)="appendHourToDate(); reload(table); op.hide()"
        >
          Cerca
        </button>
      </div>
      <div class="col-sm-6">
        <button type="button" class="btn btn-default btn-block" (click)="reset(table); op.hide()">
          Reset
        </button>
      </div>
    </div>
  </div>
</p-overlayPanel>

<div class="main-content clearfix">
  <div class="list-wrapper clearfix">
    <div class="content clearfix">
      <div class="row">
        <div class="col-sm-8">
          <h4>
            <i class="fa fa-print" aria-hidden="true"></i>
            Log di stampa
          </h4>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="clearfix"></div>
      <div class="filter-wrapper clearfix">
        <button
          type="text"
          class="btn btn-filter"
          label="Basic"
          appendTo="body"
          (click)="op.toggle($event)"
        >
          <i class="fa fa-sliders" aria-hidden="true"></i>
          Cerca
        </button>

        <div class="tags-wrapper">
          <div class="tag-item" *ngIf="service.search.like.principal">
            <strong>utente:</strong>
            {{ service.search.like.principal }}
            <button (click)="service.search.like.principal = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.obj.related_uuid">
            <strong>uuid collegata:</strong>
            {{ service.search.obj.related_uuid }}
            <button
              (click)="service.search.obj.related_uuid = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div
            class="tag-item"
            *ngIf="service.search.from.received_date || service.search.to.received_date"
          >
            <strong>data ricezione:</strong>
            {{ service.search?.from?.received_date | date: 'dd-MM-yyyy' }} -
            {{ service.search?.to?.received_date | date: 'dd-MM-yyyy' }}
            <button
              (click)="
                service.search.from.received_date = null;
                service.search.to.received_date = null;
                reload(table)
              "
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-body list">
          <div class="row">
            <p-table
              [value]="model"
              [loading]="loading"
              [lazy]="true"
              (onLazyLoad)="lazyLoad($event)"
              [paginator]="true"
              [responsive]="true"
              [rows]="service.search.pageSize"
              [totalRecords]="service.listSize"
              [resizableColumns]="true"
              stateStorage="session"
              [stateKey]="path + '_table'"
              #table
            >
              <ng-template pTemplate="header" let-columns class="search-filter">
                <tr class="sort-header">
                  <th class="RowIndex"></th>

                  <th pResizableColumn [pSortableColumn]="'principal'">
                    Utente
                    <p-sortIcon [field]="'principal'"></p-sortIcon>
                  </th>

                  <th pResizableColumn [pSortableColumn]="'related_uuid'">
                    UUID Collegato
                    <p-sortIcon [field]="'related_uuid'"></p-sortIcon>
                  </th>

                  <th pResizableColumn [pSortableColumn]="'received_date'">
                    Data ricezione
                    <p-sortIcon [field]="'received_date'"></p-sortIcon>
                  </th>

                  <th class="Operations">Operazioni</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="colspan">Nessun risultato.</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                  <td class="RowIndex">{{ rowIndex + 1 }}</td>

                  <td class="ui-resizable-column">
                    {{ rowData.principal }}
                  </td>

                  <td class="ui-resizable-column">
                    {{ rowData.related_uuid }}
                  </td>

                  <td class="ui-resizable-column">
                    {{ rowData.received_date | date: 'dd-MM-yyyy hh:mm' }}
                  </td>

                  <td>
                    <div class="btn-group p-d-flex">
                      <button
                        type="button"
                        pButton
                        (click)="view(rowData)"
                        class="btn btn-info btn-sm"
                        pTooltip="Visualizza"
                        tooltipPosition="top"
                        icon="fa fa-eye"
                      ></button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
