<p-toast></p-toast>

<p-confirmDialog
  message="Sei sicuro di voler eseguire l'azione selezionata?"
  header="Conferma"
  acceptLabel=" Sì"
  rejectLabel="No"
  [acceptIcon]="null"
  [rejectIcon]="null"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>

<p-overlayPanel #op [dismissable]="true" appendTo="body" styleClass="clearfix">
  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="text"
      name="codiceGara"
      placeholder="Codice Gara"
      [(ngModel)]="service.search.like.codiceGara"
    />
  </div>
  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="text"
      name="name"
      placeholder="Nome"
      [(ngModel)]="service.search.like.name"
    />
  </div>
  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="description"
      name="description"
      placeholder="Descrizione"
      [(ngModel)]="service.search.like.description"
    />
  </div>
  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="text"
      name="reference"
      placeholder="Riferimenti"
      [(ngModel)]="service.search.like.reference"
    />
  </div>
  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="text"
      name="entity_type"
      placeholder="Ente / Tipologia"
      [(ngModel)]="service.search.like.entity_type"
    />
  </div>
  <div class="form-group cleafix">
    <p-dropdown
      [options]="listableSectorItems"
      [(ngModel)]="service.search.obj.sector"
      placeholder="Settore"
      [style]="{ width: '100%' }"
    ></p-dropdown>
  </div>
  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="text"
      name="delivery_address"
      placeholder="Indirizzo di consegna"
      [(ngModel)]="service.search.like.delivery_address"
    />
  </div>
  <div class="form-group cleafix">
    <div class="row">
      <div class="col-sm-6">
        <p-calendar
          [locale]="langIt"
          [dateFormat]="'dd/mm/yy'"
          showButtonBar="true"
          clearButtonStyleClass="btn ui-button-text-only"
          todayButtonStyleClass="btn ui-button-text-only"
          inputStyleClass="form-control"
          [style]="{ width: '100%' }"
          [(ngModel)]="service.search.from.deadline"
          placeholder="(Scadenza) da"
        ></p-calendar>
      </div>
      <div class="col-sm-6">
        <p-calendar
          [locale]="langIt"
          [dateFormat]="'dd/mm/yy'"
          showButtonBar="true"
          clearButtonStyleClass="btn ui-button-text-only"
          todayButtonStyleClass="btn ui-button-text-only"
          inputStyleClass="form-control"
          [style]="{ width: '100%' }"
          [(ngModel)]="service.search.to.deadline"
          placeholder="(Scadenza) a"
        ></p-calendar>
      </div>
    </div>
  </div>
  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="text"
      name="bidder"
      placeholder="Offerente"
      [(ngModel)]="service.search.like.bidder"
    />
  </div>
  <div class="form-group cleafix">
    <div class="row">
      <div class="col-sm-6">
        <p-calendar
          [locale]="langIt"
          [dateFormat]="'dd/mm/yy'"
          showButtonBar="true"
          clearButtonStyleClass="btn ui-button-text-only"
          todayButtonStyleClass="btn ui-button-text-only"
          inputStyleClass="form-control"
          [style]="{ width: '100%' }"
          [(ngModel)]="service.search.from.prorogation"
          placeholder="(Proroga) da"
        ></p-calendar>
      </div>
      <div class="col-sm-6">
        <p-calendar
          [locale]="langIt"
          [dateFormat]="'dd/mm/yy'"
          showButtonBar="true"
          clearButtonStyleClass="btn ui-button-text-only"
          todayButtonStyleClass="btn ui-button-text-only"
          inputStyleClass="form-control"
          [style]="{ width: '100%' }"
          [(ngModel)]="service.search.to.prorogation"
          placeholder="(Proroga) a"
        ></p-calendar>
      </div>
    </div>
  </div>
  <div class="form-group cleafix p-d-flex p-jc-between">
    <label>Cliente di portafoglio</label>
    <p-inputSwitch
      [(ngModel)]="service.search.obj.portfolioCustomer"
      inputStyleClass="form-control"
    ></p-inputSwitch>
  </div>

  <div class="p-d-flex">
    <div class="form-group p-d-flex p-jc-between p-ai-center" style="flex: 1;">
      <label>Archiviata</label>
      <p-inputSwitch
        [(ngModel)]="service.search.obj.archived"
        inputStyleClass="form-control"
      ></p-inputSwitch>
    </div>

    <div style="width: 30px;"></div>

    <div class="form-group p-d-flex p-jc-between p-ai-center" style="flex: 1;">
      <label>Congelata</label>
      <p-inputSwitch
        [(ngModel)]="service.search.obj.closed"
        inputStyleClass="form-control"
      ></p-inputSwitch>
    </div>
  </div>

  <div class="form-group cleafix">
    <input
      class="form-control"
      pInputText
      type="text"
      name="notes"
      placeholder="Note"
      [(ngModel)]="service.search.like.notes"
    />
  </div>
  <div class="form-group cleafix">
    <div class="row">
      <div class="col-sm-6">
        <button type="button" class="btn btn-success btn-block" (click)="reload(table); op.hide()">
          Cerca
        </button>
      </div>
      <div class="col-sm-6">
        <button type="button" class="btn btn-default btn-block" (click)="reset(table); op.hide()">
          Reset
        </button>
      </div>
    </div>
  </div>
</p-overlayPanel>

<div class="main-content clearfix">
  <div class="list-wrapper clearfix">
    <div class="content clearfix">
      <div class="row">
        <div class="col-sm-8">
          <h4>
            <i class="fa fa-tachometer" aria-hidden="true"></i>
            Gare
          </h4>
        </div>
        <div class="col-sm-4">
          <button type="button" [disabled]="loading" (click)="new()" class="btn btn-add pull-right">
            <i class="fa fa-plus-circle"></i>
            Aggiungi
          </button>
        </div>
      </div>
      <div class="clearfix"></div>
      <br />
      <div class="clearfix"></div>
      <div class="filter-wrapper clearfix">
        <button
          type="text"
          class="btn btn-filter"
          label="Basic"
          appendTo="body"
          (click)="op.toggle($event)"
        >
          <i class="fa fa-sliders" aria-hidden="true"></i>
          Cerca
        </button>

        <div class="tags-wrapper">
          <div class="tag-item" *ngIf="service.search.like.codiceGara">
            <strong>codice gara:</strong>
            {{ service.search.like.codiceGara }}
            <button
              (click)="service.search.like.codiceGara = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="tag-item" *ngIf="service.search.like.name">
            <strong>nome:</strong>
            {{ service.search.like.name }}
            <button (click)="service.search.like.name = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="tag-item" *ngIf="service.search.like.description">
            <strong>descrizione:</strong>
            {{ service.search.like.description }}
            <button
              (click)="service.search.like.description = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="tag-item" *ngIf="service.search.like.reference">
            <strong>riferimenti:</strong>
            {{ service.search.like.reference }}
            <button (click)="service.search.like.reference = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="tag-item" *ngIf="service.search.like.entity_type">
            <strong>ente / tipologia:</strong>
            {{ service.search.like.entity_type }}
            <button
              (click)="service.search.like.entity_type = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="tag-item" *ngIf="service.search.obj.sector">
            <strong>settore:</strong>
            {{ service.search.obj.sector }}
            <button (click)="service.search.obj.sector = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="tag-item" *ngIf="service.search.like.delivery_address">
            <strong>indirizzo di consegna:</strong>
            {{ service.search.like.delivery_address }}
            <button
              (click)="service.search.like.delivery_address = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="tag-item" *ngIf="service.search.from.deadline || service.search.to.deadline">
            <strong>scadenza:</strong>
            {{ service.search?.from?.deadline | date: 'dd/MM/yyyy' }} -
            {{ service.search?.to?.deadline | date: 'dd/MM/yyyy' }}
            <button
              (click)="
                service.search.from.deadline = null;
                service.search.to.deadline = null;
                reload(table)
              "
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div
            class="tag-item"
            *ngIf="service.search.from.prorogation || service.search.to.prorogation"
          >
            <strong>proroga:</strong>
            {{ service.search?.from?.prorogation | date: 'dd/MM/yyyy' }} -
            {{ service.search?.to?.prorogation | date: 'dd/MM/yyyy' }}
            <button
              (click)="
                service.search.from.prorogation = null;
                service.search.to.prorogation = null;
                reload(table)
              "
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.obj.portfolioCustomer">
            <strong>cliente di portafoglio:</strong>
            {{ service.search.obj.portfolioCustomer ? 'Sì' : 'No' }}
            <button
              (click)="service.search.obj.portfolioCustomer = null; reload(table)"
              class="btn-close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.obj.archived">
            <strong>archiviata:</strong>
            {{ service.search.obj.archived ? 'Sì' : 'No' }}
            <button (click)="service.search.obj.archived = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.obj.closed">
            <strong>congelata:</strong>
            {{ service.search.obj.closed ? 'Sì' : 'No' }}
            <button (click)="service.search.obj.closed = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div class="tag-item" *ngIf="service.search.like.bidder">
            <strong>offerente:</strong>
            {{ service.search.like.bidder }}
            <button (click)="service.search.like.bidder = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="tag-item" *ngIf="service.search.like.notes">
            <strong>note:</strong>
            {{ service.search.like.notes }}
            <button (click)="service.search.like.notes = null; reload(table)" class="btn-close">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="panel panel-default">
        <div class="panel-body list">
          <div class="row">
            <p-table
              [value]="model"
              [loading]="loading"
              [lazy]="true"
              (onLazyLoad)="lazyLoad($event)"
              [paginator]="true"
              [responsive]="true"
              [rows]="service.search.pageSize"
              [totalRecords]="service.listSize"
              [resizableColumns]="true"
              stateStorage="session"
              [stateKey]="path + '_table'"
              #table
            >
              <ng-template pTemplate="header" let-columns class="search-filter">
                <tr class="sort-header">
                  <th class="RowIndex"></th>

                  <th pResizableColumn [pSortableColumn]="'codiceGara'">
                    Codice Gara
                    <p-sortIcon [field]="'codiceGara'"></p-sortIcon>
                  </th>

                  <th pResizableColumn [pSortableColumn]="'name'">
                    Nome
                    <p-sortIcon [field]="'name'"></p-sortIcon>
                  </th>

                  <th pResizableColumn [pSortableColumn]="'description'">
                    Descrizione
                    <p-sortIcon [field]="'description'"></p-sortIcon>
                  </th>

                  <th
                    pResizableColumn
                    [pSortableColumn]="'archived'"
                    [style]="{ 'max-width': '120px', 'min-width': '120px' }"
                  >
                    Archiviata
                    <p-sortIcon [field]="'archived'"></p-sortIcon>
                  </th>

                  <th
                    pResizableColumn
                    [pSortableColumn]="'closed'"
                    [style]="{ 'max-width': '120px', 'min-width': '120px' }"
                  >
                    Congelata
                    <p-sortIcon [field]="'closed'"></p-sortIcon>
                  </th>

                  <th class="Operations">Operazioni</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="colspan">Nessun risultato.</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr>
                  <td class="RowIndex">{{ rowIndex + 1 }}</td>

                  <td class="ui-resizable-column">
                    {{ rowData.codiceGara }}
                  </td>

                  <td class="ui-resizable-column">
                    {{ rowData.name }}
                  </td>

                  <td class="ui-resizable-column">
                    {{ rowData.description }}
                  </td>

                  <td class="ui-resizable-column">
                    <i class="fa fa-{{ rowData.archived ? 'check' : 'times' }}"></i>
                  </td>

                  <td class="ui-resizable-column">
                    <i class="fa fa-{{ rowData.closed ? 'check' : 'times' }}"></i>
                  </td>

                  <td>
                    <div class="btn-group p-d-flex">
                      <button
                        *ngIf="!rowData.archived && !rowData.closed"
                        type="button"
                        pButton
                        (click)="edit(rowData)"
                        class="btn btn-success btn-sm"
                        pTooltip="Modifica"
                        tooltipPosition="top"
                        icon="fa fa-edit"
                      ></button>

                      <ng-container *ngIf="primaryRole !== 'Utente'">
                        <button
                          *ngIf="!rowData.archived && !rowData.closed"
                          type="button"
                          pButton
                          pTooltip="Congela"
                          tooltipPosition="top"
                          (click)="close(rowData)"
                          class="btn btn-danger btn-sm"
                          icon="fa fa-times"
                        ></button>
                      </ng-container>

                      <button
                        *ngIf="rowData.closed && primaryRole !== 'Utente'"
                        type="button"
                        pButton
                        pTooltip="Decongela"
                        tooltipPosition="top"
                        (click)="open(rowData)"
                        class="btn btn-success btn-sm"
                        icon="fa fa-plus"
                      ></button>

                      <ng-template [ngIf]="rowData.archived">
                        <button
                          *ngIf="primaryRole !== 'Utente'"
                          type="button"
                          pButton
                          pTooltip="Disarchivia"
                          tooltipPosition="top"
                          (click)="unarchive(rowData)"
                          class="btn btn-success btn-sm"
                          icon="fa fa-folder-open"
                        ></button>
                      </ng-template>

                      <ng-template [ngIf]="!rowData.archived">
                        <button
                          *ngIf="!rowData.closed && primaryRole !== 'Utente'"
                          type="button"
                          pButton
                          pTooltip="Archivia"
                          tooltipPosition="top"
                          (click)="archive(rowData)"
                          class="btn btn-warning btn-sm"
                          icon="fa fa-folder"
                        ></button>
                      </ng-template>

                      <button
                        type="button"
                        pButton
                        (click)="view(rowData)"
                        class="btn btn-info btn-sm"
                        pTooltip="Visualizza"
                        tooltipPosition="top"
                        icon="fa fa-eye"
                      ></button>

                      <button
                        type="button"
                        pButton
                        (click)="addCatalogues(rowData.codiceGara)"
                        tooltipPosition="top"
                        pTooltip="Gestione Cataloghi"
                        icon="fa fa-th-list"
                        class="btn btn-primary btn-sm"
                      ></button>

                      <button
                        type="button"
                        pButton
                        (click)="addArticles(rowData.codiceGara)"
                        tooltipPosition="top"
                        pTooltip="Gestione Articoli"
                        icon="fa fa-file-text"
                        class="btn btn-primary btn-sm"
                      ></button>

                      <button
                        type="button"
                        pButton
                        pTooltip="Clona"
                        tooltipPosition="top"
                        (click)="clone(rowData)"
                        class="btn btn-default btn-sm"
                        icon="fa fa-clone"
                      ></button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
