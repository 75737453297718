import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PrintTemplateService } from '../../service/print-template.service';

import { AbstractListComponent } from '../../common/abstract-list-component';
import {
    PrintTemplate,
    PrintTemplateType,
    printTemplateTypeMap,
    printTemplateTypeAsItems,
} from '../../model/print-template';
import { takeUntil, first } from 'rxjs/operators';
import { Table } from 'primeng/table/table';
@Component({
    selector: 'app-print-template-list',
    templateUrl: './print-template-list.component.html',
    styleUrls: ['./print-template-list.component.css'],
})
export class PrintTemplateListComponent extends AbstractListComponent<PrintTemplate>
    implements OnInit {
    element: PrintTemplate;
    printTemplateTypeMap: Map<PrintTemplateType, string> = printTemplateTypeMap;
    printTemplateTypeAsItems = [...printTemplateTypeAsItems];
    archivedFilter = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected printTemplateService: PrintTemplateService
    ) {
        super(router, printTemplateService, 'templates');
    }

    ngOnInit() {
        this.loading = true;
        this.element = new PrintTemplate();
        this.archivedFilter = this.service.search.obj.active === false;
    }

    newElement(): PrintTemplate {
        return super.newElement();
    }

    delete(element: PrintTemplate) {
        this.service
            .delete(element.uuid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((del) => this.loaddata(true));
    }

    print(uuidTemplate: string) {
        const mimeTypes = {
            'application/pdf': 'pdf',
            'application/excel': 'xls',
            'text/csv': 'csv',
        };

        this.printTemplateService
            .getPdf(uuidTemplate)
            .pipe(first())
            .subscribe(
                (file: Blob) => {
                    const fileUrl = window.URL.createObjectURL(file);

                    if (file.type === 'application/pdf') {
                        window.open(fileUrl);
                    } else {
                        const fileLink = document.createElement('a');
                        fileLink.href = fileUrl;
                        fileLink.download = `${uuidTemplate}.${mimeTypes[file.type]}`;
                        fileLink.click();
                    }
                },
                (err) => {
                    this.service.addMessage({
                        severity: 'error',
                        summary: 'Errore',
                        detail: err.message,
                    });
                }
            );
    }

    clone(printable) {
        this.router.navigate(['/' + this.path + '/clone/' + printable.uuid]);
    }

    public new() {
        this.router.navigate(['/' + this.path + '/new']);
    }

    getId() {
        return this.element.uuid;
    }

    reload(datatable: Table) {
        if (!this.service.search.obj.default_template)
            this.service.search.obj.default_template = null;
        super.reload(datatable);
    }

    createInstance(): PrintTemplate {
        return new PrintTemplate();
    }

    onFilterArchived(event: any) {
        this.archivedFilter = event.checked;
        this.service.search.obj.active = event.checked ? false : null;
    }
}
