import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractService } from '../common/abstract-service';
import { Search } from '../common/search';
import { SystemEventLog } from '../model/system-event-log';
import { SYSTEM_EVENT_LOG_API_PATH } from '../constants/constants';

import { MessageService } from 'primeng/api';
import { ConfigurationService } from './configuration.service';

@Injectable({
    providedIn: 'root',
})
export class SystemEventLogService extends AbstractService<SystemEventLog> {
    constructor(
        httpClient: HttpClient,
        messageService: MessageService,
        configurationService: ConfigurationService
    ) {
        super(configurationService.getValue(SYSTEM_EVENT_LOG_API_PATH), httpClient, messageService);
    }

    public getId(element: SystemEventLog) {
        return element.uuid;
    }

    public buildSearch() {
        this.search = new Search<SystemEventLog>(SystemEventLog);
    }
}
