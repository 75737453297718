import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { statesAsArray } from '../../model/enum/state';
import { AbstractListComponent } from '../../common/abstract-list-component';
import { SelectItem } from 'primeng/api';
import { Attachment } from 'src/app/model/attachment';
import { AttachmentService } from 'src/app/service/attachment.service';

@Component({
    selector: 'app-existing-images-list',
    templateUrl: './existing-images-list.component.html',
    styleUrls: ['./existing-images-list.component.css'],
})
export class ExistingImagesListComponent extends AbstractListComponent<Attachment>
    implements OnInit {
    @Output() selectImage = new EventEmitter();

    stateItems: SelectItem[] = [];

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected attachmentService: AttachmentService
    ) {
        super(router, attachmentService, '');
    }

    ngOnInit() {
        statesAsArray.map((s) => {
            this.stateItems.push({ label: s, value: s });
        });
        this.attachmentService.search.like.mime_type = 'image';
    }

    imageClicked(event) {
        this.selectImage.emit(event);
    }
}
